import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type {
  SavedStyleColorAttributes,
  SavedStyleColorType,
} from "schemas/generated/savedStyles";

import * as React from "react";

import { ColorSavedStyleModifier } from "@editor/components/designLibrary/ColorSavedStyleModifier";
import SavedStyleItemEndEnhancer from "@editor/components/designLibrary/SavedStyleItemEndEnhancer";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import { SavedStyleSkeleton } from "@editor/components/designLibrary/SavedStyleSkeleton";
import useDeleteDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useDeleteDesignLibrarySavedStyle";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { trpc } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { v4 as uuidv4 } from "uuid";

import { COLOR_DEFAULTS, COLOR_MENU_ITEMS } from "../constants/colorStyles";

type ColorStyleGroupProps = {
  overrideSavedStyles?: SavedColorStyle[];
  isLoading?: boolean;
};

export const ColorStyleGroup: React.FC<ColorStyleGroupProps> = ({
  overrideSavedStyles,
  isLoading = false,
}) => {
  const [isColorModifierPopoverOpen, setIsColorModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const isEditable = !Boolean(overrideSavedStyles);
  const { colorSavedStyles } = useGetDesignLibrarySavedStyles();
  const projectId = useCurrentProjectId();
  const utils = trpc.useUtils();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void utils.designLibrary.get.invalidate();
      },
    });

  const generateNewName = (colorType: SavedStyleColorType): string => {
    const name = COLOR_MENU_ITEMS.find(
      (item) => item.colorType === colorType,
    )?.title;

    const baseName = name ?? String(colorType);
    const existingNumbers = savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/\d+$/);
        return match ? Number.parseInt(match[0], 10) : 0;
      });
    const highestNumber = Math.max(0, ...existingNumbers);
    return `${baseName} ${highestNumber + 1}`;
  };

  const handleItemClick = async (colorType: SavedStyleColorType) => {
    if (!projectId || !isEditable) {
      return null;
    }

    const newName = generateNewName(colorType);

    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: {
        type: "color",
        color: COLOR_DEFAULTS[colorType] ?? "#000000",
        colorType,
      } as SavedStyleColorAttributes,
    };
    setIsColorModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
    await createStyleMutation({
      projectId,
      type: "color",
      ...savedStyleToCreate,
    });
  };

  const menuItems = COLOR_MENU_ITEMS.map(({ colorType, title }) => ({
    id: colorType,
    title,
    onSelect: () => void handleItemClick(colorType),
    type: "leaf" as const,
    isDisabled: false,
  }));

  const savedStyles = overrideSavedStyles ?? colorSavedStyles;

  return (
    <>
      {isEditable && (
        <ColorSavedStyleModifier
          isPopoverOpen={isColorModifierPopoverOpen}
          onPopoverOpenChange={setIsColorModifierPopoverOpen}
          editingSavedStyle={
            colorSavedStyles.find(
              (savedStyle) => savedStyle.id === editingSavedStyleId,
            ) ?? null
          }
        />
      )}
      <ModifierGroup
        title="Color Styles"
        tooltipText="Add new color style"
        isDefaultOpen={savedStyles.length > 0 || isLoading}
        isCollapsible={savedStyles.length > 0}
        menuItems={isEditable ? menuItems : undefined}
      >
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <SavedStyleSkeleton />
          ) : (
            savedStyles.map((savedStyle, index) => (
              <div
                key={index}
                className="flex items-center gap-2 px-2 py-1 hover:bg-hover cursor-pointer rounded"
              >
                <SavedStylePaneRow
                  type="color"
                  savedStyle={savedStyle}
                  setEditingSavedStyleId={(id) => {
                    setEditingSavedStyleId(id);
                    setIsColorModifierPopoverOpen(true);
                  }}
                />
              </div>
            ))
          )}
        </div>
      </ModifierGroup>
    </>
  );
};

const SavedStylePaneRow: React.FC<{
  type: "color";
  savedStyle: SavedColorStyle & { id?: string };
  setEditingSavedStyleId: (id: string | null) => void;
}> = ({ type, savedStyle, setEditingSavedStyleId }) => {
  const { deleteSavedStyle, isLoading } = useDeleteDesignLibrarySavedStyle();

  return (
    <SavedStyleRow
      name={savedStyle.name}
      value={savedStyle.attributes.color}
      type={type}
      isLoading={isLoading}
      endEnhancer={
        // NOTE (Fran 2024-11-14): In the case we were showing the imported or duplicated saved styles,
        // we don't have an id. And we don't want to show the end enhancer in that case, because they
        // are not editable.
        savedStyle.id && (
          <SavedStyleItemEndEnhancer
            type={type}
            savedStyleAttributes={savedStyle.attributes}
            name={savedStyle.name}
            id={savedStyle.id}
            deleteSavedStyle={deleteSavedStyle}
            setEditingSavedStyleId={setEditingSavedStyleId}
          />
        )
      }
    />
  );
};
