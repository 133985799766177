export const shopifyHeaderSelector =
  // Note (Noah, 2022-08-18, REPL-3665): The shopify-section-header-classic
  // is here to get around an issue with Tusol, though I think this id is common
  // to use for header sections on the theme that Tusol's is based on. We should
  // probably have a more generic system for injecting certain CSS into the theme
  // on a per-store basis
  [
    "header",
    "#shopify-section-header",
    "#shopify-section-header-classic",
    // Note (Noah, 2022-11-29, REPL-5315): This selector comes from a store called Angelinos, uid 37f2f813-b4d1-4de4-93ca-d7e5c9e3421c
    ".main_nav",
    // Note (Noah, 2022-11-29, REPL-5316): This one's from "Remi", uid 07d0ec8e-fa94-4442-b2f4-74d33a970ae9
    ".main-nav",
    // Note (Noah, 2022-12-07, REPL-5490): This one's from "Bolde Bottle", uid 431c4689-55e9-4528-82e7-a2ba977356e8 - I think
    // this is common for Udesly themes
    ".navbar",
    // Note (Noah, 2022-12-17, REPL-5526): This one's from Flighty, uid a359c5bf-1749-4867-84c8-37eea231b9ab,
    // but it's also part of the default Flex theme
    // https://community.shopify.com/c/shopify-design/flex-page-details-page-headers-and-footers/td-p/1137315
    "#mobile-header-sticky-wrapper",
    // Note (Noah, 2022-12-18): From "Pearly", uid aece440a-6f8f-4e56-955d-b4edbec291e2
    ".nav",
    // Note (Sebas, 2023-02-08): From "Hushed Socks", uid e1a87b0a-196a-44b0-ba18-61ea0eb0140e
    ".header-wrapper",
    // Note (Sebas, 2023-02-10): From "Walkfulton", uid d9b6ca3d-8127-41ca-9d93-0370ac24fc8b
    "[data-site-header]",
    // Note (Fran, 2023-02-15): From "Nolaskinsentialss", uid 23b19efa-b433-446f-9d7e-6aac7c7fc041
    "#shopify-section-static-header",
    // Note (Fran, 2023-05-11): From "Promix", uid 59d82d7b-c06b-4cbb-bec5-0d2aed10fbe1
    "#header",
    // Note (Noah, 2023-08-22, REPL-8280): From "Kayode Pet", uid aeae8385-2331-469b-8eab-41b67357f8cc
    ".main-header",
    "#site-header",
    // NOTE (Gabe 2023-08-24, USE-378): From "Pheroe"
    ".shopify-section-group-header-group",
  ].join(", ");
// HACK (Noah, 2022-08-18, REPL-3629): The shopify-section-banner-image
// is here to get around an issue with Tea Box where they have this random Shopify
// section in their footer but it's not actually inside a <footer>. This is a major
// hack, we need to implement per-store CSS ASAP
export const shopifyFooterSelector = [
  // Note (Noah, 2022-01-17, REPL-5886): Some themes (e.g. Focal) have a <footer> element in the mini
  // cart. We don't want to hide that, so we need to exclude it from this selector. This isn't great
  // because it's not a perfect solution, but because we support using Replo on an arbitrary theme I
  // don't ever thing we can have a perfect solution here. This "cart-drawer footer" selector is specifically
  // for Focal.
  "footer:not(cart-drawer footer)",
  "#shopify-section-footer",
  "#shopify-section-banner-image",
  // Note (Noah, 2022-12-07, REPL-5490): This one's from "Bolde Bottle", uid 431c4689-55e9-4528-82e7-a2ba977356e8 - I think
  // this is common for Udesly themes
  ".footer-section",
  // Note (Sebas, 2022-12-28, REPL-5729): This one's from "Beauty By Earth Natural Beauty" store, uid b4cb076e-1e5a-42d1-a57f-18ed19f7efa6.
  "#shopify-section-new-footer",
  // Note (Sebas, 2023-02-10): From "Walkfulton", uid d9b6ca3d-8127-41ca-9d93-0370ac24fc8b
  "#shopify-section-layout-footer",
  // Note (Noah, 2023-04-26): From "Saguaro Bloom", uid 437c01d9-5588-41a5-b3be-5e351bad3041
  `[id^="shopify-section-footer"]`,
  // Note (Fran, 2023-05-11): From "Promix", uid 59d82d7b-c06b-4cbb-bec5-0d2aed10fbe1
  "#footer",
  // Note (Noah, 2023-08-22, REPL-8280): From "Kayode Pet", uid aeae8385-2331-469b-8eab-41b67357f8cc
  ".main-footer",
  // NOTE (Gabe 2023-08-24, USE-378): From "Pheroe", uid 41f50dda-460e-4a1e-aac5-d45210e4e1f4
  ".shopify-section-group-footer-group",
].join(", ");

// Special case for announcement bar on Kaged Store (REPL-5185)
const scrollerAnnouncementBarSelector =
  "#shopify-section-header + #shopify-section-text-scroller";

// Note (Noah, 2022-11-18): We try to cover as many theme announcement bar selectors
// here as possible. It's usually shopify-section-announcement-bar, but sometimes
// different themes use other selectors.
export const shopifyAnnouncementBarSelector = [
  "#shopify-section-announcement-bar",
  ".announcement-bar",
  "#announcement",
  "#shopify-section-announcement",
  ".header-promo",
  ".announcement_bar",
  // Note (Noah, 2022-12-18): From "Pearly", uid aece440a-6f8f-4e56-955d-b4edbec291e2
  ".nav__banner",
  // Note (Noah, 2022-12-20): From "Parker Baby", uid fd2d77bf-3184-4a2d-a112-32899ba1b270
  // Note (Noah, 2023-08-28): For Caraway, they actually have a "promo-bar" class on their
  // body element :facepalm: I'm not really sure what the right thing to do is in this case
  // - we could try to narrow down the announcement bar selector to only apply to certain
  // elements? But that seems like it could be fragile. For now I'm just going to exclude
  // body elements to solve this case
  ":not(body).promo-bar",
  // Note (Noah, 2023-01-21): From "Feel goods", uid f1254509-8805-4ad5-b267-de01edab982e
  "#shopify-section-header-notice",
  // Note (Noah, 2023-01-26): From "tumlove", uid d3e79658-0415-4c90-87e5-c00305f76016
  "#shopify-section-announcement_bar",
  scrollerAnnouncementBarSelector,
  // Note (Fran, 2023-02-15): From "Nolaskinsentialss", uid 23b19efa-b433-446f-9d7e-6aac7c7fc041
  "#shopify-section-announcement-slider",
  // NOTE (Yuxin, 2023-03-30): For https://chamberlaincoffee.com/
  "#shopify-section-header-topbar",
  // NOTE (Yuxin, 2023-03-30): For https://chamberlaincoffee.com/
  "#shopify-section-top-countdown",
  // NOTE (Gabe 2023-09-18, USE-427): For https://gir.com, uid 5d22d554-8ae1-45eb-a588-823fd05dadb0
  "#shopify-section-message-butter-bar",
].join(", ");

export const fullPageQuerySelector =
  "#alchemy-fullpage-element, #replo-fullpage-element";

export const modalMountPointQuerySelector = "#alchemy-modal-mount-point";
