import type { Announcement } from "@editor/types/sanity";
import type { AnnouncementsModalProps } from "../AppModalTypes";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { SanityComponents } from "@editor/sanity/sanityComponents";

import { PortableText } from "@portabletext/react";
import Button from "@replo/design-system/components/button";
import { RiArrowRightLine } from "react-icons/ri";
import { z } from "zod";

export const AnnouncementsModal: React.FC<AnnouncementsModalProps> = ({
  announcements,
}) => {
  return announcements.length === 0 ? null : (
    <AnnouncementsModalImpl
      // NOTE (Chance 2023-12-06): We've confirmed that we have at least one
      // announcement so we can safely cast this. This wrapper is primarily to
      // make hooks less awkward when lots of values could be empty if we don't
      // have anything to show.
      announcements={announcements as [Announcement, ...Announcement[]]}
    />
  );
};

const AnnouncementsModalImpl: React.FC<{
  announcements: [Announcement, ...Announcement[]];
}> = ({ announcements }) => {
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] =
    React.useState(0);
  const { closeModal } = useModal();
  const logEvent = useLogAnalytics();
  const currentAnnouncement = announcements[currentAnnouncementIndex];
  const [annoucementSeenDate, setAnnouncementSeenDate] = useLocalStorageState(
    currentAnnouncement?.postHogFeatureFlag
      ? `replo.${currentAnnouncement.postHogFeatureFlag}`
      : null,
    null,
    { schema: z.string().nullable() },
  );

  React.useEffect(() => {
    if (currentAnnouncement && !annoucementSeenDate) {
      const currentDate = new Date().toString();
      setAnnouncementSeenDate(currentDate);
      logEvent("user.announcement.viewed", {
        announcementFlag: currentAnnouncement.postHogFeatureFlag,
      });
    }
  }, [
    annoucementSeenDate,
    currentAnnouncement,
    logEvent,
    setAnnouncementSeenDate,
  ]);

  if (announcements.length === 0) {
    return null;
  }

  const isLastAnnouncement =
    currentAnnouncementIndex === announcements.length - 1;

  const onButtonClick = () => {
    if (isLastAnnouncement) {
      closeModal({ type: "announcementsModal" });
    } else {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    }
  };
  const content = announcements[currentAnnouncementIndex]?.content;

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {
        closeModal({ type: "announcementsModal" });
      }}
      className="w-auto"
      testId="announcements-modal"
    >
      <ModalLayout
        height="auto"
        width="25vw"
        minWidth={400}
        layoutClassnames="p-0 gap-2"
        mainContent={() => (
          <div className="mt-5 overflow-y-auto no-scrollbar">
            <PortableText value={content} components={SanityComponents} />
          </div>
        )}
        mainContentClassnames="mb-0 max-h-[82vh]"
        footerContentClassnames="pt-2"
        footerContent={() => (
          <div className="flex items-center gap-2">
            <span className="text-xs text-slate-400">
              {currentAnnouncementIndex + 1} of {announcements.length}
            </span>
            <Button
              variant="tertiary"
              size="base"
              className="hover:bg-slate-10 bg-slate-100"
              textClassNames="text-blue-600 font-medium text-xs"
              onClick={onButtonClick}
              endEnhancer={
                !isLastAnnouncement
                  ? () => (
                      <RiArrowRightLine size={16} className="text-blue-600" />
                    )
                  : undefined
              }
            >
              {isLastAnnouncement ? "Complete" : "Next"}
            </Button>
          </div>
        )}
      />
    </Modal>
  );
};

export default AnnouncementsModal;
