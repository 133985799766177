import type { ComponentActionType } from "@editor/types/component-action-type";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";

import * as React from "react";

import * as coreActions from "@actions/core-actions";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";

/**
 * NOTE (Chance 2024-06-14): Not every action receives the `activeCanvas`, but
 * many do, so we can pass it along to every action as an implicit dependency to
 * simplify use in editor components. No harm in passing it so long as other
 * actions don't take `activeCanvas` as some other type.
 */
export type UseApplyComponentActionType<
  T extends ComponentActionType = ComponentActionType,
> = T extends { type: "applyCompositeAction" }
  ? T extends { activeCanvas: any }
    ? Omit<T, "value" | "activeCanvas"> & {
        value: UseApplyComponentActionType[];
        activeCanvas?: never;
      }
    : Omit<T, "value"> & { value: UseApplyComponentActionType[] }
  : T extends { activeCanvas: any }
    ? Omit<T, "activeCanvas"> & { activeCanvas?: never }
    : T;

export function useApplyComponentAction() {
  const dispatch = useEditorDispatch();
  const activeCanvas = useEditorSelector(selectActiveCanvas);

  const applyComponentAction = React.useCallback(
    (action: UseApplyComponentActionType<ComponentActionType>) => {
      return dispatch(
        coreActions.applyComponentAction(
          forwardCanvasToAction(action, activeCanvas),
        ),
      );
    },
    [dispatch, activeCanvas],
  );

  return applyComponentAction;
}

export default useApplyComponentAction;

export function forwardCanvasToAction(
  action: UseApplyComponentActionType | ComponentActionType,
  activeCanvas: EditorCanvas,
): ComponentActionType {
  if (action.type === "applyCompositeAction") {
    const childActions = action.value;
    return {
      ...action,
      activeCanvas,
      value: childActions.map((childAction) => {
        return forwardCanvasToAction(childAction, activeCanvas);
      }),
    };
  }
  // Note (Evan, 2024-07-09): If an action specifies an active canvas, keep it.
  return { activeCanvas, ...action } as ComponentActionType;
}
