import type { SanityImageSource } from "@editor/sanity/imageBuilder";
import type {
  ButtonSize,
  ButtonVariant,
} from "@replo/design-system/components/button/button-shared";

import * as React from "react";

import { urlForImage } from "@editor/sanity/imageBuilder";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { BsArrowRight } from "react-icons/bs";
import { slugify } from "replo-utils/lib/string";

type PropsWithValue<V, T = {}> = { value: V } & T;

type SanityImageProps = {
  image: SanityImageSource;
};

export const SanityComponents = {
  types: {
    lineBreak: () => <br />,
    image: ({ value }: PropsWithValue<SanityImageProps["image"]>) => {
      const imageSource = urlForImage(value);
      return <img alt={value.alt} src={imageSource} className="object-cover" />;
    },
    youtubeEmbedVideo: ({
      value: { url },
    }: PropsWithValue<{ url: string }>) => (
      <div className="my-4 aspect-video">
        <iframe
          id="player"
          className="relative mx-auto max-w-fit lg:max-w-none"
          width="100%"
          height="100%"
          // Note (Juan, 03-06-2023): The code below is designed to extract the Video ID from a standard YouTube URL.
          // Its purpose is to facilitate the process of adding URLs for the growth team by eliminating the need to manually obtain the ID.
          src={`https://www.youtube.com/embed/${
            url.match(
              /youtube(?:-nocookie)?\.com\/(?:[^\s/]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[&?]v=)([\w-]{11})(?:\S*|$)/,
            )?.[1]
          }//example.com`}
        ></iframe>
      </div>
    ),
    buttonCTA: ({
      value: { href, type, text, size, width = false, arrowRight, isRounded },
    }: PropsWithValue<{
      href: string;
      type: ButtonVariant;
      text: string;
      size: ButtonSize;
      width: boolean;
      arrowRight: boolean;
      isRounded: boolean;
    }>) => (
      <div className="my-2">
        <Button
          href={href}
          target="_blank"
          variant={type}
          size={size}
          className={classNames({ "w-full": width })}
          endEnhancer={
            arrowRight
              ? () => (
                  <BsArrowRight
                    color={type === "primary" ? "white" : "black"}
                    size={20}
                    className="ml-1"
                  />
                )
              : undefined
          }
          isRounded={isRounded}
        >
          {text}
        </Button>
      </div>
    ),
  },
  marks: {
    link: ({
      text,
      value,
    }: {
      text: string;
      value?: {
        href: string;
      };
    }) => {
      const isReplo =
        value?.href.includes("replo.app") || value?.href.startsWith("#");
      return (
        <a
          {...value}
          target={isReplo ? undefined : "_blank"}
          rel={isReplo ? undefined : "nofollow"}
          className="hover:underline text-blue-600"
        >
          {text}
        </a>
      );
    },
  },
  list: {
    bullet: ({ children }: React.PropsWithChildren) => (
      <ul style={{ listStyleType: "disc" }} className="my-2 pl-8 text-lg">
        {children}
      </ul>
    ),
    number: ({ children }: React.PropsWithChildren) => (
      <ol style={{ listStyleType: "auto" }} className="my-2 pl-8 text-lg">
        {children}
      </ol>
    ),
  },
  block: {
    h1: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h1 className="text-3xl font-medium py-2" id={slug}>
          {children}
        </h1>
      );
    },
    h2: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h2 className="text-xl font-bold py-2" id={slug}>
          {children}
        </h2>
      );
    },
    h3: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h3 className="text-lg font-bold py-2" id={slug}>
          {children}
        </h3>
      );
    },
    h4: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h4 className="text-lg font-bold py-2" id={slug}>
          {children}
        </h4>
      );
    },
    h5: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h5 className="text-base font-bold py-2" id={slug}>
          {children}
        </h5>
      );
    },
    h6: ({ children }: React.PropsWithChildren) => {
      const slug =
        typeof children === "string"
          ? slugify(children.toLowerCase())
          : undefined;
      return (
        <h6 className="text-base font-bold py-2" id={slug}>
          {children}
        </h6>
      );
    },
    normal: ({ children }: React.PropsWithChildren) => <p>{children}</p>,
    blockquote: ({ children }: React.PropsWithChildren) => (
      <blockquote className="border-l-2 border-black pl-4">
        {children}
      </blockquote>
    ),
  },
};
