import type { Hotkey } from "@editor/utils/getHotKey";
import type { Position } from "replo-runtime/shared/types";
import type { LengthValueType } from "replo-runtime/shared/utils/units";
import type {
  PreviewableProperty,
  PreviewableSubProperty,
} from "schemas/preview";

import * as React from "react";

import Input from "@editor/components/common/designSystem/Input";
import { useGetAttribute } from "@editor/hooks/useGetAttribute";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { selectDraftComponent } from "@editor/reducers/core-reducer";
import { setDraggingCursor } from "@editor/reducers/drag-and-drop-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import {
  DraggingDirections,
  DraggingTypes,
  getCursor,
} from "@editor/utils/editor";
import { getHotKey } from "@editor/utils/getHotKey";
import { useInCanvasPreview } from "@editor/utils/preview";

import classNames from "classnames";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { CSS_LENGTH_TYPES, parseUnit } from "replo-runtime/shared/utils/units";
import { parseFloat, round } from "replo-utils/lib/math";
import { isNotNullish } from "replo-utils/lib/misc";
import { useRequiredContext } from "replo-utils/react/context";
import { useEffectEvent } from "replo-utils/react/use-effect-event";
import { twMerge } from "tailwind-merge";

export type InputOption = {
  label: string;
  value: string | null;
  isDisabled?: boolean;
};

export type LengthInputModifierHotkey = "shiftKey" | "altKey";

type KeyEvent = {
  altKey: boolean;
  shiftKey: boolean;
  key?: string | undefined;
};

type DragTrigger = "label" | "startEnhancer" | "none";

/**
 * TODO (Noah, 2022-03-01, REPL-1038): We should actually have a real mapping
 * of the actual unit types like "%": number, "px": number etc here,
 * but we don't have an actual union type for the units yet
 */
type LengthInputBounds = Record<string, number>;
type CommonProps = {
  /** Functions to do something when the input is dragging */
  onDragEnd?(): void;
  onDragStart?(): void;
  /** Function to do something when you need to preview the change */
  onPreviewChange?(value: any, hotkey: LengthInputModifierHotkey | null): void;
  /** Function to do something when the value of the input changes (Required) */
  onChange(value: any, hotkey: LengthInputModifierHotkey | null): void;
  /** Function to use to transform the value in a custom way */
  transformValue?(value: any): any;
  /** Strings or icons inside the input */
  startEnhancer?(): React.ReactNode;
  endEnhancer?(): React.ReactNode;
  /** Label of the input that will show on the left side of the input */
  label?: React.ReactNode;
  /** Name of the field used, useful in the modifier version. */
  field: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  inputClassName?: string;
  backgroundColor?: string;
  placeholder?: string;
  /** Allow the dragging vertical or horizontal. */
  draggingType?: DraggingTypes;
  /** Allow dragging positive or negative. */
  draggingDirection?: DraggingDirections;
  /**
   * Part of the input from the drag is allowed, we should use "label" on most
   * cases.
   */
  // TODO (Sebas, 2024-09-09): Once we migrated to use the label trigger we should
  // remove the startEnhancer option.
  dragTrigger?: DragTrigger;
  /** Name of property to preview in the canvas */
  previewProperty?: PreviewableProperty;
  /** Name of properties to preview in the canvas when pressing the shift key */
  previewablePropertiesForShift?: PreviewableProperty[];
  /** Name of properties to preview in the canvas when pressing the option key */
  previewablePropertiesForOption?: PreviewableProperty[];
  /**
   * Name of a sub-property that is a part of parent property. e.g: rotateZ is
   * a sub-property of the transform property.
   */
  previewSubProperty?: PreviewableSubProperty;
  /**
   * Index of the property we want to preview. This is useful for properties
   * that are composed with multiple values, like box-shadow.
   */
  previewPropertyIndex?: number;
  /** Props to allow metrics/units for the input value */
  metrics?: string[];
  unitDefaults?: LengthValueType;
  allowsNegativeValue?: boolean;
  /**
   * Value to reset the input. This value will be passed to onSubmit when the input
   * is option-clicked. If this is undefined (or not passed), nothing will happen on
   * option-click. If this is null, onSubmit will be called with null when option-clicked.
   */
  resetValue?: string | null;
  /** Value from the drag start if there is no value */
  anchorValue?: string;
  /** Options to use as preset values */
  menuOptions?: InputOption[];
  /** Values to use as min and max when you type a value. Uses clampMaxMin function */
  maxValues?: LengthInputBounds;
  minValues?: LengthInputBounds;
  /** Values to use as min and max when you drag the input. Uses clampMaxMin function */
  maxDragValues?: LengthInputBounds;
  minDragValues?: LengthInputBounds;
  autofocus?: boolean;
  /** Allowable strings for values */
  allowedNonUnitValues?: string[];

  /**
   * The value of the input on mount. The input will be controlled by this value until the
   * user changes it. If the user changes it, the input will be uncontrolled until this component
   * is rerendered with a new value for `value`.
   */
  value?: string | null;
};

type LengthInputModifierProps = CommonProps;

type LengthInputSelectorProps = CommonProps;

const PARSE_UNIT_DEFAULTS = { value: "", unit: "" };

function useOverridableRef<T>(value: T) {
  const ref = React.useRef<T>(value);

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
}

type LengthInputSelectorContext = {
  onMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  dragTrigger: CommonProps["dragTrigger"];
  draggingType: CommonProps["draggingType"];
  draggingDirection: CommonProps["draggingDirection"];
  field: CommonProps["field"];
  isDisabled: boolean;
  onSubmit: (args: {
    value: string | null;
    currentlyPressedHotkey: LengthInputModifierHotkey | null;
    shouldBlur: boolean;
  }) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  inputValue: string;
  setInputValue: (value: string) => void;
  parseValue: (value: string | number, unit?: string) => string;
  isMouseDown: boolean;
  enablePreviewCss: (event: KeyEvent, force?: boolean) => void;
  disablePreviewCss: (event: KeyEvent) => void;
  setPreviewCss: (event: KeyEvent, value: string) => void;
  metrics: CommonProps["metrics"];
  allowedNonUnitValues: CommonProps["allowedNonUnitValues"];
  unitDefaults: CommonProps["unitDefaults"];
  maxValues: CommonProps["maxValues"];
  minValues: CommonProps["minValues"];
  defaultUnit: string;
  currentUnit: string;
  anchorValue: CommonProps["anchorValue"];
  isFocused: boolean;
  setIsFocused: (value: boolean) => void;
  isDragging: boolean;
  isCurrentlySubmittingValueRef: React.RefObject<boolean>;
  resetValue: () => void;
};
const LengthInputSelectorContext =
  React.createContext<LengthInputSelectorContext | null>(null);

const LengthInputSelectorRoot: React.FC<
  React.PropsWithChildren<
    Omit<LengthInputSelectorProps, LengthInputSelectorPropsNeededForInput>
  >
> = (props) => {
  const internalInputRef = React.useRef<HTMLInputElement | null>(null);
  const inputRef: React.RefObject<HTMLInputElement> =
    props.inputRef ?? internalInputRef;
  const {
    enableCanvasPreviewCSSProperties,
    disableCanvasPreviewCSSProperties,
    setPreviewCSSPropertyValue,
  } = useInCanvasPreview();

  const defaultUnit = props.metrics?.[0] ?? "px";

  const { value: initialValue, unit: currentUnit } = parseUnit(
    props.value ?? null,
    props.unitDefaults || PARSE_UNIT_DEFAULTS,
    props.field,
    defaultUnit,
    undefined,
    props.metrics,
    props.allowedNonUnitValues,
  );

  // NOTE (Fran, 2022-08-03): We have 2 values, the input value is the value used
  // to show in the input and the current value to use when we need to update
  // the value without submiting it.
  const firstInputValue = `${initialValue}${currentUnit}`;
  const resolvedInputValue =
    firstInputValue === props.resetValue ? "" : firstInputValue;
  const currentValueRef = useOverridableRef(resolvedInputValue);
  const [inputValue, setInputValue] = useOverridableState(resolvedInputValue);
  const [lastDragStart, setLastDragStart] = React.useState<Position | null>(
    null,
  );
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const isCurrentlySubmittingValueRef = React.useRef(false);
  const [lastHotkey, setLastHotkey] = React.useState<Hotkey | "none">(null);
  // Note (Sebas, 2022-11-17): This state is for detecting if we have to
  // run enableCanvasPreviewCSSProperties() or if we should run
  // setPreviewCSSPropertyValue() instead when pressing arrow keys
  const [isPreviewEnabled, setIsPreviewEnabled] = React.useState(false);
  const dispatch = useEditorDispatch();

  const actions = {
    altKey:
      props.previewablePropertiesForOption ?? [props.previewProperty] ?? [],
    shiftKey:
      props.previewablePropertiesForShift ?? [props.previewProperty] ?? [],
    none: props.previewProperty ? [props.previewProperty] : [],
  };

  const enablePreviewCss = (event: KeyEvent, force?: boolean) => {
    if ((!isPreviewEnabled || force) && props.previewProperty) {
      setIsPreviewEnabled(true);
      const hotkey = getHotKey(event);
      const hotKeyOrNone = hotkey ?? "none";
      enableCanvasPreviewCSSProperties(
        actions[hotKeyOrNone] as PreviewableProperty[],
        currentValueRef.current || `0${defaultUnit}`,
        props.previewSubProperty,
        props.previewPropertyIndex,
      );
      props.onPreviewChange?.(
        currentValueRef.current || `0${defaultUnit}`,
        hotkey,
      );
      setLastHotkey(hotKeyOrNone);
    }
  };

  const setPreviewCss = (event: KeyEvent, value: string) => {
    if (props.previewProperty) {
      const hotkey = getHotKey(event);
      const hotKeyOrNone = hotkey ?? "none";
      // Note (Sebas, 2022-11-22): In case the user switches between
      // hotkeys without releasing the click we need to run enablePreviewCss
      // again to update to the correct previewable properties
      if (hotKeyOrNone !== lastHotkey) {
        enablePreviewCss(event, true);
      } else {
        setPreviewCSSPropertyValue(
          actions[hotKeyOrNone] as PreviewableProperty[],
          value ?? (currentValueRef.current || `0${defaultUnit}`),
          props.previewSubProperty,
          props.previewPropertyIndex,
        );
      }
      props.onPreviewChange?.(
        value ?? (currentValueRef.current || `0${defaultUnit}`),
        hotkey,
      );
      setLastHotkey(hotKeyOrNone);
    }
  };

  const disablePreviewCss = (event: KeyEvent) => {
    if (props.previewProperty && isPreviewEnabled) {
      const hotkey = getHotKey(event);
      disableCanvasPreviewCSSProperties(
        actions[hotkey ?? "none"] as PreviewableProperty[],
      );
      setLastHotkey(null);
      setIsPreviewEnabled(false);
    }
  };

  const {
    draggingType = DraggingTypes.Vertical,
    draggingDirection,
    maxValues,
    minValues,
    maxDragValues,
    minDragValues,
  } = props;

  const shouldAllowNegativeValue = props.allowsNegativeValue ?? true;

  /**
   * Used to parse the value in the current unit.
   *
   * @private
   */
  const parseValue = (value: string | number, unit?: string) => {
    if (Number.isNaN(Number.parseFloat(`${value}`))) {
      return `${clampMinMax(
        value,
        maxValues?.[currentUnit],
        minValues?.[currentUnit],
      )}`;
    }

    const { unit: parsedUnit } = parseUnit(
      String(value),
      props.unitDefaults || PARSE_UNIT_DEFAULTS,
      props.field,
      defaultUnit,
      undefined,
      props.metrics,
      props.allowedNonUnitValues,
    );

    let unitToApply =
      parsedUnit ?? currentUnit ?? props.unitDefaults?.unit ?? "px";

    if (unit) {
      unitToApply = unit;
    }

    return `${clampMinMax(
      Number.parseFloat(`${value}`),
      maxValues?.[currentUnit],
      minValues?.[currentUnit],
    )}${unitToApply}`;
  };

  /**
   * Used to submit the value in all the use cases. Also fire the onChange prop
   * and blur the input. Also use transformValue prop.
   *
   * @param {string} value
   * @param {string | null} hotkey
   * @param {boolean} shouldBlur
   * @private
   */

  const onSubmit = (args: {
    value: string | null;
    currentlyPressedHotkey: LengthInputModifierHotkey | null;
    shouldBlur: boolean;
  }) => {
    const { value, currentlyPressedHotkey: hotkey, shouldBlur } = args;
    const tokens = parseUnit(
      value,
      props.unitDefaults || PARSE_UNIT_DEFAULTS,
      props.field,
      defaultUnit,
      props.resetValue,
      props.metrics,
      props.allowedNonUnitValues,
    );

    if (
      !shouldAllowNegativeValue &&
      Number.parseFloat(String(tokens.value)) < 0
    ) {
      tokens.value = 0;
    }

    let newInputValue = parseValue(tokens.value, tokens.unit);

    if (props.transformValue) {
      newInputValue = props.transformValue(newInputValue);
    }

    setInputValue(newInputValue);
    if (shouldBlur) {
      setIsFocused(false);
    }

    if (
      newInputValue === currentValueRef.current ||
      (currentValueRef.current &&
        currentValueRef.current === props.resetValue) ||
      (!currentValueRef.current &&
        (newInputValue === props.resetValue ||
          parseFloat(newInputValue) === parseFloat(props.resetValue!)))
    ) {
      return;
    }
    // NOTE (Fran, 2022-07-12): We need to set the current value so if the next
    // time the user try to reset the value or put the same value don't call the
    // onChange again.
    currentValueRef.current = newInputValue;
    props.onChange(newInputValue, hotkey);
    if (shouldBlur) {
      isCurrentlySubmittingValueRef.current = true;
      // NOTE (Fran, 2022-08-03): The blur of the input is only do it in the
      // onSubmit function to prevent double call of this function.
      inputRef.current?.blur();
      isCurrentlySubmittingValueRef.current = false;
    }
  };

  const shouldInputDrag = Boolean(draggingType || draggingDirection);

  /**
   * Access to the prop resetValue and set this value.
   * @private
   */
  const resetValue = () => {
    const { resetValue } = props;
    if (resetValue === undefined) {
      return;
    }

    onSubmit({
      value: resetValue ? parseValue(resetValue) : null,
      currentlyPressedHotkey: null,
      shouldBlur: true,
    });
  };

  /**
   * Used to access and update the value when the user is dragging the input.
   * @private
   */
  const getUpdatedValue = (clientX: number, clientY: number) => {
    const {
      draggingType = DraggingTypes.Vertical,
      draggingDirection = DraggingDirections.Positive,
    } = props;

    // Note (Fran, 2022-04-26): We use || instead of ?? because some
    // of these could be an empty string and is ok, because that is nothing to
    // the input. In this cases we need nothing instead of a value of 0.
    // The empty string is handle in the function parseUnit
    const value = Number.parseFloat(
      `${
        parseUnit(
          currentValueRef.current || props.anchorValue || "0",
          props.unitDefaults || PARSE_UNIT_DEFAULTS,
          props.field,
          defaultUnit,
          props.resetValue,
          props.metrics,
          props.allowedNonUnitValues,
        ).value
      }`,
    );

    if (Number.isNaN(value)) {
      return currentValueRef.current;
    }
    const clientValue =
      draggingType === DraggingTypes.Horizontal ? clientX : clientY;
    const snapshot =
      draggingType === DraggingTypes.Horizontal
        ? lastDragStart?.x ?? 0
        : lastDragStart?.y ?? 0;
    let sign = draggingDirection === DraggingDirections.Negative ? -1 : 1;
    if (draggingType === DraggingTypes.Horizontal) {
      sign *= -1;
    }
    const speed = 0.5;
    return value - sign * Math.floor((clientValue - snapshot) * speed);
  };

  /**
   * Trigger: Fired when the user starts dragging
   * Functionality: Sets the cursor and enables to use the preview field
   * functionality in the canvas.
   * @private
   */
  const onMouseDown = (event: React.MouseEvent) => {
    if (props.isDisabled) {
      return;
    }
    props.onDragStart?.();
    const { draggingDirection, draggingType } = props;

    setIsMouseDown(true);
    setLastDragStart({ x: event.clientX, y: event.clientY });

    if (shouldInputDrag && draggingType) {
      dispatch(setDraggingCursor(getCursor(draggingType, draggingDirection)));
    }
    enablePreviewCss(event);
  };

  const onDragEnd = () => {
    props.onDragEnd?.();
    inputRef.current?.focus();
  };

  /**
   * Have all the logic to finish the drag, disable the preview in the canvas
   * and submit the value
   * @private
   */
  const onMouseUp = useEffectEvent((event: MouseEvent) => {
    const isInputDraggable = shouldInputDrag && isDragging;
    const finishUpdatingState = () => {
      dispatch(setDraggingCursor(null));
      setIsMouseDown(false);
      setIsDragging(false);
    };

    disablePreviewCss(event);

    const hotkey = getHotKey(event);

    // Note (Fran: 2022-09-02): We need to handle the reset with alt key only
    // here to avoid unwanted resetting when we are dragging on the input.
    // Unless we have a startEnhancer, in that case we need to allowed because
    // is the only way i found to do it.
    if (hotkey === "altKey" && !isDragging) {
      finishUpdatingState();
      resetValue();
      onDragEnd();
      return;
    }

    if (!isInputDraggable) {
      finishUpdatingState();
      onDragEnd();
      return;
    }
    finishUpdatingState();
    onSubmit({
      value: parseValue(inputValue),
      currentlyPressedHotkey: hotkey,
      shouldBlur: true,
    });
    onDragEnd();
  });

  const onMouseMove = useEffectEvent((event: MouseEvent) => {
    if (!shouldInputDrag) {
      return;
    }
    const { clientX, clientY } = event;
    let updatedValue = getUpdatedValue(clientX, clientY);

    if (Number.isNaN(Number.parseFloat(`${updatedValue}`))) {
      return;
    }

    // Note (Chance 2023-08-10) A type error surfaced here after upgrading to TS
    // 5.0 because TS doesn't want us using numeric comparison operators on
    // non-numeric types. We weren't casting or stringifying `updatedValue`
    // before so I didn't change this to avoid potential bugs or behavior
    // changes, but this won't result in a runtime error so I just casted to a
    // number and moved on. Consider improving this.
    if (!shouldAllowNegativeValue && (updatedValue as number) < 0) {
      updatedValue = 0;
    }
    // NOTE (Sebas, 2024-04-24): Previously we were using ?? here what caused the
    // preview to not work becaue we were using the wrong unit (an empty string)
    // instead of the unit from metrics.
    const unit = currentUnit || props.metrics?.[0];
    updatedValue = clampMinMax(
      updatedValue,
      maxDragValues?.[unit || ""],
      minDragValues?.[unit || ""],
    )!;

    const hotkey = getHotKey(event);
    const newValue = parseValue(
      updatedValue,
      currentUnit ?? props.metrics?.[0] ?? "px",
    );
    const value = Number.parseFloat(`${currentValueRef.current || 0}`);
    const difference = Math.abs(value - (updatedValue as number));
    setInputValue(newValue);
    // NOTE (Fran, 2022-09-05): If we drag with a hotkey we should always set
    // isDragging in true, but if is not, we have to do only if the difference
    // is equal or more than one to prevent unexpected behaviors.
    setIsDragging(hotkey ? true : difference >= 1);
    setPreviewCss(event, newValue);
    props.onPreviewChange?.(newValue, hotkey);
  });

  React.useEffect(() => {
    if (!isMouseDown) {
      return;
    }

    window.addEventListener("mouseup", onMouseUp);
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [isMouseDown, onMouseMove, onMouseUp]);

  return (
    <LengthInputSelectorContext.Provider
      value={{
        onMouseDown,
        draggingDirection: props.draggingDirection,
        dragTrigger: props.dragTrigger,
        draggingType: props.draggingType,
        field: props.field,
        isDisabled: props.isDisabled ?? false,
        onSubmit,
        inputRef,
        inputValue,
        setInputValue,
        parseValue,
        isMouseDown,
        enablePreviewCss,
        disablePreviewCss,
        setPreviewCss,
        metrics: props.metrics,
        allowedNonUnitValues: props.allowedNonUnitValues,
        unitDefaults: props.unitDefaults,
        defaultUnit,
        maxValues: props.maxValues,
        minValues: props.minValues,
        currentUnit: currentUnit,
        anchorValue: props.anchorValue,
        isFocused,
        setIsFocused,
        isDragging,
        isCurrentlySubmittingValueRef,
        resetValue,
      }}
    >
      {props.children}
    </LengthInputSelectorContext.Provider>
  );
};

const LengthInputSelectorDraggable: React.FC<
  React.PropsWithChildren<{
    className?: string;
    dataTestId?: string;
  }>
> = ({ children, dataTestId, className }) => {
  const { onMouseDown, isDisabled } = useRequiredContext(
    LengthInputSelectorContext,
  );
  return (
    <div
      className={twMerge(
        classNames("flex cursor-ns-resize", {
          "cursor-not-allowed": isDisabled,
        }),
        className,
      )}
      onMouseDown={onMouseDown}
      data-testid={dataTestId ?? "draggable-component-wrapper"}
    >
      {children}
    </div>
  );
};

type LengthInputSelectorPropsNeededForInput =
  | "startEnhancer"
  | "endEnhancer"
  | "menuOptions"
  | "placeholder"
  | "autofocus"
  | "backgroundColor";

const LengthInputSelectorInput: React.FC<
  Pick<LengthInputSelectorProps, LengthInputSelectorPropsNeededForInput> & {
    className?: string;
  }
> = (props) => {
  const {
    dragTrigger,
    draggingType,
    draggingDirection,
    isDisabled,
    field,
    onSubmit,
    inputRef,
    isMouseDown,
    inputValue,
    setInputValue,
    parseValue,
    enablePreviewCss,
    disablePreviewCss,
    setPreviewCss,
    metrics,
    allowedNonUnitValues,
    unitDefaults,
    maxValues,
    minValues,
    defaultUnit,
    currentUnit,
    anchorValue,
    isFocused,
    setIsFocused,
    isDragging,
    isCurrentlySubmittingValueRef,
    resetValue,
  } = useRequiredContext(LengthInputSelectorContext);
  const allowDragOnLabel = dragTrigger === "label";
  const shouldAllowDragOnStartEnhancer =
    !allowDragOnLabel &&
    ((!dragTrigger && Boolean(draggingType)) ||
      dragTrigger === "startEnhancer" ||
      (Boolean(props.startEnhancer) && dragTrigger !== "none"));

  const inputStyles: Record<string, string> = {
    backgroundColor: props.backgroundColor || "transparent",
    cursor: getCursor(draggingType, draggingDirection),
    caretColor: isMouseDown ? "transparent" : "auto",
  };

  /**
   * Have the logic when we press need to use key down and key up to increase
   * or decrease values.
   * @private
   */
  const _onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!["ArrowUp", "ArrowDown", "Enter"].includes(event.key)) {
      return;
    }
    if (event.key === "Enter") {
      onSubmit({
        value: parseValue(inputValue),
        currentlyPressedHotkey: null,
        shouldBlur: true,
      });
      return;
    }

    const transform = (currentValue: number, valueToChange: number): number => {
      if (event.key === "ArrowDown") {
        return clampMinMax(
          currentValue - valueToChange,
          maxValues?.[currentUnit],
          minValues?.[currentUnit],
        )!;
      }
      return clampMinMax(
        currentValue + valueToChange,
        maxValues?.[currentUnit],
        minValues?.[currentUnit],
      )!;
    };

    let valueToStartFrom = Number.parseFloat(inputValue);
    let unitToIncrementBy = parseUnit(
      inputValue,
      unitDefaults || PARSE_UNIT_DEFAULTS,
      field,
      defaultUnit,
      undefined,
      metrics,
      allowedNonUnitValues,
    ).unit;

    if (!inputValue) {
      if (!anchorValue) {
        return;
      }
      const { value: anchorValueParsed, parsedUnit: anchorUnit } = parseUnit(
        anchorValue,
        unitDefaults || PARSE_UNIT_DEFAULTS,
        field,
        defaultUnit,
        undefined,
        metrics,
        allowedNonUnitValues,
      );
      const anchorValueNumber = Number.parseFloat(`${anchorValueParsed}`);

      if (!Number.isNaN(anchorValueNumber)) {
        valueToStartFrom = anchorValueNumber;
        unitToIncrementBy = anchorUnit ?? currentUnit;
      }
    }
    let newValue = "";
    if (["", "px", "%", "vw", "vh"].includes(unitToIncrementBy)) {
      const value = transform(valueToStartFrom, 1);
      newValue = parseValue(value, unitToIncrementBy);
    } else {
      const value = round(transform(valueToStartFrom, 0.1), 1);
      newValue = parseValue(value, unitToIncrementBy);
    }
    setInputValue(newValue);
    enablePreviewCss(event);
    setPreviewCss(event, newValue);
  };

  /**
   * Submit the value when finish the key up/down logic and when we release the
   * alt or shift key when we are dragging
   * @private
   */
  const _onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isArrowKey = ["ArrowUp", "ArrowDown"].includes(event.key);
    const isDraggableUnit = CSS_LENGTH_TYPES.concat("").includes(currentUnit);
    const isHotkeyPressed = Boolean(getHotKey(event));

    if ((isArrowKey && isDraggableUnit) || (isHotkeyPressed && isDragging)) {
      disablePreviewCss(event);
      onSubmit({
        value: inputValue,
        currentlyPressedHotkey: getHotKey(event),
        shouldBlur: false,
      });
    }
  };

  const startEnhancer = props.startEnhancer;
  const isNewEditorPanelsUIEnabled = checkIfNewEditorPanelsUIIsEnabled();
  const InputWrapper = isNewEditorPanelsUIEnabled
    ? LengthInputSelectorDraggable
    : "div";

  return (
    <InputWrapper className="flex h-full w-full">
      <Input
        startEnhancer={
          startEnhancer &&
          (() => {
            return shouldAllowDragOnStartEnhancer ? (
              <LengthInputSelectorDraggable
                dataTestId={`${field}-startEnhancer`}
              >
                {startEnhancer()}
              </LengthInputSelectorDraggable>
            ) : (
              startEnhancer?.()
            );
          })
        }
        endEnhancer={props.endEnhancer}
        menuItems={
          props.menuOptions &&
          props.menuOptions.map(({ label, value: menuValue, isDisabled }) => ({
            id: label,
            type: "leaf",
            title: label,
            isDisabled,
            onSelect: () => {
              onSubmit({
                value: menuValue,
                currentlyPressedHotkey: null,
                shouldBlur: true,
              });
            },
          }))
        }
        ref={inputRef}
        isDisabled={isDisabled}
        placeholder={props.placeholder || "auto"}
        value={inputValue}
        onPaste={(e) => {
          const value = parseValue(e.clipboardData.getData("text"));
          onSubmit({
            value,
            currentlyPressedHotkey: null,
            shouldBlur: true,
          });
        }}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        unsafe_className={props.className}
        unsafe_inputClassName={props.className}
        unsafe_style={inputStyles}
        onKeyDown={_onKeyDown}
        onKeyUp={_onKeyUp}
        shouldSelectTextOnFocus
        onFocus={() => {
          if (!isDisabled) {
            setIsFocused(true);
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          setIsFocused(false);
          // Note (Noah, 2022-08-13, REPL-3548): If we're not currently in the
          // middle of a submit (aka, we initiated the blur ourselves), then
          // submit the value. We don't want to submit if we're already in the middle
          // of one because that would case duplicate updates.
          if (!isCurrentlySubmittingValueRef.current) {
            onSubmit({
              value: parseValue(inputValue),
              currentlyPressedHotkey: null,
              shouldBlur: false,
            });
          }
        }}
        onClick={(e) => {
          // Note (Fran, 2022-09-02): We only need to reset the value when we
          // click the input if we have startEnhancer. Because in other cases
          // we need to handle only where the drag
          if (
            getHotKey(e) === "altKey" &&
            !isDragging &&
            Boolean(props.startEnhancer)
          ) {
            resetValue();
          }
        }}
        allowsDragOnStartEnhancer={shouldAllowDragOnStartEnhancer}
        isFocused={isFocused}
        data-testid={`${field}-input`}
        autoFocus={props.autofocus}
      />
    </InputWrapper>
  );
};

// NOTE (Fran, 2022-08-03): More docs on https://www.notion.so/replo/LegnthInputSelector-Docs-b0be6b93767a46a8a1854f3f1f6833ce
export const LengthInputSelector = (props: LengthInputSelectorProps) => {
  const allowDragOnLabel = props.dragTrigger === "label";
  return (
    <LengthInputSelectorRoot {...props}>
      <div
        className={classNames(
          "flex flex-row items-center justify-center",
          props.className,
        )}
        style={props.style}
      >
        {props.label ? (
          allowDragOnLabel ? (
            <LengthInputSelectorDraggable dataTestId={`${props.field}-label`}>
              {props.label}
            </LengthInputSelectorDraggable>
          ) : (
            props.label
          )
        ) : null}
        <LengthInputSelectorInput
          className={props.inputClassName}
          startEnhancer={props.startEnhancer}
          endEnhancer={props.endEnhancer}
          menuOptions={props.menuOptions}
          placeholder={props.placeholder}
          autofocus={props.autofocus}
          backgroundColor={props.backgroundColor}
        />
      </div>
    </LengthInputSelectorRoot>
  );
};

LengthInputSelector.Root = LengthInputSelectorRoot;
LengthInputSelector.Input = LengthInputSelectorInput;
LengthInputSelector.DraggableArea = LengthInputSelectorDraggable;

const LengthInputModifier: React.FC<LengthInputModifierProps> = (props) => {
  const getAttribute = useGetAttribute();
  const draftComponent = useEditorSelector(selectDraftComponent);

  if (!draftComponent) {
    return null;
  }

  const value = props.value ?? getAttribute(draftComponent, props.field).value;

  return (
    <LengthInputSelector
      {...props}
      value={value}
      field={props.field}
      onPreviewChange={(newValue, hotkey) => {
        props.onPreviewChange?.(newValue, hotkey);
      }}
      inputRef={props.inputRef}
      onChange={(newValue, hotkey) => {
        props.onChange?.(newValue, hotkey);
      }}
      resetValue={props.resetValue}
      startEnhancer={props.startEnhancer}
    />
  );
};

export default LengthInputModifier;

function clampMinMax<T extends string | number>(
  value: T,
  maxValue?: T,
  minValue?: T,
) {
  if (
    isNotNullish(maxValue) &&
    // Note (Chance 2023-08-10) A type error surfaced here after upgrading to TS
    // 5.0 because TS doesn't want us using numeric comparison operators on
    // non-numeric types. We weren't casting or stringifying `maxValue` or
    // `minValue` before so I didn't change this to avoid potential bugs or
    // behavior changes, but this won't result in a runtime error so I just
    // casted to a number and moved on. Consider improving this.
    Number.parseInt(value.toString(), 10) > (maxValue as number)
  ) {
    return maxValue;
  } else if (
    isNotNullish(minValue) &&
    Number.parseInt(value.toString(), 10) < (minValue as number)
  ) {
    return minValue;
  }
  return value;
}
