import type {
  SavedStyleColorAttributes,
  SavedStyleTextAttributes,
} from "schemas/generated/savedStyles";

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorStore } from "@editor/store";
import { trpcUtils } from "@editor/utils/trpc";

type AddToDesignLibraryCacheParams = {
  savedStyleId: string;
  attributes: SavedStyleTextAttributes | SavedStyleColorAttributes;
  type: "text" | "color";
  name: string;
};

function useAddToDesignLibraryCache() {
  const store = useEditorStore();

  function addToDesignLibraryCache({
    savedStyleId,
    attributes,
    type,
    name,
  }: AddToDesignLibraryCacheParams) {
    const projectId = selectProjectId(store.getState());

    if (!projectId) {
      return;
    }

    // NOTE (Fran 2024-11-13): Cancel any ongoing queries that could override the optimistic update.
    trpcUtils.designLibrary.get.cancel();
    trpcUtils.designLibrary.get.setData({ projectId }, (data) => {
      if (!data) {
        return;
      }
      return {
        ...data,
        savedStyles: {
          ...data.savedStyles,
          [savedStyleId]:
            type === "text"
              ? {
                  attributes: attributes as SavedStyleTextAttributes,
                  type: "text",
                  name,
                }
              : {
                  attributes: attributes as SavedStyleColorAttributes,
                  type: "color",
                  name,
                },
        },
      };
    });
  }

  return { addToDesignLibraryCache };
}

export default useAddToDesignLibraryCache;
