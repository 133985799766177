import type { SolidOrGradient } from "replo-runtime/shared/types";
import type { SavedColorStyle } from "schemas/generated/designLibrary";
import type {
  SavedStyleColorAttributes,
  SavedStyleColorType,
} from "schemas/generated/savedStyles";

import * as React from "react";

import { COLOR_MENU_ITEMS } from "@common/constants/colorStyles";
import ColorPicker from "@common/designSystem/ColorPicker";
import { Input } from "@common/designSystem/Input";
import Popover from "@common/designSystem/Popover";
import Selectable from "@common/designSystem/Selectable";
import Separator from "@common/designSystem/Separator";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import useUpdateDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useUpdateDesignLibrarySavedStyle";
import { selectDraftElementColors } from "@editor/reducers/core-reducer";
import { selectLeftBarWidth } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";

import {
  cssGradientToGradient,
  gradientToCssGradient,
} from "replo-runtime/shared/utils/gradient";

type ColorSavedStyleModifierValues = {
  id: string;
  name: string;
  attributes: SavedStyleColorAttributes;
};

type ColorSavedStyleModifierProps = {
  isPopoverOpen: boolean;
  onPopoverOpenChange: (isOpen: boolean) => void;
  editingSavedStyle: (SavedColorStyle & { id: string }) | null;
};

const COLOR_TYPE_OPTIONS = COLOR_MENU_ITEMS.map((item) => ({
  value: item.colorType,
  label: item.title,
}));

export const ColorSavedStyleModifier: React.FC<
  React.PropsWithChildren<ColorSavedStyleModifierProps>
> = ({ isPopoverOpen, onPopoverOpenChange, editingSavedStyle }) => {
  const leftBarWidth = useEditorSelector(selectLeftBarWidth);
  const elementColors = useEditorSelector(selectDraftElementColors);
  const { updateSavedStyle } = useUpdateDesignLibrarySavedStyle();

  const offset = leftBarWidth;

  const colorValue = getColorValue(
    editingSavedStyle?.attributes.color ?? "#000000",
  );

  const handleNameChange = React.useCallback(
    (values: ColorSavedStyleModifierValues) => {
      void updateSavedStyle({
        savedStyleId: values.id,
        attributes: values.attributes,
        name: values.name,
      });
    },
    [updateSavedStyle],
  );

  if (!editingSavedStyle) {
    return null;
  }

  return (
    <Popover isOpen={isPopoverOpen} onOpenChange={onPopoverOpenChange}>
      <Popover.Content
        shouldPreventDefaultOnInteractOutside
        side="bottom"
        align="end"
        alignOffset={-offset}
        className="p-2 w-[250px]"
        title="Create Color Style"
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <label htmlFor="name" className="text-xs font-medium">
              Name
            </label>
            <Input
              id="name"
              placeholder="Name"
              value={editingSavedStyle.name}
              onChange={(e) => {
                handleNameChange({
                  ...editingSavedStyle,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className="flex items-center gap-1">
            <ModifierLabel label="Tag" />
            <Selectable
              id="colorType"
              options={COLOR_TYPE_OPTIONS}
              value={editingSavedStyle.attributes.colorType}
              onSelect={(colorType) => {
                if (colorType) {
                  handleNameChange({
                    ...editingSavedStyle,
                    attributes: {
                      ...editingSavedStyle.attributes,
                      colorType: colorType as SavedStyleColorType,
                    },
                  });
                }
              }}
            />
          </div>
          <Separator />
          <ColorPicker
            value={colorValue}
            allowsGradientSelection
            onChange={(color) => {
              const newColorValue = getSavedStyleColorValue(color);

              if (newColorValue && editingSavedStyle) {
                handleNameChange({
                  ...editingSavedStyle,
                  attributes: {
                    ...editingSavedStyle.attributes,
                    color: newColorValue,
                  },
                });
              }
            }}
            documentColors={elementColors}
          />
        </div>
      </Popover.Content>
      <Popover.Anchor className="top-0 left-0" />
    </Popover>
  );
};

function getColorValue(color: string): SolidOrGradient {
  if (color.includes("linear-gradient")) {
    return {
      type: "gradient",
      gradient: cssGradientToGradient(color),
    };
  }
  return {
    type: "solid",
    color,
  };
}

function getSavedStyleColorValue(color: SolidOrGradient) {
  if (color.type === "gradient") {
    return gradientToCssGradient(color.gradient);
  }
  return color.color;
}
