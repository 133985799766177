import React from "react";

import InlinePopover from "@common/designSystem/InlinePopover";
import { Input } from "@common/designSystem/Input";
import SelectionIndicator from "@common/designSystem/SelectionIndicator";
import FormFieldXButton from "@common/FormFieldXButton";
import SavedStyleRow from "@components/designLibrary/SavedStyleRow";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import { getSavedStyleValue } from "@editor/utils/designLibrary";

import { Badge } from "@replo/design-system/components/badge";
import { BsSearch, BsType } from "react-icons/bs";

type DesignLibraryTextValueIndicatorProps = {
  savedStyleValueReference: string;
  onSavedStyleSelect(value: string): void;
  onRemove?(): void;
  popoverSideOffset?: number;
};

const DesignLibraryTextValueIndicator: React.FC<
  DesignLibraryTextValueIndicatorProps
> = ({
  savedStyleValueReference,
  onSavedStyleSelect,
  onRemove,
  popoverSideOffset,
}) => {
  const { textSavedStyles } = useGetDesignLibrarySavedStyles();
  const textSavedStyleName = getSavedStyleValue(
    textSavedStyles,
    savedStyleValueReference,
  )?.name;

  const isTextSavedStyleSelected = Boolean(textSavedStyleName);
  const shouldRenderXButton = onRemove && isTextSavedStyleSelected;

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      title="Text Saved Style"
      content={
        <TextValueIndicatorPopoverContent
          onSavedStyleSelect={onSavedStyleSelect}
        />
      }
      sideOffset={popoverSideOffset}
      triggerAsChild
    >
      <SelectionIndicator
        title={textSavedStyleName}
        startEnhancer={<TextBadge />}
        placeholder="Select..."
        endEnhancer={
          shouldRenderXButton && <FormFieldXButton onClick={onRemove} />
        }
        style={{
          width: "100%",
        }}
      />
    </InlinePopover>
  );
};

const TextValueIndicatorPopoverContent: React.FC<{
  onSavedStyleSelect: (value: string) => void;
}> = ({ onSavedStyleSelect }) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { textSavedStyles, designLibrary } = useGetDesignLibrarySavedStyles();

  const filteredTextSavedStyles = textSavedStyles.filter((style) =>
    style.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="flex flex-col gap-2 w-full">
      <Input
        size="sm"
        value={searchTerm}
        startEnhancer={() => <BsSearch size={10} />}
        endEnhancer={() =>
          searchTerm?.trim() && (
            <FormFieldXButton onClick={() => setSearchTerm("")} />
          )
        }
        placeholder="Search saved styles..."
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="flex flex-col gap-1 max-h-28 overflow-y-auto styled-scrollbar">
        {filteredTextSavedStyles.map((style) => (
          <SavedStyleRow
            key={style.id}
            name={style.name}
            value={`${style.attributes.fontSize}/${style.attributes.fontWeight} • /${style.attributes.lineHeight}`}
            type="text"
            onClick={() =>
              onSavedStyleSelect?.(
                `library.${designLibrary?.id}.styles.${style.id}.attributes`,
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

const TextBadge: React.FC = () => {
  return <Badge type="icon" icon={<BsType className="text-subtle" />} />;
};

export default DesignLibraryTextValueIndicator;
