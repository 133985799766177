import type { ObjectFitType } from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";
import type { Context } from "replo-runtime/store/AlchemyVariable";

import * as React from "react";

import DynamicDataButton from "@common/designSystem/DynamicDataButton";
import { Input } from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import AssetPicker from "@editor/components/common/designSystem/AssetPicker";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import Popover from "@editor/components/common/designSystem/Popover";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { useAiAltTextMutation } from "@editor/reducers/ai-reducer";
import {
  selectDraftComponentId,
  selectImageAltText,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { selectComponentMarkers } from "@editor/reducers/tree-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BsMagic, BsX } from "react-icons/bs";
import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { evaluateVariableAsString } from "replo-runtime/store/AlchemyVariable";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { twMerge } from "tailwind-merge";

import ModifierLabel from "./extras/ModifierLabel";

type ImageSourceSelectorProps = {
  src?: string;
  objectFitValue?: ObjectFitType;
  assetLoadingValue?: AssetLoadingType;
  objectPositionValues?: { x?: string; y?: string };
  onChangeImageSource(value: string): void;
  onChangeObjectFit?(value: string): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangeObjectPositionX?(value: string): void;
  onChangeObjectPositionY?(value: string): void;
  onRemove?(): void;
  componentContext?: Context;
  allowsDynamicData?: boolean;
  componentId?: string;
  className?: string;
  openPopoverWhenEmpty?: boolean;
  generateAltText?: (imageUrl: string) => void;
  isGeneratingAltText?: boolean;
  size: "sm" | "base";
};

const imageSourceAttribute = "__imageSource";

const ImageSourceSelector = ({
  src: originalSource,
  objectFitValue,
  assetLoadingValue,
  objectPositionValues,
  onChangeImageSource,
  onChangeObjectFit,
  onChangeAssetLoading,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  onRemove: _onRemove,
  componentContext,
  allowsDynamicData = true,
  componentId,
  className,
  openPopoverWhenEmpty = false,
  size,
}: ImageSourceSelectorProps) => {
  const assetIsDynamicData = isDynamicDataValue(originalSource);
  const evaluatedSource = originalSource
    ? evaluateVariableAsString(originalSource, componentContext)
    : null;
  const resolvedSource =
    assetIsDynamicData && componentContext && evaluatedSource
      ? evaluatedSource
      : originalSource;
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const [isVisible, setVisible] = useOverridableState(
    openPopoverWhenEmpty ? !resolvedSource : false,
    undefined,
    draftComponentId || undefined,
  );
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();

  const [generateAiAltText, { isLoading: isGeneratingAltText, isSuccess }] =
    useAiAltTextMutation();

  const _openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/image",
        value: originalSource ?? null,
        onChange: (value: string) => {
          generateAltTextIfNecessary(value);
          onChangeImageSource(value);
        },
        assetContentType: "image",
      },
    });
  };

  const _onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: {
          type: "style",
          styleAttribute: imageSourceAttribute,
        },
        initialPath: originalSource
          ? getPathFromVariable(originalSource)
          : undefined,
      },
    });
  };

  const _onClickAltTextDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.TEXT,
        referrerData: {
          type: "style",
          styleAttribute: "__imageAltText",
        },
        initialPath: altTextValue
          ? getPathFromVariable(altTextValue)
          : undefined,
      },
    });
  };

  const _handleAltTextChange = React.useCallback(
    (value: string) => {
      applyComponentAction({
        type: "applyCompositeAction",
        value: [
          // Note (Ovishek, 2022-08-16): We need to pass componentId b/c this function can be called
          // from a debounced function, if we don't do this and after calling this, user quickly select another draftComponent,
          // this action might get applied on the newly selected one, so we must.
          {
            componentId: draftComponentId,
            type: "setStyles",
            value: { __imageAltText: value },
          },
          // Note (Evan, 2024-04-25): When the user manually sets alt text, mark the text as NOT ai-generated
          // so we know not to replace it. Also, ditto what Ovishek said about componentId.
          {
            componentId: draftComponentId,
            type: "setMarker",
            value: { _aiGeneratedAltText: false },
          },
        ],
      });
    },
    [draftComponentId, applyComponentAction],
  );

  const _altTextValue = useEditorSelector(selectImageAltText) ?? "";
  const [altTextValue, setAltTextValue] = useOverridableState(
    _altTextValue,
    useDebouncedCallback(_handleAltTextChange, 300),
  );

  // Note (Evan, 2024-04-26): Used to cancel generation requests
  const abortRef = React.useRef<(() => void) | null>(null);

  const componentMarkers = useEditorSelector((state) =>
    selectComponentMarkers(state, draftComponentId ?? ""),
  );

  const isAltTextAiGenerated =
    Boolean(componentMarkers?._aiGeneratedAltText) &&
    altTextValue &&
    !isDynamicDataValue(altTextValue);

  // Note (Evan, 2024-04-25): Replace alt text with AI-generated text if it doesn't exist or if it was already AI-generated
  const generateAltTextIfNecessary = (value: string) => {
    if (
      (!altTextValue || isAltTextAiGenerated) &&
      draftComponentId &&
      value.length > 0
    ) {
      const { abort } = generateAiAltText({
        componentId: draftComponentId,
        imageUrl: value,
        triggeredManually: false,
      });
      abortRef.current = abort;
    }
  };

  const removeAiAltTextIfNecessary = () => {
    if (isAltTextAiGenerated) {
      setAltTextValue("");
    }
  };

  const onRemove = () => {
    removeAiAltTextIfNecessary();
    _onRemove?.();
  };

  const onClickSelectAsset = () => {
    // Note (Noah, 2023-12-07): Regardless of whether we allow changing
    // object-fit etc, always set the popover visible instead of going straight
    // to the asset modal because we always support updating the alt text, for
    // which we need to open the popover
    setVisible(true);
  };

  const altTextInputProps = useOverridableInput({
    value: altTextValue,
    onValueChange: setAltTextValue,
  });

  const altTextInputRef = React.useRef<HTMLInputElement>(null);

  const isNewRightBarUIEnable = checkIfNewEditorPanelsUIIsEnabled();

  const getTooltipText = () => {
    if (!resolvedSource || isGeneratingAltText) {
      return "Generating with AI";
    }
    return isAltTextAiGenerated ? "Re-generate with AI" : "Generate with AI";
  };

  return (
    <div className={twMerge("flex flex-grow flex-col", className)}>
      <Popover
        isOpen={isVisible}
        onOpenChange={setVisible}
        // Note (Noah, 2022-04-23, REPL-1738): Can't ignore outside interactions
        // here because if we do, that makes the asset library modal not scrollable
        shouldIgnoreOutsideInteractions={false}
      >
        {typeof originalSource === "string" && assetIsDynamicData ? (
          <DynamicDataValueIndicator
            type="image"
            templateValue={originalSource}
            onClick={onClickSelectAsset}
            onRemove={onRemove}
            componentId={componentId}
          />
        ) : (
          <InlineAssetSelector
            swatchId="image-source-selector-swatch"
            swatchTooltip="Image"
            emptyTitle="Select Image"
            inputPlaceholder="Image URL"
            onClickSelectAsset={onClickSelectAsset}
            onRemoveAsset={onRemove}
            allowRemoveAsset={Boolean(_onRemove)}
            asset={{
              type: "image",
              // TODO (Noah, 2023-05-23): Added this "as string" since we were assuming
              // it before we switched the return type of evaluateVariable to unknown,
              // but need to investigate more since the undefined case should be handled
              // somewhere above
              src: resolvedSource as string,
            }}
            allowsDynamicData={allowsDynamicData}
            onClickDynamicData={_onClickDynamicData}
            onInputChange={(value: string) => {
              generateAltTextIfNecessary(value);
              onChangeImageSource(value);
            }}
            size={size}
          />
        )}
        <Popover.Anchor className={classNames("relative top-0 left-0")} />
        {isVisible && (
          <Popover.Content
            title="Image"
            shouldPreventDefaultOnInteractOutside={areModalsOpen}
            // NOTE (Juan, 2024-10-21): This offset is different from the others because this
            // popover is being trigger from an entire input and not from an internal badge.
            sideOffset={checkIfNewEditorPanelsUIIsEnabled() ? 84 : undefined}
          >
            <AssetPicker
              allowsSettingDynamicData={allowsDynamicData}
              onClickDynamicDataForUrl={_onClickDynamicData}
              objectFitValue={objectFitValue}
              assetLoadingValue={assetLoadingValue}
              objectPositionValue={objectPositionValues}
              assetType="image"
              emptyTitle="No Image Selected"
              selectAssetTitle="Select Image"
              changeAssetTitle="Change Image"
              url={resolvedSource}
              onChangeObjectFit={onChangeObjectFit}
              onChangeAssetLoading={onChangeAssetLoading}
              onChangeObjectPositionX={onChangeObjectPositionX}
              onChangeObjectPositionY={onChangeObjectPositionY}
              onClickSelectAsset={_openModal}
              componentId={componentId}
              urlIsDynamicData={assetIsDynamicData}
              templateValue={originalSource}
              onClickRemoveDynamicData={onRemove}
              altTextComponent={
                isNewRightBarUIEnable ? (
                  <div className="flex items-center w-full">
                    <ModifierLabel label="Alt Text" />
                    {isDynamicDataValue(altTextValue) ? (
                      <DynamicDataValueIndicator
                        type="text"
                        templateValue={altTextValue ?? ""}
                        onClick={() => _onClickAltTextDynamicData()}
                        onRemove={() => setAltTextValue("")}
                        componentId={componentId}
                      />
                    ) : (
                      <div className="flex gap-1">
                        <div className="flex-grow relative">
                          <Input
                            {...altTextInputProps}
                            ref={altTextInputRef}
                            onFocus={() => {
                              abortRef.current?.();
                            }}
                            // Note (Evan, 2024-04-26): This makes the text fade in only when a generation completes, not when it's aborted
                            unsafe_inputClassName={`${isSuccess ? "transition-opacity duration-500 ease-in-out" : ""} ${isGeneratingAltText ? "opacity-0" : "opacity-100"}`}
                            placeholder="Description"
                          />
                          {isGeneratingAltText && (
                            <div
                              className="absolute bottom-0 left-0 w-full cursor-pointer transition-all duration-700 ease-out"
                              onClick={() => {
                                abortRef.current?.();
                                altTextInputRef.current?.focus();
                              }}
                            >
                              <SimpleSkeletonLoader width="100%" height="24" />
                              <span className="absolute top-1/2 left-2 text-xs text-slate-400 animate-pulseDeep -translate-y-1/2">
                                Generating
                              </span>
                              <div className="absolute right-2 top-1/2 -translate-y-1/2 text-slate-400">
                                <BsX />
                              </div>
                            </div>
                          )}
                        </div>
                        <IconButton
                          variant="secondary"
                          className="text-ai shrink-0 p-1"
                          tooltipText={getTooltipText()}
                          onClick={() => {
                            if (resolvedSource && draftComponentId) {
                              const { abort } = generateAiAltText({
                                componentId: draftComponentId,
                                imageUrl: resolvedSource,
                                triggeredManually: true,
                              });
                              abortRef.current = abort;
                            }
                          }}
                          isDisabled={!resolvedSource || isGeneratingAltText}
                          icon={<BsMagic size={16} />}
                          isPhonyButton={false}
                          aria-label={getTooltipText()}
                        />
                        {allowsDynamicData && (
                          <div className="shrink-0">
                            <DynamicDataButton
                              onClick={() => _onClickAltTextDynamicData()}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-full relative">
                    <LabeledControl
                      size="sm"
                      label={
                        <div className="flex flex-row gap-2">
                          <span>Alt Text</span>
                          {isAltTextAiGenerated && !isGeneratingAltText && (
                            <Tooltip
                              content="This text was generated with AI"
                              triggerAsChild
                            >
                              <div className="text-ai content-center">
                                <BsMagic />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      }
                    >
                      {isDynamicDataValue(altTextValue) && (
                        <DynamicDataValueIndicator
                          type="text"
                          templateValue={altTextValue ?? ""}
                          onClick={() => _onClickAltTextDynamicData()}
                          onRemove={() => setAltTextValue("")}
                          componentId={componentId}
                        />
                      )}
                      {!isDynamicDataValue(altTextValue) && (
                        <div className="flex gap-2">
                          <div className="flex-grow relative">
                            <Input
                              {...altTextInputProps}
                              ref={altTextInputRef}
                              onFocus={() => {
                                abortRef.current?.();
                              }}
                              // Note (Evan, 2024-04-26): This makes the text fade in only when a generation completes, not when it's aborted
                              unsafe_inputClassName={`${isSuccess ? "transition-opacity duration-500 ease-in-out" : ""} ${isGeneratingAltText ? "opacity-0" : "opacity-100"}`}
                            />
                            {isGeneratingAltText && (
                              <div
                                className="absolute bottom-0 left-0 w-full cursor-pointer transition-all duration-700 ease-out"
                                onClick={() => {
                                  abortRef.current?.();
                                  altTextInputRef.current?.focus();
                                }}
                              >
                                <SimpleSkeletonLoader
                                  width="100%"
                                  height="24"
                                />
                                <span className="absolute top-1/2 left-2 text-xs text-slate-400 animate-pulseDeep -translate-y-1/2">
                                  Generating
                                </span>
                                <div className="absolute right-2 top-1/2 -translate-y-1/2 text-slate-400">
                                  <BsX />
                                </div>
                              </div>
                            )}
                          </div>
                          <Button
                            variant="inherit"
                            className="bg-gradient-to-r from-[#F46B45] to-[#EEA849] text-white"
                            style={{
                              minWidth: "unset",
                            }}
                            tooltipText={(() => {
                              if (!resolvedSource || isGeneratingAltText) {
                                return undefined;
                              }
                              return isAltTextAiGenerated
                                ? "Re-generate with AI"
                                : "Generate with AI";
                            })()}
                            onClick={() => {
                              if (resolvedSource && draftComponentId) {
                                const { abort } = generateAiAltText({
                                  componentId: draftComponentId,
                                  imageUrl: resolvedSource,
                                  triggeredManually: true,
                                });
                                abortRef.current = abort;
                              }
                            }}
                            isDisabled={!resolvedSource || isGeneratingAltText}
                          >
                            <div className="flex flex-row items-center px-1.5">
                              <BsMagic />
                              AI
                            </div>
                          </Button>
                          {allowsDynamicData && (
                            <DynamicDataButton
                              onClick={() => _onClickAltTextDynamicData()}
                            />
                          )}
                        </div>
                      )}
                    </LabeledControl>
                  </div>
                )
              }
            />
          </Popover.Content>
        )}
      </Popover>
    </div>
  );
};

export default ImageSourceSelector;
