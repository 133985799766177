import * as React from "react";

import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type PhonyLabelProps = {
  isPhonyLabel: true;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

type RealLabelProps = {
  isPhonyLabel?: false;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
};

type LabeledControlProps = (PhonyLabelProps | RealLabelProps) & {
  label?: string | React.ReactNode;
  id?: string;
  error?: React.ReactNode | null;
  className?: string;
  containerClassName?: string;
  size?: "base" | "sm";
};
const LabeledControl: React.FC<
  React.PropsWithChildren<LabeledControlProps>
> = ({
  id,
  label,
  children,
  error,
  containerClassName,
  className = "",
  isPhonyLabel,
  onClick,
  size = "base",
}) => {
  const labelClassName = twMerge(
    "font-normal leading-5 text-muted",
    classNames({
      "cursor-pointer": Boolean(onClick),
      "text-xs": size === "sm",
      "text-sm": size === "base",
    }),
    className,
  );
  return (
    <div className={twMerge("flex flex-col gap-1", containerClassName)}>
      {isPhonyLabel ? (
        <div className={labelClassName} onClick={onClick}>
          {label}
        </div>
      ) : (
        <label htmlFor={id} className={labelClassName} onClick={onClick}>
          {label}
        </label>
      )}
      {children}
      {error && <div className="text-sm text-red-600">{error}</div>}
    </div>
  );
};

export default LabeledControl;
