import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgInset = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 6 3" fill="none" {...props}>
    <path fill="#F8FAFC" d="M4.714 3H1.286L0 0h6L4.714 3Z" />
  </Svg>
);
export default SvgInset;
