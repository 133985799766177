import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { hasAnchorTagInTextProp } from "../../utils/component";
import { ContentEditableComponent } from "../ContentEditableComponent";

const Text: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const {
    ref: containerRef,
    extraAttributes,
    ...attributes
  } = componentAttributes;
  const hasAnchorTag = hasAnchorTagInTextProp(component);

  return (
    <ContentEditableComponent
      {...attributes}
      componentType="text"
      isPureText
      key={attributes.key}
      component={component}
      containerRef={containerRef}
      context={context}
      hasAnchorTag={hasAnchorTag}
    />
  );
};

export default Text;
