import * as React from "react";

import { CommandInput } from "@replo/design-system/components/shadcn/core/command";
import { BsSearch } from "react-icons/bs";

type TriggerInputProps = {
  input: string;
  setInput: (value: string) => void;
  inputPlaceholder?: string;
  endEnhancer?(): React.ReactNode;
};

const TriggerInput: React.FC<TriggerInputProps> = ({
  input,
  setInput,
  inputPlaceholder,
  endEnhancer,
}) => {
  return (
    <CommandInput
      value={input}
      onValueChange={setInput}
      placeholder={inputPlaceholder ?? "Search..."}
      startEnhancer={<BsSearch className="text-slate-400 h-3 w-3" />}
      className="text-xs"
      endEnhancer={endEnhancer?.()}
    />
  );
};

export { TriggerInput };
