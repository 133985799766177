import type { EventType } from "@testing-library/user-event/dist/types/event/types";
import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import {
  RenderEnvironmentContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { SharedShopifyLiquid } from "../SharedShopifyLiquid";

type KachingChangeEventDetails = {
  variantIdQuantities: { quantity: number; variantId: number }[];
};

type KachingBundlesBlockElement = HTMLElement & {
  currentVariantId: number;
  quantity: number;
};

const LIQUID_SOURCE =
  '<kaching-bundle product-id="{{ product.id }}"></kaching-bundle><script>window.kachingBundlesDisableAddToCartHandling = true;</script>';

function KachingBundles(props: RenderComponentProps) {
  const { componentAttributes, component, context } = props;

  const ref = React.useRef<HTMLDivElement | null>(null);

  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  const selectedVariant = context.state.product?.selectedVariant;

  // NOTE (Matt 2024-10-15): The entire point of this useEffect is to sync the kaching bundles component,
  // which essentially a variant/quantity selector, with the selected variant and quantity of the product
  // component if for some reason a user wants to include both a replo variant selector component and the
  // kaching bundles component.
  React.useEffect(() => {
    if (!selectedVariant || !ref.current) {
      return;
    }
    // NOTE (Matt 2024-10-10): this selects the kaching bundles component, which is a webcomponent that has exposed
    // a handful of custom events and attributes that we can use to sync the selectedVariantId with the widget's selector.
    const kachingBundles = ref.current?.querySelector(
      "kaching-bundles-block",
    ) as KachingBundlesBlockElement | null;
    if (
      kachingBundles &&
      kachingBundles.currentVariantId !== selectedVariant.id
    ) {
      kachingBundles.currentVariantId = selectedVariant.id;
    }
  }, [selectedVariant]);

  React.useEffect(() => {
    if (!selectedVariant || !ref.current) {
      return;
    }
    const kachingBundles = ref.current?.querySelector("kaching-bundles-block");

    const onVariantChanged = ((
      event: CustomEvent<KachingChangeEventDetails>,
    ) => {
      const variantIdQuantities = event.detail.variantIdQuantities;
      // NOTE (Matt 2024-10-10): We only sync the 'first' variant. it is possible
      // to configure the kaching bundles component to allow for multiple variants,
      // we'll only update the 'selected variant' for the first variant.
      const variantId = variantIdQuantities[0]?.variantId;
      if (variantId && selectedVariant.id !== variantId) {
        // NOTE (Matt 2024-10-10): We only update the selected variant id
        // In our docs, we recommend a user not include a quantity selector,
        // the kaching component should handle quantity selection.
        context.actionHooks.setActiveVariant?.(variantId);
      }
    }) as EventListener;
    kachingBundles?.addEventListener(
      // NOTE (Matt 2024-10-10): we have to cast this as any because it is a non-stantard event type
      "variants-changed" as EventType,
      onVariantChanged,
    );
    return () => {
      kachingBundles?.removeEventListener(
        "variants-changed" as EventType,
        onVariantChanged,
      );
    };
  }, [selectedVariant, context.actionHooks]);

  return (
    <SharedShopifyLiquid
      ref={ref}
      forceEditorPlaceholder={isEditorApp}
      liquidSource={LIQUID_SOURCE}
      componentId={component.id}
      componentAttributes={componentAttributes}
      placeholder="Kaching Bundles Widget will appear here"
      repeatedIndexPath={context.repeatedIndexPath}
      isLiquidSupported
    />
  );
}

export default KachingBundles;
