import { z } from "zod";

import {
  savedStyleColorAttributesSchema,
  savedStyleTextAttributesSchema,
} from "./savedStyles";

export const savedColorStyleSchema = z
  .object({
    type: z.literal("color"),
    name: z.string(),
    attributes: savedStyleColorAttributesSchema,
    optionalUsageGuidelines: z.string().nullish(),
    deletedAt: z.date().nullish(),
  })
  .describe("SavedColorStyle");

export const savedTextStyleSchema = z
  .object({
    type: z.literal("text"),
    name: z.string(),
    attributes: savedStyleTextAttributesSchema,
    optionalUsageGuidelines: z.string().nullish(),
    deletedAt: z.date().nullish(),
  })
  .describe("SavedTextStyle");

export const designLibrarySavedStylesSchema = z
  .record(z.string(), z.union([savedColorStyleSchema, savedTextStyleSchema]))
  .describe("DesignLibrarySavedStylesMapping");

export const designLibrarySchema = z
  .object({
    id: z.string().uuid(),
    createdAt: z.date(),
    updatedAt: z.date(),
    deletedAt: z.date().nullish(),
    savedStyles: designLibrarySavedStylesSchema,
  })
  .describe("DesignLibrary");

// #region Deprecated Schemas and Types
// NOTE (Fran 2024-10-23 REPL-13625): This will be removed when Saved Styles is release

/**
 * @deprecated
 */
export const buttonStyleSchema = z.object({
  backgroundColor: z.string().nullish(),
  textColor: z.string().nullish(),
  borderRadius: z.string().nullish(),
  width: z.number().nullish(),
  height: z.number().nullish(),
  visible: z.boolean().nullish(),
});

/**
 * @deprecated
 */
export const webpageAttributesSchema = z
  .object({
    buttonStyle: buttonStyleSchema,
    imageUrls: z.array(z.string()).nullish(),
    backgroundImageUrls: z.array(z.string()).nullish(),
    logoUrl: z.string().nullish(),
    brandName: z.string().nullish(),
    bestSellingProduct: z
      .object({
        title: z.string().nullish(),
        id: z.string().nullish(),
        variantId: z.number().nullish(),
      })
      .nullish(),
  })
  .describe("WebpageAttributes");

/**
 *  NOTE (Fran 2023-10-05): When we have the schemas for components all in one package will be nice
 * to replace with that one.
 *
 * @deprecated
 */
export const designSystemComponentStylesSchema = z
  .object({
    backgroundColor: z.string().nullish(),
    color: z.string().nullish(),
    borderRadius: z.string().nullish(),
    width: z.number().nullish(),
    height: z.number().nullish(),
  })
  .nullish();

/**
 * @deprecated
 */
export const designSystemComponentAttributesSchema = z.object({
  style: designSystemComponentStylesSchema,
});

export type DesignSystemComponentAttributes = {
  style?: {
    backgroundColor?: string | null;
    color?: string | null;
    borderRadius?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

/**
 * @deprecated
 */
export const designSystemPreviewProduct = z
  .object({
    id: z.string().nullish(),
    title: z.string().nullish(),
    variantId: z.number().nullish(),
  })
  .nullish();

/**
 * @deprecated
 */
export type DesignSystemPreviewProduct = {
  id?: string | null;
  title?: string | null;
  variantId?: number | null;
} | null;

/**
 * @deprecated
 */
export const designSystemSchema = z.object({
  id: z.string().uuid(),
  importedFromUrl: z.string(),
  brandLogoUrl: z.string().nullish(),
  brandName: z.string().nullish(),
  // NOTE (Fran 2023-10-05): When we have the schemas for components all in one package will be nice
  // to replace with that one.
  primaryButtonAttributes: designSystemComponentAttributesSchema,
  backgroundImageUrls: z.array(z.string()).nullish(),
  placeholderImageUrls: z.array(z.string()).nullish(),
  previewProduct: designSystemPreviewProduct,
  projectId: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

/**
 * @deprecated
 */
export type DesignSystem = {
  id: string;
  importedFromUrl: string;
  brandLogoUrl?: string | null;
  brandName?: string | null;
  primaryButtonAttributes: DesignSystemComponentAttributes;
  backgroundImageUrls?: string[] | null;
  placeholderImageUrls?: string[] | null;
  previewProduct?: DesignSystemPreviewProduct;
  projectId: string;
  createdAt: Date;
  updatedAt: Date;
};
// #endregion
