import * as React from "react";

import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import { decodeUrlHost } from "@editor/utils/analytics";

import { DEFAULT_URL_HOST } from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import isEqual from "lodash-es/isEqual";
import { BsCaretDownFill, BsGlobe2 } from "react-icons/bs";
import { useParams } from "react-router-dom";

type AnalyticsHostComboboxProps = {
  onChange: (value: string) => void;
  disabled?: boolean;
};

const AnalyticsUrlHostCombobox = ({
  onChange,
  disabled,
}: AnalyticsHostComboboxProps) => {
  const { workspaceUrlHosts, getParamValue } = useAnalyticsQueryContext();

  const { host = "", pageUrlPath = "" } = useParams<{
    host: string;
    pageUrlPath: string;
  }>();
  const hostsInUrlParams = getParamValue("urlHosts");
  const decodedHost = decodeUrlHost(host);

  const extendedURLOptions = React.useMemo(
    () => [
      ...(workspaceUrlHosts.data.length > 1 && pageUrlPath === ""
        ? [{ value: DEFAULT_URL_HOST, label: "All Domains" }]
        : []),
      ...workspaceUrlHosts.data,
    ],
    [workspaceUrlHosts.data, pageUrlPath],
  );

  const getComboboxValue = (): string => {
    if (decodedHost !== "") {
      return decodedHost;
    }

    const isAllWorkspaceHosts = isEqual(
      hostsInUrlParams,
      workspaceUrlHosts.data.map((option) => option.value),
    );
    const hasMultipleHosts = workspaceUrlHosts.data.length > 1;

    if (isAllWorkspaceHosts && hasMultipleHosts) {
      return DEFAULT_URL_HOST;
    }

    return hostsInUrlParams[0] ?? DEFAULT_URL_HOST;
  };

  return (
    <div>
      {workspaceUrlHosts.isLoading ? (
        <SimpleSkeletonLoader width="100%" height="24px" />
      ) : (
        <Combobox
          options={extendedURLOptions}
          value={getComboboxValue()}
          onChange={onChange}
          startEnhancer={() => <BsGlobe2 className="h-4 w-4" />}
          endEnhancer={() => (
            <BsCaretDownFill className="h-2 w-2 text-subtle" />
          )}
          isDisabled={disabled}
        />
      )}
    </div>
  );
};

export default AnalyticsUrlHostCombobox;
