import * as React from "react";

import TourStepTrigger from "@components/flows/TourStepTrigger";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { PublishPageButton } from "@editor/components/header/PublishPageButton";
import { PublishPageButtonWithCallout } from "@editor/components/header/PublishPageButtonWithCallout";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { useSubscriptionTier } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import usePublishOnClickInfo from "@editor/hooks/usePublishingInfo";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import {
  selectDoesExistDraftElement,
  selectEditorMode,
  selectInternalDebugModeOn,
  setDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { getStoreData } from "@editor/utils/project-utils";

import Button from "@replo/design-system/components/button";
import { MdQuestionMark } from "react-icons/md";
import { RiSettings3Fill } from "react-icons/ri";

export default function PageControlButtons() {
  const modal = useModal();
  const { draftElementType, pageEditorData } = usePublishOnClickInfo();

  const { project } = useCurrentProjectContext();
  const store = getStoreData(project);
  const logEvent = useLogAnalytics();
  const subscriptionTier = useSubscriptionTier();

  const isEditMode = useEditorSelector(selectEditorMode) === EditorMode.edit;
  const draftElementExists = useEditorSelector(selectDoesExistDraftElement);
  const isDebugMode = useIsDebugMode();

  if (!draftElementType) {
    return null;
  }

  const isNewLeftPanelEnabled = checkIfNewEditorPanelsUIIsEnabled();

  return (
    <>
      {isDebugMode && <DebugMenuButton />}

      {!isNewLeftPanelEnabled && (
        <Button
          id="get-help-button"
          variant="secondary"
          size="base"
          icon={<MdQuestionMark />}
          tooltipText="Get Help"
          onClick={() => {
            logEvent("user.help.requested", {
              billingPlan: subscriptionTier,
              from: "button",
            });
            modal.openModal({
              type: "supportModal",
            });
          }}
        />
      )}

      {isEditMode && draftElementExists && !isNewLeftPanelEnabled && (
        <Button
          id="page-settings-button"
          variant="secondary"
          size="base"
          icon={<RiSettings3Fill />}
          tooltipText={`${pageEditorData.singularDisplayName} Settings`}
          onClick={() => {
            modal.openModal({
              type: "updateElementModal",
            });
          }}
        />
      )}
      <TourStepTrigger step="step-5">
        <Button
          onClick={() => {
            modal.openModal({
              type: "projectMembershipModal",
            });
          }}
          variant="secondary"
          size="base"
          tooltipText="Share Project"
        >
          Share
        </Button>
      </TourStepTrigger>
      {store?.shopifyUrl ? (
        <PublishPageButton isPreviewBeforePublishView />
      ) : (
        <PublishPageButtonWithCallout />
      )}
    </>
  );
}

function DebugMenuButton() {
  const dispatch = useEditorDispatch();
  const isDebugPanelVisible = useEditorSelector(selectInternalDebugModeOn);
  const handleDebug = React.useCallback(() => {
    dispatch(setDebugPanelVisibility(!isDebugPanelVisible));
  }, [dispatch, isDebugPanelVisible]);

  return (
    <Button
      variant="secondary"
      size="base"
      icon={<>🔮</>}
      onClick={handleDebug}
      tooltipText="Replo Debug Menu"
    />
  );
}
