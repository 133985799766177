import * as React from "react";

import Banner from "@common/designSystem/Banner";
import Scrollable from "@editor/components/common/designSystem/Scrollable";
import { toast } from "@editor/components/common/designSystem/Toast";
import DraftComponentPaneHeader from "@editor/components/DraftComponentPaneHeader";
import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import DraftComponentPane from "@editor/components/editor/page/element-editor/components/DraftComponentPane";
import TabOptions from "@editor/components/editor/page/element-editor/components/TabOptions";
import TourStepTrigger from "@editor/components/flows/TourStepTrigger";
import VersionHistoryPane from "@editor/components/version-history/VersionHistoryPane";
import { useEditorPerformanceContext } from "@editor/contexts/editor-performance.context";
import useRightBarVisibility from "@editor/hooks/useRightBarVisibility";
import { analytics, trackError } from "@editor/infra/analytics";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import {
  selectEditorMode,
  selectUpdatesSinceLastRequestFinished,
} from "@editor/reducers/core-reducer";
import { selectRightBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { isDevelopment } from "@editor/utils/env";

import classNames from "classnames";
import {
  ErrorBoundary,
  ErrorBoundaryDefaultFallback,
} from "replo-runtime/shared/ErrorBoundary";

const RightBar = React.memo(function RightBar() {
  const editorMode = useEditorSelector(selectEditorMode);
  const currentTab = useEditorSelector(selectRightBarActiveTab);
  const isRightBarVisible = useRightBarVisibility();
  const { rightBarElementRef } = useEditorPerformanceContext();

  const handleRenderError = React.useCallback(
    (error: unknown, info: React.ErrorInfo) => {
      if (isDevelopment) {
        console.error("[REPLO] Component rendering error in RightBar", {
          error,
          reactErrorInfo: info,
        });
      }
      toast({
        type: "error",
        header: "Something went wrong",
        message:
          "Please try refreshing the page or reach out to support if the error persists.",
        cta: "Contact Support",
        ctaHref: "mailto:support@replo.app",
      });
      trackError(error);
    },
    [],
  );

  if (!isRightBarVisible) {
    return null;
  }

  const isNewLeftBarEnabled = checkIfNewEditorPanelsUIIsEnabled();

  // https://stackoverflow.com/questions/14962468/how-can-i-combine-flexbox-and-vertical-scroll-in-a-full-height-app
  return (
    <div
      className="flex w-full flex-1 flex-col items-end"
      ref={rightBarElementRef}
    >
      <div
        style={{ width: RIGHT_BAR_WIDTH }}
        // TODO (Yuxin, 2022-02-21) I'm just the messenger, but apparently we
        // need h-0 so that the <RightBar is positioned properly */

        // NOTE (Gabe 2023-06-12): The z-index is set to 0 so that a new
        // stacking context is created so we can use local z-indexes to
        // position sticky headers that won't interfere with the rest of the
        // page.
        className={classNames("z-0 h-0 grow", {
          "border-l border-slate-200 shadow-lg": isNewLeftBarEnabled,
        })}
      >
        {editorMode === EditorMode.edit && (
          // NOTE (Sebas, 2024-10-24): This is now on the RightBar and not in the
          // DraftComponentPane because it was interfering with the scrollable
          // component and causing weird horizontal and vertical scroll behavior.
          <div className="px-2 pt-2 z-10 sticky top-0 flex flex-col gap-1 bg-white">
            <DraftComponentPaneHeader />
            <TourStepTrigger step="step-3">
              <TabOptions currentTab={currentTab} containerClassName="gap-2" />
            </TourStepTrigger>
          </div>
        )}
        <Scrollable
          // NOTE (Gabe 2023-06-08): The bg-color must exist on this component
          // so that it exists in the same stacking context as the Canvas
          // (positioned) and appropriately overlays it.
          className={classNames("h-full w-[250px] bg-white pb-2")}
          type={editorMode === EditorMode.edit ? "both" : "vertical"}
          shouldShowScrollbar={false}
        >
          {/* Note (Noah, 2022-08-16, REPL-3607): This banner is here because we're
            suspicious of a bug where we think that user updates which are made under
            slow or volatile network conditions may sometimes result in lost work. This
            could possibly happen if the element update request was VERY slow to respond,
            and a lot of updates to the local element don't actually get sent to the
            backend because there's already a request in progress, then the user refreshes
            the page or something and loses the updates (since they've never been sent to
            the backend). This might not happen, but we're putting this banner here if they've
            made some arbitrary number of updates which would currently be lost if the page
            were to refresh, so that we can see it in full stories and debug. */}
          <UpdatesWarningBanner />
          <ErrorBoundary
            fallback={
              <div className="p-2 text-xs">
                <ErrorBoundaryDefaultFallback />
              </div>
            }
            onError={handleRenderError}
          >
            {editorMode === EditorMode.edit ? (
              <DraftComponentPane />
            ) : (
              <VersionHistoryPane />
            )}
          </ErrorBoundary>
        </Scrollable>
      </div>
    </div>
  );
});

const UpdatesWarningBanner: React.FC<{}> = () => {
  const updatesSinceLastRequestFinished = useEditorSelector(
    selectUpdatesSinceLastRequestFinished,
  );

  const showBanner = updatesSinceLastRequestFinished >= 30;

  React.useEffect(() => {
    if (showBanner) {
      analytics.logEvent("editor.pendingUpdatesWarningBannerShown", {});
    }
  }, [showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <Banner backgroundColor="bg-red-200" className="color-white p-4 text-xs">
      More than {updatesSinceLastRequestFinished} pending unsaved updates. This
      may be a bug - please reach out to support@replo.app
    </Banner>
  );
};

export default RightBar;
