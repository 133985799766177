import { getAbsoluteOffsetY } from "./measure";

/**
 * Courtesy of GPT, this function accepts an element and finds the first
 * ancestor of this element is scrollable. Useful for when we have to scroll
 * to URL hashmarks but aren't sure which element to scroll.
 */
function findScrollableAncestor(element: Element | null) {
  while (element) {
    if (element === document.body) {
      // Reached the body element, so just return it
      return element;
    }
    const overflowY = window.getComputedStyle(element).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";
    if (isScrollable && element.scrollHeight > element.clientHeight) {
      return element;
    }
    element = element.parentElement;
  }
  return null;
}

export function scrollToElement(
  element: Element,
  options?: { topOffset?: number; smoothScroll?: boolean },
) {
  const ownerWindow = element.ownerDocument.defaultView;
  if (!ownerWindow) {
    return;
  }
  const { topOffset = 0, smoothScroll = false } = options || {};
  const elementPosition = getAbsoluteOffsetY(element);
  const offsetPosition = elementPosition - topOffset;

  let elementToScroll: Element | Window = ownerWindow;

  // Note (Noah, 2023-11-07, USE-525): If themes do something like set height:
  // 100% on the html element, then then window.scrollTo doesn't actually
  // scroll. In these situations, we need to find the scrollable ancestor of the
  // element which we're trying to scroll into view, and scroll that instead.
  if (
    ownerWindow.document.documentElement.scrollHeight ===
    ownerWindow.innerHeight
  ) {
    const scrollableAncestor = findScrollableAncestor(element);
    if (scrollableAncestor) {
      elementToScroll = scrollableAncestor;
    }
  }

  elementToScroll.scrollTo({
    top: offsetPosition,
    behavior: smoothScroll ? "smooth" : "auto",
  });
}
