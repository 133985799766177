import type { Option } from "replo-runtime/shared/types";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type SelectableButtonsProps<Value extends string> = {
  options: (Option & {
    value: Value;
    tooltip?: string;
    enhancer?: React.ReactNode;
    subLabel?: string;
  })[];
  value: Value[];
  onChange: (value: Value[]) => void;
  multiSelect: boolean;
  selectedClassName?: string | ((value: Value) => string);
  unselectedClassName?: string | ((value: Value) => string);
  className?: string;
  buttonClassName?: string;
  textClassName?: string;
  /* This prop is helpful when we a have an option called Other */
  inputEnhancer?: React.ReactNode;
  renderOptionChildren?: (
    option: Option & {
      value: Value;
      tooltip?: string;
      enhancer?: React.ReactNode;
      subLabel?: string;
    },
  ) => React.ReactNode;
};

/*
NOTE (Evan, 4/12/23): This helper function allows us to pass a className that is a function
of the values (this way, the styles can depend on the selected value)
*/
const processClassName = <Value extends string>(
  className?: string | ((value: Value) => string),
  value?: Value,
): string | undefined => {
  if (className === undefined || value === undefined) {
    return undefined;
  } else if (typeof className === "string") {
    return className;
  }
  return className(value);
};

const SelectableButtons = <Value extends string>({
  options,
  renderOptionChildren,
  value: selectedValues,
  selectedClassName,
  unselectedClassName,
  className,
  buttonClassName,
  textClassName,
  onChange,
  multiSelect,
  inputEnhancer,
}: SelectableButtonsProps<Value>) => {
  return (
    <div className={twMerge("flex flex-wrap gap-3", className)}>
      {options.map((option) => {
        const processedSelectedClassName = processClassName(
          selectedClassName,
          option.value,
        );
        const processedUnselectedClassName = processClassName(
          unselectedClassName,
          option.value,
        );
        return (
          <Tooltip content={option.tooltip} key={option.label} triggerAsChild>
            <Button
              variant="primary"
              size="base"
              className={classNames(
                twMerge("px-4 py-4 text-black", buttonClassName),
                {
                  [twMerge(
                    "bg-blue-500 text-white",
                    processedSelectedClassName,
                  )]: selectedValues.includes(option.value),
                  [twMerge(
                    "bg-slate-100 hover:bg-blue-100",
                    processedUnselectedClassName,
                  )]: !selectedValues.includes(option.value),
                },
              )}
              onClick={() => {
                if (selectedValues.includes(option.value)) {
                  onChange(
                    [...selectedValues].filter((v) => v !== option.value),
                  );
                } else {
                  if (multiSelect) {
                    onChange([...selectedValues, option.value]);
                  } else {
                    onChange([option.value]);
                  }
                }
              }}
              startEnhancer={
                option.enhancer ? () => option.enhancer : undefined
              }
            >
              {renderOptionChildren ? (
                renderOptionChildren(option)
              ) : (
                <span className={textClassName}>{option.label}</span>
              )}
            </Button>
          </Tooltip>
        );
      })}
      {inputEnhancer}
    </div>
  );
};

export default SelectableButtons;
