import type { MetricName, RangeResult } from "schemas/generated/analyticsRead";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import PaginationMetrics from "@editor/components/analytics/PaginationMetrics";
import { SortButton } from "@editor/components/analytics/SortIndicator";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import { successToast } from "@editor/components/common/designSystem/Toast";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import {
  calculateDelta,
  encodeUrlHost,
  getUrlWithoutQueryParams,
  sanitizePageUrlPath,
} from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import AnalyticsFilters from "@/features/analytics/AnalyticsFilters";
import {
  DEFAULT_QUERY,
  RESULTS_PER_PAGE,
  TABLE_METRICS,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { GatheringData } from "@/features/analytics/GatheringData";
import { NoData } from "@/features/analytics/NoData";
import useAnalyticsRead from "@/features/analytics/useAnalyticsRead";
import { getDefaultAnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import useSearchAnalyticsRead from "@/features/analytics/useSearchAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import Tooltip from "@replo/design-system/components/tooltip";
import {
  generatePath,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useInterval } from "replo-utils/react/use-interval";

const FIRST_TIME_ON_ANALYTICS_PARAM = "firstTimeOnAnalytics";

const AllMetricsRow: React.FC<{
  urlPath: string;
  mainRangeResultMetrics: RangeResult["metrics"];
  compareAtRangeResultMetrics: RangeResult["metrics"];
  metadata: RangeResult["metadata"];
  workspaceId: string;
}> = ({
  urlPath,
  mainRangeResultMetrics,
  compareAtRangeResultMetrics,
  metadata,
  workspaceId,
}) => {
  const { title, url } = metadata;
  const analytics = useLogAnalytics();
  const navigate = useNavigate();

  const mainDomain = url.match(/^(?:https?:\/\/)?([^/]+)/);

  const compareAtRangeViewsArray = compareAtRangeResultMetrics.views ?? [];
  const doesCompareValueExist = (compareAtRangeViewsArray[0] ?? 0) > 0;

  const adjustedUrlPath = sanitizePageUrlPath(urlPath);

  const handlePageNameClick = () => {
    analytics("analytics.record.click", {
      from: "analytics_overview",
    });

    const newPath = generatePath(routes.analytics.pageDetails, {
      workspaceId,
      pageUrlPath: encodeURIComponent(urlPath),
      host: encodeUrlHost(mainDomain?.[1] ?? ""),
    });

    const currentSearchParams = new URLSearchParams(location.search);
    const queryParam = currentSearchParams.get("query");

    const newUrl = queryParam
      ? `${newPath}?query=${encodeURIComponent(queryParam)}`
      : newPath;

    navigate(newUrl);
  };

  return (
    <TableRow key={url}>
      <TableCell className="flex justify-left items-center">
        <div className="flex flex-col gap-1 w-[300px]">
          <Tooltip triggerAsChild content={title} delay={800} align="start">
            <span
              className="text-blue-600 truncate hover:underline cursor-pointer"
              onClick={handlePageNameClick}
            >
              {title}
            </span>
          </Tooltip>
          <Tooltip
            triggerAsChild
            content={getUrlWithoutQueryParams(url)}
            delay={800}
            className="overflow-hidden break-words"
          >
            <Link
              to={getUrlWithoutQueryParams(url) ?? ""}
              target="_blank"
              className="text-xs font-light text-slate-500 hover:underline truncate"
              rel="noopener noreferrer"
              onClick={() => {
                analytics("analytics.url.open", {});
              }}
            >
              {adjustedUrlPath}
            </Link>
          </Tooltip>
        </div>
      </TableCell>
      {TABLE_METRICS.map((metric) => {
        const mainRangeResultCurrentMetricArray =
          mainRangeResultMetrics[metric.key as MetricName] ?? [];
        const compareAtRangeResultCurrentMetricArray =
          compareAtRangeResultMetrics[metric.key as MetricName] ?? [];

        return (
          <TableCell key={metric.key}>
            <MetricWithDelta
              name={metric.key}
              value={mainRangeResultCurrentMetricArray[0] ?? 0}
              delta={calculateDelta(
                mainRangeResultCurrentMetricArray[0],
                compareAtRangeResultCurrentMetricArray[0],
              )}
              doesCompareValueExist={doesCompareValueExist}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const AllMetricsRows: React.FC<{
  mainRangeResults: RangeResult[];
  compareAtRangeResults: RangeResult[];
  workspaceId: string;
}> = ({ mainRangeResults, compareAtRangeResults, workspaceId }) => {
  return (
    <>
      {mainRangeResults.map((mainRangeResult) => {
        const {
          urlPath,
          metadata,
          metrics: mainRangeResultMetrics,
        } = mainRangeResult;

        const compareAtRangeResult = compareAtRangeResults.find(
          (rangeResult) => rangeResult.urlPath === urlPath,
        );

        const compareAtRangeResultMetrics = compareAtRangeResult?.metrics ?? {};

        return (
          <AllMetricsRow
            key={metadata.url}
            workspaceId={workspaceId}
            urlPath={urlPath}
            mainRangeResultMetrics={mainRangeResultMetrics}
            compareAtRangeResultMetrics={compareAtRangeResultMetrics}
            metadata={metadata}
          />
        );
      })}
    </>
  );
};

export const AllMetricsTable: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const shopifyURLOptions = useWorkspaceUrlHosts(workspaceId);

  return shopifyURLOptions.isLoading ? (
    <SimpleSkeletonLoader height="500" width="100%" />
  ) : (
    <LoadedAllMetricsTable
      shopifyURLOptions={shopifyURLOptions.data}
      workspaceId={workspaceId ?? ""}
    />
  );
};

type LoadedAllMetricsTableProps = {
  shopifyURLOptions: { value: string; label: string }[];
  workspaceId: string;
};

const LoadedAllMetricsTable: React.FC<LoadedAllMetricsTableProps> = ({
  workspaceId,
}) => {
  const { query, setQuery, getParamValue, setDecompressedParams } =
    useAnalyticsQueryContext();

  const [search, setSearch] = React.useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const firstTimeOnAnalytics =
    searchParams.get(FIRST_TIME_ON_ANALYTICS_PARAM) !== null;

  const {
    hasAnyData,
    rangeResults: analyticsRangeResults,
    totalRowsCount: analyticsTotalRowsCount,
    isLoading: isAnalyticsLoading,
    mainRangeId,
    compareAtRangeId,
    refetch: refetchAnalyticsQuery,
  } = useAnalyticsRead(workspaceId);

  const {
    rangeResults: searchRangeResults,
    totalRowsCount: searchTotalRowsCount,
    isLoading: isSearchLoading,
  } = useSearchAnalyticsRead(workspaceId, search);

  const isLoading = isAnalyticsLoading || isSearchLoading;
  // NOTE (Kurt, 2024-10-13): If the search is not empty, we use the search results,
  // otherwise we use the analytics results.
  const rangeResults =
    search !== "" ? searchRangeResults : analyticsRangeResults;
  const totalRowsCount =
    search !== "" ? searchTotalRowsCount : analyticsTotalRowsCount;
  const loadedButNoDataForWorkspace = !isLoading && !hasAnyData;

  const mainRangeResults = rangeResults?.filter(
    (rangeResultObj) => rangeResultObj.rangeId === mainRangeId,
  ) as RangeResult[];

  const compareAtRangeResults = rangeResults?.filter(
    (rangeResultObj) => rangeResultObj.rangeId === compareAtRangeId,
  ) as RangeResult[];

  const queryHasNoData =
    !isLoading &&
    (totalRowsCount === 0 || !totalRowsCount || !mainRangeResults);

  /**
   * NOTE Ben 2024-11-01: When we first load analytics after an install, there will be no data for
   * at least a few seconds. In this case, we want to do refetch on the analytics data every
   * 10 seconds. If we've loaded before though, or there's just no query data, we don't want to
   * do this, in which case we pass `null` to the `useInterval` which makes it skip.
   */
  useInterval(
    () => {
      void refetchAnalyticsQuery();
    },
    loadedButNoDataForWorkspace ? 10_000 : null,
  );

  // NOTE (Max, 2024-08-26): pageNumber is the value we show to the users. It starts
  // from 1. However, when querying the backend, we'll need to subtract 1, as
  // ClickHouse's OFFSET starts at 0
  const [currentPageNumber, setCurrentPageNumber] = React.useState<number>(1);

  const resultsPerPage = RESULTS_PER_PAGE;
  // TODO (Max, 2024-08-27): Uncomment once we allow user to specify how many
  // pages per result they want

  // const [resultsPerPage, setResultsPerPage] =
  //   React.useState<number>(RESULTS_PER_PAGE);

  const handleOnPageNumberChange = (clickedPageNumber: number) => {
    if (clickedPageNumber !== currentPageNumber) {
      setCurrentPageNumber(clickedPageNumber);

      // NOTE (Max, 2024-08-26): Relevant to have it as state variable once we allow users
      // to choose how many results per page they want
      const offset = (clickedPageNumber - 1) * resultsPerPage;
      setQuery({
        ...query,
        offset,
      });
    }
  };

  const handleMetricSortClick = (sortMetric: MetricName) => {
    const isActiveMetric = query.sortMetric === sortMetric;
    resetPagination();
    setQuery({
      ...query,
      sortMetric,
      order: getOrder(isActiveMetric),
      offset: 0,
    });
  };

  const resetAllFilters = () => {
    setDecompressedParams(
      getDefaultAnalyticsUrlParams(getParamValue("urlHosts")),
    );
    setQuery({
      ...DEFAULT_QUERY,
    });
    resetPagination();
    setSearch("");
  };

  const resetPagination = () => {
    setCurrentPageNumber(1);
  };

  const getOrder = (isActiveMetric: boolean) => {
    if (!isActiveMetric) {
      return query.order;
    }
    return query.order === "DESC" ? "ASC" : "DESC";
  };

  React.useEffect(() => {
    if (firstTimeOnAnalytics) {
      successToast(
        "Analytics Connected",
        "Your store has been connected and tracking has started!",
      );
      setSearchParams(
        (params) => {
          params.delete(FIRST_TIME_ON_ANALYTICS_PARAM);
          return new URLSearchParams(params);
        },
        { replace: true },
      );
    }
  }, [firstTimeOnAnalytics, setSearchParams]);

  const DisplayTableContent = () => {
    if (isLoading) {
      return (
        // TODO (Max, 2024-09-11): Placeholder for loading states, which will be added in
        // https://linear.app/replo/issue/REPL-13561/
        <></>
      );
    } else if (queryHasNoData) {
      return (
        <TableBody>
          <TableRow className="border-b-0">
            <TableCell
              colSpan={TABLE_METRICS.length + 1}
              className="h-[300px] w-full"
            >
              <div className="flex justify-center items-end h-full w-full">
                {loadedButNoDataForWorkspace ? (
                  <GatheringData />
                ) : (
                  <NoData resetAll={resetAllFilters} />
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    } else {
      return (
        <TableBody>
          <AllMetricsRows
            workspaceId={workspaceId}
            mainRangeResults={mainRangeResults}
            compareAtRangeResults={compareAtRangeResults}
          />
        </TableBody>
      );
    }
  };

  return (
    <div className="relative min-w-screen">
      <div className="flex w-full flex-col space-y-[8px]">
        <AnalyticsFilters
          resetPagination={resetPagination}
          search={{
            value: search,
            onChange: setSearch,
          }}
        />
        <Table>
          <TableHeader className="bg-subtle font-medium text-xs text-default">
            <TableRow>
              <TableHead className="w-[300px]">Page Name</TableHead>
              {TABLE_METRICS.map((metric) => (
                <TableHead key={metric.key}>
                  <div className="flex items-center gap-2">
                    <span>{metric.label}</span>
                    {/* TODO (Max, 2024-10-09): Once Tooltip gets moved into packages/design-system, add 
                    this SortButton as a prop of the shadcn's tableHead component, so we can re-use it
                    for other tables. For now, we'll have to duplicate the Sort logic
                     */}
                    <SortButton
                      sortMetric={metric.key}
                      sortOrder={query.order}
                      isActiveMetric={query.sortMetric === metric.key}
                      onChange={() => handleMetricSortClick(metric.key)}
                    />
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          {DisplayTableContent()}
        </Table>
      </div>
      {totalRowsCount && totalRowsCount > 0 ? (
        <div className="grid grid-cols-2 mt-5 mb-4">
          <div className="col-start-2">
            <PaginationMetrics
              currentPageNumber={currentPageNumber}
              totalRowsCount={totalRowsCount}
              resultsPerPage={resultsPerPage}
              handleOnPageNumberChange={handleOnPageNumberChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
