import * as React from "react";

import Button from "@replo/design-system/components/button";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import { DayPicker } from "react-day-picker";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;
export default function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={clsxMerge("p-3", className)}
      classNames={{
        months:
          "flex flex-col sm:flex-row space-y-4 sm:gap-4 sm:space-y-0 relative",
        month: "space-y-4",
        month_caption: "flex justify-center pt-1.5 items-center",
        caption_label: "text-slate-800 text-base font-semibold",
        nav: "space-x-1 flex justify-between absolute w-full z-5",
        button_previous:
          "flex items-center justify-center h-8 w-8 border border-slate-200 rounded-[4px] bg-transparent p-0",
        button_next:
          "flex items-center justify-center h-8 w-8 border border-slate-200 rounded-[4px] bg-transparent p-0",
        table: "w-full border-collapse space-y-1",
        weekdays: "flex w-full",
        weekday:
          "text-slate-500 rounded-md w-[42px] h-[24px] font-normal text-[14px]",
        week: "flex w-full mt-1",
        day: clsxMerge(
          "relative p-0 text-center text-sm focus-within:relative focus-within:z-20",
        ),
        hidden: "invisible",
        range_start: "text-white",
        range_end: "text-white",
        ...classNames,
      }}
      modifiers={{
        saturday: [(date) => date.getDay() === 6],
        sunday: [(date) => date.getDay() === 0],
        currentDay: [
          (date) =>
            date.getDate() == new Date().getDate() &&
            date.getMonth() == new Date().getMonth(),
        ],
        anyDay: [() => true],
      }}
      components={{
        DayButton({ day, modifiers, className, ...buttonProps }) {
          return (
            <div
              className={clsxMerge(
                className,
                (modifiers.range_start || modifiers.range_end) && "bg-blue-50",
                modifiers.range_start && "rounded-s-[8px]",
                modifiers.range_end && "rounded-e-[8px]",
              )}
            >
              <Button
                className={clsxMerge(
                  className,
                  "h-[42px] w-[42px] p-0 font-normal",
                  modifiers?.today && "text-slate-800",
                  modifiers?.selected &&
                    "bg-blue-600 text-white hover:bg-blue-600 hover:text-white focus:bg-blue-600 rounded-[8px]",
                  modifiers?.outside && "pointer-events-none text-slate-200",
                  modifiers?.disabled && "pointer-events-none text-slate-200",
                  modifiers?.hidden && "invisible",
                  modifiers.range_middle &&
                    "!bg-blue-50 text-slate-800 hover:bg-blue-50 hover:text-slate-800 rounded-none",
                  modifiers.outside &&
                    modifiers.selected &&
                    "bg-blue-50/40 text-slate-400 opacity-50",
                  modifiers.saturday && "rounded-r-[8px]",
                  modifiers.sunday && "rounded-l-[8px]",
                  modifiers.currentDay && "text-base font-bold",
                  modifiers.anyDay &&
                    !modifiers.disabled &&
                    !modifiers.selected &&
                    !modifiers.range_middle &&
                    "rounded-[8px] hover:bg-blue-50",
                )}
                {...buttonProps}
                aria-selected={
                  modifiers.selected || buttonProps["aria-selected"]
                }
                aria-disabled={
                  modifiers.disabled || buttonProps["aria-disabled"]
                }
                aria-hidden={modifiers.hidden || buttonProps["aria-hidden"]}
                ref={React.useRef<HTMLButtonElement>(null)}
                variant="no-style"
                size="sm"
                hasMinDimensions={false}
              >
                {day.date.getDate()}
              </Button>
            </div>
          );
        },
        Chevron({ orientation, disabled, className }) {
          const Component =
            orientation === "left" ? BsChevronLeft : BsChevronRight;
          return (
            <Component
              className={clsxMerge("w-4 h-4", className)}
              aria-disabled={disabled}
            />
          );
        },
      }}
      {...props}
    />
  );
}
