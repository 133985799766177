import type { ComponentDimensionsMap } from "@reducers/ai-reducer";
import type { Component } from "replo-runtime";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { AIStreamingOperation } from "schemas/generated/ai";

import * as React from "react";

import * as coreActions from "@actions/core-actions";
import { analytics } from "@editor/infra/analytics";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  setShowWelcomeModal,
  useAiApplySavedStylesMutation,
  useAiMobileResponsiveMutation,
  useAiMultiMutation,
  useAiTextV2Mutation,
} from "@editor/reducers/ai-reducer";
import {
  selectComponentDataMapping,
  selectDraftComponent,
  selectDraftComponentId,
  selectDraftElementId,
  selectEditorMode,
  selectProjectId,
  selectRootComponent,
  selectRootComponentId,
  selectStreamingUpdateActions,
  selectStreamingUpdateId,
  selectStreamingUpdateIsStreaming,
  setDraftElement,
  setEditorMode,
  setStreamingUpdate,
} from "@editor/reducers/core-reducer";
import { setSharedState } from "@editor/reducers/paint-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { EditorMode } from "@editor/types/core-state";
import { getEditorComponentNode } from "@editor/utils/component";

import {
  scrollToComponentId,
  selectActiveCanvas,
  selectCanvasesDocuments,
  selectCanvasLoadingType,
  selectVisibleEditorCanvases,
  setCanvasInteractionMode,
} from "@/features/canvas/canvas-reducer";
import useSetActiveCanvas from "@/features/canvas/useSetActiveCanvas";
import { useSetDeltaXY } from "@/features/canvas/useSetDeltaXY";
import omit from "lodash-es/omit";
import { forEachComponentAndDescendants } from "replo-runtime";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

export type AIStreamingSingleParams =
  | {
      type: "textV2";
      userPrompt: string;
    }
  | {
      type: "mobileResponsive";
    }
  | {
      type: "savedStyles";
    };

type AIStreamingParams =
  | AIStreamingSingleParams
  | {
      type: "multi";
      operations: AIStreamingSingleParams[];
    };

export type MenuTriggerSource =
  | "pill"
  | "previewMenu"
  | "shortcut"
  | "rightClick"
  | "onboarding";
type SetIsMenuOpenFn = (
  ...[args]:
    | [isOpen: true, source: MenuTriggerSource]
    | [isOpen: false, source?: MenuTriggerSource]
) => void;

interface AIStreamingContextType {
  menuState: AIMenuState;
  setMenuState: React.Dispatch<React.SetStateAction<AIMenuState>>;
  isMenuOpen: boolean;
  setIsMenuOpen: SetIsMenuOpenFn;
  isTextSelected: boolean;
  generationMode: AIStreamingOperation | null;
  generationSource: MenuTriggerSource | null;
  queueGeneration: (params: AIStreamingParams) => void;
  hasQueuedGeneration: boolean;
  initiateGeneration: (params: AIStreamingParams) => Promise<void>;
  abort: () => void;
  isFinished: boolean;
  applyChanges: () => void;
  discardChanges: () => void;
  status: AIStatus;
  completionPercentage?: number;
}
export type AIStatus =
  | "preGeneration"
  | "generationInitialized"
  | "generating"
  | "finishedGenerating"
  | "autoApplyingChanges";

type AIMenuState =
  | "closed"
  | "select"
  | "template"
  | "text"
  | "text.brandDetails";

const AIStreamingContext = React.createContext<
  AIStreamingContextType | undefined
>(undefined);
AIStreamingContext.displayName = "AIStreamingContext";

export const useAIStreaming = (): AIStreamingContextType => {
  const context = React.useContext(AIStreamingContext);
  if (context === undefined) {
    throw new Error(
      "useAIStreaming must be used within an AIStreamingProvider",
    );
  }
  return context;
};

export const AIStreamingProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();

  const elementId = useEditorSelector(selectDraftElementId);
  const isStreaming = useEditorSelector(selectStreamingUpdateIsStreaming);
  const isStreamingFinished = isStreaming === false;

  const [menuState, setMenuState] = React.useState<AIMenuState>("closed");
  const isMenuOpen = menuState !== "closed";
  const isTextSelected = menuState.startsWith("text");

  const [generationMode, setGenerationMode] =
    React.useState<AIStreamingOperation | null>(null);

  const [generationSource, setGenerationSource] =
    React.useState<MenuTriggerSource | null>(null);

  const shouldAutoApplyChanges = generationSource === "onboarding";

  React.useEffect(() => {
    if (!isMenuOpen) {
      setGenerationMode(null);
    }
  }, [isMenuOpen]);

  const setIsMenuOpen: SetIsMenuOpenFn = React.useCallback(
    (...[val, source]) => {
      if (val) {
        analytics.logEvent("ai.menu.triggered", {
          elementId,
          source,
        });
      }
      setMenuState(val ? "select" : "closed");
      setGenerationSource(val ? source : null);
    },
    [elementId],
  );

  const [triggerAiTextMutation] = useAiTextV2Mutation();
  const [triggerAiMobileResponsiveMutation] = useAiMobileResponsiveMutation();
  const [triggerAiApplySavedStylesMutation] = useAiApplySavedStylesMutation();
  const [triggerAiMultiMutation] = useAiMultiMutation();

  const [componentOrderMap, setComponentOrderMap] = React.useState<
    Map<string, number> | undefined
  >();

  const abortRef = React.useRef<(() => void) | null>(null);

  const measureTextComponentDimensions =
    useMeasureDraftComponentAndChildrenDimensions(
      (component) => component.type === "text",
    );
  const setActiveCanvas = useSetActiveCanvas();

  const editorMode = useEditorSelector(selectEditorMode);
  const actions = useEditorSelector(selectStreamingUpdateActions);

  const status: AIStatus = React.useMemo(() => {
    if (editorMode === EditorMode.aiGeneration) {
      if (isStreaming === false) {
        return shouldAutoApplyChanges
          ? "autoApplyingChanges"
          : "finishedGenerating";
      } else if (actions?.length) {
        return "generating";
      }
      return "generationInitialized";
    }
    return "preGeneration";
  }, [editorMode, isStreaming, actions?.length, shouldAutoApplyChanges]);

  const openAllCollapsibles = useOpenAllCollapsibles();

  const setDeltaXY = useSetDeltaXY();

  const initiateGeneration = React.useCallback(
    async (params: AIStreamingParams, onFinish?: () => void) => {
      // NOTE (Gabe 2024-08-01): If another generation has already been initiated,
      // this function is a no-op.
      if (status !== "preGeneration") {
        return;
      }

      const rootComponent = selectRootComponent(store.getState());

      const draftComponent = selectDraftComponent(store.getState());
      const projectId = selectProjectId(store.getState());

      const componentToEdit = draftComponent ?? rootComponent;

      // NOTE (Gabe 2024-08-01): This shouldn't happen but the check is here to
      // make the types happy.
      if (!componentToEdit || !projectId) {
        console.error("No Component or project id");
        return;
      }
      setCompletionPercentage(0);

      dispatch(setEditorMode(EditorMode.aiGeneration));
      dispatch(setCanvasInteractionMode("locked"));
      setGenerationMode(params.type);

      setComponentOrderMap(buildComponentOrderMap(componentToEdit));

      dispatch(
        scrollToComponentId({
          componentId: componentToEdit.id,
          zoom: true,
          centerHorizontally: true,
        }),
      );

      await exhaustiveSwitch(params)({
        textV2: async (params) => {
          const { userPrompt } = params;
          openAllCollapsibles();
          const textComponentDimensions = measureTextComponentDimensions();

          const { abort } = triggerAiTextMutation({
            component: componentToEdit,
            userPrompt,
            textComponentDimensions,
            projectId,
            onFinish,
          });
          abortRef.current = abort;
        },
        mobileResponsive: async () => {
          const { abort } = triggerAiMobileResponsiveMutation({
            component: componentToEdit,
            projectId,
          });
          abortRef.current = abort;

          setActiveCanvas({ canvas: "mobile", source: "ai" });
        },
        savedStyles: async () => {
          const { abort } = triggerAiApplySavedStylesMutation({
            component: componentToEdit,
            projectId,
          });
          abortRef.current = abort;
        },
        multi: async (params) => {
          const textOperation = params.operations.find(
            (op) => op.type === "textV2",
          );
          const mobileResponsiveOperation = params.operations.find(
            (op) => op.type === "mobileResponsive",
          );
          const savedStylesOperation = params.operations.find(
            (op) => op.type === "savedStyles",
          );
          const { abort } = triggerAiMultiMutation({
            component: componentToEdit,
            projectId,
            textV2: textOperation ? omit(textOperation, "type") : undefined,
            mobileResponsive: mobileResponsiveOperation
              ? omit(mobileResponsiveOperation, "type")
              : undefined,
            savedStyles: savedStylesOperation
              ? omit(savedStylesOperation, "type")
              : undefined,
            onFinish,
          });
          abortRef.current = abort;
        },
      });
    },
    [
      status,
      store,
      dispatch,
      setActiveCanvas,
      measureTextComponentDimensions,
      openAllCollapsibles,
      triggerAiMobileResponsiveMutation,
      triggerAiTextMutation,
      triggerAiApplySavedStylesMutation,
      triggerAiMultiMutation,
    ],
  );

  const [queuedGeneration, setQueuedGeneration] =
    React.useState<AIStreamingParams | null>(null);

  const rootComponentId = useEditorSelector(selectRootComponentId);
  const draftComponentId = useEditorSelector(selectDraftComponentId);

  const canvasLoadingType = useEditorSelector(selectCanvasLoadingType);

  // Note (Evan, 2024-10-10): If there's a queued generation, initialize the draft component
  // to the root component so things like measurements etc work
  React.useEffect(() => {
    if (queuedGeneration && rootComponentId && !draftComponentId) {
      dispatch(setDraftElement({ componentId: rootComponentId }));
    }
  }, [queuedGeneration, rootComponentId, draftComponentId, dispatch]);

  React.useEffect(() => {
    if (
      queuedGeneration &&
      draftComponentId &&
      canvasLoadingType === "loaded"
    ) {
      // Note (Evan, 2024-10-09): This setTimeout should not be necessary, I think,
      // but we don't really have a reliable way of rendering in response to the
      // canvas successfully rendering - see https://replohq.slack.com/archives/C03AACVP08Y/p1728515710916769.
      setTimeout(() => {
        void initiateGeneration(
          queuedGeneration,
          // Note (Evan, 2024-10-15): on finish, reset the canvas back to the top and show
          // the welcome modal (since this is an onboarding generation)
          () => {
            // Note (Evan, 2024-10-09): This isn't an exact number, just roughly enough
            // to get the whole canvas (+ header) to show
            setDeltaXY({ deltaY: 100 });
            dispatch(setShowWelcomeModal(true));
          },
        );
        setIsMenuOpen(true, "onboarding");
        setQueuedGeneration(null);
      });
    }
  }, [
    canvasLoadingType,
    queuedGeneration,
    draftComponentId,
    initiateGeneration,
    setIsMenuOpen,
    dispatch,
    setDeltaXY,
  ]);

  const apply = React.useCallback(() => {
    const actions = selectStreamingUpdateActions(store.getState());
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!actions || !streamingUpdateId) {
      return;
    }
    analytics.logEvent("ai.changes.approved", {
      elementId,
      streamingUpdateId,
      model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : "gpt-4o",
      generationType: generationMode!,
    });
    dispatch(
      coreActions.applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
        activeCanvas: selectActiveCanvas(store.getState()),
      }),
    );

    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
  }, [store, elementId, generationMode, dispatch]);

  const discard = React.useCallback(() => {
    const streamingUpdateId = selectStreamingUpdateId(store.getState());
    if (!streamingUpdateId) {
      return;
    }
    analytics.logEvent("ai.changes.rejected", {
      elementId,
      streamingUpdateId,
      model: isFeatureEnabled("ai-claude") ? "claude-3.5-sonnet" : "gpt-4o",
      generationType: generationMode!,
    });
    dispatch(setStreamingUpdate(null));
    dispatch(setEditorMode(EditorMode.edit));
  }, [dispatch, elementId, store, generationMode]);

  const abort = () => {
    analytics.logEvent("ai.action.canceled", {
      elementId,
      generationType: generationMode!,
    });
    abortRef.current?.();
  };

  const [completionPercentage, setCompletionPercentage] =
    React.useState<number>(0);

  // NOTE (Gabe 2024-07-26): Because there isn't always a 1:1 mapping between
  // (easily countable) components and actions, we instead use the position in
  // the component tree of the latest action as a proxy for completion
  // percentage.
  const latestActionCompletionPercentage = React.useMemo(() => {
    const latestAction = actions?.[actions.length - 1];

    const numComponents = componentOrderMap?.size;
    const lastActionComponentPosition =
      latestAction?.componentId &&
      componentOrderMap?.get(latestAction.componentId);
    if (numComponents && lastActionComponentPosition) {
      return Math.round((lastActionComponentPosition / numComponents) * 100);
    }
    return 0;
  }, [actions, componentOrderMap]);

  // NOTE (Gabe 2024-08-07): Ensure that completion percentage is always
  // increasing (until it gets reset to 0).
  React.useEffect(() => {
    if (latestActionCompletionPercentage > 0) {
      setCompletionPercentage((val) =>
        Math.max(val, latestActionCompletionPercentage),
      );
    }
  }, [latestActionCompletionPercentage]);

  // Note (Evan, 2024-10-07): This effect is responsible for actually auto-applying changes
  React.useEffect(() => {
    if (status === "autoApplyingChanges") {
      setComponentOrderMap(undefined);
      setIsMenuOpen(false);
      apply();
    }
  }, [status, setIsMenuOpen, apply]);

  return (
    <AIStreamingContext.Provider
      value={{
        menuState,
        setMenuState,
        isMenuOpen,
        setIsMenuOpen,
        isTextSelected,
        generationMode,
        generationSource,
        initiateGeneration,
        queueGeneration: setQueuedGeneration,
        hasQueuedGeneration: Boolean(queuedGeneration),
        abort,
        isFinished: isStreamingFinished,
        applyChanges: () => {
          setComponentOrderMap(undefined);
          setIsMenuOpen(false);
          apply();
        },
        discardChanges: () => {
          setComponentOrderMap(undefined);
          setIsMenuOpen(false);
          discard();
        },
        status,
        completionPercentage,
      }}
    >
      {children}
    </AIStreamingContext.Provider>
  );
};

/**
 * @param filter Optional function that returns whether a component
 * should be measured
 * @returns For each active canvas, a mapping from component id to width and height
 */
function useMeasureDraftComponentAndChildrenDimensions(
  filter?: (component: Component) => boolean,
): () => Partial<Record<EditorCanvas, ComponentDimensionsMap>> {
  const store = useEditorStore();

  return React.useCallback(() => {
    const targetDocuments = selectCanvasesDocuments(store.getState());
    const draftElementId = selectDraftElementId(store.getState());
    const draftComponent = selectDraftComponent(store.getState());

    if (!draftElementId || !draftComponent) {
      return {};
    }

    const componentIdsToMeasure: Set<string> = new Set();

    const result: Partial<Record<EditorCanvas, ComponentDimensionsMap>> = {};

    forEachComponentAndDescendants(draftComponent, (component) => {
      if (filter === undefined || filter(component)) {
        componentIdsToMeasure.add(component.id);
      }
    });

    const visibleEditorCanvases = selectVisibleEditorCanvases(store.getState());

    // Note (Evan, 2024-09-30): This requestAnimationFrame makes all the
    // calculations happen in one batch.
    requestAnimationFrame(() => {
      for (const canvas of visibleEditorCanvases) {
        const targetDocument = targetDocuments[canvas];
        if (!targetDocument) {
          return;
        }

        componentIdsToMeasure.forEach((componentId) => {
          const node = getEditorComponentNode(
            targetDocument,
            draftElementId,
            componentId,
          );
          if (!node) {
            return;
          }
          const rect = node.getBoundingClientRect();
          const { height, width } = rect;
          result[canvas] = {
            ...result[canvas],
            [componentId]: { width, height },
          };
        });
      }
    });

    return result;
  }, [store, filter]);
}

function buildComponentOrderMap(
  component: Component | null,
): Map<string, number> {
  const map = new Map<string, number>();
  let count = 0;
  forEachComponentAndDescendants(component, (c) => {
    map.set(c.id, count++);
  });
  return map;
}

const useOpenAllCollapsibles = () => {
  const dispatch = useEditorDispatch();
  const store = useEditorStore();
  return React.useCallback(() => {
    const draftComponent = selectDraftComponent(store.getState());
    if (!draftComponent) {
      return;
    }
    const componentDataMapping = selectComponentDataMapping(store.getState());
    const accordionsToOpen: { [accordionId: string]: string[] } = {};
    const componentIdsToOpen: Set<string> = new Set();
    forEachComponentAndDescendants(draftComponent, (component) => {
      if (component.type === "collapsibleV2") {
        const ancestorComponentData =
          componentDataMapping[component.id]?.ancestorComponentData;
        // Note (Evan, 2024-10-16): The shared state that opens/closes collapsibles ~in accordions~
        // is different, so we have to check this and set state accordingly (accordion-gly?)
        const accordionId = ancestorComponentData?.find(
          ([_, type]) => type === "accordionBlock",
        )?.[0];

        if (accordionId) {
          accordionsToOpen[accordionId] = [
            ...(accordionsToOpen[accordionId] ?? []),
            component.id,
          ];
        } else {
          componentIdsToOpen.add(component.id);
        }
      }
    });
    // Note (Evan, 2024-10-18): No need to batch() these on React 18
    Object.entries(accordionsToOpen).forEach(([accordionId, componentIds]) => {
      dispatch(
        setSharedState({
          key: `${accordionId}.accordionOpenItems`,
          value: componentIds,
        }),
      );
    });
    componentIdsToOpen.forEach((componentId) => {
      dispatch(setSharedState({ key: `${componentId}.isOpen`, value: true }));
    });
  }, [dispatch, store]);
};
