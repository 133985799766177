import * as React from "react";

import { Loader } from "@editor/components/common/Loader";
import { routes } from "@editor/utils/router";

import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { ExperimentsEditTabV2 } from "@/features/experiments/tabs/ExperimentsEditTabV2";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { generatePath, useNavigate } from "react-router-dom";
import { getExperimentStatus } from "schemas/experiment";

const ExperimentDetailsTab: React.FC = () => {
  const { isLoadingExperiment, simpleExperiment, experiment, workspaceId } =
    useExperimentDetails();

  const navigate = useNavigate();
  const status = experiment ? getExperimentStatus(experiment) : null;

  const [dataChanged, setDataChanged] = React.useState(false);

  const onDataChange = (dataChanged: boolean) => {
    setDataChanged(dataChanged);
  };

  if (isLoadingExperiment || !simpleExperiment) {
    return <Loader />;
  }

  const { name } = simpleExperiment;

  return (
    <ExperimentsLayout
      headerTitle={name}
      backNavigation={{
        label: "Back",
        onClick: () => {
          navigate(
            generatePath(routes.workspace.experiments.list, { workspaceId }),
          );
        },
      }}
      headerActions={
        experiment ? (
          <ExperimentActionsBar
            workspaceId={workspaceId ?? ""}
            experiment={experiment}
            isEditingExperiment={dataChanged}
          />
        ) : null
      }
      editable
      status={status ?? undefined}
    >
      <ExperimentsEditTabV2
        dataChanged={dataChanged}
        onDataChange={onDataChange}
      />
    </ExperimentsLayout>
  );
};

export default ExperimentDetailsTab;
