import type { ModifierGroupType } from "schemas/modifiers";

import * as React from "react";

import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { BackgroundModifier } from "@editorModifiers/BackgroundModifier";
import BorderModifier from "@editorModifiers/BorderModifier";
import BoxShadowModifierDeprecated from "@editorModifiers/BoxShadowModifier";
import { ColorModifier } from "@editorModifiers/ColorModifier";
import DeprecatedBorderModifier from "@editorModifiers/DeprecatedBorderModifier";
import DeprecatedFlexboxModifier from "@editorModifiers/DeprecatedFlexboxModifier";
import DeprecatedPositioningModifier from "@editorModifiers/DeprecatedPositioningModifier";
import EffectsModifier from "@editorModifiers/EffectsModifier";
import FontModifier from "@editorModifiers/FontModifier";
import { ForegroundColorModifier } from "@editorModifiers/ForegroundColorModifier";
import { IconModifier } from "@editorModifiers/IconModifier";
import ImageSourceModifier from "@editorModifiers/ImageSourceModifier";
import ItemsModifier from "@editorModifiers/ItemsModifier";
import LayoutModifier from "@editorModifiers/LayoutModifier";
import PositioningModifier from "@editorModifiers/PositioningModifier";
import { RowColumnSpanModifier } from "@editorModifiers/RowColumnSpanModifier";
import SpacingModifier from "@editorModifiers/SpacingModifier";
import TextOutlineModifierDeprecated from "@editorModifiers/TextOutlineModifier";
import TextShadowModifierDeprecated from "@editorModifiers/TextShadowModifier";
import TextStyleModifier from "@editorModifiers/TextStyleModifier";
import Transform2d3dModifierDeprecated from "@editorModifiers/Transform2D3DModifier";
import VideoSourceModifier from "@editorModifiers/VideoSourceModifier";
import { VisibilityModifier } from "@editorModifiers/VisibilityModifier";

import { exhaustiveSwitch } from "replo-utils/lib/misc";

import FontAndColorModifier from "./modifiers/FontAndColorModifier";
import SizeModifier from "./modifiers/SizeModifier";

export const ModifierGroups: React.FC<
  React.PropsWithChildren<{ groupTypes: ModifierGroupType[] }>
> = ({ groupTypes }) => {
  const newRightBarUIEnabled = checkIfNewEditorPanelsUIIsEnabled();

  return (
    <>
      {groupTypes.map((groupType) => (
        <React.Fragment key={groupType.type}>
          {exhaustiveSwitch(groupType)({
            rowColumnSpans: <RowColumnSpanModifier />,
            spacing: <SpacingModifier />,
            visibility: <VisibilityModifier />,
            textStyle: <TextStyleModifier />,
            // TODO (Sebas, 2024-10-17): This will be removed when the new right bar is enabled
            foregroundColor: ({ allowsGradientSelection }) =>
              !newRightBarUIEnabled ? (
                <ForegroundColorModifier
                  allowsGradientSelection={allowsGradientSelection}
                />
              ) : null,
            fontAndColor: ({ allowsGradientSelection, allowsFontSelection }) =>
              newRightBarUIEnabled ? (
                <FontAndColorModifier
                  allowsGradientSelection={allowsGradientSelection}
                  allowsFontSelection={allowsFontSelection}
                />
              ) : null,
            size: newRightBarUIEnabled ? <SizeModifier /> : null,
            textShadow: !newRightBarUIEnabled ? (
              <TextShadowModifierDeprecated />
            ) : null,
            textOutline: !newRightBarUIEnabled ? (
              <TextOutlineModifierDeprecated />
            ) : null,
            color: !newRightBarUIEnabled ? <ColorModifier /> : null,
            border: newRightBarUIEnabled ? (
              <BorderModifier />
            ) : (
              <DeprecatedBorderModifier />
            ),
            positioning: newRightBarUIEnabled ? (
              <PositioningModifier />
            ) : (
              <DeprecatedPositioningModifier />
            ),
            background: <BackgroundModifier />,
            items: ({
              allowsDynamicDataWhenAvailable,
              field,
              title,
              allowedItemTypes,
            }) => (
              <ItemsModifier
                allowsDynamicDataWhenAvailable={allowsDynamicDataWhenAvailable}
                allowedItemTypes={allowedItemTypes}
                title={title}
                field={field}
              />
            ),
            // TODO (Sebas, 2024-10-17): This will be removed when the new right bar is enabled
            font: !newRightBarUIEnabled ? <FontModifier /> : null,
            layout: ({ includeAlignmentModifier }) =>
              newRightBarUIEnabled ? (
                <LayoutModifier />
              ) : (
                <div className="border-b border-slate-200 pb-3">
                  <DeprecatedFlexboxModifier
                    includeAlignmentModifier={includeAlignmentModifier}
                  />
                </div>
              ),
            effects: <EffectsModifier />,
            icon: <IconModifier />,
            imageSource: <ImageSourceModifier />,
            videoSource: <VideoSourceModifier />,
            transform2d3d: !newRightBarUIEnabled ? (
              <Transform2d3dModifierDeprecated />
            ) : null,
            boxShadow: !newRightBarUIEnabled ? (
              <BoxShadowModifierDeprecated />
            ) : null,
          })}
        </React.Fragment>
      ))}
    </>
  );
};
