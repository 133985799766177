import * as React from "react";

import ModifierLabel from "./ModifierLabel";

type ControlGroupProps = {
  label?: string;
};

const ControlGroup: React.FC<React.PropsWithChildren<ControlGroupProps>> = ({
  label,
  children,
}) => {
  return (
    <div className="flex items-center w-full">
      {label ? <ModifierLabel label={label} /> : null}
      {children}
    </div>
  );
};

export default ControlGroup;
