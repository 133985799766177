import * as React from "react";

/**
 * This hook is not considered safe in React 18 and later. Using state from
 * previous renders is a code smell and should generally be avoided.
 *
 * If you are using this to compare values manually to diff in an effect, you
 * may be able to avoid this by breaking the effect into multiple effects and
 * isolating concerns.
 *
 * If you are sure you need to compare values between renders, there are two
 * alternatives:
 *
 *   1. To access the previous value *directly in render*, you can use a second
 *      local state value to store the previous value, then update it directly in
 *      render when the values change. See
 *      https://react.dev/reference/react/useState#storing-information-from-previous-renders
 *   2. To access the previous value in an effect, you can use a ref to store the
 *      previous value and reset it inside the effect.
 *
 * @deprecated
 */
export function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
