import { z } from "zod";

export const DEFAULT_DOMAIN = "reploedge.com";

export const constructedAnalyticsLinkSchema = z
  .object({
    id: z.string().uuid(),
    workspaceId: z.string().uuid(),
    experimentId: z.string().uuid().nullable(),
    url: z.string(),
    isActive: z.boolean(),
  })
  .describe("ConstructedAnalyticsLink");

const validateAnalyticsLink = (
  data:
    | {
        type: "values";
        analyticsLink: {
          customDomain: string | null;
          shortName: string | null;
          path: string;
        };
      }
    | {
        type: "ids";
        analyticsLink: {
          customDomainId: string | null;
          shortNameId: string | null;
          path: string;
        };
      },
  ctx: z.RefinementCtx,
) => {
  const isValues = data.type === "values";
  const customDomainInput = isValues
    ? data.analyticsLink.customDomain
    : data.analyticsLink.customDomainId;
  const shortNameInput = isValues
    ? data.analyticsLink.shortName
    : data.analyticsLink.shortNameId;

  if (
    (customDomainInput === null && shortNameInput === null) ||
    (customDomainInput !== null && shortNameInput !== null)
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Exactly one of customDomain or shortName must be set",
      path: isValues
        ? ["customDomain", "shortName"]
        : ["customDomainId", "shortNameId"],
    });
  }

  if (data.analyticsLink.path === "") {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Path should not be empty",
      path: ["path"],
    });
  }
};

export const analyticsLinkWithIdsSchema = z
  .object({
    customDomainId: z.string().nullable(),
    shortNameId: z.string().uuid().nullable(),
    path: z.string(),
  })
  .superRefine((data, ctx) =>
    validateAnalyticsLink({ type: "ids", analyticsLink: data }, ctx),
  )
  .describe("AnalyticsLinkWithIds");

export const analyticsLinkForExperimentUpdateSchema = z
  .discriminatedUnion("type", [
    z.object({
      type: z.literal("chooseLink"),
      value: z.string().uuid().nullable(),
    }),
    z.object({
      type: z.literal("createNewLink"),
      value: analyticsLinkWithIdsSchema,
    }),
  ])
  .describe("AnalyticsLinkForExperimentUpdate");

export const analyticsLinkForEdgeserverSchema = z
  .object({
    customDomain: z.string().nullable(),
    shortName: z.string().nullable(),
    path: z.string().max(256),
  })
  .superRefine((data, ctx) =>
    validateAnalyticsLink({ type: "values", analyticsLink: data }, ctx),
  )
  .describe("AnalyticsLinkForEdgeserver");
