import * as React from "react";

import { useCookies } from "react-cookie";
import { z } from "zod";

const PENDING_SHOPIFY_INTEGRATION_COOKIE = "replo_pending_shopify_integration";

const PendingShopifyIntegrationCookieSchema = z.object({
  type: z.enum([
    "sections",
    "noShopifyErrorModal",
    "projectSettings",
    "newProject",
    "publish",
    "assets",
    "productPicker",
    "templateDefault",
    "integrationHub",
    "billingModal",
  ]),
  workspaceId: z.string(),
  projectId: z.string().nullish(),
});

export type PendingShopifyIntegrationCookie = z.infer<
  typeof PendingShopifyIntegrationCookieSchema
>;

const usePendingShopifyIntegrationCookie = () => {
  const [cookies, setCookie] = useCookies([PENDING_SHOPIFY_INTEGRATION_COOKIE]);

  const pendingShopifyIntegrationCookie = React.useMemo(() => {
    if (!cookies.replo_pending_shopify_integration) {
      return null;
    }
    const parsedCookie = PendingShopifyIntegrationCookieSchema.safeParse(
      cookies.replo_pending_shopify_integration,
    );
    if (!parsedCookie.success) {
      return null;
    }
    return parsedCookie.data;
  }, [cookies.replo_pending_shopify_integration]);

  const setPendingShopifyIntegrationCookie = (
    pendingShopifyIntegrationCookie: PendingShopifyIntegrationCookie,
  ) => {
    const validatedCookie = PendingShopifyIntegrationCookieSchema.parse(
      pendingShopifyIntegrationCookie,
    );
    return setCookie(
      PENDING_SHOPIFY_INTEGRATION_COOKIE,
      JSON.stringify(validatedCookie),
      {
        path: "/",
        maxAge: 60 * 10, // 10 minutes
      },
    );
  };

  return {
    pendingShopifyIntegrationCookie,
    setPendingShopifyIntegrationCookie,
  };
};

export default usePendingShopifyIntegrationCookie;
