import React from "react";

interface DetailsContainerProps {
  title: string;
  isRequired?: boolean;
  description?: string;
  headerComponent?: React.ReactNode;
}

export const DetailsContainer: React.FC<
  React.PropsWithChildren<DetailsContainerProps>
> = ({ title, description, children, isRequired = false, headerComponent }) => {
  return (
    <div className="border border-slate-300 px-12 py-6 rounded-md flex flex-col gap-4 text-default">
      <div className=" flex flex-col gap-1">
        <div className="font-semibold flex flex-row gap-2 justify-between">
          <div className="flex gap-1">
            <span>{title}</span>
            {isRequired && <span className="text-danger">*</span>}
          </div>
          {headerComponent}
        </div>
        {description && <span className="text-subtle">{description}</span>}
      </div>
      {children}
    </div>
  );
};
