import type { TextOutline } from "replo-runtime/shared/types";

import { getFormattedColorWithoutOpacity } from "@editor/utils/colors";

import { filterNulls } from "replo-utils/lib/array";

export const getTextOutlineObject = (textOutlines: string) => {
  const textOutlineComponents = textOutlines.split(" ");
  const [width, color] = textOutlineComponents;
  return {
    width: width!,
    color: color!,
  };
};

export const getTextOutlineString = (
  textOutline: TextOutline,
  withoutOpacity = false,
) => {
  const propertiesOrder = ["width", "color"] as const;

  const orderedOutlineValues = filterNulls(
    propertiesOrder.map((prop) => {
      if (textOutline[prop]?.includes("#") && withoutOpacity) {
        return getFormattedColorWithoutOpacity(textOutline[prop]);
      }
      return textOutline[prop];
    }),
  );

  return orderedOutlineValues.join(" ");
};
