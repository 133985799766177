import type { AnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import type { FilterName } from "schemas/analyticsRead";
import type {
  AnalyticsReadQuery,
  MetricName,
  Order,
} from "schemas/generated/analyticsRead";

import { decodeUrlHost } from "@editor/utils/analytics";

import { DEFAULT_FILTERS } from "@/features/analytics/constants";
import useBaseAnalyticsRead from "@/features/analytics/useBaseAnalyticsRead";
import { useParams } from "react-router-dom";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

const DEFAULT_SEARCH_FILTER_NAME: FilterName = "urlPath";

const useSearchAnalyticsRead = (
  workspaceId: string | null,
  urlPathFilterValue: string,
) => {
  const { host = "" } = useParams<{ host: string }>();
  const decodedHost = decodeUrlHost(host ?? "");

  const getCustomFilters = (
    params: AnalyticsUrlParams,
  ): AnalyticsReadQuery["filters"] => ({
    ...params.filters,
    [DEFAULT_SEARCH_FILTER_NAME]: urlPathFilterValue
      ? [
          {
            operator: ConditionOperatorEnum.CONTAINS,
            value: urlPathFilterValue,
          },
        ]
      : DEFAULT_FILTERS[DEFAULT_SEARCH_FILTER_NAME],
  });

  const additionalQueryParams =
    urlPathFilterValue === "" && decodedHost !== ""
      ? {
          limit: 10,
          sortMetric: "unique_sessions" as MetricName,
          order: "DESC" as Order,
        }
      : {};

  return useBaseAnalyticsRead({
    workspaceId,
    getCustomFilters,
    additionalQueryParams,
  });
};

export default useSearchAnalyticsRead;
