// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ElementVersionRevisionHistory } from "@editor/types/core-state";
import type { User } from "replo-runtime/shared/types";
import type { ReploElementVersionRevision } from "schemas/generated/element";

import * as React from "react";

import { Group, GroupHeader } from "@common/designSystem/Group";
import Spinner from "@common/designSystem/Spinner";
import VersionCard from "@components/version-history/VersionCard";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import useElementVersioning from "@editor/hooks/useElementVersioning";
import {
  selectSelectedRevisionId,
  setSelectedRevisionId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { orderByDate } from "@utils/array";

import { differenceInCalendarWeeks, format, isToday } from "date-fns";
import groupBy from "lodash-es/groupBy";
import { useDispatch } from "react-redux";
import { ReploElementVersionKinds } from "schemas/element";
import { v4 as uuid } from "uuid";

import Chip from "../common/designSystem/Chip";

const revisionsOrder = ["Today", "This Week", "Last Week", "Before Last Week"];

const formatDateAndTime = (date?: string) => {
  if (!date) {
    return "";
  }
  return format(new Date(date), "MMMM d, hh:mma");
};

const getWeekDiffTitle = (diffInWeeks: number) => {
  switch (diffInWeeks) {
    case 0:
      return "This Week";
    case 1:
      return "Last Week";
    default:
      return "Before Last Week";
  }
};

const getVersionTitle = (version: ReploElementVersionRevision) => {
  if (version.kind === ReploElementVersionKinds.revert) {
    return `Revert to ${formatDateAndTime(version?.revertedTo)}`;
  }
  if (version.kind === ReploElementVersionKinds.automatic) {
    return "Autosave";
  }
  return "Published Page";
};

export const createFakeRevision = (user: User): ReploElementVersionRevision => {
  return {
    id: uuid(),
    createdAt: new Date().toISOString(),
    kind: ReploElementVersionKinds.automatic,
    title: "Current Version",
    createdBy: user,
  };
};

export default function VersionHistory() {
  const dispatch = useDispatch();
  const selectedRevisionId = useEditorSelector(selectSelectedRevisionId);
  const [filterByPublished, setFilterByPublished] =
    React.useState<boolean>(false);
  const { user } = useCurrentUser();
  const {
    versions: _versions,
    isLoading,
    isFetchingNextPage,
    loadMoreRef,
  } = useElementVersioning(filterByPublished);
  const versions = orderByDate(_versions, "createdAt");

  const fakeRevision = createFakeRevision(user!);

  const versionHistory: ElementVersionRevisionHistory[] = React.useMemo(() => {
    const versionsToShow = [fakeRevision, ...versions];

    const groupedVersionsObj = groupBy(versionsToShow, (version) => {
      const title = isToday(new Date(version.createdAt))
        ? "Today"
        : getWeekDiffTitle(
            differenceInCalendarWeeks(new Date(version.createdAt), new Date()),
          );

      return title;
    });

    return Object.keys(groupedVersionsObj)
      .map((key) => {
        return {
          title: key,
          items: groupedVersionsObj[key],
        };
      })
      .sort(
        (a, b) =>
          revisionsOrder.indexOf(a.title) - revisionsOrder.indexOf(b.title),
      );
  }, [versions, fakeRevision]) as ElementVersionRevisionHistory[];

  return (
    <div className="flex h-screen w-full flex-col">
      <h2 className="px-2 py-2 text-sm font-semibold text-default transition-colors">
        Version History
      </h2>
      <div className="px-2 flex gap-2">
        <Chip
          onClick={() => setFilterByPublished(false)}
          isSelected={!filterByPublished}
        >
          All
        </Chip>
        <Chip
          onClick={() => setFilterByPublished(true)}
          isSelected={filterByPublished}
        >
          Published
        </Chip>
      </div>
      {!isLoading ? (
        <div className="divide-y">
          {versionHistory.map(({ items, title }, groupIndex) => (
            <Group
              key={title}
              name={title}
              isCollapsible
              isDefaultOpen
              className="pt-2 pb-2"
              header={<GroupHeader className="mb-0 px-2" />}
            >
              {items.map((item, itemIndex) => {
                const cardIsActive =
                  (item.id === fakeRevision.id && !selectedRevisionId) ||
                  selectedRevisionId === item.id;
                return (
                  <VersionCard
                    key={item.id}
                    ref={
                      groupIndex === versionHistory.length - 1 &&
                      itemIndex === items.length - 1
                        ? loadMoreRef
                        : undefined
                    }
                    title={getVersionTitle(item)}
                    isActive={cardIsActive}
                    userName={item.createdBy?.name ?? "Anonymous"}
                    date={
                      item.id === fakeRevision.id
                        ? "Now"
                        : formatDateAndTime(item.createdAt)
                    }
                    onClick={() => {
                      if (item.id === fakeRevision.id) {
                        dispatch(setSelectedRevisionId(null));
                      } else {
                        dispatch(setSelectedRevisionId(item.id));
                      }
                    }}
                  />
                );
              })}
            </Group>
          ))}
          {isFetchingNextPage && (
            <div className="flex min-h-[100px] items-center justify-center">
              <Spinner type="secondary" size={33} className="text-blue-600" />
            </div>
          )}
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <Spinner type="secondary" size={33} className="text-blue-600" />
        </div>
      )}
    </div>
  );
}
