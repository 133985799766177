import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";

import * as React from "react";

import AssetPicker from "@editor/components/common/designSystem/AssetPicker";
import InlineAssetSelector from "@editor/components/common/designSystem/InlineAssetSelector";
import Popover from "@editor/components/common/designSystem/Popover";
import { BADGE_TRIGGER_OFFSET } from "@editor/components/editor/constants";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useModal } from "@editor/hooks/useModal";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { useEditorSelector } from "@editor/store";
import { getPathFromVariable } from "@editor/utils/dynamic-data";
import { DynamicDataValueIndicator } from "@editorExtras/DynamicDataValueIndicator";

import { DynamicDataTargetType } from "replo-runtime/shared/dynamicData";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";

type VideoSourceIndicatorProps = {
  src: any;
  posterSrc?: string;
  assetLoadingValue: AssetLoadingType;
  objectFitValue?: any;
  objectPositionValues?: { x?: string; y?: string };
  onChangeObjectFit?(value: string): void;
  onChangeObjectPositionX?(value: string): void;
  onChangeObjectPositionY?(value: string): void;
  onChangeAssetLoading?(value: AssetLoadingType): void;
  onChangePoster?(value: string | null): void;
  onRemove?(): void;
  componentId: string | undefined;
};

const VideoSourceIndicator = ({
  src,
  objectFitValue,
  objectPositionValues,
  onChangeObjectFit,
  onChangeObjectPositionX,
  onChangeObjectPositionY,
  onRemove,
  componentId,
  posterSrc,
  onChangeAssetLoading,
  onChangePoster,
  assetLoadingValue,
}: VideoSourceIndicatorProps) => {
  const [isVisible, setVisible] = React.useState<boolean>();
  const modal = useModal();
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);
  const applyComponentAction = useApplyComponentAction();

  const _openModal = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/video",
        assetContentType: "video",
        value: src,
      },
    });
  };

  const _openModalForPoster = () => {
    modal.openModal({
      type: "assetLibraryModal",
      props: {
        referrer: "modifier/videoPoster",
        assetContentType: "image",
        value: posterSrc ?? null,
      },
    });
  };

  const onClickSelectAsset = () => {
    if (
      Boolean(onChangeObjectFit) ||
      Boolean(onChangeObjectPositionX) ||
      Boolean(onChangeObjectPositionY)
    ) {
      setVisible(true);
    } else {
      _openModal();
    }
  };

  const _onClickDynamicData = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: { type: "prop", propId: "url" },
        initialPath: src ? getPathFromVariable(src) : undefined,
      },
    });
  };

  const _onClickDynamicDataForPoster = () => {
    modal.openModal({
      type: "dynamicDataModal",
      props: {
        requestType: "prop",
        targetType: DynamicDataTargetType.URL,
        referrerData: { type: "prop", propId: "poster" },
        initialPath: posterSrc ? getPathFromVariable(posterSrc) : undefined,
      },
    });
  };

  const assetIsDynamicData = isDynamicDataValue(src);

  const handlePosterChange = React.useCallback(
    (poster: string) => {
      applyComponentAction({
        type: "setProps",
        value: { poster },
      });
    },
    [applyComponentAction],
  );

  return (
    <div className="flex flex-grow flex-col">
      <Popover isOpen={isVisible} onOpenChange={setVisible}>
        {assetIsDynamicData ? (
          <DynamicDataValueIndicator
            type="image"
            templateValue={src}
            onClick={_onClickDynamicData}
            onRemove={() => onRemove?.()}
            componentId={componentId}
          />
        ) : (
          <InlineAssetSelector
            size="sm"
            swatchTooltip="Video"
            emptyTitle="Select Video"
            inputPlaceholder="Video URL"
            onClickDynamicData={_onClickDynamicData}
            allowsDynamicData
            onClickSelectAsset={() => onClickSelectAsset?.()}
            onRemoveAsset={() => onRemove?.()}
            allowRemoveAsset={Boolean(onRemove)}
            asset={{
              type: "video",
              src: src,
            }}
            onInputChange={(url) =>
              applyComponentAction({
                type: "setProps",
                value: { url },
              })
            }
          />
        )}
        <Popover.Anchor className="relative top-0 left-0" />
        {isVisible && (
          <Popover.Content
            title="Video"
            shouldPreventDefaultOnInteractOutside={areModalsOpen}
            sideOffset={
              checkIfNewEditorPanelsUIIsEnabled()
                ? BADGE_TRIGGER_OFFSET
                : undefined
            }
          >
            <AssetPicker
              objectFitValue={objectFitValue}
              objectPositionValue={objectPositionValues}
              assetType="video"
              emptyTitle="No Video Selected"
              selectAssetTitle="Select Video"
              changeAssetTitle="Change Video"
              url={src}
              onChangeObjectFit={onChangeObjectFit}
              onChangeObjectPositionX={onChangeObjectPositionX}
              onChangeObjectPositionY={onChangeObjectPositionY}
              onClickSelectAsset={() => {
                _openModal();
                setVisible(false);
              }}
              assetLoadingValue={assetLoadingValue}
              onChangeAssetLoading={onChangeAssetLoading}
              onChangeAssetLoadingPoster={onChangePoster}
              onClickDynamicDataForPoster={_onClickDynamicDataForPoster}
              onClickSelectAssetForPoster={_openModalForPoster}
              onPosterInputChange={handlePosterChange}
              onRemovePoster={() => {
                onChangePoster?.(null);
              }}
              posterSrc={posterSrc}
              componentId={componentId}
            />
          </Popover.Content>
        )}
      </Popover>
    </div>
  );
};

export default VideoSourceIndicator;
