export type DomainProvider = {
  name: string;
  url: string;
};

export const DOMAIN_PROVIDERS: DomainProvider[] = [
  { name: "Shopify", url: "https://www.shopify.com" },
  { name: "Cloudflare", url: "https://www.cloudflare.com" },
  { name: "GoDaddy", url: "https://www.godaddy.com" },
  { name: "Squarespace", url: "https://www.squarespace.com" },
  { name: "Namecheap", url: "https://www.namecheap.com" },
];
