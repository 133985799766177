import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgHeight = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 6 9" fill="none" {...props}>
    <path fill="#94A3B8" stroke="#94A3B8" d="M3 1.5H2.207L3 .707l.793.793H3Z" />
    <path stroke="#94A3B8" d="M3 8V1M0 8h6" />
  </Svg>
);
export default SvgHeight;
