import * as React from "react";

import classNames from "classnames";
import debounce from "lodash-es/debounce";
import { twMerge } from "tailwind-merge";

type TextareaProps = {
  id?: string;
  value?: string;
  placeholder?: string;
  autoFocus?: boolean;
  isDisabled?: boolean;
  debounce?: boolean;
  onChange(value: string): void;
  onBlur?(): void;
  onFocus?(): void;
  onEnter?(): void;
  onEscape?(): void;
  onKeyDown?(e: React.KeyboardEvent): void;
  onKeyUp?(e: React.KeyboardEvent): void;
  onKeyPress?(e: React.KeyboardEvent): void;
  onMouseDown?(): void;
  onMouseUp?(): void;
  onMouseMove?(): void;
  className?: string;
  style?: React.CSSProperties;
  ref?: React.RefObject<HTMLTextAreaElement>;
  maxLength?: number;
};

const Textarea = ({
  id,
  value = "",
  placeholder = "",
  autoFocus = false,
  isDisabled = false,
  debounce: hasDebounce = false,
  onChange,
  onBlur,
  onFocus,
  onEnter,
  onEscape,
  onKeyDown,
  onKeyUp,
  onKeyPress,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  className,
  style,
  ref,
  maxLength,
}: TextareaProps) => {
  const [debouncedValue, setDebouncedValue] = React.useState<string>(value);
  const debouncedOnChange = React.useMemo(() => {
    return debounce((value: string) => onChange?.(value), 300);
  }, [onChange]);

  function _onFocusInput() {
    return onFocus?.();
  }

  function _onBlurInput() {
    return onBlur?.();
  }

  function _onChangeInput(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const trimmedValue =
      maxLength !== undefined
        ? e.target.value.slice(0, Math.max(0, maxLength))
        : e.target.value;
    if (hasDebounce) {
      setDebouncedValue(trimmedValue);
      debouncedOnChange(trimmedValue);
    } else {
      onChange?.(trimmedValue);
    }
  }

  function _onKeyPressInput(e: React.KeyboardEvent) {
    if (e.key === "Enter" && onEnter) {
      onEnter?.();
    }
    if (e.key === "Escape" && onEscape) {
      onEscape?.();
    }
    return onKeyPress?.(e);
  }

  const internalClassNames = classNames(
    "resize-none rounded bg-slate-100 px-[10px] py-[5px] text-default outline-none transition-all duration-300 placeholder:text-slate-300 focus:ring-1 focus:ring-blue-500 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400",
    {
      // TODO (Gabe 2024-09-25): Why does this have a width baked into it?
      "w-[190px]": !className && !style?.width,
      "min-h-[132px]": !className && !style?.height,
    },
  );

  return (
    <textarea
      id={id}
      className={twMerge(internalClassNames, className)}
      style={style}
      value={hasDebounce ? debouncedValue : value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      disabled={isDisabled}
      onChange={_onChangeInput}
      onBlur={_onBlurInput}
      onFocus={_onFocusInput}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={_onKeyPressInput}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      ref={ref}
    ></textarea>
  );
};

export default Textarea;
