import type { Action } from "schemas/actions";
import type { Component } from "schemas/component";
import type { RenderComponentAttributes } from "../../shared/types";
import type { Context } from "../AlchemyVariable";

import * as React from "react";

import classNames from "classnames";
import isEmpty from "lodash-es/isEmpty";
import omit from "lodash-es/omit";
import throttle from "lodash-es/throttle";
import unescape from "lodash-es/unescape";
import { noop } from "replo-utils/lib/misc";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { useComposedRefs } from "replo-utils/react/use-composed-refs";

import {
  ComponentUpdateContext,
  EditorCanvasContext,
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../shared/runtime-context";
import { useRenderChildren } from "../../shared/utils/renderComponents";
import { getOwnerWindowSafe } from "../../shared/Window";
import { useCanUseLiquid } from "../../store/hooks/useCanUseLiquid";
import { replaceLeadingSlashWithRoot } from "../../utils/url";
import { getComponentRedirectAction } from "../utils/component";
import { ReploComponent } from "./ReploComponent";
import ReploLiquidChunk from "./ReploLiquid/ReploLiquidChunk";

type ContentEditableDOMProps = Omit<
  RenderComponentAttributes,
  "ref" | "extraAttributes"
>;

interface ContentEditableProps extends ContentEditableDOMProps {
  componentType: "text" | "button";
  hasAnchorTag: boolean;
  component: Component;
  context: Context;
  containerRef: React.RefObject<any>;
  isPureText?: boolean;
}

const EMPTY_CLICK_ACTIONS: Action[] = [];

export function ContentEditableComponent(props: ContentEditableProps) {
  const {
    componentType,
    component,
    context,
    hasAnchorTag,
    containerRef,
    className: containerClassName,
    style: containerStyle,
    isPureText,
    // TODO (Noah, 2022-12-21): We don't want these passed into the
    // container's props
    onClick: _onClick,
    onKeyPress: _onKeyPress,
    ...attributes
  } = props;

  const onClickActions = component.props.onClick ?? EMPTY_CLICK_ACTIONS;

  // Note (Evan, 2024-07-16): Apply the editor text override, if there is one
  const editorOverrideTextValue = useRuntimeContext(
    RuntimeHooksContext,
  ).useEditorOverrideTextValue(component.id);
  const innerHtml = editorOverrideTextValue ?? component.props.text;

  const repeatedIndex = attributes["data-replo-repeated-index"];
  const canvas = useRuntimeContext(EditorCanvasContext);
  const isContentEditable = useRuntimeContext(
    RuntimeHooksContext,
  ).useIsContentEditing({
    canvas,
    componentId: component.id,
    repeatedIndex: repeatedIndex ?? "",
  });

  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const canUseLiquid = useCanUseLiquid();
  const isPreviewMode = isEditorApp && !isEditorCanvas;

  // Note (Fran, 2022-05-06): This need to be added because the space bar doesn't
  // works on chrome when the container tag has the content editable property
  const contentRef = React.useRef<HTMLSpanElement>(null);

  const eventHandlers = useEventHandlers(props, {
    contentRef,
    isContentEditable,
  });

  const renderChildren = useRenderChildren(component, { context }).map(
    (child) => {
      return (
        <ReploComponent
          key={child.component.id}
          component={child.component}
          context={child.context}
          repeatedIndexPath={context.repeatedIndexPath}
        />
      );
    },
  );
  const effectiveChildren = !isPureText ? renderChildren : null;

  const renderProps: RenderProps = {
    containerProps: {
      ...attributes,
      ...eventHandlers,
      className: classNames(containerClassName, "alchemy-rte", {
        "outline--blue": isContentEditable,
      }),
      style: containerStyle,
      // @ts-expect-error
      target: isPreviewMode && componentType === "button" ? "_top" : undefined,
    },
    contentProps: {
      // Note (Noah, 2024-11-01, USE-1402): We don't just pass contentEditable:
      // isContentEditable here because that bloats the page size. Just passing
      // a conditional prop works the same way
      ...(isContentEditable
        ? {
            contentEditable: true,
            style: {
              // NOTE (Fran 2024-07-01): We need to set outline and box shadow to none to avoid weird styles
              // when the user is editing the text in the editor.
              outline: "none",
              boxShadow: "none",
            },
          }
        : {}),
    },
  };

  // Note (Noah, 2022-11-14): If we're purely text, we need to set the innerHTML
  // of our link/span/etc. If we try to pass this along with children, React
  // will complain.
  if (isPureText && innerHtml) {
    // NOTE (Chance 2024-02-12): On the client, dynamic data is accessed in a
    // JSON script and HTML entities are escaped. The need to be unescaped to
    // properly render HTML.
    renderProps.contentProps.dangerouslySetInnerHTML = {
      __html: unescape(innerHtml),
    };
  }

  if (isEditorApp) {
    renderProps.contentProps["data-testid"] = "editable-content";
  }

  // Note (Noah, 2022-08-17, REPL-2933, REPL-3621): Sometimes we render a
  // button with a span inside and sometimes we render a link. In the first
  // case, the component ref (used for hover states etc) is on the button and
  // the text ref (used for content-editable updates) is on the span. If the
  // two are combined, we need to combine the refs as well to make sure that
  // the rendered <a> tag both has the event listener for states as well as
  // the content editable listeners for editing in the Canvas.
  const componentAndTextRef = useComposedRefs(containerRef, contentRef);

  const contentProps = {
    ...renderProps.contentProps,
    // Note (Noah, 2022-07-07): The span style needs to take up 100%
    // of the width of the container, otherwise text alignment doesn't
    // work when the text is less wide than the set width of the button
    style: { ...renderProps.contentProps.style, width: "100%" },
  };

  const href = useLinkHrefIfApplicable({
    onClickActions,
    shouldUseLiquidRoot: canUseLiquid,
  });
  // Special treatment for button components where there's a link inside
  // of them. We want to prevent links inside links in the markup.
  if (component.type === "button") {
    const buttonHasMultipleActions = onClickActions.length > 1;
    if (hasAnchorTag || buttonHasMultipleActions) {
      return (
        <button
          {...renderProps.containerProps}
          key={renderProps.containerProps.key}
          ref={containerRef}
        >
          {effectiveChildren ?? <span {...contentProps} ref={contentRef} />}
        </button>
      );
    }

    const redirectAction = getComponentRedirectAction(component);
    if (redirectAction) {
      const linkProps: React.ComponentProps<"a"> = {
        ...renderProps.containerProps,
        href: !isEditorCanvas ? href : undefined,
        ref: containerRef,
      };

      if (
        redirectAction?.type === "redirect" &&
        typeof redirectAction.value !== "string" &&
        redirectAction.value.openNewTab
      ) {
        linkProps.target = "_blank";
        linkProps.rel = "noreferrer";
      }

      // NOTE (Matt 2024-11-06): If using liquid root, we need to preserve this <a> tag as
      // a liquid chunk to preserve the locale root in the href.
      const anchorTag = (
        <a
          {...linkProps}
          key={linkProps.key}
          {...(href ? { onClick: (e) => e.stopPropagation() } : null)}
        >
          {effectiveChildren ?? <span {...contentProps} ref={contentRef} />}
        </a>
      );
      if (canUseLiquid) {
        return <ReploLiquidChunk>{anchorTag}</ReploLiquidChunk>;
      }
      return anchorTag;
    }
  }

  // Special treatment for text components where there's a link inside
  // of them. We want to prevent links inside links in the markup.
  if (component.type === "text" && hasAnchorTag) {
    return (
      <div
        {...renderProps.containerProps}
        key={renderProps.containerProps.key}
        ref={containerRef}
      >
        <span {...contentProps} ref={contentRef} />
      </div>
    );
  }

  // Note (Noah, 2022-11-14, but this code has been here since the dawn of Replo):
  // if there's only one action and it's a redirect/phone, then render a link instead of
  // a button/div/etc. This is both more accessible and has the nice property that
  // it will work even before our javascript loads.
  if (!isEmpty(onClickActions) && onClickActions.length === 1) {
    // Note (Noah, 2022-11-14): If we're purely text, use the combined ref so that
    // we both select this element and content-edit it when double-clicking. If we're
    // not pure text, only set the componentRef - the text ref doesn't matter, because
    // this component is not content-editable, it's children are. In the same way, only
    // pass the contentProps (contentEditable, etc) if we're pure text.
    const linkRef = isPureText ? componentAndTextRef : containerRef;
    const linkProps = isPureText
      ? {
          ...omit(renderProps.containerProps, "onClick"),
          ...renderProps.contentProps,
        }
      : omit(renderProps.containerProps, "onClick");

    const lastAction = onClickActions[onClickActions.length - 1];
    if (lastAction && lastAction.type === "redirect") {
      // Note (Reinaldo, 2020-03-30): This is for supporting legacy actions that were just strings
      if (typeof lastAction.value === "string") {
        // eslint-disable-next-line react/jsx-no-target-blank
        return (
          <a
            {...linkProps}
            key={linkProps.key}
            href={!isEditorCanvas ? href : undefined}
            ref={linkRef}
            target="_top"
            onClick={href ? (e) => e.stopPropagation() : undefined}
          >
            {effectiveChildren ?? undefined}
          </a>
        );
      }

      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
          {...linkProps}
          href={!isEditorCanvas ? href : undefined}
          key={linkProps.key}
          target={lastAction.value.openNewTab ? "_blank" : "_top"}
          rel={lastAction.value.openNewTab ? "noreferrer" : undefined}
          ref={linkRef}
          onClick={href ? (e) => e.stopPropagation() : undefined}
        >
          {effectiveChildren ?? undefined}
        </a>
      );
    }

    const actionCallPhone = onClickActions.find(
      (action) => action.type === "phoneNumber",
    );
    if (actionCallPhone) {
      return (
        <a
          {...linkProps}
          key={linkProps.key}
          href={!isEditorCanvas ? href : undefined}
          target="_top"
          ref={linkRef}
        />
      );
    }
  }

  // Note (Noah, 2022-11-14): If it's a button, render the button tag with its children,
  // OR an inner editable span if there are no children. If it's NOT a button, render a
  // div with editable span always, since there are never any children.
  if (componentType === "button") {
    return (
      <button
        {...renderProps.containerProps}
        key={renderProps.containerProps.key}
        ref={containerRef}
      >
        {effectiveChildren ?? <span {...contentProps} ref={contentRef} />}
      </button>
    );
  }

  return (
    <div
      {...renderProps.containerProps}
      key={renderProps.containerProps.key}
      ref={containerRef}
    >
      <span {...contentProps} ref={contentRef} />
    </div>
  );
}

/**
 * Returns an href to apply to this component based on its onClick actions, if any.
 * This should be passed to the <a> tag in the case where we decide to render one,
 * so that we render e.g. redirect actions and phone call actions as proper links.
 *
 * @param opts.onClickActions Actions to check for a linkable action
 * @param opts.shouldUseLiquidRoot Indicates whether we're in a liquid environment.
 * @returns Href to pass to any relevant <a> tags. If no actions are applicable,
 * returns undefined (which can be passed to the <a> tag as well, to make the href
 * attribute not rendered)
 */
const useLinkHrefIfApplicable = ({
  onClickActions,
  shouldUseLiquidRoot,
}: {
  onClickActions: Action[];
  shouldUseLiquidRoot: boolean;
}) => {
  let { activeShopifyUrlRoot: root } = useRuntimeContext(ShopifyStoreContext);
  // NOTE (Matt 2024-11-06): The reason we need this is to preserve the user's selected locale for market urls, which we
  // only can access via liquid when constructing the href.
  if (shouldUseLiquidRoot) {
    root =
      "{{ routes.root_url }}{%- unless routes.root_url.size == 1 -%}/{%- endunless -%}";
  }
  if (onClickActions.length === 0) {
    return undefined;
  }
  const lastAction = onClickActions[onClickActions.length - 1];
  if (lastAction && lastAction.type === "redirect") {
    // Note (Reinaldo, 2020-03-30): This is for supporting legacy actions that were just strings
    if (typeof lastAction.value === "string") {
      return replaceLeadingSlashWithRoot(lastAction.value, root);
    }
    // NOTE (Gabe 2024-02-01, REPL-10102): Some redirect actions are missing the
    // url field, so we coalesce to an empty string to avoid a crash.
    return replaceLeadingSlashWithRoot(lastAction.value.url ?? "", root);
  } else if (lastAction && lastAction.type === "phoneNumber") {
    return `tel:${lastAction.value.url}`;
  }
};

export default ContentEditableComponent;

function useEventHandlers(
  props: ContentEditableProps,
  {
    contentRef,
    isContentEditable,
  }: {
    contentRef: React.RefObject<HTMLSpanElement>;
    isContentEditable: boolean;
  },
) {
  const { component, containerRef, onClick, onKeyPress } = props;
  const { onSubmitContentEditableTextUpdate } = useRuntimeContext(
    ComponentUpdateContext,
  );
  const isEditorCanvas =
    useRuntimeContext(
      RuntimeHooksContext,
    ).useIsEditorEditModeRenderEnvironment();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  React.useEffect(() => {
    if (!isEditorApp || !containerRef.current || !contentRef.current) {
      return;
    }

    const element = contentRef.current;
    // NOTE (Reinaldo, 2022-09-06): We are throttling the text update so we don't call
    // the api more than we need to. Also, making it invoke the update on the leading edge of the timeout
    // so the user can change other content editables as soon as possible
    const throttledOnSubmitContentEditableTextUpdate =
      onSubmitContentEditableTextUpdate &&
      throttle(onSubmitContentEditableTextUpdate, 500, {
        trailing: false,
        leading: true,
      });

    element.addEventListener("blur", handleSave);
    element.addEventListener("keypress", handleKeypress);
    element.addEventListener("keyup", handleKeyup);
    element.addEventListener("keydown", handleKeydown);
    element.addEventListener("paste", handlePaste);

    return () => {
      element.removeEventListener("keypress", handleKeypress);
      element.removeEventListener("keyup", handleKeyup);
      element.removeEventListener("blur", handleSave);
      element.removeEventListener("paste", handlePaste);
    };

    function handleSave() {
      const ownerWindow = getOwnerWindowSafe(element!);
      if (!ownerWindow) {
        return;
      }
      const selection = ownerWindow.getSelection();
      selection?.removeAllRanges();

      throttledOnSubmitContentEditableTextUpdate?.(
        component.id,
        element!.innerHTML,
      );

      // Ugly Hack (Ovishek, 2022-05-30): When user clicks on the outside of the
      // editor (content-editing) to save it but on the iframe, in this case
      // further hotkeys doesn't work. It's b/c after content editing the iframe
      // is focused that's why hotkeys (copy/paste) didn't work! To Hotkeys to
      // work, we need body to be focused. Also we do need a setTimeout here to
      // get it work.
      setTimeout(() => {
        // biome-ignore lint/style/noRestrictedGlobals: allow document
        const activeElement = document.activeElement as HTMLElement | undefined;
        activeElement?.blur?.();
      }, 1);
    }

    function triggerBlur() {
      // If the user pressed enter when content-editing, trigger a blur (this
      // will trigger a component update in the editor but ensures we don't
      // accidentally do another update when the element blur completes)
      contentRef?.current?.blur();
      if (contentRef?.current !== containerRef.current) {
        containerRef.current?.blur();
      }
    }

    function handleKeypress(e: KeyboardEvent) {
      if (e.key === "Enter" && e.shiftKey) {
        e.preventDefault();
        triggerBlur();
      }
    }

    function handleKeyup(e: KeyboardEvent) {
      if (e.key === "Escape") {
        triggerBlur();
      }
    }

    function handleKeydown(e: KeyboardEvent) {
      // Note (Noah, 2022-07-01): This needs to be in a keydown handler because
      // for some reason metaKey is not supported in keypress or keyup handlers.
      if (e.key === "Enter" && e.metaKey) {
        e.preventDefault();
        triggerBlur();
      }
    }

    function handlePaste(event: ClipboardEvent) {
      event.preventDefault();
      const clipboardData = event.clipboardData;
      const pastedData = clipboardData?.getData("Text");
      const ownerDocument = getOwnerWindowSafe(element!)?.document;
      const isUrl = pastedData ? isValidHttpUrl(pastedData) : false;
      const selectedText = ownerDocument?.getSelection()?.toString();

      if (isUrl && selectedText) {
        const linkText = `<a href="${pastedData}" target="_blank">${selectedText}</a>`;
        ownerDocument?.execCommand("insertHTML", false, linkText);
      } else {
        ownerDocument?.execCommand("insertHTML", false, pastedData);
      }
    }
  }, [
    component.id,
    containerRef,
    isEditorApp,
    onSubmitContentEditableTextUpdate,
    contentRef,
  ]);

  React.useEffect(() => {
    const currentTextElement = contentRef.current;
    const ownerWindow = currentTextElement
      ? getOwnerWindowSafe(currentTextElement)
      : null;
    if (
      !isContentEditable ||
      !containerRef.current ||
      !currentTextElement ||
      !ownerWindow
    ) {
      return;
    }

    const ownerDocument = ownerWindow.document;
    const selection = ownerWindow.getSelection();
    const range = ownerDocument.createRange();
    range.selectNodeContents(currentTextElement);
    selection?.removeAllRanges();
    selection?.addRange(range);
    containerRef.current.focus();
    currentTextElement.focus();
    // Add an onClick handler if we're content editing so we can capture the
    // click event and stop its propagation - this lets us ignore AlchemyAction
    // click handlers when we're clicking around in the element to edit content.
    currentTextElement.addEventListener("click", handleOnClick);
    return () => {
      currentTextElement.removeEventListener("click", handleOnClick);
    };

    function handleOnClick(e: Event) {
      if (isContentEditable) {
        e.stopPropagation();
      }
    }
  }, [containerRef, isContentEditable, contentRef]);

  React.useEffect(() => {
    if (!containerRef.current || !isEditorApp) {
      return;
    }

    const container = containerRef.current as HTMLElement;
    const cleanUpEventListeners = noop;

    const disableChildAnchorTags = () => {
      cleanUpEventListeners();

      // NOTE (Fran 2024-05-20): We need to prevent the default behavior of the anchor tags in the text
      // element when we are in the editor app. This is because we want to prevent the text having a
      // link set on it from navigating away when clicked. We only want to allow the user to edit the text.
      const anchorTagsInTextElement = container.querySelectorAll("a");
      const cleanupFunctions: Array<() => void> = [];
      if (anchorTagsInTextElement.length > 0) {
        for (const anchorElement of anchorTagsInTextElement) {
          anchorElement.addEventListener("click", handleOnClick);
          cleanupFunctions.push(() => {
            anchorElement.removeEventListener("click", handleOnClick);
          });
        }
      }
    };

    disableChildAnchorTags();

    // NOTE (Fran 2024-05-21): We need to observe the container for changes in the child list so we can
    // disable the anchor tags in the text element when they are added.
    // Shoutout to Chance for the idea of using MutationObserver.
    const observer = new MutationObserver(disableChildAnchorTags);
    observer.observe(containerRef.current, {
      subtree: true,
      childList: true,
    });

    return () => {
      observer.disconnect();
      cleanUpEventListeners();
    };

    function handleOnClick(e: Event) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, [containerRef, isEditorApp]);

  // Do not return event handler props in editing mode. These events get
  // triggered when pressing spacebar or enter in content editing mode.
  return !isEditorCanvas ? { onClick, onKeyPress } : {};
}

type RenderProps = Record<
  "containerProps" | "contentProps",
  React.ComponentProps<"span"> & {
    [key: `data-${string}`]: unknown;
  }
>;
