import type {
  ComponentActionType,
  CompositeActionType,
} from "@editor/types/component-action-type";
import type { CoreState } from "@editor/types/core-state";
import type { RuntimeTriggerProp } from "@editor/types/get-attribute-function";
import type { RuntimeStyleAttributeEditorData } from "@editor/utils/styleAttribute";
import type { ConditionStatement } from "replo-runtime/shared/types";
import type { ComponentStyleProps } from "replo-runtime/shared/utils/renderComponents";
import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Action } from "schemas/actions";
import type { MediaSize } from "schemas/breakpoints";
import type { Component, ReploComponentType } from "schemas/component";
import type { InlineItemsConfig } from "schemas/dynamicData";
import type { ReploElement } from "schemas/generated/element";
import type { ReploState, ReploSymbol } from "schemas/generated/symbol";
import type { RuntimeStyleAttribute } from "schemas/styleAttribute";

import { getNewTabPanel } from "@components/editor/templates/newTabPanel";
import { giveNameToComponent } from "@editor/components/editor/component";
import { getConditionFieldDisplayName } from "@editor/components/editor/conditionFieldToDisplayName";
import {
  MAX_STYLE_PROPERTY_VALUE_LENGTH,
  NEW_STATE_NAME,
} from "@editor/components/editor/constants";
import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  prepareComponentTemplate,
} from "@editor/components/editor/defaultComponentTemplates";
import { EditorMode } from "@editor/types/core-state";
import { canvasToStyleMap } from "@editor/utils/editor";
import { ImageSourceTooLongError } from "@editor/utils/errors";
import { getApplicableRuntimeStyleAttributes } from "@editor/utils/modifierGroups";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import { paramsStringToMapping } from "@editor/utils/url";
import { analytics, getGlobalEventProperties } from "@infra/analytics";
import transformSetStyles from "@reducers/utils/transforms/transform-set-styles";
import { move } from "@utils/array";
import {
  findAncestorComponentOrSelfWithVariants,
  findComponentById,
  findComponentByIdInComponent,
} from "@utils/component";
import { getAttributeRetriever, getVariants } from "@utils/component-attribute";
import { expandAllSymbols } from "@utils/editorSymbols";

import produce from "immer";
import camelCase from "lodash-es/camelCase";
import capitalize from "lodash-es/capitalize";
import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";
import isObject from "lodash-es/isObject";
import mapValues from "lodash-es/mapValues";
import merge from "lodash-es/merge";
import mergeWith from "lodash-es/mergeWith";
import pickBy from "lodash-es/pickBy";
import remove from "lodash-es/remove";
import set from "lodash-es/set";
import truncate from "lodash-es/truncate";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_MONEY_FORMAT,
} from "replo-runtime/shared/liquid";
import {
  editorCanvasToMediaSize,
  mediaSizeStyles,
  mediaSizeStylesToAnimationSize,
  supportedMediaSizes,
} from "replo-runtime/shared/utils/breakpoints";
import {
  findComponentInComponent,
  findParent,
  forEachComponentAndDescendants,
  getChildren,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { mapNull } from "replo-runtime/shared/utils/optional";
import {
  findDefault,
  findVariant,
  findVariantByConditionField,
} from "replo-runtime/shared/variant";
import { fakeProducts } from "replo-runtime/store/utils/fakeProducts";
import { itemsConfigToRenderData } from "replo-runtime/store/utils/items";
import { refreshComponentIds } from "replo-shared/refreshComponentIds";
import { filterNulls } from "replo-utils/lib/array";
import {
  exhaustiveSwitch,
  isEmpty,
  isNullish,
  mergeReplacingArrays,
  mergeReplacingObjects,
} from "replo-utils/lib/misc";
import { isArray, isFunction, isString } from "replo-utils/lib/type-check";
import { mediaSizes } from "schemas/breakpoints";
import { styleAttributeToDefaultStyle } from "schemas/styleAttribute";
import { v4 as uuidv4 } from "uuid";

export const getValidFilteredStyleProps = (
  styleProps: Record<string, Record<string, string>>,
  componentType: ReploComponentType,
  extras: { colorValue: string | null; textValue?: string },
  addExtraStyles = false,
) => {
  const validCssProperties = getApplicableRuntimeStyleAttributes(
    componentType,
    extras.colorValue,
    extras.textValue,
    addExtraStyles,
  );

  return pickBy(
    mapValues(styleProps, (values) =>
      pickBy(values, (_, key) =>
        validCssProperties?.includes(key as RuntimeStyleAttribute),
      ),
    ),
    (values) => !isEmpty(values),
  );
};

const getImpliedDraftComponent = (state: CoreState) => {
  const elementId = state.elements.draftElementId;
  const componentId = state.elements.draftComponentId;
  return {
    elementId,
    componentId,
  };
};

/**
 * finds the component while considering symbol ref
 */

const resolveComponentConsideringSymbolInstance = (
  componentId: string,
  element: ReploElement,
  state: CoreState,
): Component | null => {
  let component = mapNull(element, (element) =>
    findComponentById(element, componentId),
  );
  if (!component && state.elements.draftSymbolInstanceId && element) {
    // try expanding symbols
    const symbolInstance = findComponentById(
      element,
      state.elements.draftSymbolInstanceId,
    );
    if (symbolInstance) {
      component = mapNull(componentId, (componentId) =>
        findComponentByIdInComponent(
          expandAllSymbols(
            symbolInstance,
            state.symbols.mapping,
            state.elements.componentIdToDraftVariantId,
          ),
          componentId,
        ),
      );
    }
  }
  return component;
};

/**
 * calculates element and one component from given op.componentId or draftComponent
 */

export const resolveComponentAndElement = (
  op: ComponentActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
): { component: Component | null; element: ReploElement } => {
  const data = getImpliedDraftComponent(state);
  const elementId = op.elementId || data.elementId;
  const componentId = op.componentId || data.componentId!;

  const element = mapNull(elementId, (elementId) => get(elements, elementId));

  const component = resolveComponentConsideringSymbolInstance(
    componentId,
    element!,
    state,
  );

  return { component, element: element! };
};

/**
 * calculates element and multiple components from given op.componentIds
 */

const resolveMultipleComponentsAndElement = (
  op: ComponentActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
): { components: (Component | null)[]; element: ReploElement } => {
  const data = getImpliedDraftComponent(state);
  const elementId = op.elementId || data.elementId;
  let componentIds: string[];
  if (op.componentIds) {
    componentIds = op.componentIds;
  } else if (data.componentId != null) {
    componentIds = [data.componentId];
  } else {
    componentIds = [];
  }

  const element = mapNull(elementId, (elementId) => elements[elementId]);

  const components = componentIds.map((componentId) => {
    return resolveComponentConsideringSymbolInstance(
      componentId,
      element!,
      state,
    );
  });

  return { components, element: element! };
};

export const resolveComponent = (
  op: ComponentActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  return component;
};

const setStyles = (
  op: ComponentActionType & { type: "setStyles" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  let prefix = "style";
  if (op.activeCanvas === "tablet") {
    prefix = "style@md";
  }
  if (op.activeCanvas === "mobile") {
    prefix = "style@sm";
  }
  return applyPropUpdate(op, { [prefix]: op.value }, elements, state);
};

const setStylesFromCSS = (
  op: ComponentActionType & {
    type: "setStylesFromCSS";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const componentToPasteStyles = resolveComponent(op, elements, state);
  if (!componentToPasteStyles) {
    return [];
  }
  const getAttribute = getAttributeRetriever(state, op.activeCanvas);
  const stylesFromComponentToPasteStyles = Object.fromEntries(
    filterNulls(
      mediaSizeStyles.map((mediaSize) => {
        const styles = getAttribute(
          componentToPasteStyles,
          `props.${mediaSize}`,
          null,
        ).value;
        if (styles) {
          return [mediaSize, styles];
        }
        return null;
      }),
    ),
  );

  const cssObject = convertCSSStylesToReploStyles(
    op.value,
    componentToPasteStyles.type === "text",
  );
  const textValue = getAttribute(
    componentToPasteStyles,
    `props.text`,
    null,
  ).value;
  const colorValue = getAttribute(
    componentToPasteStyles,
    `style.color`,
    null,
  ).value;

  const mergedStyles = merge({}, stylesFromComponentToPasteStyles, {
    style: cssObject,
  });

  const filteredValidStyleProps = getValidFilteredStyleProps(
    mergedStyles,
    componentToPasteStyles?.type,
    { colorValue, textValue },
    true,
  );

  return applyPropUpdate(op, filteredValidStyleProps, elements, state);
};

const pasteStyles = (
  op: ComponentActionType & {
    type: "pasteStyles";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const componentToPasteStyles = resolveComponent(op, elements, state);
  if (!componentToPasteStyles) {
    return [];
  }
  const getAttribute = getAttributeRetriever(state, op.activeCanvas);
  const stylesFromComponentToPasteStyles = Object.fromEntries(
    filterNulls(
      mediaSizeStyles.map((mediaSize) => {
        const styles = getAttribute(
          componentToPasteStyles,
          `props.${mediaSize}`,
          null,
        ).value;
        if (styles) {
          return [mediaSize, styles];
        }
        return null;
      }),
    ),
  );

  const textValue = getAttribute(
    componentToPasteStyles,
    `props.text`,
    null,
  ).value;
  const colorValue = getAttribute(
    componentToPasteStyles,
    `style.color`,
    null,
  ).value;

  // NOTE (Sebas, 2022-12-04): We need to merge the current styles
  // them with the ones the user copied.
  const mergedStyles = merge({}, stylesFromComponentToPasteStyles, op.value);

  const filteredValidStyleProps = getValidFilteredStyleProps(
    mergedStyles,
    componentToPasteStyles?.type,
    { colorValue, textValue },
  );

  return applyPropUpdate(op, filteredValidStyleProps, elements, state);
};

const getVariantOrDefault = (
  componentWithVariants: Component,
  state: CoreState,
  variantId?: string,
): ReploState => {
  const variants = getVariants(componentWithVariants, state.symbols.mapping);

  const variantIdToFind =
    variantId ||
    state.elements.componentIdToDraftVariantId[componentWithVariants.id];

  if (variantIdToFind) {
    return findVariant(variants, variantIdToFind) ?? findDefault(variants);
  }
  return findDefault(variants);
};

function filterComponentStyles(
  styles: Record<string, any>,
  shouldRemoveDefaultValues: boolean,
) {
  return pickBy(styles, (value, key) => {
    // Note (Chance 2023-07-17) Cast `as any` here for simplicity.
    // `styleAttributeToEditorData` might be missing some keys from
    // `RuntimeStyleAttributeEditorData` so technically access is unsafe, but
    // really it'll just be undefined so we can cast the resulting object with
    // that in mind.
    const styleAttributeData = (styleAttributeToEditorData as any)[key] as
      | RuntimeStyleAttributeEditorData
      | undefined;

    const isValueNaN =
      typeof value === "string" ? value?.includes("NaN") : false;

    const isUnsupportedValue =
      value === "" ||
      isValueNaN ||
      (isNullish(value) && !styleAttributeData?.allowNullishValue);

    // Note (Fran, 2022-06-16): If the styles that we have to filter are from
    // the desktop version, we need to filter the styles that are equal to the
    // default values.
    const isSameAsDefault = isEqual(value, styleAttributeData?.defaultValue);

    const shouldRemoveDefaultValuesLocal =
      shouldRemoveDefaultValues && styleAttributeData?.shouldFilterDefaultValue;

    return (
      !isUnsupportedValue &&
      !(shouldRemoveDefaultValuesLocal && isSameAsDefault)
    );
  });
}

function filterStyles(object: Component["props"]) {
  if (object?.style) {
    object.style = filterComponentStyles(object.style, true);
  }

  // TODO: (Fran, 2022-06-20): We have to improve the way we filter the styles.
  // Not always we want to remove the default values on desktop and probably we
  // need to remove the default values on mobile sometimes.
  // https://github.com/replohq/andytown/pull/2129#discussion_r899498179

  mediaSizes.forEach((mediaSize) => {
    if (object?.[`style@${mediaSize}`]) {
      object[`style@${mediaSize}`] = filterComponentStyles(
        // @ts-ignore
        object[`style@${mediaSize}`],
        false,
      );
    }
  });
}

const applyPropUpdate = (
  op: ComponentActionType & { variantId?: string },
  newPropValues: { [propName: string]: any }, // field, not including "props"
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );

  assertValidProps(newPropValues);

  if (!component) {
    return [];
  }

  const symbolInstance =
    state.elements.draftSymbolInstanceId &&
    findComponentById(element, state.elements.draftSymbolInstanceId);
  if (symbolInstance && !op.allVariants) {
    const draftComponentId = op.componentId || state.elements.draftComponentId;

    const { symbolChildId } =
      draftComponentId === symbolInstance.id
        ? { symbolChildId: symbolInstance.id }
        : paramsStringToMapping(draftComponentId ?? "");

    // Note (Noah, 2021-12-21): There might not be a symbol child id even if there's
    // a draft symbol instance id because we could be applying this action to a component
    // which is not the draft component. Therefore, only apply the update to the symbol
    // instance if we're actually editing the symbol child
    if (symbolChildId) {
      const variant = getVariantOrDefault(symbolInstance, state, op.variantId)!;

      const mergePath = {
        variantOverrides: {
          [variant.id]: {
            componentOverrides: {
              [symbolChildId]: {
                props: newPropValues,
              },
            },
          },
        },
      };
      mergeReplacingArrays(symbolInstance, mergePath);
      return [symbolInstance.id];
    }
  }

  const ancestorComponentWithVariants = findAncestorComponentOrSelfWithVariants(
    element,
    component.id,
    state.symbols.mapping,
  );

  if (ancestorComponentWithVariants && !op.allVariants) {
    const variant = getVariantOrDefault(
      ancestorComponentWithVariants,
      state,
      op.variantId,
    );

    if (variant && variant.name !== "default") {
      const mergePath = {
        [variant.id]: {
          componentOverrides: {
            [component.id]: {
              props: newPropValues,
            },
          },
        },
      };

      ancestorComponentWithVariants.variantOverrides = produce(
        ancestorComponentWithVariants.variantOverrides || {},
        (draft) => {
          mergeReplacingArrays(draft, mergePath);
        },
      );

      if (ancestorComponentWithVariants.props) {
        ancestorComponentWithVariants.props = produce(
          ancestorComponentWithVariants.props,
          filterStyles,
        );
      }

      return [ancestorComponentWithVariants.id];
    }
  }

  if (!component.props) {
    component.props = {};
  }

  // Merge replacing arrays, because we want any array prop values (e.g. collection
  // products) to be subject to lodash's recursive merge strategy which actually
  // tries to merge the arrays instead of replacing them
  component.props = produce(component.props, (draft) => {
    mergeReplacingArrays(draft, newPropValues);
  });

  component.props = produce(component.props, filterStyles);
  return [component.id];
};

const setProps = (
  op: ComponentActionType & { type: "setProps" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  return applyPropUpdate(op, op.value, elements, state);
};

const deleteProps = (
  op: ComponentActionType & { type: "deleteProps" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }
  delete component.props[op.propName];
  return [component.id];
};

const deleteStyle = (
  op: ComponentActionType & { type: "deleteStyle" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }
  if (!component.props.style) {
    return [];
  }
  delete component.props.style[op.style];
  return [component.id];
};

const setAttribute = (
  op: ComponentActionType & { type: "setAttribute" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  for (const [key, value] of Object.entries(op.value)) {
    set(component, `${key}`, value);
  }
  return [component.id];
};

const setMarker = (
  op: ComponentActionType & { type: "setMarker" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const newMarkers = { ...component.markers };
  for (const [key, value] of Object.entries(op.value ?? {})) {
    set(newMarkers, key, value);
  }
  set(component, "markers", newMarkers);

  return [component.id];
};

const updateVariant = (
  op: ComponentActionType & { type: "updateVariant" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  component.variants = (component.variants || []).map((variant) => {
    const variantFromComponentAction = op.value.variant;
    // Note (Evan, 2023-12-18): The variant in the component action is either a variant or a
    // function mapping the current variant to the next variant. If it's the latter, then we
    // call the function to get the next variant.
    const nextVariant = isFunction(variantFromComponentAction)
      ? variantFromComponentAction(variant)
      : variantFromComponentAction;
    if (variant.id === nextVariant.id) {
      return nextVariant;
    }
    return variant;
  });
  return [component.id];
};

const createOrUpdateAction = (
  op: ComponentActionType & {
    type: "createOrUpdateAction";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const { trigger, action } = op.value;

  const currentActions =
    getAttributeRetriever(state, op.activeCanvas)(
      component,
      `props.${trigger as RuntimeTriggerProp}`,
      null,
    ).value || [];
  const index = currentActions.findIndex((a: Action) => a.id === action.id);

  return applyPropUpdate(
    op,
    {
      [trigger]:
        index !== -1
          ? currentActions.map((value: any, mapIndex: number) => {
              if (mapIndex === index) {
                return action;
              }
              return value;
            })
          : [...currentActions, action],
    },
    elements,
    state,
  );
};

const moveActionsToParent = (
  op: ComponentActionType & {
    type: "moveActionsToParent";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { trigger, destinationComponentId } = op.value;

  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }

  const destinationComponent = resolveComponent(
    { ...op, componentId: destinationComponentId },
    elements,
    state,
  );

  const getAttribute = getAttributeRetriever(state, op.activeCanvas);

  if (destinationComponent) {
    const buttonComponentActions =
      getAttribute(
        destinationComponent,
        `props.${trigger as RuntimeTriggerProp}`,
        null,
      ).value ?? [];

    const componentActions =
      getAttribute(component, `props.${trigger as RuntimeTriggerProp}`, null)
        .value ?? [];

    /* Copy all the actions from the child to the button ancestor */
    const op1UpdatedComponentIds =
      applyPropUpdate(
        {
          ...op,
          componentId: destinationComponent?.id,
        },
        {
          [trigger]: [...buttonComponentActions, ...componentActions],
        },
        elements,
        state,
      ) ?? [];

    /* Remove all actions from the button child */
    const op2UpdatedComponentIds =
      applyPropUpdate(
        op,
        {
          [trigger]: [],
        },
        elements,
        state,
      ) ?? [];

    return [...op1UpdatedComponentIds, ...op2UpdatedComponentIds];
  }

  return [];
};

const updateComponentName = (
  op: ComponentActionType & { type: "updateComponentName" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  component.name = op.value;
  return [component.id];
};

// Note (Evan, 2024-08-13): This is marked "unsafe" because it doesn't
// validate the compponent's props against the new type. It simply updates
// the "type" prop.
const unsafeUpdateComponentType = (
  op: ComponentActionType & { type: "unsafeUpdateComponentType" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  component.type = op.value;
  return [component.id];
};

const deleteComponent = (
  op: ComponentActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );

  const parent = findParent(element, component?.id ?? null);
  if (parent) {
    remove(parent.children!, (child) => child.id === component?.id);
    return [parent.id];
  }

  return [];
};

const deleteAction = (
  op: ComponentActionType & {
    type: "deleteAction";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const { trigger, actionId } = op.value;

  const currentActions = getAttributeRetriever(state, op.activeCanvas)(
    component,
    `props.${trigger as RuntimeTriggerProp}`,
    null,
  ).value;
  const next = currentActions.filter((a: Action) => a.id !== actionId);
  return applyPropUpdate(op, { [trigger]: next }, elements, state);
};

const deleteActions = (
  op: ComponentActionType & {
    type: "deleteActions";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const { trigger, actionIds } = op.value;

  const currentActions = getAttributeRetriever(state, op.activeCanvas)(
    component,
    `props.${trigger as RuntimeTriggerProp}`,
    null,
  ).value;

  const next = [];

  if (actionIds && actionIds.length > 0) {
    next.push(
      ...currentActions.filter((a: Action) => !actionIds.includes(a.id)),
    );
  }

  return applyPropUpdate(op, { [trigger]: next }, elements, state);
};

const moveComponentToParent = (
  op: ComponentActionType & {
    type: "moveComponentToParent";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const data = getImpliedDraftComponent(state);
  op.componentIds = [op.componentId || data.componentId!];
  return moveMultipleComponentsToParent(op, elements, state);
};

const applyPresetProps = (
  op: ComponentActionType & {
    type: "applyPresetProps";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );

  if (!component) {
    return [];
  }

  const getAttribute = getAttributeRetriever(state, op.activeCanvas);
  const getNewContainer = () => {
    const newContainer = prepareComponentTemplate(
      HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
      component,
      element,
      {
        getAttribute,
        productResolutionDependencies: {
          products: [],
          currencyCode: DEFAULT_ACTIVE_CURRENCY,
          language: DEFAULT_ACTIVE_LANGUAGE,
          moneyFormat: DEFAULT_MONEY_FORMAT,
          templateProduct: null,
          isEditor: true,
        },
        context: getCurrentComponentContext(component.id, 0) ?? null,
        componentDataMapping: state.componentDataMapping,
      },
    );
    return refreshComponentIds(newContainer).component;
  };

  remove(
    component.children || [],
    (child) => child.type === "container" && !child.children?.length,
  );

  for (
    let i = getChildren(component).length;
    i < op.value.children.length;
    i++
  ) {
    if (!component.children?.length) {
      component.children = [];
    }
    const updatedComponent = getNewContainer();
    component.children.push(updatedComponent);
  }

  if (op.value.children.length === 1) {
    remove(
      component.children || [],
      (child) => child.type === "container" && !child.children?.length,
    );
  } else {
    op.value.children.forEach((props, index) => {
      const child = component.children![index]!;
      const modifiedOp = { ...op, componentId: child.id };
      applyPropUpdate(modifiedOp, props, elements, state);
    });
  }

  return [component.id];
};

/**
 * moves one or more components to another parent in given positionWithinSiblings
 */

const moveMultipleComponentsToParent = (
  op: ComponentActionType & {
    type: "moveMultipleComponentsToParent" | "moveComponentToParent";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  if (!op.componentIds || op.componentIds.length === 0) {
    return [];
  }

  const { components, element } = resolveMultipleComponentsAndElement(
    op,
    elements,
    state,
  );
  const componentsToMove = filterNulls(components);
  const newParentComponent = findComponentById(
    element,
    op.value.parentComponentId,
  );

  // assuming all components share same parent
  const oldParentComponent =
    componentsToMove[0]?.id != null &&
    findParent(element, componentsToMove[0].id);

  if (
    !newParentComponent ||
    !oldParentComponent ||
    !oldParentComponent.children
  ) {
    return [];
  }

  const updatedComponentIds = [oldParentComponent.id];
  let newSiblingPosition = op.value.positionWithinSiblings;

  if (newParentComponent.id === oldParentComponent.id) {
    // If we're moving siblings in the same component, offset the new sibling index
    // to account for the fact that we're about to remove ourselves
    for (const componentId of op.componentIds) {
      const indexOfMovingComponent = oldParentComponent.children.findIndex(
        (child) => child.id === componentId,
      );
      if (
        newSiblingPosition != null &&
        newSiblingPosition > 0 &&
        op.value.positionWithinSiblings! > indexOfMovingComponent
      ) {
        newSiblingPosition -= 1;
      }
    }
  } else {
    updatedComponentIds.push(newParentComponent.id);
  }

  const indexes: Record<string, number> = {};
  for (const component of componentsToMove) {
    indexes[component.id] = oldParentComponent.children.findIndex(
      (child) => child.id === component.id,
    );
  }

  componentsToMove.sort((a, b) => {
    return indexes[a.id]! - indexes[b.id]!;
  });

  for (const componentId of op.componentIds) {
    remove(oldParentComponent.children, (child) => child.id === componentId);
  }

  if (newSiblingPosition != null) {
    for (const component of componentsToMove) {
      newParentComponent.children?.splice(newSiblingPosition, 0, component);
      newSiblingPosition += 1;
      updatedComponentIds.push(component.id);
    }
  }

  // delete old parent if it's empty and doesn't have a background image
  const getAttribute = getAttributeRetriever(state, op.activeCanvas);
  const oldParentHasBackgroundImage = getAttribute(
    oldParentComponent,
    "style.backgroundImage",
  ).value;
  if (
    shouldRemoveWhenLastChildIsRemoved(oldParentComponent.type) &&
    oldParentComponent.children.length === 0 &&
    !oldParentHasBackgroundImage
  ) {
    const parent = findParent(element, oldParentComponent.id);
    // don't remove it if it's a carousel slide because it break things for it
    if (parent?.children && parent.type !== "carouselV3Slides") {
      remove(parent.children, (child) => child.id === oldParentComponent.id);
    }
  }

  return updatedComponentIds;
};

const addVariantTriggerStatement = (
  op: ComponentActionType & { type: "addVariantTriggerStatement" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const variant = getVariantOrDefault(component, state, op.value.variantId);
  const newStatement = op.value.statement;
  const statementsFromOverrides: ConditionStatement[] | null = get(
    component,
    `variantOverrides.${variant.id}.query.statements`,
    null,
  );
  const statementsFromVariants = get(component, `variants`, []).find(
    (v: ReploState) => v.id === variant.id,
  )?.query?.statements;
  const newStatements = statementsFromOverrides || statementsFromVariants || [];

  const index = newStatements.findIndex(
    (s: ConditionStatement) => s.id === newStatement.id,
  );

  if (index !== -1) {
    newStatements.splice(index, 1, newStatement);
  } else {
    newStatements.push(newStatement);
  }

  if (statementsFromOverrides) {
    const toMerge = {
      variantOverrides: {
        [variant.id]: {
          query: {
            type: "expression",
            operator: "or",
            statements: newStatements,
          },
          name:
            variant.name === NEW_STATE_NAME
              ? getConditionFieldDisplayName(newStatement.field)
              : variant.name,
        },
      },
    };
    // eslint-disable-next-line object-merge/no-side-effects
    merge(component, toMerge);
  } else {
    component.variants = component.variants?.map((v: ReploState) => {
      if (v.id === variant.id) {
        return {
          ...v,
          query: {
            type: "expression",
            operator: "or",
            statements: newStatements,
          },
          name:
            variant.name === NEW_STATE_NAME
              ? getConditionFieldDisplayName(newStatement.field)
              : variant.name,
        };
      }
      return v;
    });
  }

  return [component.id];
};

const deleteVariantTriggerStatement = (
  op: ComponentActionType & {
    value: { statementId: string; variantId: string };
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  const variant = getVariantOrDefault(component, state, op.value.variantId);

  const statementsFromOverrides: ConditionStatement[] | null = get(
    component,
    `variantOverrides.${variant.id}.query.statements`,
    null,
  );
  const statementsFromVariants = get(component, `variants`, []).find(
    (v: ReploState) => v.id === variant.id,
  )?.query?.statements;
  const existingStatements =
    statementsFromOverrides || statementsFromVariants || [];
  const newStatements = existingStatements.filter(
    (s: ConditionStatement) => s.id !== op.value.statementId,
  );
  if (statementsFromOverrides) {
    set(
      component,
      `variantOverrides.${variant.id}.query.statements`,
      newStatements,
    );
  } else {
    component.variants = component.variants?.map((v: ReploState) => {
      if (v.id === variant.id) {
        return { ...v, query: { ...v.query, statements: newStatements } };
      }
      return v;
    });
  }

  return [component.id];
};

/* Adds a sibling */
const addComponentToComponent = (
  op: ComponentActionType & {
    type: "addComponentToComponent";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  const { newComponent, position, positionWithinSiblings } = op.value;

  // Note (Noah, 2022-03-30, REPL-1515): This shouldn't really ever be the case,
  // but apparently there might be a bug somewhere where sometimes this
  // newComponent is actually null. If this is the case, bail early because
  // inserting null is going to screw a bunch of stuff up and probably result in
  // a page crash.
  if (!newComponent || !component) {
    return [];
  }

  assertValidProps(newComponent.props);

  let targetComponent;
  if (position === "sibling-before" || position === "sibling-after") {
    targetComponent = findParent(element, component.id);
  } else {
    targetComponent = findComponentById(element, component.id);
  }

  if (targetComponent && targetComponent.children) {
    let insertIndex;
    if (position === "child") {
      if (positionWithinSiblings !== undefined) {
        insertIndex = positionWithinSiblings;
      } else {
        insertIndex = targetComponent.children.length;
      }
    } else {
      insertIndex = targetComponent.children.findIndex(
        (child) => child.id === component.id,
      );
      if (position === "sibling-after") {
        insertIndex = insertIndex + 1;
      }
    }

    targetComponent.children?.splice(insertIndex, 0, newComponent);

    return [targetComponent.id];
  }

  return [];
};

export const refreshIdsAndNameOfComponent = (
  newComponent: Component,
  element: ReploElement,
  newComponentId?: string,
) => {
  const refreshedComponent = refreshComponentIds(
    newComponent,
    newComponentId,
  ).component;
  for (const key of Object.keys(refreshedComponent)) {
    newComponent[key as keyof Component] = refreshedComponent[
      key as keyof Component
    ] as any;
  }
  giveNameToComponent(newComponent, element.component);
};

const duplicateComponent = (
  op: ComponentActionType & { type: "duplicateComponent" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }

  return addComponentToComponent(
    {
      type: "addComponentToComponent",
      componentId: component.id,
      value: {
        position: "sibling-after",
        newComponent: op.newComponent,
      },
      activeCanvas: op.activeCanvas,
    },
    elements,
    state,
  );
};

const replaceComponent = (
  op: ComponentActionType & {
    type: "replaceComponent";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  if (!component) {
    return [];
  }

  const shouldRefreshComponentIdsAndName =
    op.value.shouldRefreshComponentIdsAndNames ?? true;
  if (shouldRefreshComponentIdsAndName) {
    refreshIdsAndNameOfComponent(op.value.newComponent as Component, element);
  }

  for (const key of Object.keys(component)) {
    delete (component as any)[key];
  }

  for (const key of Object.keys(op.value.newComponent)) {
    (component as any)[key] = op.value.newComponent[key];
  }

  return [op.value.newComponent.id as string];
};

const addAnimation = (
  op: ComponentActionType & {
    type: "addAnimation";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }
  const animation = op.value;
  const availableMediaSizes = mediaSizeStyles.filter(
    (mediaSize) => !component.props?.[mediaSize]?.__animation,
  );
  // NOTE (Sebas, 2024-04-25): Set the available media sizes to the animation
  // devices to only add it to the styles that don't have an animation yet.
  animation.devices = availableMediaSizes.flatMap(
    (mediaSize) => mediaSizeStylesToAnimationSize[mediaSize],
  ) as MediaSize[];

  component.animations = [...(component.animations || []), animation];

  const updates = { ...component.props };

  for (const device of availableMediaSizes) {
    updates[device] = { __animation: animation.value.styles };
  }

  if (updates) {
    return applyPropUpdate(op, updates, elements, state);
  }

  return [];
};

const updateAnimation = (
  op: ComponentActionType & {
    type: "updateAnimation";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  const animation = op.value;

  // NOTE (Sebas, 2024-04-25): This is in charge of updating the animation
  // values in the component.
  component.animations = produce(component.animations, (draft) => {
    for (const draftAnimation of draft ?? []) {
      if (draftAnimation.id === animation.id) {
        if (op.resetValues) {
          // NOTE (Sebas, 2024-04-24): In case we want to reset the values we need to merge replacing objects
          // to replace the entire value object with the new one.
          mergeWith(draftAnimation, animation, (_, b) =>
            isObject(b) && isEmpty(b) ? b : undefined,
          );
        } else {
          // NOTE (Sebas, 2024-04-24): In case we don't want to reset the values we need to merge
          // replacing arrays to avoid having duplicated values in the devices array.
          mergeWith(draftAnimation, animation, (_, b) =>
            isArray(b) ? b : undefined,
          );
        }
      }
    }
  });

  // NOTE (Sebas, 2024-04-25): This is in charge of updating the style property
  // values in the component.
  const updates: ComponentStyleProps = {}; // Initialize an empty object for updates
  for (const mediaSize of mediaSizeStyles) {
    const mediaSizeAnimation = component.animations?.find(({ devices }) =>
      devices.some((device) =>
        mediaSizeStylesToAnimationSize[mediaSize].includes(device),
      ),
    );
    updates[mediaSize] = mediaSizeAnimation
      ? { __animation: mediaSizeAnimation.value.styles }
      : { __animation: null };
  }

  if (updates) {
    return applyPropUpdate(op, updates, elements, state);
  }

  return [];
};

const deleteAnimation = (
  op: ComponentActionType & { type: "deleteAnimation" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  const animationToDelete = component.animations?.find(
    (animation) => animation.id === op.value,
  );
  component.animations = component.animations?.filter(
    (animation) => animation.id !== animationToDelete?.id,
  );

  const updates: ComponentStyleProps = {};
  for (const device of animationToDelete?.devices ?? []) {
    const prefix =
      `style${["xl", "lg"].includes(device) ? "" : `@${device}`}` as keyof ComponentStyleProps;

    updates[prefix] = { __animation: null };
  }

  if (updates) {
    return applyPropUpdate(op, updates, elements, state);
  }

  return [];
};

const addVariant = (
  op: ComponentActionType & { type: "addVariant" | "addPredefinedVariant" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  const newVariant: ReploState = {
    ...op.value.variant,
    componentOverrides: null,
  };
  if (component.variants) {
    component.variants = [...component.variants, newVariant];
  } else {
    const defaultVariant: ReploState = {
      id: uuidv4(),
      name: "default",
      query: null,
      componentOverrides: null,
    };
    component.variants = [defaultVariant, newVariant];
  }

  return [component.id];
};

const getValueToApplyStylesToAllMediaSizes = (styles: Record<string, any>) => {
  return Object.fromEntries(
    mediaSizeStyles.map((mediaSize) => {
      return [mediaSize, styles];
    }),
  );
};

const addPredefinedVariant = (
  op: ComponentActionType & {
    type: "addPredefinedVariant";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  addVariant(op, elements, state);
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  if (op.value.predefinedVariantType) {
    const componentActions: ComponentActionType[] = [];
    exhaustiveSwitch({ type: op.value.predefinedVariantType })({
      loading: () => {
        // NOTE (Fran 2024-04-15): We need to add a spinner to the component only if it
        // has a single text child.
        if (
          component.children?.length === 1 &&
          component.children[0]?.type === "text"
        ) {
          const variants = getVariants(component, state.symbols.mapping);
          const loadingVariant = findVariantByConditionField(
            variants,
            "state.action.loading",
          );

          const hasButtonDefinedAnAllignment = Boolean(
            getAttributeRetriever(state, op.activeCanvas)(
              component,
              "style.justifyContent",
              null,
            ).value,
          );

          if (!hasButtonDefinedAnAllignment) {
            componentActions.push({
              componentId: component.id,
              type: "setProps",
              value: getValueToApplyStylesToAllMediaSizes({
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }),
              allVariants: true,
              activeCanvas: op.activeCanvas,
            });
          }

          // Add a spinner to the component
          const newSpinnerComponentId = uuidv4();
          componentActions.push({
            componentId: component.id,
            type: "addComponentToComponent",
            value: {
              newComponent: {
                id: newSpinnerComponentId,
                type: "spinner",
                props: {
                  circleWidth: "20px",
                  foregroundColor: "#e2e8f0",
                  secondaryColor: "#64748b",
                },
                name: "Spinner",
              },
              position: "child",
            },
            activeCanvas: op.activeCanvas,
            analyticsExtras: {
              actionType: "create",
              createdBy: "replo",
            },
          });

          // Hide the spinner by default
          componentActions.push({
            componentId: newSpinnerComponentId,
            type: "setProps",
            value: getValueToApplyStylesToAllMediaSizes({
              display: "none",
            }),
            allVariants: true,
            activeCanvas: op.activeCanvas,
          });

          // Show the spinner when the component is in the loading state
          componentActions.push({
            componentId: newSpinnerComponentId,
            type: "setProps",
            value: getValueToApplyStylesToAllMediaSizes({
              display: styleAttributeToDefaultStyle["display"],
            }),
            variantId: loadingVariant?.id,
            activeCanvas: op.activeCanvas,
          });

          // Hide the text when the component is in the loading state
          const textComponentId = component.children[0].id;
          componentActions.push({
            componentId: textComponentId,
            type: "setProps",
            value: getValueToApplyStylesToAllMediaSizes({
              display: "none",
            }),
            activeCanvas: op.activeCanvas,
            variantId: loadingVariant?.id,
          });
        }
      },
      outOfStock: () => null,
      hover: () => null,
      activeTab: () => null,
      selectedOption: () => null,
      selectedVariant: () => null,
      selectedSellingPlan: () => null,
      collapsibleOpen: () => null,
      beforeAfterDragging: () => null,
      tooltipOpen: () => null,
      selectedListItem: () => null,
    });
    return applyComponentAction({
      action: {
        type: "applyCompositeAction",
        value: componentActions,
        activeCanvas: op.activeCanvas,
      },
      elements,
      state,
    });
  }

  return [component.id];
};

const deleteVariant = (
  op: ComponentActionType & { type: "deleteVariant" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  component.variants = (component.variants || []).filter(
    (v: ReploState) => v.id !== op.value.variantId,
  );

  if (component.variantOverrides?.[op.value.variantId]) {
    delete component.variantOverrides[op.value.variantId];
  }

  if (
    component.variants.length === 1 &&
    component.variants[0]!.name === "default"
  ) {
    delete component.variants;
  }

  return [component.id];
};

const reorderVariant = (
  op: ComponentActionType & { type: "reorderVariant" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }

  const { oldIndex, newIndex } = op.value;
  component.variants = move(component.variants, oldIndex, newIndex);

  return [component.id];
};

const reorderAction = (
  op: ComponentActionType & {
    type: "reorderAction";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }

  const { oldIndex, newIndex, property } = op.value;

  const currentActions =
    getAttributeRetriever(state, op.activeCanvas)(
      component,
      `props.${property as RuntimeTriggerProp}`,
      null,
    ).value || [];

  return applyPropUpdate(
    op,
    { [property]: move(currentActions, oldIndex, newIndex) },
    elements,
    state,
  );
};

const syncTabContent = (
  op: ComponentActionType & { type: "syncTabContent" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const component = resolveComponent(op, elements, state);
  if (!component) {
    return [];
  }

  const tabsContentComponent = findComponentInComponent(
    component,
    (c) => c.type === "tabs__panelsContent",
  );

  if (!tabsContentComponent) {
    return [];
  }

  const tabItems: InlineItemsConfig =
    getAttributeRetriever(state, op.activeCanvas)(
      component,
      "props._tabsConfig",
      null,
    ).value ?? [];

  const tabValues = itemsConfigToRenderData(tabItems).getValues({
    dataTables: state.dataTables.mapping,
    context: getCurrentComponentContext(component.id, 0),
    productDependencies: {
      products: [],
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      fakeProducts,
      templateProduct: null,
      isEditor: true,
    },
  });
  const tabItemIds = tabValues.map((item) => item.id);
  for (const tabItemId of tabItemIds) {
    if (!tabsContentComponent.props[`_tabPanel_${tabItemId}`]) {
      const newComponent: Component = getNewTabPanel(tabItemId);
      tabsContentComponent.props[`_tabPanel_${tabItemId}`] = newComponent;
    }
  }

  // @ts-expect-error
  const existingTabIds: string[] =
    tabsContentComponent.props["_tabPanelIds"] || [];
  for (const existingTabId of existingTabIds) {
    if (
      !tabItemIds.includes(existingTabId) &&
      tabsContentComponent.props[`_tabPanel_${existingTabId}`]
    ) {
      delete tabsContentComponent.props[`_tabPanel_${existingTabId}`];
    }
  }

  // @ts-ignore
  tabsContentComponent.props["_tabPanelIds"] = tabItemIds;

  return [component.id, tabsContentComponent.id];
};

function duplicateComponentOverrides(
  op: ComponentActionType & {
    type: "duplicateComponentOverrides";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  if (!component) {
    return [];
  }

  const ancestorComponentWithVariants = findAncestorComponentOrSelfWithVariants(
    element,
    component.id,
    state.symbols.mapping,
  );

  if (ancestorComponentWithVariants) {
    const variant = getVariantOrDefault(
      ancestorComponentWithVariants,
      state,
      op.variantId,
    );

    const variantsOverrides = cloneDeep(
      ancestorComponentWithVariants.variantOverrides,
    );

    if (variant && variant.name !== "default") {
      ancestorComponentWithVariants.variantOverrides = produce(
        ancestorComponentWithVariants.variantOverrides || {},
        (draft) => {
          const mergePath = {
            [op.variantId]: {
              componentOverrides: {
                [op.destinationComponentId]:
                  variantsOverrides?.[op.variantId]?.componentOverrides?.[
                    op.componentIdToCopyOverridesFrom
                  ],
              },
            },
          };
          mergeReplacingArrays(draft, mergePath);
        },
      );

      return [ancestorComponentWithVariants.id, op.destinationComponentId];
    }
  }

  return [];
}

const resetStateToDefault = (
  op: ComponentActionType & {
    type: "resetStateToDefault";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  if (!component) {
    return [];
  }

  const ancestorComponentWithVariants = findAncestorComponentOrSelfWithVariants(
    element,
    component.id,
    state.symbols.mapping,
  );
  if (ancestorComponentWithVariants) {
    const variant = getVariantOrDefault(
      ancestorComponentWithVariants,
      state,
      op.variantId,
    );
    if (
      ancestorComponentWithVariants?.variantOverrides?.[op.variantId] &&
      variant.name !== "default"
    ) {
      delete ancestorComponentWithVariants.variantOverrides?.[op.variantId]
        ?.componentOverrides?.[op.componentId];

      return [ancestorComponentWithVariants.id, op.componentId];
    }
  }

  return [];
};

const pushOverridePropsToDefault = (
  op: ComponentActionType & {
    type: "pushOverridePropsToDefault";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  if (!component) {
    return [];
  }

  const ancestorComponentWithVariants = findAncestorComponentOrSelfWithVariants(
    element,
    component.id,
    state.symbols.mapping,
  );
  const defaultVariantId = ancestorComponentWithVariants?.variants?.find(
    (variant) => variant?.name === "default",
  )?.id;
  if (ancestorComponentWithVariants && defaultVariantId) {
    const componentProps =
      ancestorComponentWithVariants?.variantOverrides?.[op.variantId]
        ?.componentOverrides?.[component.id]?.props;
    if (componentProps) {
      return applyComponentAction({
        action: {
          componentId: op.componentId,
          variantId: defaultVariantId,
          type: "setProps",
          value: componentProps,
          analyticsExtras: {
            actionType: "edit",
            createdBy: "replo",
          },
          activeCanvas: op.activeCanvas,
        },
        elements,
        state,
      });
    }
  }

  return [];
};

/**
 * Take the styles from this component (and optionally all descendants) of the current device
 * size and merge them the into "upstream" (larger) device size style objects. This is mostly
 * useful for customer support scenarios where someone is like "oh no I built my whole page in
 * mobile view and now I want to make it desktop view but I don't want to have to rebuild it
 * because I didn't read the instructions and I didn't realize how styles override on mobile".
 *
 * Eventually we should support styles flowing from mobile up rather than from desktop down,
 * but this helps in pinches with customers for now.
 */
const persistStylesToUpstreamDevices = (
  op: ComponentActionType & { type: "persistStylesToUpstreamDevices" },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component } = resolveComponentAndElement(op, elements, state);
  if (!component) {
    return [];
  }

  const activeCanvas = op.activeCanvas;
  const getAttribute = getAttributeRetriever(state, op.activeCanvas);
  const { mediaSize } = editorCanvasToMediaSize[activeCanvas];

  const mediaSizeToPostfixes: Record<MediaSize, string[]> = {
    sm: ["@md", ""],
    md: [""],
    lg: [],
  };

  const applyUpdateForComponent = (component: Component) => {
    const mediaStyles = getAttribute(
      component,
      `props.style${mediaSize === "lg" ? "" : `@${mediaSize}`}`,
      null,
    ).value;

    if (!mediaStyles || isEmpty(mediaStyles)) {
      return;
    }

    for (const postfix of mediaSizeToPostfixes[mediaSize]) {
      const dependentDeviceStyles = getAttribute(
        component,
        `props.style${postfix}`,
        null,
      ).value;
      applyComponentAction({
        action: {
          componentId: component.id,
          type: "setProps",
          value: {
            [`style${postfix}`]: merge({}, dependentDeviceStyles, mediaStyles),
          },
          analyticsExtras: {
            actionType: "edit",
            createdBy: "replo",
          },
          activeCanvas: op.activeCanvas,
        },
        elements,
        state,
      });
    }
  };

  if (op.includeDescendants) {
    forEachComponentAndDescendants(component, applyUpdateForComponent);
  } else {
    applyUpdateForComponent(component);
  }

  return [component.id];
};

const applyCompositeAction = (
  op: CompositeActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const componentIds: string[] = [];
  for (const action of op.value) {
    const updatedComponentIds = applyComponentAction({
      action,
      elements,
      state,
      ignoreTransform: op.ignoreTransform,
    });
    if (updatedComponentIds) {
      componentIds.push(...updatedComponentIds);
    }
  }

  return componentIds;
};

/*
 * Function used to transform actions based on specific rules set
 * at an operation type level.
 */
function transformAction(
  action: ComponentActionType,
  elements: Record<string, ReploElement>,
  state: CoreState,
) {
  switch (action.type) {
    case "setStyles":
      return transformSetStyles(action, elements, state);
    default:
      return action;
  }
}

/*
 * Helper function so that transforms can automatically return a composite
 * action that will not transform their children.
 */
export function convertIntoCompositeAction(
  actions: ComponentActionType[],
  ignoreTransform = true,
): CompositeActionType {
  return {
    type: "applyCompositeAction",
    value: actions,
    ignoreTransform,
    activeCanvas: actions[0]?.activeCanvas ?? "desktop",
  };
}

export const convertCSSStylesToReploStyles = (
  cssStyles: string,
  isTextComponent: boolean,
) => {
  // Note (Sebas, 2023-04-11): This regular expression is used to remove all
  // the scripts that could be inside the CSS string.
  const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  cssStyles = cssStyles.replaceAll(scriptRegex, "");
  // Note (Sebas, 2023-04-11): This regular expression is used to remove all
  // the comments that could be inside the CSS string.
  const commentsRegex = /\/\/.*/g;
  cssStyles = cssStyles.replaceAll(commentsRegex, "");

  const cssArray = cssStyles.split(";").filter(Boolean);
  const cssObject: { [key: string]: string } = {};
  cssArray.forEach((rule) => {
    const [property, value] = rule.split(":").map((str) => str.trim());
    // Note (Sebas, 2023-03-31): We need to validate if the value contains "object" because
    // when we paste styles from Figma, if the border-radius has different values for each
    // side, it returns "border-radius: [object Object]px;".
    if (property && value && !value.includes("object")) {
      const [prop1, prop2, prop3, prop4] = value.split(" ");
      switch (property) {
        case "padding":
          cssObject.paddingTop = prop1!;
          cssObject.paddingBottom = prop2 ?? prop1!;
          cssObject.paddingLeft = prop3 ?? prop1!;
          cssObject.paddingRight = prop4 ?? prop1!;
          break;
        case "background":
          if (isTextComponent) {
            cssObject.color = value;
          } else {
            cssObject.backgroundColor = value;
          }
          break;
        case "border":
          const sides = ["Top", "Right", "Bottom", "Left"];
          sides.forEach((side) => {
            cssObject[`border${side}Width`] = prop1 ?? "0px";
            cssObject[`border${side}Style`] = prop2 ?? "solid";
            cssObject[`border${side}Color`] = prop3 ?? "#000000";
          });
          break;
        case "border-radius":
          cssObject.borderTopLeftRadius = prop1!;
          cssObject.borderTopRightRadius = prop2 ?? prop1!;
          cssObject.borderBottomLeftRadius = prop3 ?? prop1!;
          cssObject.borderBottomRightRadius = prop4 ?? prop1!;
          break;
        case "border-bottom":
        case "border-top":
        case "border-left":
        case "border-right":
          const borderSide = capitalize(property.split("-")[1]);
          cssObject[`border${borderSide}Width`] = prop1 ?? "0px";
          cssObject[`border${borderSide}Style`] = prop2 ?? "solid";
          cssObject[`border${borderSide}Color`] = prop3 ?? "#000000";
          break;
        case "margin":
          cssObject.marginTop = prop1!;
          cssObject.marginBottom = prop2 ?? prop1!;
          cssObject.marginLeft = prop3 ?? prop1!;
          cssObject.marginRight = prop4 ?? prop1!;
          break;
        case "gap":
          cssObject.__flexGap = value;
          break;
        default:
          cssObject[camelCase(property)] = value;
          break;
      }
    }
  });
  return cssObject;
};

const logApplyComponentActionEvent = ({
  component,
  element,
  operation,
  elementVersion,
  state,
}: {
  component: Component | null;
  element: ReploElement;
  operation: ComponentActionType;
  elementVersion: number | undefined;
  state: CoreState;
}) => {
  // NOTE (Fran 2024-04-11): It might never be the case that we have a component without a type, but just in case
  // we are adding this fallback. This is only for analytics.
  let componentType = component?.type ?? "unknown";
  if (
    "value" in operation &&
    typeof operation.value === "object" &&
    "newComponent" in operation.value &&
    operation.value?.newComponent
  ) {
    componentType = operation.value.newComponent.type;
  }
  analytics.logEvent("applyComponentAction", {
    elementId: element?.id ?? undefined,
    componentId: component?.id ?? null,
    elementVersion,
    type: operation.type,
    actionType:
      operation.type === "applyCompositeAction"
        ? "other"
        : operation.analyticsExtras?.actionType ?? "edit",
    createdBy:
      operation.type === "applyCompositeAction"
        ? "replo"
        : operation.analyticsExtras?.createdBy ?? "user",
    componentType: operation.analyticsExtras?.isSavedComponent
      ? "savedComponent"
      : (componentType as Component["type"]) ?? "unknown",
    activeFrame: operation.activeCanvas,
    ...getGlobalEventProperties(state, null, null),
  });
};

/*
 * Function used to apply a component action to the state.
 * This function is the main entry point for all component actions.
 * @returns an array of component ids that were affected by the action.
 */
export const applyComponentAction = ({
  action,
  elements,
  state,
  ignoreTransform = false,
}: {
  action: ComponentActionType;
  elements: Record<string, ReploElement>;
  state: CoreState;
  ignoreTransform?: boolean;
}): string[] => {
  const op = ignoreTransform
    ? action
    : transformAction(action, elements, state);

  const { component, element } = resolveComponentAndElement(
    action,
    elements,
    state,
  );

  const elementVersion = state.elements.versionMapping[element.id];
  // NOTE (Gabe 2024-08-15): We don't want to log the applyComponentAction event
  // when we are in AI Generation mode so we don't overload posthog with non
  // user events.
  if (state.editorMode !== EditorMode.aiGeneration) {
    logApplyComponentActionEvent({
      component,
      element,
      operation: op,
      elementVersion,
      state,
    });
  }

  return exhaustiveSwitch(op)({
    setStyles: (op) => setStyles(op, elements, state),
    pasteStyles: (op) => pasteStyles(op, elements, state),
    deleteStyle: (op) => deleteStyle(op, elements, state),
    setProps: (op) => setProps(op, elements, state),
    deleteProps: (op) => deleteProps(op, elements, state),
    setAttribute: (op) => setAttribute(op, elements, state),
    setMarker: (op) => setMarker(op, elements, state),
    createOrUpdateAction: (op) => createOrUpdateAction(op, elements, state),
    moveActionsToParent: (op) => moveActionsToParent(op, elements, state),
    deleteAction: (op) => deleteAction(op, elements, state),
    deleteActions: (op) => deleteActions(op, elements, state),
    updateComponentName: (op) => updateComponentName(op, elements, state),
    unsafeUpdateComponentType: (op) =>
      unsafeUpdateComponentType(op, elements, state),
    deleteComponent: (op) => deleteComponent(op, elements, state),
    moveComponentToParent: (op) => moveComponentToParent(op, elements, state),
    moveMultipleComponentsToParent: (op) =>
      moveMultipleComponentsToParent(op, elements, state),
    addVariantTriggerStatement: (op) =>
      addVariantTriggerStatement(op, elements, state),
    deleteVariantTriggerStatement: (op) =>
      deleteVariantTriggerStatement(op, elements, state),
    addVariant: (op) => addVariant(op, elements, state),
    addPredefinedVariant: (op) => addPredefinedVariant(op, elements, state),
    deleteVariant: (op) => deleteVariant(op, elements, state),
    reorderVariant: (op) => reorderVariant(op, elements, state),
    reorderAction: (op) => reorderAction(op, elements, state),
    addComponentToComponent: (op) =>
      addComponentToComponent(op, elements, state),
    applyPresetProps: (op) => applyPresetProps(op, elements, state),
    replaceComponent: (op) => replaceComponent(op, elements, state),
    duplicateComponent: (op) => duplicateComponent(op, elements, state),
    applyCompositeAction: (op) => applyCompositeAction(op, elements, state),
    updateVariant: (op) => updateVariant(op, elements, state),
    syncTabContent: (op) => syncTabContent(op, elements, state),
    addAnimation: (op) => addAnimation(op, elements, state),
    updateAnimation: (op) => updateAnimation(op, elements, state),
    deleteAnimation: (op) => deleteAnimation(op, elements, state),
    duplicateComponentOverrides: (op) =>
      duplicateComponentOverrides(op, elements, state),
    resetStateToDefault: (op) => resetStateToDefault(op, elements, state),
    pushOverridePropsToDefault: (op) =>
      pushOverridePropsToDefault(op, elements, state),
    persistStylesToUpstreamDevices: (op) =>
      persistStylesToUpstreamDevices(op, elements, state),
    setStylesFromCSS: (op) => setStylesFromCSS(op, elements, state),
    resetStyleOverrides: (op) =>
      resetStyleOverridesForEachComponentAndDescendants(op, elements, state),
  });
};

export const applyComponentActions = (
  ops: ComponentActionType[],
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  return applyComponentAction({
    action: {
      type: "applyCompositeAction",
      value: ops,
      activeCanvas: ops[0]?.activeCanvas ?? "desktop",
    },
    elements,
    state,
  });
};

/**
 * Resets the style overrides for a specific breakpoint in a given component.
 *
 * Iterates through the component's variants, excluding the "default" variant,
 * and updates the componentOverrides by removing the style overrides for the
 * specified canvas. Also removes style overrides from the component's props.
 */
function resetStyleOverridesFromCanvas(
  element: ReploElement,
  component: Component,
  canvas: EditorCanvas,
  symbolsMapping: Record<string, ReploSymbol>,
  activeVariantId?: string,
) {
  const ancestorOrSelfWithVariants = findAncestorComponentOrSelfWithVariants(
    element,
    component.id,
    symbolsMapping,
  );
  if (ancestorOrSelfWithVariants && activeVariantId) {
    const componentOverridesFromVariantId:
      | Record<string, Record<string, any>>
      | undefined =
      ancestorOrSelfWithVariants.variantOverrides?.[activeVariantId]
        ?.componentOverrides;

    if (componentOverridesFromVariantId) {
      const overrides: Record<string, Record<string, any>> = {};

      const override = componentOverridesFromVariantId[component.id];
      if (override) {
        overrides[component.id] = {
          props: override.props,
        };
        delete overrides[component.id]?.props[canvasToStyleMap[canvas]];
      }

      const mergePath = {
        [activeVariantId]: {
          componentOverrides: overrides,
        },
      };
      ancestorOrSelfWithVariants.variantOverrides = produce(
        ancestorOrSelfWithVariants.variantOverrides || {},
        (draft) => mergeReplacingObjects(draft, mergePath),
      );
    }
  }

  const activeVariant = ancestorOrSelfWithVariants?.variants?.find(
    (variant) => variant.id === activeVariantId,
  );

  if (!activeVariant || activeVariant.name === "default") {
    component.props = produce(component.props || {}, (draft) => {
      delete draft[canvasToStyleMap[canvas]];
    });
  }
}

/**
 * Resets the style overrides for the specified component, as well as its
 * descendants, for the given canvas.
 *
 * Utilizes the resetStyleOverridesFromCanvas function to remove style overrides
 * for the specified component and all its descendant components, based on the
 * selected canvas.
 */
const resetStyleOverridesForEachComponentAndDescendants = (
  op: ComponentActionType & {
    type: "resetStyleOverrides";
  },
  elements: Record<string, ReploElement>,
  state: CoreState,
) => {
  const { component, element } = resolveComponentAndElement(
    op,
    elements,
    state,
  );
  if (!component) {
    return [];
  }

  const updatedComponentIds: string[] = [];
  forEachComponentAndDescendants(component, (currentComponent) => {
    updatedComponentIds.push(currentComponent.id);
    resetStyleOverridesFromCanvas(
      element,
      currentComponent,
      op.activeCanvas,
      state.symbols.mapping,
      op.activeVariantId,
    );
  });

  return updatedComponentIds;
};

function assertValidImageSource(value: unknown): asserts value is string {
  if (!isString(value) || value.length > MAX_STYLE_PROPERTY_VALUE_LENGTH) {
    throw new ImageSourceTooLongError({
      maxLength: MAX_STYLE_PROPERTY_VALUE_LENGTH,
      // NOTE (Sebas, 2024-04-20): We need to truncate the value to avoid
      // crashing the app in case the value is a huge SVG string.
      provided: isString(value) ? truncate(value, { length: 80 }) : value,
    });
  }
}

function assertValidProps(props: Record<string, unknown>) {
  // NOTE (Chance 2023-11-13): We need to validate that image source strings are
  // not super long to prevent memory performance issues or publish requests
  // failing. This usually happens if the user tries to add an SVG with an
  // embedded image encoded as a data string. We should only need to look at
  // style keys that we know will contain a source URL. `assertValidImageSource`
  // will throw a custom error we can catch to ID and show the user an
  // appropriate error message.
  if ("src" in props) {
    assertValidImageSource(props.src);
  }
  const imageStyleKeys = ["__imageSource", "backgroundImage"];

  for (let i = 0; i < supportedMediaSizes.length + 1; i++) {
    const size = mediaSizes[i];
    const styleKey = size ? `style@${size}` : "style";
    if (!(styleKey in props)) {
      continue;
    }
    const styleDef = props[styleKey];

    if (styleDef && typeof styleDef === "object") {
      for (const key of imageStyleKeys) {
        const styleValue = styleDef[key as keyof typeof styleDef];
        if (styleValue) {
          assertValidImageSource(styleValue);
        }
      }
    }
  }
}

export const shouldRemoveWhenLastChildIsRemoved = (
  oldParentType: ReploComponentType,
) => {
  return exhaustiveSwitch({ type: oldParentType })({
    container: true,
    symbolRef: true,
    text: true,
    button: true,
    spacer: true,
    circle: true,
    icon: true,
    modal: true,
    image: true,
    accordionBlock: true,
    subscribeAndSave: true,
    collapsible: true,
    slidingCarousel: true,
    player: true,
    player__playIcon: true,
    player__muteIcon: true,
    player__fullScreenIcon: true,
    collectionSelect: true,
    product: true,
    productCollection: true,
    quantitySelector: true,
    dropdown: true,
    variantSelect: true,
    optionSelect: true,
    variantSelectDropdown: true,
    optionSelectDropdown: true,
    sellingPlanSelect: true,
    sellingPlanSelectDropdown: true,
    collection: true,
    collectionV2: true,
    googleMapsEmbed: true,
    klaviyoEmbed: true,
    temporaryCart: true,
    temporaryCartItems: true,
    vimeoEmbed: true,
    vimeoEmbedV2: true,
    rebuyWidget: true,
    buyWithPrimeButton: true,
    youtubeEmbed: true,
    youtubeEmbedV2: true,
    carouselV2: true,
    carouselV2__panels: true,
    carouselV2__indicator: true,
    carouselV3: true,
    carouselV3Slides: true,
    carouselV3Control: true,
    carouselV3Indicators: true,
    carouselPanelsCount: true,
    shopifySection: true,
    shopifyAppBlocks: true,
    shopifyRawLiquid: true,
    collapsibleV2: true,
    collapsibleV2Header: true,
    collapsibleV2Content: true,
    tabsBlock: true,
    tabs__list: true,
    tabs__panelsContent: true,
    tabs__onePanelContent: true,
    tabsV2__block: true,
    tabsV2__list: true,
    tabsV2__panelsContent: true,
    marquee: true,
    rawHtmlContent: true,
    starRating: true,
    tikTokEmbed: true,
    rechargeSubscriptionWidget: true,
    staySubscriptionWidget: true,
    okendoReviewsWidget: true,
    okendoProductRatingSummary: true,
    junipProductRating: true,
    junipReviews: true,
    yotpoProductRating: true,
    yotpoReviews: true,
    looxProductRating: true,
    looxReviews: true,
    reviewsIoProductRating: true,
    reviewsIoReviews: true,
    h1: true,
    h2: true,
    h3: true,
    spinner: true,
    dynamicCheckoutButtons: true,
    countdownTimer: true,
    judgeProductRatingWidget: true,
    judgeProductReviewsWidget: true,
    feraProductRatingWidget: true,
    feraProductReviewsWidget: true,
    feraStoreReviewsWidget: true,
    feraMediaGalleryWidget: true,
    shopifyProductReviewsWidget: true,
    shopifyProductRatingWidget: true,
    stampedProductReviewsWidget: true,
    stampedProductRatingWidget: true,
    knoCommerceWidget: true,
    infiniteOptionsWidget: true,
    kachingBundles: true,
    postscriptSignupForm: true,
    toggleContainer: true,
    toggleIndicator: true,
    // NOTE (Sebas, 2024-07-04): If the old parent is a tooltip, we don't want to remove it
    // because it also contains the tooltip content. If there is no trigger, we show an empty
    // container for the user to add new components.
    tooltip: false,
    tooltipContent: true,
    beforeAfterSlider: true,
    beforeAfterSliderThumb: true,
    beforeAfterSliderBeforeContent: true,
    beforeAfterSliderAfterContent: true,
    selectionList: true,
  });
};
