import type { AnalyticsReadQueryWithoutUrlParams } from "@/features/analytics/constants";
import type { AnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import { decodeUrlHost } from "@editor/utils/analytics";
import { trpc } from "@editor/utils/trpc";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { getRangeFromUrlParams } from "@/features/analytics/time";
import { getDefaultAnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import { skipToken } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

// NOTE (Kurt, 2024-10-13): We only allow additional query params that are not already defined inside
// of AnalyticsReadQueryWithoutUrlParams. This is to ensure that we don't override the query params
// stored in the URL.
type AdditionalQueryParamsType = Pick<
  AnalyticsReadQuery,
  keyof AnalyticsReadQueryWithoutUrlParams
>;

type BaseAnalyticsReadProps = {
  workspaceId: string | null;
  getCustomFilters?: (
    params: AnalyticsUrlParams,
  ) => AnalyticsReadQuery["filters"];
  defaultInterval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"];
  additionalQueryParams?: Partial<AdditionalQueryParamsType>;
};

const useBaseAnalyticsRead = ({
  workspaceId,
  getCustomFilters,
  defaultInterval,
  additionalQueryParams,
}: BaseAnalyticsReadProps) => {
  // NOTE (Kurt, 2024-10-13): The host is only set in a page specific path (i.e. /analytics/:workspaceId/:host/:...),
  // otherwise it's undefined.
  const { host } = useParams<{ host?: string }>();
  const decodedHost = host ? decodeUrlHost(host) : undefined;
  const {
    query: queryWithoutUrlParams,
    getAllParams,
    urlHosts,
  } = useAnalyticsQueryContext();

  const urlParamObject = React.useMemo(() => {
    const params =
      getAllParams() || getDefaultAnalyticsUrlParams(urlHosts, defaultInterval);
    if (getCustomFilters) {
      params.filters = getCustomFilters(params);
    }
    return params;
  }, [getAllParams, urlHosts, defaultInterval, getCustomFilters]);

  const updatedRanges = getRangeFromUrlParams(
    urlParamObject.selectedTimePeriod,
    defaultInterval,
  ).updatedRanges;

  // NOTE (Kurt, 2024-11-12): This is a pretty stupid hack to make sure that within the chart context
  // we override the interval calculated from the time period. This is because we want the chart interval
  // to persist in the URL. This should definitely be fixed in future clean up tickets.
  if (defaultInterval) {
    updatedRanges.mainRange.interval =
      urlParamObject.selectedTimePeriod.interval ?? defaultInterval;
    updatedRanges.compareAtRanges[0]!.interval =
      urlParamObject.selectedComparePeriod.interval ?? defaultInterval;
  }

  const mainRangeId = updatedRanges.mainRange.id;
  const compareAtRangeId = updatedRanges.compareAtRanges[0]?.id;

  const constructedQuery = React.useMemo(
    (): AnalyticsReadQuery => ({
      ...queryWithoutUrlParams,
      // NOTE (Kurt, 2024-10-13): If the host is not set, we use the urlParamObject.urlHosts.
      // But if the host is set in the path (i.e. /analytics/:workspaceId/:host/:...),
      // then we only use that host instead of the hosts stored in the URL params.
      urlHosts: decodedHost ? [decodedHost] : urlParamObject.urlHosts,
      filters: urlParamObject.filters,
      ranges: updatedRanges,
      ...additionalQueryParams,
    }),
    [
      queryWithoutUrlParams,
      decodedHost,
      urlParamObject,
      updatedRanges,
      additionalQueryParams,
    ],
  );

  const shouldFetch = Boolean(workspaceId) && urlHosts.length > 0;

  const find = trpc.analytics.find.useQuery(
    shouldFetch
      ? { query: constructedQuery, workspaceId: workspaceId ?? "" }
      : skipToken,
  );
  const hasData = trpc.analytics.hasData.useQuery(
    shouldFetch ? { workspaceId: workspaceId ?? "" } : skipToken,
  );
  const refetch = async () => Promise.all([find.refetch(), hasData.refetch()]);
  return {
    refetch,
    hasAnyData: Boolean(hasData.data),
    rangeResults: find.data?.rangeResults ?? null,
    totalRowsCount: find.data?.totalRowsCount ?? null,
    isLoading: find.isLoading || hasData.isLoading,
    mainRangeId,
    compareAtRangeId,
    constructedQuery,
  };
};

export default useBaseAnalyticsRead;
