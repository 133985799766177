// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import type { ComponentTemplateCategoryType } from "schemas/componentTemplates";
import type { ReploElementType } from "schemas/generated/element";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { ModalLayout } from "@common/ModalLayout";
import ComponentPreview from "@components/ComponentPreview";
import { Loader } from "@components/marketplace/Loader";
import BackButton from "@editor/components/marketplace/BackButton";
import { FiltersSidebar } from "@editor/components/marketplace/FiltersSidebar";
import { ComponentTemplatesEmptyState } from "@editor/components/marketplace/TemplateEmptyState";
import TemplatesGrid from "@editor/components/marketplace/TemplatesGrid";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import useInfiniteTemplates from "@editor/hooks/useInfiniteTemplates";
import useMarketplaceFiltersState from "@editor/hooks/useMarketplaceFiltersState";
import {
  selectDraftElementId,
  selectDraftElementType,
  selectElementIsLoading,
  selectElementsMapping,
} from "@editor/reducers/core-reducer";
import { setLastMarketplacePath } from "@editor/reducers/marketplace-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { generateEditorPathname, routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";
import { useModal } from "@hooks/useModal";

import Button from "@replo/design-system/components/button";
import { skipToken } from "@tanstack/react-query";
import omit from "lodash-es/omit";
import values from "lodash-es/values";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { componentTemplateCategoryTypeToModalTitle } from "replo-runtime/shared/componentTemplates";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";

function TemplateCollectionsPageModal() {
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedCollectionId = params.collectionId;

  const modal = useModal();

  const draftElementType = useEditorSelector(selectDraftElementType);
  const elementType: ReploElementType =
    state?.elementType ?? draftElementType ?? "shopifySection";
  const [type, setType] =
    useOverridableState<ComponentTemplateCategoryType | null>(null);
  const elementIsLoading = useEditorSelector(selectElementIsLoading);
  const elementsMapping = useEditorSelector(selectElementsMapping);
  const isZeroElements = values(elementsMapping).length === 0;

  const projectId = useCurrentProjectId();
  const draftElementId = useEditorSelector(selectDraftElementId);

  const dispatch = useEditorDispatch();

  const [selectedFilters, setSelectedFilters] = useMarketplaceFiltersState();

  const { data: collection, isFetching: isFetchingCollection } =
    trpc.componentTemplates.collections.get.useQuery(
      selectedCollectionId ? { collectionId: selectedCollectionId } : skipToken,
    );

  const {
    componentTemplatesList,
    fetchNextPage,
    isLoading: isLoadingComponentTemplates,
    isSuccessComponentTemplates,
    hasNextPage,
    isLoadingInitialPage: isLoadingComponentTemplatesInitialPage,
  } = useInfiniteTemplates({
    collectionId: selectedCollectionId,
    includeComponent: false,
    selectedBadges: selectedFilters.badge,
    selectedIndustries: selectedFilters.industry,
    selectedCategories: selectedFilters.category,
    templateType: type,
  });

  if (!collection) {
    return null;
  }

  const handleClose = () => {
    if (!elementIsLoading && isZeroElements) {
      return undefined;
    }
    modal.closeModal({});
    dispatch(
      setLastMarketplacePath(
        selectedCollectionId
          ? `${routes.marketplaceModal}/collection/${selectedCollectionId}`
          : `${routes.marketplaceModal}/collections`,
      ),
    );

    navigate(
      generateEditorPathname("", {
        projectId: projectId ?? "",
        elementId: draftElementId,
      }),
    );
  };

  const templates = componentTemplatesList ?? [];

  const shouldShowCollectionTemplates =
    selectedCollectionId && !isFetchingCollection;
  const shouldShowFullPageLoader = isFetchingCollection;

  const showComponentTemplatesEmptyState = componentTemplatesList?.length === 0;
  return (
    <Modal isOpen onRequestClose={handleClose} className="w-auto p-0">
      <ModalLayout
        height="90vh"
        width="85vw"
        wrapperClassnames="rounded bg-stone-50"
        layoutClassnames="p-0"
        // Note (Noah, 2023-05-13): Set overflow-hidden to override the default behavior
        // since we specify the overflow in the collection-modal-content, which is referred
        // to by the infinite scrolling component to know when to load more content. We also
        // don't want the back button etc to scroll with the content
        mainContentClassnames="mb-0 overflow-hidden"
        mainContent={() => {
          return (
            <div className="flex w-full flex-col gap-2 pt-16 pl-12 pr-8">
              <BackButton />
              <div
                id="collection-modal-content"
                className="no-scrollbar flex w-full flex-1 flex-col overflow-auto"
              >
                {shouldShowCollectionTemplates && (
                  <div className="flex flex-col pt-6 pb-12">
                    <div className="flex">
                      {collection?.coverImageUrl && (
                        <div className="aspect-video w-96 cursor-pointer">
                          <ComponentPreview
                            type="img"
                            src={collection?.coverImageUrl}
                          />
                        </div>
                      )}
                      <div className="flex-1 pl-10">
                        <div className="flex h-full flex-col justify-between">
                          <div>
                            <div className="mb-4 flex items-start justify-between">
                              <div className="flex flex-row items-center gap-2">
                                <img
                                  src={collection?.profileImageUrl}
                                  className="h-12 w-12 rounded-full object-cover"
                                />
                                <div className="flex flex-col">
                                  <h3 className="text-lg font-semibold">
                                    {collection?.name}
                                  </h3>
                                  <p className="text-slate-600">
                                    {collection?.organizationName}
                                  </p>
                                </div>
                              </div>
                              <Button
                                variant="secondary"
                                size="base"
                                className="bg-slate-200 bg-opacity-50 text-base font-semibold text-blue-600"
                                textClassNames="font-medium px-3"
                                isRounded
                                href={collection?.websiteUrl ?? ""}
                                target="_blank"
                              >
                                WEBSITE
                              </Button>
                            </div>
                            {collection?.descriptionMarkdown && (
                              <ReactMarkdown
                                components={getMarkdownComponents}
                                className="text-default"
                              >
                                {collection.descriptionMarkdown}
                              </ReactMarkdown>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-6 w-full">
                      <FiltersSidebar
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        templateType={type}
                        setTemplateType={(value) => setType(value ?? null)}
                        className="py-6 flex-shrink-0 w-64"
                      />
                      {isSuccessComponentTemplates &&
                        !showComponentTemplatesEmptyState && (
                          <div className="my-6 mx-4 w-full">
                            <TemplatesGrid
                              scrollableTargetId="collection-modal-content"
                              templates={templates}
                              settings={{
                                hasNextPage,
                                isNextPageLoading: isLoadingComponentTemplates,
                                loadNextPage: () => void fetchNextPage(),
                              }}
                              elementType={elementType}
                              title={
                                type
                                  ? componentTemplateCategoryTypeToModalTitle[
                                      type
                                    ]
                                  : "Industry Templates"
                              }
                            >
                              {isLoadingComponentTemplatesInitialPage ||
                                ((templates?.length ?? 0) === 0 &&
                                  componentTemplatesList &&
                                  componentTemplatesList.length > 0 && (
                                    <Loader label="Beep Boop, Loading Sections" />
                                  ))}
                            </TemplatesGrid>
                          </div>
                        )}
                      {showComponentTemplatesEmptyState && (
                        <ComponentTemplatesEmptyState
                          emptyStateLabel="No Templates for the current filters"
                          resetButton={
                            <Button
                              variant="secondary"
                              className="bg-blue-600 py-3.5 px-5 text-stone-50"
                              isRounded
                              onClick={() =>
                                setSelectedFilters({
                                  badge: [],
                                  industry: [],
                                  category: [],
                                })
                              }
                            >
                              Reset Filters
                            </Button>
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {shouldShowFullPageLoader && (
                  <Loader
                    label="Loading Community Templates"
                    className="self-center"
                  />
                )}
              </div>
            </div>
          );
        }}
      />
    </Modal>
  );
}

export const getMarkdownComponents: ReactMarkdownOptions["components"] = {
  h1: (props) => (
    <h1 className="text-5xl font-extrabold" {...omit(props, "node")} />
  ),
  h2: (props) => <h2 className="text-4xl font-bold" {...omit(props, "node")} />,
  h3: (props) => <h3 className="text-3xl font-bold" {...omit(props, "node")} />,
  h4: (props) => <h4 className="text-2xl font-bold" {...omit(props, "node")} />,
  h5: (props) => <h5 className="text-xl font-bold" {...omit(props, "node")} />,
  h6: (props) => <h6 className="text-lg font-bold" {...omit(props, "node")} />,
  p: (props) => <p {...omit(props, "node")} />,
};

export default TemplateCollectionsPageModal;
