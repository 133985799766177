import type { ButtonSharedProps } from "@replo/design-system/components/button";

import * as React from "react";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type DashboardMenuItemProps = {
  item: {
    title: string;
    onClick?: () => void;
    path?: string;
    startEnhancer?: () => React.ReactNode;
    endEnhancer?: () => React.ReactNode;
    href?: string;
    className?: string;
    textClassName?: string;
    isSelected?: boolean;
    hasSubItems?: boolean;
  };
};

const DashboardMenuItem: React.FC<DashboardMenuItemProps> = ({
  item: {
    title,
    onClick,
    path,
    startEnhancer,
    href,
    endEnhancer,
    className,
    textClassName,
    isSelected,
    hasSubItems,
  },
}) => {
  const buttonProps = {
    variant: "tertiary",
    size: "base",
    isFullWidth: true,
    className: twMerge(
      classNames("flex gap-2 justify-start font-normal hover:bg-blue-100", {
        "bg-blue-100 text-default": path && isSelected && !hasSubItems,
      }),
      className,
    ),
    textClassNames: twMerge("text-slate-800", textClassName),
    startEnhancer,
    endEnhancer,
  } satisfies ButtonSharedProps;

  if (href) {
    return (
      <Button key={title} {...buttonProps} href={href} target="_blank">
        {title}
      </Button>
    );
  }

  return (
    <Button key={title} {...buttonProps} onClick={onClick}>
      {title}
    </Button>
  );
};

export default DashboardMenuItem;
