import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

export function generateTextSavedStylePreviewValue(
  attributes: SavedStyleTextAttributes,
) {
  return `${attributes.fontSize}/${attributes.lineHeight}`;
}

export const getSavedStyleValue = (
  savedStyles: ((SavedTextStyle | SavedColorStyle) & { id: string })[],
  selectedSavedStyleValue: string,
) => {
  const parsedSavedStyleId = selectedSavedStyleValue.split(".")[3];
  return savedStyles.find((style) => style.id === parsedSavedStyleId);
};
