import type { HotkeyAction, HotkeyMetaKey } from "@editor/utils/hotkeys";

import * as React from "react";

import { isSavedStylesEnabled } from "@editor/infra/featureFlags";
import { getHotkeyEditorData } from "@editor/utils/hotkeys";
import { isMacintosh } from "@editor/utils/platform";

import { Badge } from "@replo/design-system/components/badge";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const characterAliases: Record<
  HotkeyMetaKey,
  string | { mac: string | React.ReactNode; windows: string | React.ReactNode }
> = {
  meta: {
    mac: "⌘",
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>CTRL</span>,
  },
  shift: "⇧",
  altOption: {
    mac: "⌥",
    windows: "⎇",
  },
  backspace: "⌫",
  delete: "del",
  esc: {
    mac: "⎋",
    windows: <span style={{ fontSize: "6px", fontWeight: "bold" }}>ESC</span>,
  },
  space: "␣",
  arrowRight: "→",
  arrowDown: "↓",
  arrowLeft: "←",
  arrowUp: "↑",
};

export const HotkeyIndicator: React.FC<{
  title?: string;
  hotkey: HotkeyAction;
  className?: string;
  badgeBackgroundColor?: string;
}> = ({ hotkey, title, className, badgeBackgroundColor }) => {
  const savedStylesEnabled = isSavedStylesEnabled();
  const hotkeyData = getHotkeyEditorData(hotkey, savedStylesEnabled);
  return (
    <div
      className={twMerge(
        "flex flex-row items-center justify-center gap-1 text-xs",
        className,
      )}
    >
      <span className="block">{title ?? hotkeyData.displayName}</span>
      <div className="flex flex-row items-center gap-1">
        {hotkeyData.indicatorCharacters
          .map((character) => {
            const alias = characterAliases[character as HotkeyMetaKey];
            if (alias) {
              if (typeof alias === "string") {
                return { id: character, display: alias };
              }
              return {
                id: character,
                display: !isMacintosh() ? alias.windows : alias.mac,
              };
            }
            return { id: character, display: character.toUpperCase() };
          })
          .map(({ id, display }) => (
            <Badge
              key={id}
              type="icon"
              icon={display}
              isFilled
              className={classNames(
                "text-subtle",
                badgeBackgroundColor ? badgeBackgroundColor : "bg-slate-200",
              )}
            />
          ))}
      </div>
    </div>
  );
};
