import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { getFromRecordOrNull } from "../../../shared/utils/optional";
import { useComponentClassNames } from "../../../shared/utils/renderComponents";
import { useCurrentMediaSize } from "../../hooks/useCurrentMediaSize";
import { iconDirectory } from "../../iconDirectory";
import { styleElements } from "../Icon/config";

const Icon: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const iconName = component.props.iconName;
  const Icon = getFromRecordOrNull(iconDirectory, iconName);
  useCurrentMediaSize();

  const iconAltText = component.props.style?.__iconAltText;
  const classNameMap = useComponentClassNames("icon", component, context);

  if (!Icon?.Component) {
    return null;
  }

  return (
    <div {...componentAttributes} key={componentAttributes.key}>
      <Icon.Component
        // Note (Chance 2023-08-09) Not all assistive technologies recognize
        // SVGs as images by default. If we have alt text we should use the
        // `img` role. If we do not, use `presentation` to mark the image as
        // decorative.
        role={iconAltText ? "img" : "presentation"}
        aria-label={iconAltText ?? undefined}
        // Note (Noah, 2023-06-09): in the case of Icon, the processing in the
        // iconDirectory actually looks for the styles and inspects width/height
        // and translates it into props, so we need to set them.
        style={styleElements.icon.defaultStyles}
        className={classNameMap?.icon}
      />
    </div>
  );
};

export default Icon;
