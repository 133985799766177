import type {
  ModifierType,
  ModifierTypeToControlType,
} from "schemas/modifiers";

import * as React from "react";

import { selectDraftComponentId } from "@editor/reducers/core-reducer";
import {
  modifierTypeToSelector,
  selectIsDesignLibraryValue,
} from "@editor/selectors/modifiers";
import { useEditorSelector } from "@editor/store";

import { useLogAnalytics } from "../useLogAnalytics";

export const useGetModifierControls = <T extends ModifierType>(
  modifierType: ModifierType,
): [
  Set<ModifierTypeToControlType[T]>,
  (
    newControl: ModifierTypeToControlType[T] | ModifierTypeToControlType[T][],
  ) => void,
] => {
  const logEvent = useLogAnalytics();
  const values: Set<ModifierTypeToControlType[T]> = useEditorSelector(
    modifierTypeToSelector[modifierType],
  );
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const isDesignLibraryValue = useEditorSelector(selectIsDesignLibraryValue);

  const designLibraryValueRef = React.useRef(isDesignLibraryValue);
  const draftComponentIdRef = React.useRef(draftComponentId);

  const [controls, setControls] =
    React.useState<Set<ModifierTypeToControlType[T]>>(values);

  const addControl = React.useCallback(
    (
      newControl: ModifierTypeToControlType[T] | ModifierTypeToControlType[T][],
    ) => {
      const newControls = new Set(controls);
      if (Array.isArray(newControl)) {
        for (const control of newControl) {
          newControls.add(control);
        }
      } else {
        newControls.add(newControl);
      }

      if (
        Array.isArray(newControl) &&
        newControl.some((control) =>
          ["minWidth", "maxWidth", "minHeight", "maxHeight"].includes(control),
        )
      ) {
        logEvent("design.modifier.add", {
          type: modifierType,
          propertyType: "minMaxWidthHeight",
        });
      } else if (typeof newControl === "string") {
        logEvent("design.modifier.add", {
          type: modifierType,
          propertyType: newControl,
        });
      }

      setControls(newControls);
    },
    [controls, logEvent, modifierType],
  );

  React.useEffect(() => {
    if (
      // NOTE (Sebas, 2024-10-14): We want to reset the control values to the values
      // from the selector only when the draft component id changes. This prevents
      // the controls from being reset when the user is adding a value to a control.
      draftComponentIdRef.current !== draftComponentId ||
      // NOTE (Sebas, 2024-11-14): We also want to reset the control values to the values
      // from the selector when the user selects or removes a design library value.
      designLibraryValueRef.current !== isDesignLibraryValue
    ) {
      setControls(values);
      draftComponentIdRef.current = draftComponentId;
      designLibraryValueRef.current = isDesignLibraryValue;
    }
  }, [draftComponentId, isDesignLibraryValue, values]);

  return [controls, addControl];
};
