import type {
  SavedColorStyle,
  SavedTextStyle,
} from "schemas/generated/designLibrary";

import { selectProjectId } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

function useGetDesignLibrarySavedStyles() {
  const projectId = useEditorSelector(selectProjectId);
  const { data: designLibrary } = trpc.designLibrary.get.useQuery(
    projectId ? { projectId } : skipToken,
  );

  if (!designLibrary?.savedStyles) {
    return {
      textSavedStyles: [],
      colorSavedStyles: [],
      designLibrary,
    };
  }

  const savedStyles = Object.entries(designLibrary.savedStyles).reduce(
    (savedStyles, [id, savedStyle]) => {
      if (!savedStyle.deletedAt) {
        savedStyles.push({
          ...savedStyle,
          id,
        });
      }
      return savedStyles;
    },
    [] as ((SavedColorStyle | SavedTextStyle) & { id: string })[],
  );

  const textSavedStyles = savedStyles.filter(
    (savedStyle) => savedStyle.type === "text",
  ) as (SavedTextStyle & { id: string })[];

  const colorSavedStyles = savedStyles.filter(
    (savedStyle) => savedStyle.type === "color",
  ) as (SavedColorStyle & { id: string })[];

  return { textSavedStyles, colorSavedStyles, designLibrary };
}

export default useGetDesignLibrarySavedStyles;
