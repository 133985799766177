import * as React from "react";

import classNames from "classnames";
import { twMerge } from "tailwind-merge";

const Spinner: React.FC<{
  width?: number;
  color?: string;
  size: number;
  speed?: number;
  className?: string;
}> = ({
  width = 5,
  color = "rgb(37 99 235)",
  size,
  className,
  speed = 0.5,
}) => {
  return (
    <div
      className={twMerge(classNames("relative my-0 mx-auto", className))}
      style={{
        borderTop: `${width}px solid rgba(255, 255, 255, 0.2)`,
        borderBottom: `${width}px solid rgba(255, 255, 255, 0.2)`,
        borderRight: `${width}px solid rgba(255, 255, 255, 0.2)`,
        borderLeft: `${width}px solid ${color}`,
        borderRadius: "50%",
        height: size,
        width: size,
        animation: `spinner-rotate ${speed}s infinite linear`,
      }}
    />
  );
};

export { Spinner };
