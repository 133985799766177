import type { WorkspaceMembershipModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import Avatar from "@editor/components/common/designSystem/Avatar";
import Modal from "@editor/components/common/designSystem/Modal";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useModal } from "@editor/hooks/useModal";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";

export const WorkspaceMembershipModal = ({
  workspaceId,
  workspaceName,
  email,
}: WorkspaceMembershipModalProps) => {
  const { closeModal } = useModal();

  const { mutate, isPending } = trpc.workspace.inviteMembers.useMutation({
    onSuccess: () => {
      void trpcUtils.workspace.getWorkspaceAndProjectMembers.invalidate(
        workspaceId,
      );
      closeModal({ type: "workspaceMembershipModal" });
      successToast("Invitation sent!", "The invitation was sent successfully.");
    },
  });

  return (
    <Modal
      style={{ maxWidth: "350px", width: "350px" }}
      className="z-max p-6"
      onRequestClose={() => closeModal({ type: "workspaceMembershipModal" })}
      isOpen={true}
    >
      <div className="flex w-full flex-col gap-y-4">
        <div className="text-base text-default font-semibold">
          Add to Workspace
        </div>
        <div className="text-slate-400 font-normal text-sm">
          Did you want to add this person to the {workspaceName} Workspace in
          addition to the project? They would have access to all projects in the
          workspace.
        </div>
        <UserRow email={email} />
        <div className="flex flex-row justify-end gap-2">
          <Button
            variant="secondary"
            type="submit"
            className="text-xs"
            size="sm"
            onClick={() => closeModal({ type: "workspaceMembershipModal" })}
          >
            Skip for now
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="text-xs"
            size="sm"
            isDisabled={isPending}
            onClick={() => {
              void mutate({
                workspaceId,
                usersToGrantAccess: [
                  {
                    email,
                    role: "member",
                  },
                ],
              });
            }}
          >
            Invite
          </Button>
        </div>
      </div>
    </Modal>
  );
};
const UserRow = ({ email }: { email: string }) => {
  return (
    <div className="flex gap-2 items-center">
      <Avatar className="h-7 w-7" />
      <div className="flex flex-col items-start text-sm text-slate-400">
        {email}
      </div>
    </div>
  );
};
