import * as React from "react";

import AllProjects from "@editor/components/dashboard/projects/AllProjects";
import DashboardLayout from "@editor/components/layouts/DashboardLayout";
import useConnectShopifyIntegrationNavigator from "@editor/hooks/useConnectShopifyIntegrationNavigator";
import useUserHasNewAnnouncements from "@editor/hooks/useUserHasNewAnnouncements";

import { Outlet } from "react-router-dom";

export default function ProjectDashboard() {
  useUserHasNewAnnouncements();
  useConnectShopifyIntegrationNavigator();

  return (
    <DashboardLayout>
      <div className="bg-white no-scrollbar col-span-9 mt-8 flex flex-grow flex-col items-start gap-y-2 overflow-scroll">
        <AllProjects />
      </div>
      <Outlet />
    </DashboardLayout>
  );
}
