import type { SortEnd } from "@common/designSystem/SortableList";
import type { ReploComponentIssue } from "@editor/types/component-issues";
import type {
  AlchemyActionTrigger,
  AlchemyActionType,
} from "replo-runtime/shared/enums";
import type { ActionWithNullableValue } from "replo-runtime/shared/types";
import type { Action } from "schemas/actions";
import type { ReploComponentType } from "schemas/component";

import * as React from "react";

import { SortableItem, SortableList } from "@common/designSystem/SortableList";
import {
  actionTypeToEditorData,
  getActionOptions,
  getActionValueTypeToEditorData,
} from "@components/editor/action";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { useStoreProductsFromPartialAction } from "@editor/hooks/useStoreProducts";
import {
  selectDraftComponentActionIssues,
  selectDraftComponentActions,
  selectDraftComponentId,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectPropOnClick,
  selectPropOnHover,
} from "@editor/reducers/core-reducer";
import { selectAreModalsOpen } from "@editor/reducers/modals-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { setRightBarActiveTab } from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { getDynamicDataValueDisplayName } from "@editor/utils/dynamic-data";
import { hasActionIssues } from "@editor/utils/getIssuesForComponent";
import ModifierGroup from "@editorExtras/ModifierGroup";
import RightBarIssues from "@editorExtras/RightBarIssues";
import { getDefaultActionValue } from "@editorModifiers/utils";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import classNames from "classnames";
import kebabCase from "lodash-es/kebabCase";
import { BsPlus, BsX } from "react-icons/bs";
import { AlchemyActionTriggers } from "replo-runtime/shared/enums";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { isDynamicDataValue } from "replo-runtime/shared/utils/dynamic-data";
import { v4 as uuidv4 } from "uuid";

const ActionModifier: React.FC = () => {
  return (
    <>
      {AlchemyActionTriggers.map((trigger) => (
        <ActionControl key={trigger} trigger={trigger} />
      ))}
    </>
  );
};

const ActionControl: React.FC<{
  trigger: AlchemyActionTrigger;
}> = ({ trigger }) => {
  const applyComponentAction = useApplyComponentAction();
  const actionIssues = useEditorSelector(selectDraftComponentActionIssues);
  const isTriggerOnClick = trigger === "onClick";
  const actions: Action[] =
    useEditorSelector(
      isTriggerOnClick ? selectPropOnClick : selectPropOnHover,
    ) ?? [];

  const [draftAction, setDraftAction] = React.useState<Partial<Action>>(
    generateInitialDraftAction(),
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const areModalsOpen = useEditorSelector(selectAreModalsOpen);

  const onChangeActionOrder = ({ oldIndex, newIndex }: SortEnd) => {
    applyComponentAction({
      type: "reorderAction",
      value: {
        oldIndex,
        newIndex,
        property: trigger,
      },
    });
  };

  const title = isTriggerOnClick ? "On Click" : "On Mouse Over";

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          setDraftAction(generateInitialDraftAction());
        }
      }}
    >
      <div className="border-b border-slate-200 pb-3 last:border-b-0 last:pb-0">
        <Popover.Anchor>
          <ModifierGroup
            title={title}
            endEnhancer={
              <Popover.Trigger asChild>
                <IconButton
                  id={`add-${kebabCase(title)}-interaction`}
                  className="px-0"
                  icon={<BsPlus size={20} className="text-muted" />}
                  tooltipText={`Add ${title} Interaction`}
                  variant="tertiary"
                />
              </Popover.Trigger>
            }
            hideEndEnhancerOnGroupClosed={false}
          >
            <Popover.Content
              title={title}
              data-testid={`action-editor-popover-${trigger}`}
              shouldPreventDefaultOnInteractOutside={areModalsOpen}
            >
              <ActionEditor
                trigger={trigger}
                draftAction={draftAction}
                onUpdate={(value) => {
                  setDraftAction(value);
                }}
                onCancel={() => {
                  setDraftAction(generateInitialDraftAction());
                  setIsOpen(false);
                }}
                onSave={() => {
                  applyComponentAction({
                    type: "createOrUpdateAction",
                    value: { trigger, action: draftAction },
                  });
                  setDraftAction(generateInitialDraftAction());
                  setIsOpen(false);
                }}
              />
            </Popover.Content>

            {isTriggerOnClick && actions.length === 0 && (
              <div className="mx-auto w-full cursor-pointer text-gray-400">
                <Popover.Trigger asChild>
                  <div className="text-left text-xs">
                    Click the + icon to add an interaction when the user clicks
                    their mouse or taps the component
                  </div>
                </Popover.Trigger>
              </div>
            )}

            {actions.length > 0 && (
              <div className="w-full flex flex-col gap-2">
                <SortableList onReorderEnd={onChangeActionOrder} withDragHandle>
                  {actions.map((action) => {
                    const hasIssues = hasActionIssues(action.id, actionIssues);
                    return (
                      <SortableItem key={action.id} id={action.id}>
                        <ActionItem
                          trigger={trigger}
                          action={action}
                          draftActionId={draftAction?.id}
                          onClick={(value) => {
                            setDraftAction(value);
                            setIsOpen(true);
                          }}
                          className={hasIssues ? "opacity-50" : undefined}
                        />
                      </SortableItem>
                    );
                  })}
                </SortableList>
                {isTriggerOnClick && actionIssues.length > 0 && (
                  <ActionIssues issues={actionIssues} />
                )}
              </div>
            )}

            {!isTriggerOnClick && actions.length === 0 && (
              <div className="mx-auto w-full cursor-pointer text-gray-400">
                <Popover.Trigger asChild>
                  <div className="text-left text-xs">
                    Click the + icon to add an interaction when the user moves
                    the mouse over the component
                  </div>
                </Popover.Trigger>
              </div>
            )}
          </ModifierGroup>
        </Popover.Anchor>
      </div>
    </Popover>
  );
};

const ActionIssues: React.FC<{ issues: ReploComponentIssue[] }> = ({
  issues,
}) => {
  const applyComponentAction = useApplyComponentAction();
  const setDraftElement = useSetDraftElement();
  const dispatch = useEditorDispatch();

  const hasChildWithInteractionsIssues = issues.some(
    (issue) => issue.type === "actions.childWithInteractions",
  );
  const actionIssues = hasChildWithInteractionsIssues
    ? issues.filter((issue) => {
        return issue.type === "actions.childWithInteractions";
      })
    : issues;

  const onClickMoveToParentButton = (
    ancestorId: string,
    ancestorType: ReploComponentType,
  ) => {
    if (ancestorType === "button") {
      applyComponentAction({
        type: "moveActionsToParent",
        value: {
          trigger: "onClick",
          destinationComponentId: ancestorId,
        },
      });

      setDraftElement({
        componentId: ancestorId,
      });

      // NOTE (Fran 2024-05-27): We need to use a timeout here because everytime we select a new
      // component we reset the active tab to the design tab. We cannot change that behavior because
      // this is only one case where we need to set the interactions tab.
      setTimeout(() => {
        dispatch(setRightBarActiveTab("interactions"));
      }, 100);
    } else {
      applyComponentAction({
        type: "deleteActions",
        value: {
          trigger: "onClick",
        },
      });
    }
  };

  return (
    <RightBarIssues
      issues={actionIssues}
      endEnhancer={(issue) =>
        issue.type === "actions.childWithInteractions" ? (
          <div className="text-blue-600 underline">
            <Button
              variant="inherit"
              size="sm"
              onClick={() =>
                onClickMoveToParentButton(issue.ancestorId, issue.ancestorType)
              }
            >
              <span>
                {issue.ancestorType === "button"
                  ? "Move To Parent"
                  : "Delete Actions"}
              </span>
            </Button>
          </div>
        ) : null
      }
    />
  );
};

const ActionEditor: React.FC<{
  trigger: AlchemyActionTrigger;
  draftAction: Partial<Action>;
  onUpdate: (action: Partial<Action>) => void;
  onCancel: () => void;
  onSave: () => void;
}> = ({ trigger, draftAction: _draftAction, onUpdate, onCancel, onSave }) => {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const actions = useEditorSelector(selectDraftComponentActions);

  const defaultValue = getDefaultActionValue(_draftAction);
  const draftAction =
    _draftAction.type && !_draftAction.value && defaultValue
      ? { ..._draftAction }
      : _draftAction;

  const { products } = useStoreProductsFromPartialAction(
    draftAction.type ? (draftAction as ActionWithNullableValue) : null,
    draftComponentId,
  );
  const templateEditorProduct = useEditorSelector(
    selectTemplateEditorStoreProduct,
  );

  const currentComponentContext = getCurrentComponentContext(
    draftComponentId,
    0,
  );

  /**
   * NOTE (Evan, 7/10/23) This is a slightly hacky way to avoid having to click "save" twice when editing
   * the "Run Javascript" action (REPL-7873). On unmount, if the draft action is a javascript action, we
   * run the onSave function. The shouldSaveJavascriptOnUnmount ref allows us to prevent this from happening,
   * which we want to do in 2 cases:
   * 1) when the user manually clicks the "save" button (so we don't run onSave twice)
   * 2) when the user clicks "cancel"
   * We use a ref for this so that the value can update in time for the unmount.
   */

  const shouldSaveJavascriptOnUnmount = React.useRef(true);
  const onUnmount = React.useRef<() => void>();

  React.useEffect(() => {
    onUnmount.current = () => {
      if (
        draftAction?.type === "executeJavascript" &&
        shouldSaveJavascriptOnUnmount.current
      ) {
        onSave();
      }
    };
  }, [draftAction.type, onSave]);

  React.useEffect(() => {
    return () => {
      if (onUnmount.current) {
        onUnmount.current();
      }
    };
  }, []);

  if (!draftAction) {
    return null;
  }

  const options = getActionOptions(actions, trigger);
  const { valueType = null } = draftAction.type
    ? actionTypeToEditorData[draftAction.type]
    : {};

  const actionValueEditorData = valueType
    ? getActionValueTypeToEditorData(valueType)
    : null;

  return (
    <form
      className="flex flex-col justify-between"
      onSubmit={(e) => {
        e.preventDefault();
        shouldSaveJavascriptOnUnmount.current = false;
        onSave();
      }}
    >
      <div className="justify-self-auto">
        <div className="flex w-full flex-col pb-3">
          <Selectable
            id="choose-interaction"
            className="mb-2 w-full"
            value={draftAction.type ?? undefined}
            placeholder="Choose Interaction"
            options={options}
            onSelect={(value: AlchemyActionType) => {
              // Note (Noah, 2024-11-03): The value type is not actually enforced correctly
              // here, so typescript thinks that we're assigning a value that can't be assigned
              // based on the action type. We should fix this somehow probably
              // @ts-expect-error
              const newAction: Partial<Action> = {
                ...draftAction,
                type: value,
                value: undefined,
              };

              // Note (Noah, 2023-11-06, USE-537, REPL-9181, REPL-9184): We want
              // to make sure that the default value is added for add product to
              // cart actions, because we want to make sure that configuration
              // options like allowThirdPartySellingPlan are correctly set to
              // their default value. However, currently the validation system
              // for actions is brittle and doesn't actually validate all
              // actions correctly. If an action gets saved which we think is
              // valid but doesn't match our types, it can result in the editor
              // crashing which is very bad. So, until we implement actual
              // schema-based validation for actions, we ONLY add the default
              // value for the add product to cart type.
              if (value === "addProductVariantToCart") {
                newAction.value = getDefaultActionValue(newAction);
              }

              onUpdate(newAction);
            }}
          />
          {draftAction.type &&
            draftComponentId &&
            actionValueEditorData &&
            actionValueEditorData.render(
              draftAction.value,
              (value: any) => {
                onUpdate({
                  ...draftAction,
                  value,
                });
              },
              {
                element: draftElement,
                actionType: draftAction.type,
                componentId: draftComponentId,
                products,
                componentContext: currentComponentContext,
                templateProduct: templateEditorProduct ?? null,
              },
            )}
        </div>
      </div>
      <div className="flex flex-row items-center justify-end justify-items-end">
        <Button
          variant="secondary"
          type="button"
          className="mr-2"
          size="sm"
          onClick={() => {
            shouldSaveJavascriptOnUnmount.current = false;
            onCancel();
          }}
        >
          <span>Cancel</span>
        </Button>
        <Button
          variant="primary"
          type="submit"
          isDisabled={
            !actionValueEditorData?.isValid(draftAction?.value, {
              actionType: draftAction.type,
            })
          }
          size="sm"
        >
          <span>Save</span>
        </Button>
      </div>
    </form>
  );
};

const ActionItem: React.FC<{
  trigger: AlchemyActionTrigger;
  action: Action;
  draftActionId?: string;
  onClick: (action: Action) => void;
  className?: string;
}> = ({ trigger, action, draftActionId, onClick, className }) => {
  const applyComponentAction = useApplyComponentAction();
  const isVisible = draftActionId === action.id;
  const editorData = actionTypeToEditorData[action.type];
  const value = getActionValue(action);

  return (
    <div
      className={classNames(
        "flex flex-1 cursor-pointer flex-row rounded bg-subtle p-1 transition-colors gap-2",
        { "bg-gray-200": isVisible },
        className,
      )}
      onClick={() => onClick(action)}
      id="draft-action-item"
    >
      <div className="flex flex-1 flex-row items-center truncate text-left text-xs gap-1">
        <span className="basis-0">{editorData.label}</span>
        {action.type === "redirect" && (
          <span className="grow truncate shrink-1">
            {isDynamicDataValue(value)
              ? getDynamicDataValueDisplayName(value)
              : value}
          </span>
        )}
      </div>
      <BsX
        size={16}
        className="place-items-end text-subtle"
        onClick={(e) => {
          e.stopPropagation();
          applyComponentAction({
            type: "deleteAction",
            value: { trigger, actionId: action.id },
          });
        }}
      />
    </div>
  );
};

function generateInitialDraftAction() {
  return {
    id: uuidv4(),
  };
}

function getActionValue(action: Action) {
  if (action.type === "redirect") {
    if (typeof action.value === "string") {
      return action.value;
    } else if (action.value?.url) {
      return action.value.url;
    }
  }

  return "";
}

export default ActionModifier;
