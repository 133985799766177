import {
  selectEditorMode,
  selectHasDraftComponentFromDraftElement,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { EditorMode } from "@editor/types/core-state";

import { selectCanvasIsLoading } from "@/features/canvas/canvas-reducer";

export default function useRightBarVisibility() {
  const hasDraftComponent = useEditorSelector(
    selectHasDraftComponentFromDraftElement,
  );
  const editorMode = useEditorSelector(selectEditorMode);
  const isCanvasLoading = useEditorSelector(selectCanvasIsLoading);

  return (
    !isCanvasLoading &&
    ((editorMode === EditorMode.edit && hasDraftComponent) ||
      editorMode === EditorMode.versioning)
  );
}
