import type { GlobalWindow } from "../../shared/Window";

import * as React from "react";

import {
  getFullPageOffset,
  selectRuntimeElementRootNode,
} from "../../shared/utils/dom";
import ScrollSpy from "../../shared/utils/ScrollSpy";

export const useScrollSpy = (
  reploElementId: string,
  props: {
    hasScrolled: React.MutableRefObject<boolean>;
    globalWindow: GlobalWindow | null;
  },
) => {
  const { hasScrolled, globalWindow } = props;
  const isScrollSpyInitialized = React.useRef(false);

  // Note (Noah, 2024-08-18): We need to get the initial fullpage offset here
  // because we need to compare to it later, but we don't want to run the query
  // selector every time we render, so just use an unsettable state
  const [initialFullPageOffset] = React.useState(() => {
    return globalWindow ? getFullPageOffset(globalWindow.document) : 0;
  });

  const initializeScrollSpy = React.useCallback(() => {
    // Always set the scroll offset to be the offset of the full page element,
    // since there may be a header etc
    if (globalWindow) {
      const scrollSpy = new ScrollSpy({
        querySelectorNode: selectRuntimeElementRootNode(
          globalWindow.document,
          reploElementId,
        ),
        targetWindow: globalWindow,
        scrollOffset: ScrollSpy.defaultScrollOffset,
        onBecomeActive: (element) => {
          if (
            element.dataset.alchemyUrlHashmark &&
            !element.dataset.alchemyUrlHashmarkIgnore
          ) {
            // NOTE (Evan, 6/29/23) This is a minor hack that prevents the page from
            // auto-scrolling when we change the hash on scroll, to avoid "jumpy" behavior.
            // See:
            // • USE-268 for the issue
            // • https://stackoverflow.com/questions/1489624/modifying-location-hash-without-page-scrolling) for the solution
            const oldId = element.id;
            element.id = "";
            globalWindow.location.hash = element.dataset.alchemyUrlHashmark;
            element.id = oldId;
          }
        },
      });
      scrollSpy.refreshTargets();
    }
  }, [reploElementId, globalWindow]);

  const handleWheel = () => {
    // Whenever the window is scrolled, make sure we set hasScrolledToHashmack
    // so that a component won't accidentally try to scroll to a hashmark if
    // one is created on the window (e.g. if a component has a set hashmark)
    hasScrolled.current = true;

    // Because there may be random stuff like async js loading on the page which
    // may offset the full-page Replo element, when we scroll, make SUPER sure
    // that we don't need to re-adjust the scroll offset
    const offset = globalWindow ? getFullPageOffset(globalWindow.document) : 0;
    if (initialFullPageOffset !== offset && !isScrollSpyInitialized.current) {
      isScrollSpyInitialized.current = true;
      initializeScrollSpy();
    }
  };
  // NOTE (Chance 2024-05-05): Save a reference to the wheel handler. We don't
  // need to detach/re-attach it when its deps change.
  const savedWheelHandler = React.useRef(handleWheel);
  React.useEffect(() => {
    savedWheelHandler.current = handleWheel;
  });

  React.useEffect(() => {
    const targetDocument = globalWindow?.document;
    if (!targetDocument) {
      return;
    }
    const listener = () => savedWheelHandler.current();
    targetDocument.addEventListener("wheel", listener);
    targetDocument.addEventListener("touchmove", listener);
    return () => {
      targetDocument.removeEventListener("wheel", listener);
      targetDocument.removeEventListener("touchmove", listener);
    };
  }, [globalWindow]);

  React.useEffect(() => {
    if (!isScrollSpyInitialized.current) {
      isScrollSpyInitialized.current = true;
      initializeScrollSpy();
    }
  }, [initializeScrollSpy]);
};
