import inRange from "lodash-es/inRange";

import { isNumber } from "./type-check";

const EPSILON = 0.03;

export function isCloseTo(
  number: number,
  comparator: number,
  epsilon: number = EPSILON,
) {
  return Math.abs(number - comparator) < epsilon;
}

export function round(number: number, precision: number) {
  return Number.parseFloat(Math.round(number).toFixed(precision));
}

export function clamp(
  number: number,
  minimum: number,
  maximum: number,
): number {
  const _minimum = Math.min(minimum, maximum);
  const _maximum = Math.max(minimum, maximum);
  return Math.min(Math.max(_minimum, number), _maximum);
}

export { inRange };

export function parseInteger(value: string | number) {
  let number: number;
  if (isNumber(value) && Number.isInteger(value)) {
    number = value;
  } else {
    // NOTE (Chance 2024-05-23): Passing a number value to parseInt is safe. No
    // reason to cast to a string. We still want to attempt to "parse"
    // non-integer numbers, as floats will be truncated and infinite values
    // returned as NaN.
    number = Number.parseInt(value as any, 10);
  }
  // NOTE (Chance 2024-01-12): 0 eq check removes -0 as a possible return value
  return number === 0 ? 0 : number;
}

export function parseFloat(value: string | number) {
  const number = isNumber(value) ? value : Number.parseFloat(value);
  // NOTE (Chance 2024-01-12): 0 eq check removes -0 as a possible return value
  return number === 0 ? 0 : number;
}

/**
 * Given a number representing a price in USD, returns a string
 * with that price formatted as a whole number (if the price is an integer),
 * otherwise two decimal points.
 * e.g.:
 * 72.2 -> "$72.20"
 * 49 -> "$49"
 *
 * @param price The price in dollars
 * @returns Price-formatted string
 */
export const formatPrice = (price: number) => {
  const priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: Number.isInteger(price) ? 0 : 2,
  });
  return priceFormatter.format(price);
};

/**
 * @returns Distance between two points
 */
export function distance(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
): number {
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
}

export function formatWithCommasAndTwoDecimals(
  num: number | undefined,
  shouldShowDecimals: boolean,
): string {
  if (num === undefined) {
    return shouldShowDecimals ? "0.00" : "0";
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: shouldShowDecimals ? 2 : 0,
    maximumFractionDigits: shouldShowDecimals ? 2 : 0,
  }).format(Math.abs(num));
}
