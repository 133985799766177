import type { Position } from "replo-runtime/shared/types";

import * as React from "react";

import { HEADER_HEIGHT, LEFT_BAR_WIDTH } from "@components/editor/constants";
import { getTargetFrameWindow } from "@editor/hooks/useTargetFrame";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { selectIsPreviewMode } from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";

import { useEffectEvent } from "replo-utils/react/use-effect-event";

import {
  selectCanvasDeltaXY,
  selectCanvasInteractionMode,
  selectCanvasScale,
  selectVisibleCanvases,
} from "./canvas-reducer";
import { useCanvasPortalPan } from "./useCanvasPortalPan";

export function useWheelHandler() {
  const store = useEditorStore();
  const visibleCanvases = useEditorSelector(selectVisibleCanvases);
  const isPreviewMode = useEditorSelector(selectIsPreviewMode);
  const handleCanvasPortalPan = useCanvasPortalPan();

  const iframeCoordinateToCanvasCoordinate = React.useCallback(
    (iframePosition: Position): Position => {
      const leftBarOffsetContribution = checkIfNewEditorPanelsUIIsEnabled()
        ? 0
        : LEFT_BAR_WIDTH;
      const state = store.getState();
      const canvasScale = selectCanvasScale(state);
      const { deltaX, deltaY } = selectCanvasDeltaXY(state);
      const x =
        canvasScale * iframePosition.x + deltaX + leftBarOffsetContribution;
      const y = canvasScale * iframePosition.y + deltaY + HEADER_HEIGHT;
      return { x, y };
    },
    [store],
  );

  // Note (Noah, 2022-01-11): Set handlers for wheel so that when we're content
  // editing (editing text inside the iframe) and the iframe's pointer-events is
  // auto, we still intercept events and scroll/zoom the canvas
  const getWheelHandler = useEffectEvent((targetFrame: HTMLIFrameElement) => {
    return (event: WheelEvent) => {
      const { x: clientX, y: clientY } = iframeCoordinateToCanvasCoordinate({
        x: event.clientX,
        y: event.clientY,
      });
      const isContentEditing =
        selectCanvasInteractionMode(store.getState()) == "content-editing";

      if (!isContentEditing) {
        handleCanvasPortalPan({
          clientX,
          clientY,
          deltaX: event.deltaX,
          deltaY: event.deltaY,
          metaKey: event.metaKey,
          ctrlKey: event.ctrlKey,
        });
        return;
      }

      event.preventDefault();
      event.stopImmediatePropagation();

      if (event.ctrlKey) {
        targetFrame.style.setProperty("pointer-events", "none");
      }

      handleCanvasPortalPan({
        clientX,
        clientY,
        deltaX: event.deltaX,
        deltaY: event.deltaY,
        metaKey: event.metaKey,
        ctrlKey: event.ctrlKey,
      });
      targetFrame.style.setProperty("pointer-events", "auto");
    };
  });

  React.useEffect(() => {
    if (
      // Note (Noah, 2022-01-13): Don't do anything in this listener if we're
      // not editing content inside the DOM, because we might be in preview
      // mode and if so we don't want the pan/zoom to happen
      isPreviewMode
    ) {
      return;
    }

    const cleanupFunctions: (() => void)[] = [];
    for (const canvas of Object.values(visibleCanvases)) {
      const canvasIFrame = canvas.targetFrame;
      const targetWindow = canvasIFrame
        ? getTargetFrameWindow(canvasIFrame)
        : null;
      if (!targetWindow || !canvasIFrame) {
        continue;
      }
      const handleWheel = getWheelHandler(canvasIFrame);
      targetWindow.addEventListener("wheel", handleWheel, {
        passive: false,
      });
      cleanupFunctions.push(() => {
        targetWindow.removeEventListener("wheel", handleWheel);
      });
    }
    return () => {
      for (const cleanup of cleanupFunctions) {
        cleanup();
      }
    };
  }, [visibleCanvases, isPreviewMode, getWheelHandler]);
}
