import type { Component, ReploComponentType } from "schemas/component";
import type { Flow, FlowSlugs } from "schemas/generated/flow";

import * as React from "react";

import Banner from "@common/designSystem/Banner";
import { Group } from "@common/designSystem/Group";
import Input from "@common/designSystem/Input";
import LabeledControl from "@common/designSystem/LabeledControl";
import Modal from "@common/designSystem/Modal";
import Switch from "@common/designSystem/Switch";
import { ModalLayout } from "@common/ModalLayout";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { useReploFlowsContext } from "@editor/components/flows/context/ReploFlowsContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import {
  clearFeatureFlagOverrides,
  disableFeatureFlag,
  enableFeatureFlag,
  isFeatureEnabled,
} from "@editor/infra/featureFlags";
import { selectProductData } from "@editor/reducers/commerce-reducer";
import {
  selectComponentMapping,
  selectDataTablesMapping,
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  setDebugPanelVisibility,
} from "@editor/reducers/core-reducer";
import { selectTemplateEditorStoreProduct } from "@editor/reducers/template-reducer";
import { selectThemeId, setThemeId } from "@editor/reducers/ui-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { calculateDependencies } from "@editor/utils/dependencies";
import { trpc } from "@editor/utils/trpc";

import { Badge } from "@replo/design-system/components/badge";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BiSearch } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import { RiCloseFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_MONEY_FORMAT,
} from "replo-runtime/shared/liquid";
import { DependencyType } from "replo-runtime/shared/types";
import { allMediaSizeStyles } from "replo-runtime/shared/utils/breakpoints";
import { forEachComponentAndDescendants } from "replo-runtime/shared/utils/component";
import { recordEntries } from "replo-runtime/shared/utils/object";
import { featureFlags } from "replo-utils/lib/featureFlags";
import { isEmpty } from "replo-utils/lib/misc";
import { capitalizeFirstLetter } from "replo-utils/lib/string";
import { useForceUpdate } from "replo-utils/react/use-force-update";
import { ReploComponentTypes } from "schemas/component";
import z from "zod";

import { DEBUG_PANEL_WIDTH } from "../constants";
import SelectablePopover from "../page/element-editor/components/SelectablePopover";

const FeatureFlagsBlock: React.FC = () => {
  /**
   * Note (Noah, 2023-01-19): We have to do this rerender hack because we
   * don't have a hook to respond to changes in posthog feature flags
   */
  const rerender = useForceUpdate();
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredSortedFlags = React.useMemo(() => {
    return featureFlags
      .filter((flag) => flag.toLowerCase().includes(searchTerm.toLowerCase()))
      .sort((a, b) => a.localeCompare(b));
  }, [searchTerm]);

  return (
    <div className="flex flex-col gap-3 py-3">
      <Banner backgroundColor="bg-yellow-200" className="p-4 text-xs">
        <span>
          ⚠️ These toggles ONLY override feature flags for your local session.
          Toggling them will NOT enable them for the customer. See{" "}
          <a
            className="underline"
            href="https://www.notion.so/replo/Feature-Flags-Guide-for-Support-b360ef51974b4ea2bb68a1e38a48f9f9?pvs=4"
          >
            here
          </a>{" "}
          for more info on how to use Posthog to enable feature flags.
        </span>
      </Banner>
      <Input
        placeholder="Search feature flags"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        endEnhancer={() => <BiSearch size={12} />}
      />
      {filteredSortedFlags.map((flag) => {
        return (
          <div
            key={flag}
            className="flex flex-row items-center justify-between gap-2"
          >
            <div className="text-xs text-slate-600">{flag}</div>
            <Switch
              isOn={isFeatureEnabled(flag)}
              onChange={() => {
                if (isFeatureEnabled(flag)) {
                  disableFeatureFlag(flag);
                } else {
                  enableFeatureFlag(flag);
                }
                rerender();
              }}
            />
          </div>
        );
      })}
      <Button
        variant="primary"
        onClick={() => {
          clearFeatureFlagOverrides();
          rerender();
        }}
      >
        Reset Overrides
      </Button>
    </div>
  );
};

const ThemeIdBlock: React.FC = () => {
  const themeId = useEditorSelector(selectThemeId);
  const dispatch = useEditorDispatch();

  return (
    <div className="flex flex-col gap-3 py-1">
      <div className="text-xs text-slate-600">Override theme ID.</div>
      <Input
        value={themeId ?? undefined}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(setThemeId(e.target.value));
        }}
      />
    </div>
  );
};

const OptionsBlock: React.FC = () => {
  const [reduxLoggingToConsole, setReduxLoggingToConsole] =
    useLocalStorageState("replo.debug.reduxLoggingToConsole", false, {
      schema: z.boolean(),
    });
  const [usePublisherStagingUrl, setUsePublisherStagingUrl] =
    useLocalStorageState("replo.debug.usePublisherStagingUrl", false, {
      schema: z.boolean(),
    });
  const [useShopifyDebugTools, setUseShopifyDebugTools] = useLocalStorageState(
    "replo.debug.useShopifyDebugTools",
    false,
    { schema: z.boolean() },
  );
  const [keepProductTooltipOpen, setKeepProductTooltipOpen] =
    useLocalStorageState("replo.debug.keepProductTooltipOpen", false, {
      schema: z.boolean(),
    });
  const [enablePosthogSessionRecording, setEnablePosthogSessionRecording] =
    useLocalStorageState("replo.debug.enablePosthogSessionRecording", false, {
      schema: z.boolean(),
    });
  const [copyComponentJsonMinify, setCopyComponentJsonMinify] =
    useLocalStorageState("replo.debug.copyComponentJsonMinify", false, {
      schema: z.boolean(),
    });
  return (
    <div className="flex flex-col gap-2">
      <OptionsItemBlock label="Redux logging to console">
        <Switch
          isOn={reduxLoggingToConsole ?? false}
          onChange={() => {
            setReduxLoggingToConsole(
              (reduxLoggingToConsole) => !reduxLoggingToConsole,
            );
          }}
        />
      </OptionsItemBlock>
      <OptionsItemBlock label="Use publisher staging URL">
        <Switch
          isOn={usePublisherStagingUrl ?? false}
          onChange={() => {
            setUsePublisherStagingUrl(
              (usePublisherStagingUrl) => !usePublisherStagingUrl,
            );
          }}
        />
      </OptionsItemBlock>
      <OptionsItemBlock label="Use shopify debugging tools">
        <Switch
          isOn={useShopifyDebugTools ?? false}
          onChange={() => {
            setUseShopifyDebugTools(
              (useShopifyDebugTools) => !useShopifyDebugTools,
            );
          }}
        />
      </OptionsItemBlock>
      <OptionsItemBlock label="Keep product tooltip open">
        <Switch
          isOn={keepProductTooltipOpen ?? false}
          onChange={() => {
            setKeepProductTooltipOpen(
              (keepProductTooltipOpen) => !keepProductTooltipOpen,
            );
          }}
        />
      </OptionsItemBlock>
      <OptionsItemBlock label="Enable posthog session recording">
        <Switch
          isOn={enablePosthogSessionRecording ?? false}
          onChange={() => {
            setEnablePosthogSessionRecording(
              (keepProductTooltipOpen) => !keepProductTooltipOpen,
            );
          }}
        />
      </OptionsItemBlock>
      <OptionsItemBlock label="Copy component JSON as minified">
        <Switch
          isOn={copyComponentJsonMinify ?? false}
          onChange={() => {
            setCopyComponentJsonMinify(
              (copyComponentJsonMinify) => !copyComponentJsonMinify,
            );
          }}
        />
      </OptionsItemBlock>
    </div>
  );
};

function OptionsItemBlock({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-row items-center justify-between gap-2">
      <div className="text-xs text-slate-600">{label}</div>
      {children}
    </div>
  );
}

const SelectTypeComponentFinderBlock: React.FC = () => {
  const [type, setType] = React.useState<ReploComponentType>("rawHtmlContent");
  // Note (Noah, 2024-03-20): support requested these to be at the top since
  // they're the most common to look for
  const prioritizedTypes: ReploComponentType[] = [
    "rawHtmlContent",
    "shopifyRawLiquid",
  ];
  const sortedTypes = [
    ...ReploComponentTypes.filter((t) => !prioritizedTypes.includes(t)),
  ].sort();
  sortedTypes.unshift(...prioritizedTypes);
  return (
    <div className="flex flex-col gap-2">
      <SelectablePopover
        title="Component Type"
        itemSize={36}
        itemsOnViewCount={5}
        options={sortedTypes.map((type) => ({
          value: type,
          label: type,
          isSelectable: true,
        }))}
        onSelect={(value) => {
          setType(value as ReploComponentType);
        }}
        popoverSide="right"
        shouldCloseOnSelect
        isRemovable={false}
        selectFirstSearchOptionOnInputEnter
        trigger={
          <SelectionIndicator
            title={`${type} (click to change)`}
            startEnhancer={
              <Badge
                type="icon"
                icon={type.charAt(0).toUpperCase()}
                className="text-sm bg-accent text-white"
              />
            }
          />
        }
      />
      <ComponentTypeFinderBlock types={[type]} />
    </div>
  );
};

const ComponentTypeFinderBlock: React.FC<{
  types: ReploComponentType[];
  test?: (component: Component) => boolean;
}> = ({ types, test }) => {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const setDraftElement = useSetDraftElement();

  if (!draftElement) {
    return <span>No element selected!</span>;
  }

  const relevantComponents: Component[] = [];
  forEachComponentAndDescendants(draftElement.component, (component) => {
    if (types.includes(component.type)) {
      if (!test) {
        relevantComponents.push(component);
      } else {
        if (test(component)) {
          relevantComponents.push(component);
        }
      }
    }
  });

  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs gray-400">
        Total components: {relevantComponents.length}
      </p>
      <div className="flex flex-col ml-2 gap-1 max-h-[400px] overflow-auto">
        {relevantComponents.map((component) => {
          return (
            <p
              key={component.id}
              onClick={() => {
                setDraftElement({
                  componentId: component.id,
                });
              }}
              className="text-xs gray-300 underline cursor-pointer"
            >
              {component.name ?? component.type}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const ComponentsWithDataSvgsBlock: React.FC = () => {
  return (
    <ComponentTypeFinderBlock
      types={["image"]}
      test={(component: Component) => {
        for (const mediaPropsKey of allMediaSizeStyles) {
          if (
            component.props[mediaPropsKey] &&
            component.props[mediaPropsKey]!.__imageSource?.includes(
              "data:image/svg",
            )
          ) {
            return true;
          }
        }
        if (
          component.props.src &&
          typeof component.props.src === "string" &&
          component.props.src.includes("data:image/svg")
        ) {
          return true;
        }
        return false;
      }}
    />
  );
};

const ProductDependenciesFinderBlock: React.FC = () => {
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const componentMapping = useEditorSelector(selectComponentMapping);
  const dataTablesMapping = useEditorSelector(selectDataTablesMapping);
  const productData = useEditorSelector(selectProductData);
  const templateProduct =
    useEditorSelector(selectTemplateEditorStoreProduct) ?? null;
  const setDraftElement = useSetDraftElement();

  if (!draftElement) {
    return <span>No element selected!</span>;
  }

  const { dependencies } = calculateDependencies(draftElement.component, {
    dataTables: dataTablesMapping,
    productResolutionDependencies: {
      products: Object.values(productData),
      currencyCode: DEFAULT_ACTIVE_CURRENCY,
      language: DEFAULT_ACTIVE_LANGUAGE,
      moneyFormat: DEFAULT_MONEY_FORMAT,
      templateProduct,
      isEditor: true,
    },
    // Note (Noah, 2023-08-19): No need to pass metafield key mappings here, since we
    // don't care about metafields, just products
    metafieldsNamespaceKeyTypeMapping: { product: {}, variant: {} },
  });
  const productIdToComponentIds: Record<string | number, string[]> = {};
  Object.entries(dependencies).forEach(([componentId, dependencies]) => {
    dependencies.forEach((dependency) => {
      if (dependency.type === DependencyType.products) {
        dependency.productIds.forEach((productId) => {
          if (!productIdToComponentIds[productId]) {
            productIdToComponentIds[productId] = [];
          }
          productIdToComponentIds[productId]!.push(componentId);
        });
      }
    });
  });

  return (
    <div className="flex flex-col gap-2">
      <p className="text-xs gray-400">
        Total products referenced: {Object.keys(productIdToComponentIds).length}
      </p>
      {recordEntries(productIdToComponentIds).map(
        ([productId, componentIds]) => {
          return (
            <LabeledControl
              key={productId}
              label={productData[productId]?.title ?? "Unknown product"}
            >
              <div className="flex flex-col gap-1 ml-2">
                {componentIds.map((componentId) => {
                  return (
                    <p key={componentId} className="text-xs gray-300">
                      Referenced by{" "}
                      <span
                        onClick={() => {
                          setDraftElement({
                            componentId,
                          });
                        }}
                        className="underline cursor-pointer"
                      >
                        {componentMapping[componentId]?.component.name ??
                          componentMapping[componentId]?.component.type ??
                          `Unknown Component ${componentId}`}
                      </span>
                    </p>
                  );
                })}
              </div>
            </LabeledControl>
          );
        },
      )}
    </div>
  );
};

const ComponentSearchBlock: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const draftElement = useEditorSelector(
    selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  );
  const setDraftElement = useSetDraftElement();

  if (!draftElement) {
    return null;
  }

  const relevantComponents: Component[] = [];
  forEachComponentAndDescendants(draftElement.component, (component) => {
    if (
      searchTerm &&
      ((component.name &&
        component.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        component.id.includes(searchTerm))
    ) {
      relevantComponents.push(component);
    }
  });

  return (
    <div className="flex flex-col gap-2">
      <Input
        placeholder="Enter component name or id"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        endEnhancer={() => (
          <IconButton
            variant="tertiary"
            className="bg-clear hover:bg-slate-50"
            tooltipText="Clear Search"
            icon={<RiCloseFill size={12} />}
            onClick={() => setSearchTerm("")}
          />
        )}
      />

      <div className="flex flex-col ml-2 gap-1 max-h-[200px] overflow-auto">
        {relevantComponents.map((component) => {
          return (
            <p
              key={component.id}
              onClick={() => {
                setDraftElement({
                  componentId: component.id,
                });
              }}
              className="text-xs gray-300 underline cursor-pointer"
            >
              {component.name ?? component.type}
            </p>
          );
        })}
      </div>
    </div>
  );
};

const FlowsDebugMenu: React.FC<{ flows: Flow[] | undefined }> = ({ flows }) => {
  const dispatch = useEditorDispatch();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const {
    debug: { setFlowToDebug },
  } = useReploFlowsContext();

  const onClick = (flowSlug: string, type: "route" | "editor") => {
    dispatch(setDebugPanelVisibility(false));
    if (type === "editor") {
      setFlowToDebug?.(flowSlug as FlowSlugs);
    } else {
      navigate(`/flows/${flowSlug}?debug=true`);
    }
  };

  return (
    <ul className="flex flex-col gap-2">
      {flows?.map((flow) => {
        const userInstance = user?.flowInstances?.filter(
          ({ instance }) =>
            instance.flow.slug === flow.slug &&
            !instance.completedAt &&
            instance.isDebug,
        );
        // NOTE (Sebas, 2024-02-22): In case we add more flows that are not router flows
        // we will need to update this condition.
        const isEditorTour = flow.slug === "editor-tour";
        return (
          <li key={flow.slug} className="flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <span className="text-sm">{flow.name}</span>
              <Tooltip
                content={capitalizeFirstLetter(flow.status)}
                triggerAsChild
              >
                <span tabIndex={0}>
                  <BsFillCircleFill
                    size={10}
                    className={classNames("pt-0.5", {
                      "text-slate-400": flow.status === "draft",
                      "text-green-400": flow.status === "live",
                    })}
                  />
                </span>
              </Tooltip>
            </div>
            <Button
              variant="primary"
              size="sm"
              hasMinDimensions={false}
              onClick={() =>
                onClick(flow.slug, isEditorTour ? "editor" : "route")
              }
            >
              {isEmpty(userInstance) ? "Test" : "Continue"}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

const DebugPanelInner = () => {
  const isDebugModeAllowed = useIsDebugMode();
  const { data } = trpc.flow.getManyDebug.useQuery({});
  const flows = data?.flows ?? [];
  const sections = [
    {
      component: <FeatureFlagsBlock />,
      title: "Feature Flags",
    },
    {
      component: <ThemeIdBlock />,
      title: "Theme Id",
    },
    {
      component: <OptionsBlock />,
      title: "Options",
    },
    {
      component: <ComponentsWithDataSvgsBlock />,
      title: "Components With data:svg",
    },
    {
      component: (
        <ComponentTypeFinderBlock
          types={["shopifyRawLiquid", "rawHtmlContent"]}
        />
      ),
      title: "Shopify Raw Liquid Components",
    },
    {
      component: <ComponentTypeFinderBlock types={["shopifySection"]} />,
      title: "Shopify Sections Components",
    },
    {
      component: <ProductDependenciesFinderBlock />,
      title: "Product Dependencies",
    },
    {
      component: <SelectTypeComponentFinderBlock />,
      title: "Component Finder",
    },
    {
      component: <ComponentSearchBlock />,
      title: "Component Search",
    },
    {
      component: <FlowsDebugMenu flows={flows} />,
      title: "Flows Debug",
    },
  ];

  if (!isDebugModeAllowed) {
    return null;
  }
  return sections.map(({ component, title }) => (
    <div className="px-3 py-4 border-b-slate-200 border-b" key={title}>
      <Group name={title} isCollapsible isDefaultOpen>
        <div className="ml-1 pl-3 border-l-slate-200 border-l-2">
          {component}
        </div>
      </Group>
    </div>
  ));
};

export const DebugLeftPanel = () => {
  return (
    <div className="flex z-0">
      <div className="relative flex h-full">
        <div
          className="flex-col flex-1 bg-white border-r border-r-slate-200 border-t border-t-slate-200 overflow-y-scroll"
          style={{
            width: DEBUG_PANEL_WIDTH,
          }}
        >
          <DebugPanelInner />
        </div>
      </div>
    </div>
  );
};

export const DebugModal = () => {
  const dispatch = useEditorDispatch();
  return (
    <Modal
      isOpen
      className="h-auto w-auto overflow-scroll no-scrollbar"
      onRequestClose={() => {
        dispatch(setDebugPanelVisibility(false));
      }}
      includesCloseIcon={false}
    >
      <ModalLayout
        width={1000}
        height={600}
        mainContent={() => (
          <div className="flex-col">
            <DebugPanelInner />
          </div>
        )}
      />
    </Modal>
  );
};
