import type { PayloadAction } from "@reduxjs/toolkit";
import type {
  MetafieldEntityType,
  MetafieldKey,
  MetafieldNamespace,
  MetafieldType,
  OkendoNamespace,
  ProductMetafieldMapping,
  StoreProduct,
  VariantMetafieldMapping,
} from "replo-runtime/shared/types";

import { createSelector, createSlice } from "@reduxjs/toolkit";
import merge from "lodash-es/merge";
import {
  DEFAULT_ACTIVE_CURRENCY,
  DEFAULT_ACTIVE_LANGUAGE,
  DEFAULT_ACTIVE_SHOPIFY_URL_ROOT,
  DEFAULT_MONEY_FORMAT,
} from "replo-runtime/shared/liquid";

/**
 * Mapping which maps metafield namespace to a mapping of key -> type. Useful for
 * situations where we have metafield values but haven't fetched definitions yet, but
 * still need to know the type of the metafield.
 */
export type MetafieldsNamespaceKeyTypeMapping = Record<
  MetafieldEntityType,
  Record<MetafieldNamespace, Record<MetafieldKey, MetafieldType>>
>;

export interface CommerceState {
  /**
   * Currently known mapping of product ID -> metafield values mapping (namespace ->
   * key -> value). May be empty if we have not fetched metafield values yet.
   */
  productMetafieldValues: ProductMetafieldMapping;
  /**
   * Currently known mapping of variant ID -> metafield values mapping (namespace ->
   * key -> value). May be empty if we have not fetched metafield values yet.
   */
  variantMetafieldValues: VariantMetafieldMapping;
  /**
   * Currently known Shopify products, grouped by their id. Useful as a cache for when
   * we have to fetch products in different places and don't want to depend on query
   * caches. Also helps with implementing pagination for products, since we can
   * easily request 5 products at a time from the backend and those 5 can be the first
   * 5 we _don't_ have in this mapping.
   */
  products: Record<string | number, StoreProduct>;

  activeCurrency: string;
  activeLanguage: string;
  moneyFormat: string | null;
  shopifyUrlRoot: string;
  okendoWidgetNamespace: OkendoNamespace | null;
}

const initialState: CommerceState = {
  variantMetafieldValues: {},
  productMetafieldValues: {},
  products: {},
  activeCurrency: DEFAULT_ACTIVE_CURRENCY,
  activeLanguage: DEFAULT_ACTIVE_LANGUAGE,
  moneyFormat: DEFAULT_MONEY_FORMAT,
  shopifyUrlRoot: DEFAULT_ACTIVE_SHOPIFY_URL_ROOT,
  okendoWidgetNamespace: null,
};

export type UploadResult = {
  asset: {
    contentType: string;
    id: string;
    publicUrl: string;
  };
};

const commerceSlice = createSlice({
  name: "commerce",
  initialState,
  reducers: {
    setProductData: (state, action: PayloadAction<StoreProduct[]>) => {
      action.payload.forEach((product) => {
        state.products[Number(product.id)] = product;
      });
    },
    resetProductData: (state) => {
      state.products = {};
    },
    setProductMetafieldValues: (
      state,
      action: PayloadAction<ProductMetafieldMapping>,
    ) => {
      state.productMetafieldValues = {
        ...state.productMetafieldValues,
        ...action.payload,
      };
    },
    setVariantsMetafieldValues: (
      state,
      action: PayloadAction<VariantMetafieldMapping>,
    ) => {
      Object.entries(action.payload).forEach(([variantId, values]) => {
        const key = Number.parseInt(variantId);
        state.variantMetafieldValues[key] = merge(
          state.variantMetafieldValues[key],
          values,
        );
      });
    },
    setLocaleData: (
      state,
      action: PayloadAction<{
        activeCurrency: string;
        activeLanguage: string;
        moneyFormat: string | null;
      }>,
    ) => {
      state.activeCurrency = action.payload.activeCurrency;
      state.activeLanguage = action.payload.activeLanguage;
      state.moneyFormat = action.payload.moneyFormat;
    },
    setShopifyUrlRoot: (state, action: PayloadAction<string>) => {
      state.shopifyUrlRoot = action.payload;
    },
    setOkendoWidgetNamespace: (
      state,
      action: PayloadAction<OkendoNamespace | null>,
    ) => {
      state.okendoWidgetNamespace = action.payload;
    },
  },
});

type EditorRootState = { commerce: CommerceState };

export const selectProductMetafieldValues = (state: EditorRootState) => {
  return state.commerce.productMetafieldValues;
};

export const selectVariantMetafieldValues = (state: EditorRootState) => {
  return state.commerce.variantMetafieldValues;
};

export const selectProductData = (state: EditorRootState) => {
  return state.commerce.products;
};

export const selectAllProducts = createSelector(
  selectProductData,
  (products) => {
    return Object.values(products);
  },
);

export type Locale = {
  activeCurrency: string;
  activeLanguage: string;
  moneyFormat: string | null;
};

export const selectLocaleData = createSelector(
  (state: EditorRootState) => state.commerce.activeCurrency,
  (state: EditorRootState) => state.commerce.activeLanguage,
  (state: EditorRootState) => state.commerce.moneyFormat,
  (activeCurrency, activeLanguage, moneyFormat) => ({
    activeCurrency,
    activeLanguage,
    moneyFormat,
  }),
);

export const selectShopifyUrlRoot = (state: EditorRootState) => {
  return state.commerce.shopifyUrlRoot;
};

export const selectOkendoWidgetNamespace = (state: EditorRootState) => {
  return state.commerce.okendoWidgetNamespace;
};

export const { reducer } = commerceSlice;
const { actions } = commerceSlice;

export const {
  resetProductData,
  setLocaleData,
  setOkendoWidgetNamespace,
  setProductData,
  setProductMetafieldValues,
  setShopifyUrlRoot,
  setVariantsMetafieldValues,
} = actions;
