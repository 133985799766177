import type { Component } from "schemas/component";

import cloneDeep from "lodash-es/cloneDeep";
import { AlchemyActionTriggers } from "replo-runtime/shared/enums";
import {
  forEachComponentAndDescendants,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import { v4 as uuidv4 } from "uuid";

export const refreshComponentIds = (
  template: Component,
  newComponentId?: string,
): { component: Component; oldIdToNewId: Record<string, string> } => {
  const result = cloneDeep(template);
  const oldIdToNewId: Record<string, string> = {};
  forEachComponentAndDescendants(result, (component) => {
    const newId = uuidv4();
    if (component.id !== "$uid") {
      oldIdToNewId[component.id] = newId;
    }
    component.id = newId;

    component.variants?.forEach((variant) => {
      const newVariantId = uuidv4();
      oldIdToNewId[variant.id] = newVariantId;
    });
    for (const trigger of AlchemyActionTriggers) {
      component.props?.[trigger]?.forEach((action) => {
        const newActionId = uuidv4();
        oldIdToNewId[action.id] = newActionId;
      });
    }

    for (const customPropDefinition of getCustomPropDefinitions(component)) {
      if (customPropDefinition.type === "inlineItems") {
        // @ts-ignore
        const items: { id: string }[] =
          component.props?.[customPropDefinition.name];
        if (items) {
          items.forEach((item) => {
            const newItemId = uuidv4();
            oldIdToNewId[item.id] = newItemId;
            item.id = newItemId;
          });
        }
      }
    }
  });

  // TODO(Noah, 2021-06-28): Since actions and variant overrides (among other things?)
  // in this component may now refer to out of date ids, we do a batch find and
  // replace by stringifying and parsing. Maybe there's a less heavy handed way
  // to do this

  if (newComponentId) {
    oldIdToNewId[result.id] = newComponentId;
  }

  let stringified = JSON.stringify(result);
  for (const [oldId, newId] of Object.entries(oldIdToNewId)) {
    stringified = stringified.replace(new RegExp(oldId, "g"), newId);
  }
  return { component: JSON.parse(stringified), oldIdToNewId };
};
