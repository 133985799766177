import type {
  GradientOrSolidOnChangeProps,
  SolidOrGradient,
} from "replo-runtime/shared/types";

import * as React from "react";

import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectColor,
  selectDraftComponentId,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import GradientSelector from "@editorComponents/GradientSelector";
import SolidColorSelector from "@editorComponents/SolidColorSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";

type ColorSelectorProps = {
  onRemove?(): void;
  openDynamicData?(): void;
  onDragStart?(e: React.MouseEvent): void;
  onDragEnd?(e: React.MouseEvent): void;
  popoverTitle?: string;
  isDisabled?: boolean;
  popoverSideOffset?: number;
  isPopoverOpen?: boolean;
  onOpenPopoverChange?(isOpen: boolean): void;
  onSavedStyleSelect?(value: string): void;
  showSavedStyles?: boolean;
} & GradientOrSolidOnChangeProps;

export const ColorSelector: React.FC<ColorSelectorProps> = (props) => {
  if (props.allowsGradientSelection) {
    return (
      <GradientSelector
        isPopoverOpen={props.isPopoverOpen}
        onOpenPopoverChange={props.onOpenPopoverChange}
        onDragEnd={props.onDragEnd}
        onDragStart={props.onDragStart}
        onPreviewChange={props.onPreviewChange}
        value={props.value}
        onChange={(value: SolidOrGradient) => props.onChange(value)}
        onRemove={props.onRemove}
        openDynamicData={props.openDynamicData}
        popoverTitle={props.popoverTitle}
        isDisabled={props.isDisabled}
        popoverSideOffset={props.popoverSideOffset}
        onSavedStyleSelect={props.onSavedStyleSelect}
        showSavedStyles={props.showSavedStyles}
      />
    );
  }
  return (
    <SolidColorSelector
      isPopoverOpen={props.isPopoverOpen}
      onOpenPopoverChange={props.onOpenPopoverChange}
      onPreviewChange={props.onPreviewChange}
      onChange={(value) => props.onChange?.(value)}
      value={props.value}
      onDragEnd={props.onDragEnd}
      onDragStart={props.onDragStart}
      openDynamicData={props.openDynamicData}
      popoverTitle={props.popoverTitle}
      isDisabled={props.isDisabled}
      popoverSideOffset={props.popoverSideOffset}
      onSavedStyleSelect={props.onSavedStyleSelect}
      showSavedStyles={props.showSavedStyles}
    />
  );
};

const ColorModifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const color = useEditorSelector(selectColor);
  const applyComponentAction = useApplyComponentAction();
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  return (
    <ModifierGroup title="Color">
      <ColorSelector
        allowsGradientSelection={false}
        value={color || null}
        onChange={(v) =>
          applyComponentAction({
            type: "setStyles",
            componentId: draftComponentId,
            value: { color: v },
          })
        }
      />
    </ModifierGroup>
  );
};

export { ColorModifier };
