import type { MediaSize } from "schemas/breakpoints";
import type {
  Component,
  CustomPropDefinition,
  CustomPropsRecord,
  ReploComponentType,
} from "schemas/component";
import type { ItemsConfig } from "schemas/dynamicData";
import type {
  RuntimeStyleProperties,
  StringableRecord,
} from "schemas/styleAttribute";
import type { Context } from "../../store/AlchemyVariable";
import type { AllComponentRenderData } from "../../store/components";
import type { StyleElementContext } from "../styles";
import type { EditorPropType, VariantWithState } from "../types";

import * as React from "react";

import isObject from "lodash-es/isObject";
import { filterNulls } from "replo-utils/lib/array";
import { isEmpty } from "replo-utils/lib/misc";
import { ItemsConfigType } from "schemas/dynamicData";

import { getRenderData } from "../../store/components";
import { isCompletelyHiddenComponent } from "../../store/utils/isCompletelyHiddenComponent";
import { getItemObjectsForRender } from "../../store/utils/items";
import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../runtime-context";
import { generateStyleId, getComponentRuleName } from "../styles";
import { mergeContext } from "./context";

export type EditorProp = {
  type: EditorPropType;
  title: string;
  description: string;
  defaultValue: any;
};

// NOTE (Gabe 2023-06-16): We use Stringable here so we can set properties like
// objectFit to "var(--custom-var)". We still get type suggestions in the IDE,
// but this makes sure we don't have to do any special casting when using
// variables.
export type StringableCSSProperties = StringableRecord<React.CSSProperties>;

/**
 * The subset of component props which relate to style. I.e., an object
 * like this:
 *
 * {
 *   style: { ... },
 *   style@sm: { ... },
 *   ...
 * }
 */
export type ComponentStyleProps = {
  [key in "style" | `style@${MediaSize}`]?: RuntimeStyleProperties;
};

type GetDefaultStyles = (args: {
  component: Component;
  context: StyleElementContext;
}) => StringableCSSProperties | undefined;

type GetOverrideStyles = (args: {
  component: Component;
  styleProps: ComponentStyleProps;
  context: StyleElementContext;
}) => StringableCSSProperties | undefined;

type ChildrenOverrideStyles = (args: {
  component: Component;
}) => StringableCSSProperties | undefined;

export interface StyleElement {
  defaultStyles?: StringableCSSProperties | GetDefaultStyles;
  overrideStyles?: StringableCSSProperties | GetOverrideStyles;
  childrenOverrideStyles?: StringableCSSProperties | ChildrenOverrideStyles;
  shouldRender?(props: StyleElementContext & { isEditor: boolean }): boolean;
}

export type StyleElements = Record<string, StyleElement>;

export type EditorPropsRecord = Record<string, EditorProp>;

export function convertToLegacyProps(
  props: CustomPropsRecord,
): CustomPropDefinition[] {
  return Object.entries(props).map(([key, value]) => {
    return {
      ...value,
      id: key,
    };
  });
}

/**
 * Array of Replo components which should be displayed as children of another possibly-dynamic
 * Replo component. This is used in dynamic components like Carousel, where either the
 * children are static from the component's `children` prop, or they repeat one or more
 * of the children N times, for each dyamic data item (e.g. for each image in a product
 * collection).
 */
export type RenderChildren = {
  /**
   * The component that should be rendered as a the child
   */
  component: Component;

  /**
   * Context which should be merged into the child's context (e.g. so the current item
   * can be passed to the child image in a carousel)
   */
  context: Context;

  /**
   * The dynamic item which is associated with this child. If the children are static,
   * this will be null. Useful for matching up with the "selected item" of a carousel
   * to figure out whether to auto-scroll to it when the selected item changes.
   */
  item: unknown | null;
}[];

type UseRenderChildrenOptions = {
  context: Context;
  itemsConfig?: ItemsConfig | null;
  currentItemId?: string;
  alwaysPreferChildComponent?: boolean;
};

export function useRenderChildren(
  component: Component | null,
  options: UseRenderChildrenOptions,
): RenderChildren {
  const { context, itemsConfig } = options;
  const emptyChildren = React.useMemo(() => [], []);
  const staticChildren = React.useMemo(() => {
    return getStaticChildren(component, context);
  }, [component, context]);

  const dynamicChildren = useDynamicChildren(component, {
    ...options,
    staticChildren,
  });

  if (!component) {
    return emptyChildren;
  }

  // Note (Fran, 2022-08-10): If the dynamic data doesn't have elements, we
  // need to fallback to the static children, otherwise the component will
  // render incorrectly.
  // https://linear.app/replo/issue/REPL-3467/setting-carousel-to-product-images-makes-it-auto-width
  const noDynamicChildren = dynamicChildren.length === 0;

  if (!itemsConfig || noDynamicChildren) {
    return staticChildren;
  }

  // TODO (Noah, 2022-07-22, REPL-3075): If the type of the items is inline but there
  // are no items, treat it as if there were no items at all. This happens when the user
  // adds items, then removes all of them. This can be removed once we don't support
  // adding "specific items" anymore.
  if (
    itemsConfig.type === ItemsConfigType.inline &&
    itemsConfig.valueType === "string" &&
    itemsConfig.values?.length === 0
  ) {
    return staticChildren;
  }

  return dynamicChildren;
}

function useDynamicChildren(
  component: Component | null,
  options: UseRenderChildrenOptions & { staticChildren: RenderChildren },
): RenderChildren {
  const {
    context,
    itemsConfig,
    currentItemId,
    alwaysPreferChildComponent,
    staticChildren,
  } = options;
  const {
    fakeProducts,
    activeCurrency: currencyCode,
    activeLanguage: language,
    moneyFormat,
    templateProduct,
  } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const dataTableMapping =
    useRuntimeContext(RuntimeHooksContext).useDataTableMapping();
  const emptyChildren = React.useMemo(() => [], []);
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);

  const items = React.useMemo(() => {
    const firstChildComponent = component?.children?.[0];

    if (!firstChildComponent || !itemsConfig) {
      return emptyChildren;
    }

    const itemsObjects = getItemObjectsForRender(
      itemsConfig,
      dataTableMapping,
      context,
      {
        products,
        currencyCode,
        moneyFormat,
        fakeProducts,
        language,
        templateProduct,
        isEditor: isEditorApp,
      },
    );

    // If its a data table and there are no items, fallback to static children
    if (
      itemsConfig.type === ItemsConfigType.dataTable &&
      isEmpty(itemsObjects)
    ) {
      return staticChildren;
    }

    // NOTE (Fran 2024-10-29): If the itemsObjects is not an array, we return an empty array.
    if (!itemsObjects || !Array.isArray(itemsObjects)) {
      return [];
    }

    return itemsObjects.map((item, index) => ({
      component: alwaysPreferChildComponent
        ? component.children?.[index] ?? firstChildComponent
        : firstChildComponent,
      context: mergeContext(context, {
        attributes: {
          _currentItem: isObject(item)
            ? { ...item, index: String(index + 1) }
            : item,
        },
        attributeKeyToComponentId: {
          _currentItem: currentItemId,
        },
      }),
      item,
    }));
  }, [
    component,
    itemsConfig,
    context,
    currentItemId,
    alwaysPreferChildComponent,
    dataTableMapping,
    products,
    fakeProducts,
    currencyCode,
    moneyFormat,
    language,
    templateProduct,
    emptyChildren,
    staticChildren,
    isEditorApp,
  ]);

  return items;
}

function getStaticChildren(
  component: Component | null | undefined,
  context: Context,
): RenderChildren {
  const children = filterNulls(
    component?.children?.map((child) => {
      if (!child || isCompletelyHiddenComponent(child)) {
        return null;
      }
      return {
        component: child,
        context,
        item: null,
      };
    }),
  );

  return children;
}
/**
 * Note (Chance, 2023-05-12): This function is not truly type safe as it
 * performs an arbitrary string operation. We accept the component type, so we
 * should be able to do a runtime check if we can construct a scheme or even an
 * object that maps components to its class keys. Be careful with usage in the
 * mean time and make sure you only call this function if you definitely know
 * the component type.
 *
 * @returns A map of `styleElements` (the key for each DOM element rendered by a
 * component, eg. 'root', 'button', etc.) to its classname.
 *
 * Note (Chance, 2023-05-18) This return type basically says that if a
 * component's render data has a `styleElements` key, the return type will
 * extract the type from its value in the render data object. For example,
 * because `AllComponentRenderData['container']` has a `styleElements` key, and
 * the type of `AllComponentRenderData['container']['styleElements']` is `{
 * root: { ... } }`, `useComponentClassNames('container', ...args)` will
 * return an object with the type `{ root: string }`. If a component's render
 * data doesn't define a `styleElements` key, the return type will be an
 * inaccessable object, eg `{ [key: never]: never }`
 */
export function useComponentClassNames<T extends ReploComponentType>(
  componentType: T,
  component: Component,
  context: Context,
): undefined | StyleElementRecord<T, string> {
  const editorOverrideVariantId = useRuntimeContext(
    RuntimeHooksContext,
  ).useEditorOverrideActiveVariantId(component.id);

  // Get all the variants for this component (NOT any from ancestors)
  const variants: VariantWithState[] =
    context.ancestorWithVariantsId === component.id
      ? context.variants ?? []
      : [];

  // Next, figure out which variant is active, if any
  let activeVariant: VariantWithState | undefined = undefined;
  if (editorOverrideVariantId) {
    // If the user has specifically selected a variant in the editor, then
    // treat that variant as active
    activeVariant = variants.find((v) => v.id == editorOverrideVariantId);
  }
  if (!activeVariant) {
    // Otherwise, the active variant is the one whose conditions are true (in
    // ReploComponent, we already mapped this to isActive = true on this
    // VariantWithState object)
    activeVariant = variants.find((variant) => variant.isActive);
  }
  if (activeVariant?.isDefault) {
    // If the active variant is the default variant, then act like there's no
    // active variant, since we don't want to include any variant id in the
    // className which is going to get returned from this function (we just want
    // the "normal") class name
    activeVariant = undefined;
  }
  const styleElementNames = Object.keys(
    getRenderData(componentType)?.styleElements ?? {
      root: {},
    },
  );
  return Object.fromEntries(
    styleElementNames.map((styleElementName) => {
      return [
        styleElementName,
        [
          generateStyleId(
            getComponentRuleName(component.id, {
              styleElementName,
            }),
          ),
          // If there is an active variant we combine the root className with
          // the variant className
          activeVariant
            ? generateStyleId(
                getComponentRuleName(component.id, {
                  styleElementName,
                  variantId: activeVariant.id,
                }),
              )
            : false,
        ]
          .filter(Boolean)
          .join(" "),
      ];
    }),
  ) as StyleElementRecord<T, string>;
}

type StyleElementRecord<
  T extends ReploComponentType,
  V,
> = AllComponentRenderData[T] extends {
  styleElements: any;
}
  ? Record<keyof AllComponentRenderData[T]["styleElements"], V>
  : Record<"root", V>;
