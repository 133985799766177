import type { MetricName, RangeResult } from "schemas/generated/analyticsRead";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { SimpleSkeletonLoader } from "@editor/components/common/designSystem/SkeletonLoader";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import {
  calculateDelta,
  decodeUrlHost,
  getUrlWithAnalyticsUrlParams,
} from "@editor/utils/analytics";
import { routes } from "@editor/utils/router";

import AnalyticsFilters from "@/features/analytics/AnalyticsFilters";
import { getLineChartDataFromAnalyticsRead } from "@/features/analytics/chart";
import {
  DEFAULT_ANALYTICS_CHART_CONFIG,
  DETAILS_PAGE_CARD_METRICS,
  METRICS_REQUIRING_CURRENCY,
  METRICS_REQUIRING_PERCENTAGE,
} from "@/features/analytics/constants";
import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import AnalyticsLayout from "@/features/analytics/Layout";
import AnalyticsQueryIntervalCombobox from "@/features/analytics/selects/AnalyticsQueryIntervalCombobox";
import { getRangeInDays } from "@/features/analytics/time";
import useChartAnalyticsRead from "@/features/analytics/useChartAnalyticsRead";
import usePageAnalyticsRead from "@/features/analytics/usePageAnalyticsRead";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import ReploLineChart from "@replo/design-system/components/shadcn/LineChart";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { hours } from "replo-utils/lib/datetime";
import { formatWithCommasAndTwoDecimals } from "replo-utils/lib/math";

const PageDetails: React.FC = () => {
  const workspaceId = useCurrentWorkspaceId();
  const shopifyURLOptions = useWorkspaceUrlHosts(workspaceId);
  const { pageUrlPath = "", host = "" } = useParams<{
    pageUrlPath: string;
    host: string;
  }>();
  const decodedPageUrlPath = decodeURIComponent(pageUrlPath);
  const decodedHost = decodeUrlHost(host);
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackNavigation = () => {
    const overviewPath = generatePath(routes.analytics.overview, {
      workspaceId,
    });

    const urlSearchParams = new URLSearchParams(location.search);
    navigate(getUrlWithAnalyticsUrlParams(overviewPath, urlSearchParams));
  };

  return (
    <AnalyticsLayout
      headerTitle="Deep Dive"
      backNavigation={{
        label: "Back to Overview",
        onClick: handleBackNavigation,
      }}
    >
      {shopifyURLOptions.isLoading ? (
        <SimpleSkeletonLoader height="300" width="100%" />
      ) : (
        <LoadedPageDetails
          workspaceId={workspaceId ?? ""}
          pageUrlPath={decodedPageUrlPath}
          host={decodedHost}
        />
      )}
    </AnalyticsLayout>
  );
};

type LoadedPageDetailsProps = {
  workspaceId: string;
  pageUrlPath: string;
  host: string;
};

const LoadedPageDetails: React.FC<LoadedPageDetailsProps> = ({
  workspaceId,
  pageUrlPath,
}) => {
  const { workspaceUrlHosts, getParamValue } = useAnalyticsQueryContext();

  const {
    rangeResults,
    isLoading,
    mainRangeId,
    compareAtRangeId,
    constructedQuery,
  } = usePageAnalyticsRead(workspaceId, pageUrlPath);

  const rangeInDays = React.useMemo(() => {
    return getRangeInDays(constructedQuery.ranges.mainRange);
  }, [constructedQuery.ranges.mainRange]);

  const filterRangeResults = React.useCallback(
    (
      rangeResults: RangeResult[] | null | undefined,
      rangeId: string | undefined,
      pageName: string,
    ) => {
      return (
        rangeResults?.filter(
          (rangeResultObj) =>
            rangeResultObj.rangeId === rangeId &&
            rangeResultObj.urlPath === pageName,
        ) ?? []
      );
    },
    [],
  );

  const mainRangeResults = React.useMemo(
    () => filterRangeResults(rangeResults, mainRangeId, pageUrlPath),
    [rangeResults, mainRangeId, pageUrlPath, filterRangeResults],
  );

  const compareAtRangeResults = React.useMemo(
    () => filterRangeResults(rangeResults, compareAtRangeId, pageUrlPath),
    [rangeResults, compareAtRangeId, pageUrlPath, filterRangeResults],
  );

  const {
    rangeResults: chartResults,
    isLoading: isChartResultsLoading,
    mainRangeId: chartMainRangeId,
    compareAtRangeId: chartCompareAtRangeId,
    constructedQuery: chartQuery,
    interval,
    handleIntervalChange,
  } = useChartAnalyticsRead(workspaceId, pageUrlPath);

  const chartMainRangeResults = React.useMemo(
    () => filterRangeResults(chartResults, chartMainRangeId, pageUrlPath),
    [chartResults, chartMainRangeId, pageUrlPath, filterRangeResults],
  );

  const chartCompareAtRangeResults = React.useMemo(
    () => filterRangeResults(chartResults, chartCompareAtRangeId, pageUrlPath),
    [chartResults, chartCompareAtRangeId, pageUrlPath, filterRangeResults],
  );

  const chartMetrics = [
    {
      key: "conversion_rates",
      label: "Conversion Rate",
      tooltipValueFormatter: (value: string) =>
        `${formatWithCommasAndTwoDecimals(Number(value), true)}%`,
    },
    {
      key: "unique_sessions",
      label: "Total Sessions",
      tooltipValueFormatter: (value: string) => `${value} sessions`,
    },
    {
      key: "average_order_value",
      label: "Average Order Value",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
    {
      key: "revenue_per_session",
      label: "Revenue Per Session",
      tooltipValueFormatter: (value: string) =>
        `$${formatWithCommasAndTwoDecimals(Number(value), true)}`,
    },
  ];

  const getChartData = React.useCallback(
    (metricKey: string) => {
      // NOTE (Kurt, 2024-10-04): We use the first element of these arrays because each array contains results for a single range (main or compare).
      // The filterRangeResults function ensures that we only have results for the specific page and range we're interested in.
      // Therefore, there should only be one element in each array, representing the data for our current page and range.
      if (chartMainRangeResults[0] && chartCompareAtRangeResults[0]) {
        return {
          data: getLineChartDataFromAnalyticsRead(
            chartMainRangeResults[0],
            chartCompareAtRangeResults[0],
            chartQuery,
            metricKey as MetricName,
          ),
          showPercentage: METRICS_REQUIRING_PERCENTAGE.includes(
            metricKey as MetricName,
          ),
          showCurrency: METRICS_REQUIRING_CURRENCY.includes(
            metricKey as MetricName,
          ),
        };
      }
      return null;
    },
    [chartMainRangeResults, chartCompareAtRangeResults, chartQuery],
  );

  const chartData = chartMetrics.map((metric) => ({
    ...metric,
    ...getChartData(metric.key),
  }));

  return (
    <div className="flex flex-col gap-4">
      <AnalyticsFilters />
      {isLoading && workspaceUrlHosts.isLoading ? (
        <SimpleSkeletonLoader height="200" width="100%" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {DETAILS_PAGE_CARD_METRICS.map((metric, index) => {
            const metricData =
              mainRangeResults[0]?.metrics[metric.key as MetricName];
            const compareAtMetricData =
              compareAtRangeResults[0]?.metrics[metric.key as MetricName];
            const delta = calculateDelta(
              metricData?.[0] as number,
              compareAtMetricData?.[0] as number,
            );
            const doesCompareValueExist =
              ((compareAtMetricData?.[0] as number) ?? 0) > 0;

            return (
              <div
                key={index}
                className="border border-slate-300 rounded-xl p-6"
              >
                <div className="flex flex-col">
                  <div className="text-base text-default font-medium pb-2">
                    <div className="inline-block">{metric.label}</div>
                  </div>
                  <MetricWithDelta
                    name={metric.key}
                    value={metricData?.[0] as number}
                    delta={delta}
                    doesCompareValueExist={doesCompareValueExist}
                    wrapperClassName="justify-left"
                    valueClassName="text-2xl font-semibold min-w-[40px] text-default"
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-row w-full justify-end">
        <AnalyticsQueryIntervalCombobox
          currentInterval={interval}
          rangeInDays={rangeInDays}
          onChange={handleIntervalChange}
        />
      </div>
      {isChartResultsLoading ? (
        <SimpleSkeletonLoader height="200" width="100%" />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {chartData.map((metric) => (
            <div key={metric.key} className="w-full h-[350px]">
              <ReploLineChart<string>
                title={`${metric.label}`}
                data={metric.data ?? []}
                config={{
                  ...DEFAULT_ANALYTICS_CHART_CONFIG,
                  xAxis: {
                    ...DEFAULT_ANALYTICS_CHART_CONFIG.xAxis,
                    // NOTE (Kurt, 2024-10-03): We reconstruct the config object here based on the current query state
                    // to format the x-axis labels differently based on the interval. This is necessary since we don't want
                    // to store any formatted data in the chart data object. This makes it necessary for us to use formatter
                    // functions that can be passed to the chart component. We conditionally construct the x-axis tick
                    // formatter based on the current interval and range in days.
                    tickFormatter: (value) => {
                      const date = new Date(value);
                      if (
                        getParamValue("selectedTimePeriod")?.interval ===
                          hours(1) &&
                        rangeInDays <= 2
                      ) {
                        return date.toISOString().slice(11, 16);
                      } else {
                        return date.toLocaleDateString("en-US", {
                          month: "numeric",
                          day: "2-digit",
                        });
                      }
                    },
                  },
                }}
                showTooltipCursor={true}
                tooltipKey={DEFAULT_ANALYTICS_CHART_CONFIG.xAxis.dataKey}
                tooltipValueFormatter={metric.tooltipValueFormatter}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageDetails;
