import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import useBaseAnalyticsRead from "@/features/analytics/useBaseAnalyticsRead";

const useAnalyticsRead = (
  workspaceId: string | null,
  defaultInterval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"],
) => {
  return useBaseAnalyticsRead({ workspaceId, defaultInterval });
};

export default useAnalyticsRead;
