import * as React from "react";

import Button from "@replo/design-system/components/button";
import clsxMerge from "@replo/design-system/components/shadcn/utils/cn-merge";
import classNames from "classnames";

type TriggerSelectProps = {
  isDisabled?: boolean;
  className?: string;
  startEnhancer?: () => React.ReactNode;
  endEnhancer?: () => React.ReactNode;
  staticPlaceholder?: string;
  placeholderPrefix?: string;
  placeholder?: string;
  selectedLabel: string;
  open: boolean;
};

const TriggerSelect: React.FC<TriggerSelectProps> = ({
  isDisabled,
  className,
  startEnhancer,
  endEnhancer,
  staticPlaceholder,
  placeholderPrefix,
  placeholder,
  selectedLabel,
  open,
}) => {
  const displayBoxLabel = () => {
    return (
      <div className="whitespace-nowrap overflow-hidden text-ellipsis">
        {staticPlaceholder ? (
          staticPlaceholder
        ) : (
          <>
            {placeholderPrefix}
            {selectedLabel ? (
              selectedLabel
            ) : (
              <span className="text-subtle">{placeholder}</span>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Button
      variant="no-style"
      isPhonyButton
      role="combobox"
      aria-expanded={open}
      className={clsxMerge(
        classNames(
          "h-[32px] rounded-[4px] p-2 flex justify-between items-center gap-[8px] w-full overflow-hidden",
          {
            " bg-slate-100 hover:bg-slate-200 text-slate-800": !isDisabled,
            "bg-gray-200 text-gray-500 cursor-not-allowed": isDisabled,
          },
        ),
        className,
      )}
      isDisabled={isDisabled}
      startEnhancer={startEnhancer}
      endEnhancer={endEnhancer}
    >
      <div className="text-xs leading-5 items-center font-normal">
        {displayBoxLabel()}
      </div>
    </Button>
  );
};

export { TriggerSelect };
