import type { DragType } from "@editor/types/drag-type";
import type * as React from "react";
import type { CanvasFrameRefs, EditorCanvas } from "./canvas-types";

import { createContext } from "replo-utils/react/context";

export interface CanvasContextValue {
  canvas: EditorCanvas;
  canvasHeight: number;
  canvasWidth: number;
  targetFrame: HTMLIFrameElement | null;
}

export const CanvasContext = createContext<CanvasContextValue>("CanvasContext");

export interface CanvasAreaContextValue {
  currentDragType: DragType | null;
  canvasHtml: string | null;
  frameRefs: React.MutableRefObject<CanvasFrameRefs>;
}

export const CanvasAreaContext =
  createContext<CanvasAreaContextValue>("CanvasAreaContext");
