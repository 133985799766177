import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { getProjectName, getStoreData } from "@editor/utils/project-utils";

export default function useGetStoreNameAndUrl(): {
  storeName?: string;
  storeUrl?: string;
} {
  const { project } = useCurrentProjectContext();

  if (!project) {
    return {};
  }

  return {
    storeName: getProjectName(project),
    storeUrl: getStoreData(project)?.shopifyUrl ?? undefined,
  };
}
