import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import { OptionsList } from "@replo/design-system/components/shadcn/combobox/components/OptionsList";
import { TriggerSelect } from "@replo/design-system/components/shadcn/combobox/components/triggers/TriggerSelect";
import { Command } from "@replo/design-system/components/shadcn/core/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@replo/design-system/components/shadcn/core/popover";
import { useControllableState } from "replo-utils/react/use-controllable-state";

export function Combobox({
  areOptionsSearchable,
  options,
  placeholder,
  placeholderPrefix,
  staticPlaceholder,
  startEnhancer,
  endEnhancer,
  className,
  defaultValue,
  value: controlledValue,
  onChange: onControlledChange,
  open: controlledOpen,
  onOpenChange: onControlledOpenChange,
  input: inputValue,
  onInputChange: onControlledInputChange,
  isDisabled,
  inputPlaceholder,
  isLoading,
  popoverTriggerClassName,
}: ComboboxProps) {
  const [open, setOpen] = useControllableState(
    controlledOpen,
    false,
    onControlledOpenChange,
  );
  const [value, setValue] = useControllableState(
    controlledValue,
    defaultValue ?? "",
    onControlledChange,
  );

  const [input, setInput] = useControllableState(
    inputValue,
    "",
    onControlledInputChange,
  );

  const selectedLabel =
    options.find((option) => option.value === value)?.label || value;

  return (
    <Popover open={isDisabled ? false : open} onOpenChange={setOpen}>
      <PopoverTrigger className={popoverTriggerClassName}>
        <TriggerSelect
          isDisabled={isDisabled}
          className={className}
          startEnhancer={startEnhancer}
          endEnhancer={endEnhancer}
          staticPlaceholder={staticPlaceholder}
          placeholderPrefix={placeholderPrefix}
          placeholder={placeholder}
          selectedLabel={selectedLabel}
          open={open}
        />
      </PopoverTrigger>
      <PopoverContent
        className="w-full p-0 max-w-[550px]"
        style={{ minWidth: "var(--radix-popper-anchor-width)" }}
      >
        <Command
          filter={(value, search) => {
            if (value.includes(search)) {
              return 1;
            }
            return 0;
          }}
        >
          <OptionsList
            options={options}
            setValue={setValue}
            setOpen={setOpen}
            value={value}
            input={input}
            inputPlaceholder={inputPlaceholder}
            setInput={setInput}
            areOptionsSearchable={areOptionsSearchable}
            isLoading={isLoading}
          />
        </Command>
      </PopoverContent>
    </Popover>
  );
}
