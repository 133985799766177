import type { AnalyticsUrlParamsFilterTypes } from "@/features/analytics/moreFilters/constants";
import type { FindUrlParamsQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import Popover from "@editor/components/common/designSystem/Popover";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";

import { useAnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import {
  OPERATOR_OPTIONS,
  URL_PARAMS_FILTER_OPTIONS,
} from "@/features/analytics/moreFilters/constants";
import {
  addEntryToUrlParams,
  findLabelForValue,
} from "@/features/analytics/moreFilters/utils";
import useFindUrlParams from "@/features/analytics/useFindUrlParams";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { ComboboxMultiSelect } from "@replo/design-system/components/shadcn/combobox/ComboboxMultiSelect";
import { BsArrowReturnRight, BsCaretDownFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

type BarPopoverProps = {
  selectedUrlParamOptionValue: string;
  isOpen: boolean;
  onOpenChange(isOpen: boolean): void;
};

const INITIAL_SEARCH_VALUE = "";
const INITIAL_OPERATOR_VALUE = ConditionOperatorEnum.EQUALS;
const INITIAL_SEARCH_LIMIT = 20;
const INITIAL_SEARCH_OFFSET = 0;
const INITIAL_SEARCH_ORDER = "DESC";

function createOptionFromValue(value: string) {
  return {
    label: value,
    value,
  };
}

const BarPopover: React.FC<BarPopoverProps> = ({
  selectedUrlParamOptionValue,
  isOpen,
  onOpenChange,
}) => {
  const { getParamValue, setParamValue } = useAnalyticsQueryContext();
  const [searchValue, setSearchValue] = React.useState(INITIAL_SEARCH_VALUE);
  const [operatorValue, setOperatorValue] =
    React.useState<ConditionOperatorEnum>(INITIAL_OPERATOR_VALUE);

  const analytics = useLogAnalytics();

  const handleSearchInputValueChange = (value: string) => setSearchValue(value);

  const { urlPageName = "" } = useParams<{ urlPageName: string }>();

  const urlHosts = getParamValue("urlHosts");

  const handleSearchSelectValueChange = (value: string) => {
    const filters = getParamValue("filters");
    const { urlParams: currentUrlParams } = filters;

    const urlParamToAdd = {
      attribute: selectedUrlParamOptionValue,
      operator: operatorValue,
      value,
    };

    const updatedUrlParams = addEntryToUrlParams(
      currentUrlParams,
      urlParamToAdd,
    );

    setParamValue("filters", {
      ...filters,
      urlParams: updatedUrlParams,
    });

    setSearchValue(value);

    resetState();
    onOpenChange(false);
    analytics("analytics.filter", {
      type: selectedUrlParamOptionValue as AnalyticsUrlParamsFilterTypes,
      filterDetail: operatorValue,
      location:
        urlPageName === "" ? "analytics_overview" : "analytics_deep_dive",
    });
  };

  const resetState = () => {
    setSearchValue(INITIAL_SEARCH_VALUE);
    setOperatorValue(INITIAL_OPERATOR_VALUE);
  };

  const workspaceId = useCurrentWorkspaceId();

  const findUrlParamsQuery: FindUrlParamsQuery = React.useMemo(() => {
    return {
      urlHosts,
      order: INITIAL_SEARCH_ORDER,
      limit: INITIAL_SEARCH_LIMIT,
      offset: INITIAL_SEARCH_OFFSET,
      key: selectedUrlParamOptionValue,
      value: searchValue,
    };
  }, [urlHosts, selectedUrlParamOptionValue, searchValue]);

  const { urlParams: findUrlParamsResponse } = useFindUrlParams(
    findUrlParamsQuery,
    workspaceId,
  );

  const urlParamsOptionsRetrieved =
    findUrlParamsResponse?.map((urlParam) => createOptionFromValue(urlParam)) ??
    [];

  /**
   * NOTE (Max, 2024-10-08): We'll add the option that's currently being typed
   * (i.e. the searchValue) only if it's not empty, AND it's not an already-existing
   * option that we've retrieved from ClickHouse.
   */
  const shouldPrependCurrentOption =
    searchValue &&
    !urlParamsOptionsRetrieved
      .map((option) => option.value)
      .includes(searchValue);

  const urlParamsOptions = [
    ...(shouldPrependCurrentOption ? [createOptionFromValue(searchValue)] : []),
    ...urlParamsOptionsRetrieved,
  ];

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={() => {
        onOpenChange(!isOpen);
        resetState();
      }}
    >
      <div className="absolute top-0 left-0 w-0 h-0">
        <Popover.Anchor className="relative" />
      </div>
      <Popover.Content
        hideCloseButton={true}
        shouldPreventDefaultOnInteractOutside={false}
        side="bottom"
        sideOffset={35}
        className="border"
        align="start"
      >
        <div className="flex flex-row gap-2 items-center">
          <div>
            <BsArrowReturnRight className="h-5 w-5 text-subtle" />
          </div>
          <span className="flex whitespace-nowrap text-xs items-center text-medium p-2">
            {findLabelForValue(
              URL_PARAMS_FILTER_OPTIONS,
              selectedUrlParamOptionValue,
            )}
          </span>
          <div>
            <Combobox
              options={OPERATOR_OPTIONS}
              value={operatorValue}
              endEnhancer={() => (
                <BsCaretDownFill className="h-2 w-2 text-subtle" />
              )}
              onChange={(operator) => {
                setOperatorValue(operator as ConditionOperatorEnum);
              }}
              className="min-w-[125px]"
            />
          </div>
          <div className="min-w-[300px] ml-2">
            <ComboboxMultiSelect
              options={urlParamsOptions}
              value={searchValue}
              onChange={handleSearchSelectValueChange}
              onInputChange={handleSearchInputValueChange}
            />
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
};

export default BarPopover;
