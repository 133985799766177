import type { EditorRootState } from "@editor/store";
import type { PayloadAction } from "@reduxjs/toolkit";

import { isArrayUnOrderlyEqual } from "@editor/utils/tree-utils";

import { createSelector, createSlice } from "@reduxjs/toolkit";

export type SelectionState = {
  selectedIds: string[];
  lastSelectedId: string | null;
};

const initialState: SelectionState = {
  selectedIds: [],
  lastSelectedId: null,
};

const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setMultipleSelectedIds: (state, action: PayloadAction<string[]>) => {
      if (!isArrayUnOrderlyEqual(state.selectedIds, action.payload)) {
        state.selectedIds = action.payload;
      }
    },
    setLastSelectedId: (state, action: PayloadAction<string | null>) => {
      state.lastSelectedId = action.payload;
    },
  },
});

export const selectSelectedIds = (state: EditorRootState) =>
  state.selection.selectedIds;

export const selectLastSelectedId = (state: EditorRootState) =>
  state.selection.lastSelectedId;

export const selectIsSelected = (id: string) => {
  return (state: EditorRootState) => state.selection.selectedIds.includes(id);
};

export const selectModalComponentIdFromSelection = createSelector(
  selectSelectedIds,
  (state: EditorRootState) => state.core.componentDataMapping,
  (selectedIds, componentDataMapping) => {
    const modalIdFromSelectedIds = selectedIds.find(
      (id) => componentDataMapping[id]?.type === "modal",
    );

    if (modalIdFromSelectedIds) {
      return modalIdFromSelectedIds;
    }

    let ancestorModalFromSelectedIds = null;
    for (const id of selectedIds) {
      const ancestorModal = componentDataMapping[
        id
      ]?.ancestorComponentData.find(([_, ancestorComponentType]) =>
        ancestorComponentType.includes("modal"),
      );
      if (ancestorModal) {
        ancestorModalFromSelectedIds = ancestorModal;
        break;
      }
    }

    if (ancestorModalFromSelectedIds) {
      return ancestorModalFromSelectedIds[0];
    }

    return null;
  },
);

const { actions, reducer } = selectionSlice;

export const { setMultipleSelectedIds, setLastSelectedId } = actions;
export default reducer;
