import type { ReploElement } from "schemas/generated/element";

import * as React from "react";

import Modal from "@common/designSystem/Modal";
import { errorToast, ToastManager } from "@common/designSystem/Toast";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import { EditorNoAccessToProjectScreen } from "@components/editor/EditorNoAccessToProjectScreen";
import GlobalHotkeysListener from "@components/GlobalHotkeysListener";
import { RichTextComponentProvider } from "@components/RichTextComponentContext";
import RightBar from "@components/RightBar";
import { updateElement } from "@editor/actions/core-actions";
import { ModalLayout } from "@editor/components/common/ModalLayout";
import DraggingCursorFrame from "@editor/components/DraggingCursorFrame";
import { RIGHT_BAR_WIDTH } from "@editor/components/editor/constants";
import { DebugLeftPanel } from "@editor/components/editor/debug/DebugPanel";
import Header from "@editor/components/header/Header";
import LeftBar from "@editor/components/LeftBar";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import { EditorPerformanceProvider } from "@editor/contexts/editor-performance.context";
import { useSubscriptionDetails } from "@editor/hooks/subscription";
import { useModal } from "@editor/hooks/useModal";
import { usePrefetchBillingInfoImmediately } from "@editor/hooks/usePrefetchImmediately";
import usePreventZoom from "@editor/hooks/usePreventZoom";
import { useDraftElementProductsIds } from "@editor/hooks/useStoreProducts";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import { useEditorDispatch, useEditorSelector } from "@editor/store";
import { isValidComponent } from "@editor/utils/component";
import { docs } from "@editor/utils/docs";
import { routes } from "@editor/utils/router";
import { handleTRPCClientError, trpcClient } from "@editor/utils/trpc";
import AIWelcomeModal from "@editorComponents/AIWelcomeModal";
import FigmaImportPanel from "@editorComponents/FigmaImportPanel";
import TemplateProductSelectorWrapper from "@editorComponents/TemplateProductSelector";
import useCurrentUser from "@hooks/useCurrentUser";
import useFetchMetafields from "@hooks/useFetchMetafields";
import useRightBarVisibility from "@hooks/useRightBarVisibility";
import { useSyncDraftElementWithRouter } from "@hooks/useSyncDraftElementWithRouter";
import { initProjectBasedAnalytics } from "@infra/analytics";
import { DragAndDropProvider } from "@providers/DragAndDropProvider";
import {
  resetHistory,
  selectInternalDebugModeOn as selectDevDebugPanelVisibility,
  selectDraftElementId,
  selectDraftElementType,
  selectElementIsLoading,
  selectProject,
  selectProjectId,
} from "@reducers/core-reducer";
import { selectIsModalOpen } from "@reducers/modals-reducer";
import {
  selectLeftBarActiveTab,
  selectLeftBarWidth,
} from "@reducers/ui-reducer";

// Importing the selector for left bar width

import { CanvasArea } from "@/features/canvas/Canvas";
import { CanvasControls } from "@/features/canvas/CanvasControls";
import Button from "@replo/design-system/components/button";
import { TRPCClientError } from "@trpc/client";
import values from "lodash-es/values";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { shallowEqual } from "react-redux";
import {
  Navigate,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { hasOwnProperty } from "replo-utils/lib/misc";
import { validate as isUUIDValid } from "uuid";

const PRODUCTS_LIMIT = 20;
const elementTypeToLabel: Record<ReploElement["type"], string> = {
  page: "page",
  shopifyArticle: "article",
  shopifyProductTemplate: "product template",
  shopifySection: "Shopify section",
};

export function EditorLayout() {
  const { projectId = "" } = useParams();

  // Note (Noah, 2024-08-18): JSS checks for this csp-nonce meta property any
  // time a stylesheet is created, memoizing the result. Adding in the nonce
  // here, even though it's a dummy value, means that JSS won't do an expensive
  // querySelector every time a new stylesheet is added, which would adversely
  // affect performance. See
  // https://github.com/cssinjs/jss/blob/901882a894c7e802450696ebe2ea633ae63c5977/packages/jss/src/DomRenderer.js#L219
  React.useEffect(() => {
    const nonce = document.createElement("meta");
    nonce.setAttribute("property", "csp-nonce");
    nonce.setAttribute("content", "replo-jss-nonce");
    document.head.append(nonce);
  }, []);

  if (!isUUIDValid(projectId)) {
    return <Navigate to={routes.dashboard} replace />;
  }

  return (
    <>
      <EditorApp />
      <Outlet />
    </>
  );
}

function EditorApp() {
  const { isLoading: isUserLoading, isAuthenticated } = useCurrentUser();
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useCurrentProjectContext();

  const zoomRef = usePreventZoom();
  const isDebugPanelVisible = useEditorSelector(selectDevDebugPanelVisibility);

  useFetchDraftElement();
  useSyncDraftElementWithRouter();
  useTrackGroupAnalytics();
  usePrefetchBillingInfoImmediately();

  const isUserNotAuthenticated = !isUserLoading && !isAuthenticated;

  const projectNotFound = Boolean(
    !isProjectLoading && projectError?.meta.response?.status == 404,
  );

  /**
   * If we've tried to load the store, and it's a permission-denied error, then
   * we should show the 'no-access' screen
   */
  const noAccessToProject =
    !isProjectLoading && projectError?.meta.response?.status == 403;

  React.useEffect(() => {
    const modeSuffix =
      window.__BUILD_MODE__ === "development"
        ? " - Replo Dev Mode"
        : " - Replo";
    document.title = `${project?.name ?? "Dashboard"}${modeSuffix}`;
  }, [project]);

  if (isUserNotAuthenticated) {
    return <LoginModal />;
  }

  if (projectNotFound) {
    errorToast(
      "Store Not Found",
      "Please try again or reach out to support@replo.app for help.",
    );
    return <Navigate to={routes.dashboard} replace />;
  }

  return (
    <div id="app" className="andytownApp h-screen w-full bg-[#f0f0f0]">
      <SupportChatBubbleStyles />
      {noAccessToProject ? (
        <EditorNoAccessToProjectScreen />
      ) : (
        <EditorPerformanceProvider>
          <DndProvider backend={HTML5Backend} context={window}>
            <DragAndDropProvider>
              <MetafieldsAutoFetcher />
              <QuickStartNavigationListener />
              {isUserLoading || isProjectLoading ? <FullScreenLoader /> : null}
              <RichTextComponentProvider>
                <GlobalHotkeysListener />
                <ToastWrapper />
                {!isProjectLoading && <CanvasWrapper />}
                <DraggingCursorFrame />
                <div
                  className="flex h-full w-full flex-col overflow-hidden"
                  style={{ zIndex: 2_147_483_649 }}
                  ref={zoomRef}
                >
                  <Header />
                  <div className="flex w-full flex-1 overflow-hidden">
                    {isDebugPanelVisible ? <DebugLeftPanel /> : null}
                    <LeftBar />
                    <RightBar />
                    <CanvasControls />
                    <TemplateProductSelectorWrapper />
                    <FigmaImportPanel />
                    <GlobalModals />
                    <AIWelcomeModal />
                  </div>
                </div>
              </RichTextComponentProvider>
            </DragAndDropProvider>
          </DndProvider>
        </EditorPerformanceProvider>
      )}
    </div>
  );
}

function QuickStartNavigationListener() {
  const { isLoading: isUserLoading } = useCurrentUser();
  const {
    project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useCurrentProjectContext();
  const projectId = project?.id;
  const isElementLoading = useEditorSelector(selectElementIsLoading);
  const draftElementId = useEditorSelector(selectDraftElementId);
  const isZeroElements =
    !isProjectLoading && values(project?.elements).length === 0;

  // Note (Sebas, 2023-03-09): In case there is a new customer page,
  // draftElementId is going to have an undefined value, so we need to check if
  // there are no elements on the page to open the addElementTemplateModal.
  // REPL-6582
  const isCanvasLoading = !draftElementId && !isZeroElements;
  const isEditorLoading = isUserLoading || isProjectLoading || isCanvasLoading;
  const isEditorOrElementsLoading = isEditorLoading || isElementLoading;
  const projectNotFound = Boolean(
    !isProjectLoading &&
      projectError &&
      hasOwnProperty(projectError, "status") &&
      projectError.status == 404,
  );

  useQuickStartNavigation(projectId, {
    draftElementId,
    isZeroElements,
    isLoading: isEditorOrElementsLoading,
    projectNotFound,
  });

  return null;
}

function LoginModal() {
  const navigate = useNavigate();

  return (
    <div id="app" className="andytownApp h-screen w-full bg-blue-200">
      <Modal onRequestClose={() => null} isOpen={true} className="w-96">
        <div className="flex flex-col items-start rounded-lg bg-white p-8">
          <p className="text-base font-medium">
            Your Replo Session has Expired. Please Log In again.
          </p>
          <p className="pt-4 text-slate-400" style={{ maxWidth: "400px" }}>
            Please note that Replo cannot edit pages in Private or Incognito
            browser sessions. If using private browsing, please try again
            without private browsing.
          </p>
          <div className="mt-8 flex w-full items-center justify-end">
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                navigate("/auth/login");
              }}
            >
              Log In
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// Wrapper components to prevent unnecessary re-renders on EditorApp
function ToastWrapper() {
  const isRightBarVisible = useRightBarVisibility();
  const rightOffset = isRightBarVisible ? RIGHT_BAR_WIDTH : 0;
  return (
    <ToastManager
      containerStyle={rightOffset ? { right: rightOffset } : undefined}
    />
  );
}

function CanvasWrapper() {
  const projectId = useEditorSelector(selectProjectId);
  const elementId = useEditorSelector(selectDraftElementId);
  const isCanvasVisible = Boolean(projectId) && Boolean(elementId);
  return isCanvasVisible ? <CanvasArea /> : null;
}

/**
 * Component which registers hooks to auto-fetch metafield values when needed and
 * returns nothing. This is in its own component rather than in EditorApp because
 * we don't want changes in the products which we need to fetch metafields for to
 * have to rerender the entire React tree
 */
function MetafieldsAutoFetcher() {
  useFetchMetafields({ type: "variant" });
  useFetchMetafields({ type: "product" });
  return null;
}

function GlobalModals() {
  return <TooManyProductsModalWrapper />;
}

function TooManyProductsModalWrapper() {
  const elementType = useEditorSelector(selectDraftElementType);
  const readableElementType =
    elementTypeToLabel[elementType] ?? elementTypeToLabel["page"];
  const productIds = useDraftElementProductsIds();
  const productsCount = new Set(productIds.map((id) => String(id))).size;
  const [modalAlreadyShown, setModalAlreadyShown] = React.useState(false);
  const isOverLimit = productsCount > PRODUCTS_LIMIT;

  return (
    isOverLimit &&
    !modalAlreadyShown && (
      <Modal
        isOpen
        onRequestClose={() => {
          setModalAlreadyShown(true);
        }}
        className="w-[320px]"
      >
        <ModalLayout
          width="100%"
          height="auto"
          mainContent={() => (
            <div className="flex flex-col gap-4">
              <h1 className="text-lg">Too many Shopify products!</h1>
              <div className="flex flex-col gap-1">
                <p className="text-xs text-slate-400">
                  {productsCount}/{PRODUCTS_LIMIT} referenced products
                </p>
                <p className="text-sm text-gray-700">
                  Your {readableElementType} references more than{" "}
                  {PRODUCTS_LIMIT} products, which is a Shopify limit. You’ll
                  have to remove a product in order to publish successfully.{" "}
                  <a
                    href={docs.shopifyMaxProductsOnPage}
                    className="text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More &raquo;
                  </a>
                </p>
              </div>
              <div className="flex items-center flex-grow w-full justify-end">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    setModalAlreadyShown(true);
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          )}
        />
      </Modal>
    )
  );
}

function useFetchDraftElement() {
  const dispatch = useEditorDispatch();
  const params = useParams();
  const { openModal } = useModal();
  const draftElementId = useEditorSelector(selectDraftElementId);
  const projectId = useEditorSelector(selectProjectId);
  const elementIdExists = Boolean(draftElementId) || Boolean(params.elementId);
  const shouldSkip = !elementIdExists || projectId !== params.projectId;
  const elementIdParam = draftElementId ?? params.elementId;

  const fetchElementData = React.useCallback(async () => {
    if (shouldSkip) {
      return;
    }
    try {
      const data = await trpcClient.element.getById.query(elementIdParam);
      if (data) {
        dispatch(updateElement(data.element.id, data.element));
        // Note (Noah, 2023-12-11): If we had a componentId= param, set
        // it as the initial component id for debugging purposes (e.g.
        // when DEQ generates a link to a specific component we have to fix)
        const params = new URLSearchParams(window.location.search);
        const initialComponentId = params.get("componentId");
        if (initialComponentId) {
          // Note (Noah, 2023-12-11): dispatch here is not the same typing
          // as the dispatch from useEditorDispatch so it doesn't understand
          // that setDraftElement is a valid action. Unclear as to how to get
          // this to work, so just expecting the error for now
          // @ts-expect-error
          dispatch(setDraftElement({ componentId: initialComponentId }));
        }
        const isNotValidComponent =
          data.element.component && !isValidComponent(data.element.component);

        if (isNotValidComponent) {
          openModal({
            type: "fullPageErrorModal",
            props: {
              details: {
                header: "Page misconfigured",
                message:
                  "Oops! The content of this page was unexpectedly empty. This usually means something went wrong when creating the page. Please contact support@replo.app to help resolve this issue.",
                isFullPage: true,
              },
            },
          });
        }
      }
    } catch (error) {
      if (error instanceof TRPCClientError) {
        // NOTE (Sebas, 2024-08-15): In this case we are not using `trpc` but the `trpcClient` directly
        // so we need to handle TRPC errors manually.
        handleTRPCClientError(error);
      }
    }
  }, [dispatch, elementIdParam, shouldSkip, openModal]);

  React.useEffect(() => {
    void fetchElementData();
  }, [fetchElementData]);

  React.useEffect(() => {
    if (draftElementId) {
      dispatch(resetHistory());
    }
  }, [draftElementId, dispatch]);
}

function useTrackGroupAnalytics() {
  const { user } = useCurrentUser();
  const project = useEditorSelector(selectProject, shallowEqual);
  const { data: subscriptionDetails } = useSubscriptionDetails();

  React.useEffect(() => {
    if (project && user) {
      initProjectBasedAnalytics(project, subscriptionDetails ?? undefined);
    }
  }, [project, user, subscriptionDetails]);
}

function useQuickStartNavigation(
  projectId: string | undefined,
  {
    draftElementId,
    isZeroElements,
    isLoading,
    projectNotFound,
  }: {
    draftElementId: string | undefined;
    isZeroElements: boolean;
    isLoading: boolean;
    projectNotFound: boolean;
  },
) {
  // NOTE (Chance 2023-12-12, USE-603): Previously there was a bug where the add
  // modal navigation would kick off when the user selected the "Start From
  // Template" option, which navigates to the relative marketplace route. This
  // means that the user couldn't actually access the marketplace options before
  // being redirected back from where they started. To prevent this, I am
  // checking to make sure the user is not on the marketplace route sub-path
  // before navigating.
  const addElementModalPath = `/editor/${projectId}/add`;
  const isAddElementFromMarketplaceModalOpen = location.pathname.startsWith(
    `${addElementModalPath}/${routes.marketplaceModal}`,
  );

  const quickStartTour = useMatch(`${routes.editor.element}/add`);
  const quickStartTourWithoutElements = useMatch(
    `${routes.editor.project}/add`,
  );

  const isCreateElementModalOpen = useEditorSelector((state) =>
    selectIsModalOpen(state, "createElementModal"),
  );
  const shouldNavigate =
    !isLoading &&
    !isAddElementFromMarketplaceModalOpen &&
    !(quickStartTour || quickStartTourWithoutElements) &&
    !draftElementId &&
    isZeroElements &&
    !isCreateElementModalOpen;

  const navigate = useNavigate();
  const navigatedForStore = React.useRef<string>();

  React.useEffect(() => {
    if (!projectId) {
      return;
    }
    if (!shouldNavigate) {
      return;
    }
    if (navigatedForStore.current !== projectId && !projectNotFound) {
      navigatedForStore.current = projectId;
      navigate(`${addElementModalPath}?type=page`);
    }
  }, [
    addElementModalPath,
    navigate,
    projectId,
    projectNotFound,
    shouldNavigate,
  ]);
}

const SupportChatBubbleStyles = () => {
  const isLeftBarOpened = Boolean(useEditorSelector(selectLeftBarActiveTab));
  const leftBarWidth = useEditorSelector(selectLeftBarWidth); // Fetching the current width of the left bar
  const isNewLeftBarEnabled = checkIfNewEditorPanelsUIIsEnabled();

  const supportChatPosition = isLeftBarOpened ? leftBarWidth + 51 : 51;

  const editorStyles = `
    .PylonChat-bubbleFrameContainer {
      position: fixed;
      z-index: 2147480002;
      bottom: 16px;
      left: ${isNewLeftBarEnabled ? supportChatPosition : leftBarWidth}px;
    }

    .PylonChat-chatWindowFrameContainer {
      bottom: 76px !important;
      left: ${(isNewLeftBarEnabled ? supportChatPosition : leftBarWidth) + 15}px !important;
      right: 0px;
      transform-origin: bottom left !important;
    }
  `;

  return <style dangerouslySetInnerHTML={{ __html: editorStyles }} />;
};
