import type { RenderComponentProps } from "../../../shared/types";

import * as React from "react";

import { ReploComponent } from "../ReploComponent";

const Container: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
  context,
}) => {
  const { repeatedIndexPath = ".0" } = context;
  const accessibilityAttributes = getAccessibilityAttributes({
    role: component.props._accessibilityRole,
    hidden: component.props._accessibilityHidden,
    checked: component.props._accessibilityChecked,
    labelledBy: component.props._accessibilityLabelledBy,
  });

  return (
    <div
      {...componentAttributes}
      {...accessibilityAttributes}
      key={componentAttributes.key}
    >
      {component?.children?.map((child) => {
        return (
          <ReploComponent
            key={child.id}
            component={child}
            context={context}
            repeatedIndexPath={repeatedIndexPath}
          />
        );
      })}
    </div>
  );
};

export default Container;

interface AccessibilityConfig {
  role: string | undefined;
  hidden: boolean | undefined;
  checked: boolean | undefined;
  labelledBy: string | undefined;
}

const mapAccessibilityTypeToAttributes: Record<
  keyof AccessibilityConfig,
  string
> = {
  role: "role",
  hidden: "aria-hidden",
  checked: "aria-checked",
  labelledBy: "aria-labelledby",
};

const getAccessibilityAttributes = (
  accessibilityConfig: AccessibilityConfig,
) => {
  const accessibilityAttributes: Record<string, string | number | boolean> = {};
  for (const [key, value] of Object.entries(accessibilityConfig)) {
    if (value != null) {
      accessibilityAttributes[
        mapAccessibilityTypeToAttributes[key as keyof AccessibilityConfig]
      ] = value;
    }
  }

  if (accessibilityConfig.role === "switch") {
    accessibilityAttributes.tabIndex = 0;
  }

  return accessibilityAttributes;
};
