// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { OptionWithProductData } from "@editor/components/editor/page/ProductSelectionPopover";
import type { StoreProductSummary } from "schemas/product";

import * as React from "react";

import { useInfiniteStoreProductsSummary } from "@editor/hooks/useInfiniteStoreProducts";
import { productSummaryRequestLimits } from "@editor/hooks/useProductsSummary";
import { formatQueryWithIds } from "@editor/hooks/useStoreProducts";
import { imgOrPlaceholder } from "@editor/utils/image";
import { InfiniteList } from "@editorComponents/Lists";

import Tooltip from "@replo/design-system/components/tooltip";

export type ProductListType = "assigned" | "all" | "placeholder";
export const getProductSummaryListOptions = ({
  productSummaries,
  productListType,
  searchTerm,
}: {
  productSummaries: StoreProductSummary[];
  productListType: ProductListType;
  searchTerm?: string;
}): OptionWithProductData[] => {
  let filteredProductsSummaries = productSummaries;
  // Note (Evan, 2023-10-27): When the list is all products, we use an infinite list with
  // search on the backend, so no need to search client-side
  if (searchTerm && productListType !== "all") {
    filteredProductsSummaries = productSummaries.filter((productSummary) => {
      if (searchTerm === "") {
        return true;
      }
      const cleanedSearchTerm = searchTerm.trim().toLowerCase();
      if (productSummary.title.toLowerCase().includes(cleanedSearchTerm)) {
        return true;
      }
      if (String(productSummary.id).includes(cleanedSearchTerm)) {
        return true;
      }
      return false;
    });
  }
  return filteredProductsSummaries.map((productSummary) => ({
    productId: productSummary.id,
    title: productSummary.title,
    featuredImage: productSummary.featuredImage,
    defaultVariantId: productSummary.defaultVariantId,
    label: (
      <Tooltip
        content={`${productSummary.title} (${productSummary.id})`}
        triggerAsChild
      >
        <span className="truncate">{productSummary.title}</span>
      </Tooltip>
    ),
    value: productSummary.id,
    startEnhancer: imgOrPlaceholder(
      productSummary.featuredImage,
      "rounded-md h-5 w-5 object-contain shrink-0",
      "bg-gray-200",
    ),
  }));
};

export const useInfiniteProductSummaryListOptions = ({
  searchTerm,
}: {
  searchTerm: string;
}) => {
  const {
    fetchNextPage,
    products: productSummaries,
    pageInfo,
    isFetching: isNextPageLoading,
  } = useInfiniteStoreProductsSummary({
    pageSize: productSummaryRequestLimits.infinite,
    query: searchTerm,
    debounceDelay: 300,
  });

  const options = getProductSummaryListOptions({
    productSummaries,
    productListType: "all",
    searchTerm,
  });

  return {
    options,
    hasNextPage: pageInfo?.hasNextPage ?? false,
    isNextPageLoading,
    loadNextPage: fetchNextPage,
  };
};

export const InfiniteProductSummaryList: React.FC<{
  selectedProductIds: number[];
  onSelect: (productId: number) => void;
  isMultiselect: boolean;
  searchTerm?: string;
  itemSize?: number;
  itemsOnViewCount?: number;
}> = ({
  selectedProductIds,
  onSelect,
  isMultiselect,
  searchTerm,
  itemSize = 36,
  itemsOnViewCount,
}) => {
  // Note (Noah, 2024-02-21, REPL-10503): Save the initial value of selectedProductIds
  // when this component first renders because we want to fetch and show them at the
  // top of the list. Using a ref here means products won't jump automatically to the
  // top when you select them, but when the component rerenders you'll see them there
  // initially
  const initialSelectedProductIdsRef = React.useRef(selectedProductIds);
  const initialSelectedProductIdsQuery = formatQueryWithIds(
    initialSelectedProductIdsRef.current,
  );
  const {
    products: selectedProductsSummaries,
    fetchNextPage: fetchNextPageSelectedProducts,
    pageInfo: selectedProductsPageInfo,
    isFetching: isFetchingSelectedProductsNextPage,
  } = useInfiniteStoreProductsSummary({
    pageSize: productSummaryRequestLimits.infinite,
    query: [
      initialSelectedProductIdsQuery
        ? `(${initialSelectedProductIdsQuery})`
        : null,
      searchTerm ? `"${searchTerm}"` : null,
    ]
      .filter(Boolean)
      .join(" AND "),
    debounceDelay: 300,
  });

  const {
    products: productsSummaries,
    fetchNextPage,
    pageInfo,
    isFetching: isFetchingNextPage,
  } = useInfiniteStoreProductsSummary({
    pageSize: productSummaryRequestLimits.infinite,
    query: [
      initialSelectedProductIdsQuery
        ? `(NOT ${initialSelectedProductIdsQuery})`
        : null,
      searchTerm ? `"${searchTerm}"` : null,
    ]
      .filter(Boolean)
      .join(" AND "),
    debounceDelay: 300,
  });

  const allProductSummaries = selectedProductsPageInfo?.hasNextPage
    ? selectedProductsSummaries
    : selectedProductsSummaries.concat(productsSummaries);

  const options = getProductSummaryListOptions({
    productSummaries: allProductSummaries,
    productListType: "all",
    searchTerm,
  });

  const loadNextPage = selectedProductsPageInfo?.hasNextPage
    ? fetchNextPageSelectedProducts
    : fetchNextPage;
  const hasNextPage =
    (selectedProductsPageInfo?.hasNextPage || pageInfo?.hasNextPage) ?? false;
  const isNextPageLoading =
    isFetchingSelectedProductsNextPage || isFetchingNextPage;

  return (
    <InfiniteList
      options={options}
      selectedItems={selectedProductIds}
      onSelect={(value) => onSelect(Number(value))}
      settings={{
        loadNextPage,
        hasNextPage,
        isNextPageLoading,
      }}
      isMultiselect={isMultiselect}
      itemSize={itemSize}
      itemsOnViewCount={itemsOnViewCount}
      labelClassName="tracking-tight text-xs min-w-0"
      noItemsPlaceholder="No products found."
    />
  );
};
