import type { AnalyticsReadQueryWithoutUrlParams } from "@/features/analytics/constants";

import * as React from "react";

import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";

import { DEFAULT_QUERY } from "@/features/analytics/constants";
import { AnalyticsQueryContext } from "@/features/analytics/contexts/AnalyticsQueryContext";
import { useAnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import { useSearchParams } from "react-router-dom";

const AnalyticsContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const workspaceId = useCurrentWorkspaceId();
  const workspaceUrlHostsResult = useWorkspaceUrlHosts(workspaceId);

  const [query, setQuery] = React.useState<AnalyticsReadQueryWithoutUrlParams>({
    ...DEFAULT_QUERY,
  });

  const urlHosts = workspaceUrlHostsResult.data.map((host) => host.value);

  const { getAllParams, getParamValue, setParamValue, setDecompressedParams } =
    useAnalyticsUrlParams(urlHosts, searchParams, setSearchParams);

  const contextValues = {
    query,
    setQuery,
    workspaceUrlHosts: workspaceUrlHostsResult,
    getAllParams,
    getParamValue,
    setParamValue,
    setDecompressedParams,
    urlHosts,
  };

  return (
    <AnalyticsQueryContext.Provider value={contextValues}>
      {children}
    </AnalyticsQueryContext.Provider>
  );
};

export default AnalyticsContext;
