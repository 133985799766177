import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgHash = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 8 11" fill="none" {...props}>
    <path stroke="#94A3B8" d="m3 1-1.5 9M6 1l-1.5 9M.5 4h7M0 7h7" />
  </Svg>
);
export default SvgHash;
