import * as React from "react";

import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { isWindows } from "@editor/utils/platform";

import { flushSync } from "react-dom";
import { getAlchemyEditorWindow } from "replo-runtime/shared/Window";
import { clamp } from "replo-utils/lib/math";

import { handleTransformWillChange } from "./canvas-actions";
import { MAX_SCALE, MIN_SCALE } from "./canvas-constants";
import {
  selectCanvasArea,
  selectCanvasInteractionMode,
  selectCanvasScale,
  zoomCanvas,
} from "./canvas-reducer";
import { clientPosToTranslatedPos, scaleFromPoint } from "./canvas-utils";
import { useSetDeltaXY } from "./useSetDeltaXY";

export function useCanvasPortalPan() {
  const store = useEditorStore();
  const canvasArea = useEditorSelector(selectCanvasArea);
  const setDeltaXY = useSetDeltaXY();
  const dispatch = useEditorDispatch();

  const handleCanvasPortalPan = React.useCallback(
    (e: {
      clientX: number;
      clientY: number;
      deltaX: number;
      deltaY: number;
      ctrlKey: boolean;
      metaKey: boolean;
    }) => {
      const canvasScale = selectCanvasScale(store.getState());
      const canvasInteractionMode = selectCanvasInteractionMode(
        store.getState(),
      );
      // Note (Noah, 2022-08-04): We need to wrap this whole thing in
      // flushSync because React 18's state batching can cause the scale
      // calculation to work incorrectly (because each successive scale
      // operation depends on the last scale value, batching them causes
      // issues)
      flushSync(() => {
        if (canvasInteractionMode === "locked") {
          return;
        }

        // When holding down the command key, zoom in/out
        if (e.metaKey || e.ctrlKey) {
          dispatch(handleTransformWillChange());
          // Note (Sebas, 2023-04-04): In case the user is using Windows we
          // have to use a different scale factor to avoid zooming too fast
          // (which shows as a big jump).
          const scaleChange = isWindows()
            ? 2 ** (e.deltaY * (e.ctrlKey ? 0.0012 : 0.002))
            : 2 ** (e.deltaY * (e.ctrlKey ? 0.01 : 0.002));

          const newScale = clamp(
            canvasScale + (1 - scaleChange),
            MIN_SCALE,
            MAX_SCALE,
          );
          dispatch(zoomCanvas(newScale));
          setDeltaXY((prevState) => {
            if (!canvasArea) {
              return prevState;
            }

            const mousePos = clientPosToTranslatedPos(
              canvasArea,
              {
                x: e.clientX,
                y: e.clientY,
              },
              { x: prevState.deltaX, y: prevState.deltaY },
            );
            const editorWindow = getAlchemyEditorWindow();
            if (editorWindow) {
              editorWindow.alchemyEditor.canvasScale = newScale;
              editorWindow.alchemyEditor.canvasYOffset = prevState.deltaY;
            }
            return scaleFromPoint(
              canvasScale,
              newScale,
              prevState.deltaX,
              prevState.deltaY,
              mousePos,
            );
          });
        } else {
          dispatch(handleTransformWillChange());
          setDeltaXY((prevState) => {
            const editorWindow = getAlchemyEditorWindow();
            if (editorWindow) {
              editorWindow.alchemyEditor.canvasScale = canvasScale;
              editorWindow.alchemyEditor.canvasYOffset = prevState.deltaY;
            }
            return {
              deltaX: prevState.deltaX - e.deltaX,
              deltaY: prevState.deltaY - e.deltaY,
            };
          });
        }
      });
    },
    [canvasArea, store, dispatch, setDeltaXY],
  );

  React.useEffect(() => {
    const handleAndPreventCanvasPortalPan = (e: WheelEvent) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      handleCanvasPortalPan(e);
    };

    canvasArea?.addEventListener("wheel", handleAndPreventCanvasPortalPan);
    return () => {
      canvasArea?.removeEventListener("wheel", handleAndPreventCanvasPortalPan);
    };
  }, [canvasArea, handleCanvasPortalPan]);

  return handleCanvasPortalPan;
}
