import * as React from "react";

import { ToastManager } from "@common/designSystem/Toast";
import LogoBadge from "@common/LogoBadge";
import { useEditorSelector } from "@editor/store";
import useGetHeaderLogoRedirection from "@hooks/useGetHeaderLogoRedirection";
import { selectProjectOrStoreName } from "@reducers/core-reducer";
import { routes } from "@utils/router";

import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import { BsArrowLeft } from "react-icons/bs";
import {
  generatePath,
  Link,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";

const ProjectDashboardLayout: React.FC = () => {
  return (
    <div className="h-screen w-screen">
      <Header />
      {/*
        Note (Fran, 2023-09-26): we need to calculate the exact height of the content without the
        header to avoid unnecessary scrolls when the user adds more url inputs.
      */}
      <div className="flex" style={{ height: "calc(100vh - 60px)" }}>
        <Sidebar />
        <div className="px-10 py-8 w-full h-full overflow-y-scroll no-scrollbar">
          <Outlet />
          <ToastManager />
        </div>
      </div>
    </div>
  );
};

const Header: React.FC = () => {
  const projectOrStoreName = useEditorSelector(selectProjectOrStoreName);
  const redirectTo = useGetHeaderLogoRedirection();
  return (
    <div className="w-full h-[60px] flex items-center justify-center px-4 py-3 border-b border-gray-200">
      <Tooltip triggerAsChild key="logo" content="Back to Replo Dashboard">
        <Link
          to={redirectTo}
          className="flex flex-row items-center absolute left-4"
        >
          <span className="sr-only">Back to Replo Dashboard</span>
          <LogoBadge className="h-9 w-9" />
        </Link>
      </Tooltip>

      <div className="font-medium text-sm">{projectOrStoreName}</div>
    </div>
  );
};

const useSidebarRoutes = () => {
  const routes = React.useMemo(
    () => [
      {
        path: "/project-settings",
        label: "Project Settings",
      },
      {
        path: "/template-defaults",
        label: "Template Defaults",
      },
      {
        path: "/experiments",
        label: "Experiments",
      },
      {
        path: "/integrations",
        label: "Integrations",
      },
    ],
    [],
  );

  return routes;
};

const Sidebar: React.FC = () => {
  const sidebarRoutes = useSidebarRoutes();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const handleBackButton = () =>
    navigate(
      generatePath(routes.editor.project, {
        projectId,
      }),
    );
  return (
    <div className="w-60 h-full border-gray-100 border-r px-3 py-6">
      <div
        className="flex items-center px-2 py-2 gap-2 text-blue-600 text-sm font-medium cursor-pointer mb-6"
        onClick={handleBackButton}
      >
        <BsArrowLeft size={18} /> Back to Editor
      </div>
      <div className="flex flex-col gap-y-2">
        {sidebarRoutes.map((route) => {
          const storeEditorPath = generatePath(routes.editor.project, {
            projectId,
          });
          const routePath = `${storeEditorPath}${route.path}`;
          const isActive = location.pathname.startsWith(routePath);
          return (
            <SidebarRoute
              key={routePath}
              isActive={isActive}
              label={route.label}
              path={routePath}
            />
          );
        })}
      </div>
    </div>
  );
};

const SidebarRoute = ({
  label,
  path,
  isActive,
}: {
  label: string;
  path: string;
  isActive: boolean;
}) => {
  return (
    <Link
      className={classNames(
        "flex items-center px-3 py-2 h-8 text-sm font-medium cursor-pointer rounded",
        {
          "hover:bg-slate-50": !isActive,
          "bg-slate-100": isActive,
        },
      )}
      to={path}
    >
      {label}
    </Link>
  );
};

export default ProjectDashboardLayout;
