import { setMultipleSelectedIds } from "@editor/reducers/selection-reducer";
import { useEditorDispatch, useEditorSelector } from "@editor/store";

import { shallowEqual } from "react-redux";

export default function useSelection() {
  const selectedIds = useEditorSelector(
    (state) => state.selection.selectedIds,
    shallowEqual,
  );
  const lastSelectedId = useEditorSelector(
    (state) => state.selection.lastSelectedId,
  );
  const dispatch = useEditorDispatch();

  return {
    selectedIds,
    lastSelectedId,
    setMultipleSelectedIds: (selectedIds: string[]) =>
      dispatch(setMultipleSelectedIds(selectedIds)),
  };
}
