import type { FullPageErrorModalProps } from "@editor/components/AppModalTypes";

import * as React from "react";

import Modal from "@editor/components/common/designSystem/Modal";
import { useModal } from "@editor/hooks/useModal";
import { publisherApi } from "@editor/reducers/api-reducer";
import { useEditorDispatch } from "@editor/store";

import Button from "@replo/design-system/components/button";
import classNames from "classnames";
import isArray from "lodash-es/isArray";
import { useNavigate, useParams } from "react-router-dom";
import { exhaustiveSwitch } from "replo-utils/lib/misc";

import { ConnectShopifyIntegrationCta } from "./ConnectShopifyCallout";

export const FullPageErrorModal: React.FC<FullPageErrorModalProps> = ({
  details,
}) => {
  const { projectId } = useParams();
  const fullPageError = details;
  const dispatch = useEditorDispatch();
  const navigate = useNavigate();
  const { closeModal } = useModal();

  if (!fullPageError) {
    return null;
  }

  const { header, subheader, message, isRecoverable, callToAction } =
    fullPageError;

  const getCustomCallbackData = () => {
    if (callToAction?.type === "custom" && callToAction.callToActionType) {
      return exhaustiveSwitch({
        type: callToAction?.callToActionType,
      })({
        navigateToProjectEditor: () => ({
          name: "Go back to project",
          callback: () => {
            closeModal({ type: "fullPageErrorModal" });
            // NOTE (Sebas, 2024-03-12): We need to invalidate the project cache
            // to make sure we fetch the project again when navigating back to the
            // project to trigger a re-render of the canvas. Otherwise, the canvas
            // will not show.
            dispatch(publisherApi.util.invalidateTags(["project"]));
            navigate(`/editor/${projectId}`);
          },
        }),
        reload: () => null,
        installApp: () => null,
        goToHomepage: () => null,
        closeModal: () => null,
      });
    }
    return null;
  };

  const customCallbackData = getCustomCallbackData();
  const button = callToAction ? (
    exhaustiveSwitch(callToAction)({
      link: ({ to, name, target }) => (
        <Button
          className="mt-6"
          variant="primary"
          size="lg"
          href={to}
          target={target}
          isFullWidth={true}
          data-testid="refresh-editor-button"
        >
          {name}
        </Button>
      ),
      callback: ({ onClick, name }) => (
        <Button
          className="mt-6"
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={onClick}
        >
          {name}
        </Button>
      ),
      closeModal: ({ name }) => (
        <Button
          className="mt-6"
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={() => dispatch(closeModal({ type: "fullPageErrorModal" }))}
        >
          {name}
        </Button>
      ),
      installApp: () => (
        <ConnectShopifyIntegrationCta type="noShopifyErrorModal" />
      ),
      custom: () => (
        <Button
          className="mt-6"
          variant="primary"
          size="lg"
          isFullWidth={true}
          data-testid="refresh-editor-button"
          onClick={customCallbackData?.callback}
        >
          {customCallbackData?.name}
        </Button>
      ),
    })
  ) : (
    <Button
      className="mt-6"
      variant="primary"
      size="lg"
      isFullWidth={true}
      data-testid="refresh-editor-button"
      onClick={() => {
        window.location.reload();
      }}
    >
      Refresh Editor
    </Button>
  );

  const messages = isArray(message) ? message : [message];

  return (
    <Modal
      onRequestClose={() => closeModal({ type: "fullPageErrorModal" })}
      isOpen={true}
      style={{ width: 420 }}
    >
      <div className="flex flex-col items-start bg-white p-2 text-default">
        <div className="text-xl font-semibold text-default w-full">
          {header}
        </div>
        <div className="mt-4 gap-2 w-full">
          {subheader && (
            <div className="text-sm text-slate-400">{subheader}</div>
          )}
          {messages.map((msg) => (
            <p
              key={msg}
              className={classNames("text-sm", {
                "text-red-600": !isRecoverable,
              })}
            >
              {msg}
            </p>
          ))}
          {button}
        </div>
      </div>
    </Modal>
  );
};
