import type { BoxSide } from "replo-utils/lib/types";
import type { PreviewableProperty } from "schemas/preview";

import * as React from "react";

import LabeledControl from "@components/common/designSystem/LabeledControl";
import Selectable from "@components/common/designSystem/Selectable";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { useTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import {
  selectBottom,
  selectDraftComponentPositionKey,
  selectDraftElementHideDefaultHeader,
  selectDraftElementType,
  selectTop,
  selectTransform,
  selectZIndex,
} from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import { DraggingDirections, DraggingTypes } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import DocumentationInfoIcon from "@editorComponents/DocumentationInfoIcon";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier from "@editorModifiers/LengthInputModifier";
import Stepper from "@editorModifiers/Stepper";
import {
  isEmptyOrCssDefault,
  transformDefaultValues,
} from "@editorModifiers/utils";
import HeightIcon from "@svg/height";
import WidthIcon from "@svg/width";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { Badge } from "@replo/design-system/components/badge";
import Tooltip from "@replo/design-system/components/tooltip";
import capitalize from "lodash-es/capitalize";
import merge from "lodash-es/merge";
import {
  AiOutlineVerticalAlignBottom,
  AiOutlineVerticalAlignMiddle,
  AiOutlineVerticalAlignTop,
} from "react-icons/ai";
import { BsFront } from "react-icons/bs";
import { FiMinus } from "react-icons/fi";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { shopifyHeaderSelector } from "replo-runtime/store/utils/cssSelectors";
import { coerceNumberToString } from "replo-utils/lib/misc";

import { useCurrentPosition } from "./PositioningModifier";

/**
 * NOTE (Yuxin) We set a z-index here so that it stacks above other components which don't
 * have a z-index, but not an insanely high value so that it overlaps with things like the page header
 *
 * https://replohq.slack.com/archives/C02784LUZPD/p1668282852421849
 */
const FIXED_Z_INDEX = 1;

const menuOptions = [
  { label: "Reset", value: "0px" },
  { label: "12px", value: "12px" },
  { label: "24px", value: "24px" },
  { label: "32px", value: "32px" },
];

const createPositioningOptions = (isStickyToHeaderDisabled: boolean) => [
  {
    value: "relative",
    label: getCursorLabel("Default", <FiMinus size={10} />),
  },
  {
    value: "absolute",
    label: getCursorLabel(
      "Relative to Container",
      <span style={{ fontSize: 10 }}>A</span>,
    ),
  },
  {
    value: "fixed",
    label: getCursorLabel("Fixed", <span style={{ fontSize: 10 }}>F</span>),
  },
  {
    value: "sticky",
    label: getCursorLabel("Sticky", <span style={{ fontSize: 10 }}>S</span>),
  },
  {
    value: "__alchemy:stickyToHeader",
    label: getCursorLabel(
      "Sticky to Header",
      <span style={{ fontSize: 10 }}>S</span>,
    ),
    isDisabled: isStickyToHeaderDisabled,
  },
];

const DeprecatedPositioningModifier: React.FC = () => {
  const position = useCurrentPosition();
  const zIndex = useEditorSelector(selectZIndex);
  return (
    <ModifierGroup
      title="Positioning"
      titleEnhancer={<DocumentationInfoIcon documentationType="positioning" />}
      isDefaultOpen={
        !isEmptyOrCssDefault(
          position,
          styleAttributeToEditorData.position.defaultValue,
        ) ||
        !isEmptyOrCssDefault(
          coerceNumberToString(zIndex),
          styleAttributeToEditorData.zIndex.defaultValue,
        )
      }
    >
      <div className="flex flex-col gap-2">
        <PositionSelectable />
        <PositionConditionalControls />
      </div>
    </ModifierGroup>
  );
};

const PositionSelectable = () => {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const draftElementHideDefaultHeader = useEditorSelector(
    selectDraftElementHideDefaultHeader,
  );
  const applyComponentAction = useApplyComponentAction();
  const currentPosition = useCurrentPosition();
  const zIndex = useEditorSelector(selectZIndex);
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const targetFrameDocument = useTargetFrameDocument(activeCanvas);

  const shopifySectionHeader = targetFrameDocument?.querySelector(
    shopifyHeaderSelector,
  );
  const isShopifySectionHeaderFixed =
    shopifySectionHeader &&
    getComputedStyle(shopifySectionHeader).position === "fixed";

  const shouldShowStickyToHeader =
    draftElementType === "page" &&
    !draftElementHideDefaultHeader &&
    !isShopifySectionHeaderFixed;

  // Note (Sebas, 2022-10-19): This is necessary to reset the
  // transform translate properties for centered relative
  // to container components
  const translateResetValue = {
    __transform: {
      translateY: "0px",
      translateX: "0px",
    },
  };
  // Note (Sebas, 2022-10-20): When we change between default/absolute
  // we need to reset the flexbox modifier values to show the correct
  // option on the toggle group
  const getFlexboxModifierResetValue = () => {
    return { alignSelf: "auto", flexGrow: "unset" };
  };

  return (
    <Selectable
      className="w-full"
      value={currentPosition}
      options={createPositioningOptions(!shouldShowStickyToHeader)}
      onSelect={(value) => {
        switch (value) {
          case "relative":
            applyComponentAction({
              type: "setStyles",
              value: {
                position: value,
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "auto",
                ...translateResetValue,
                ...getFlexboxModifierResetValue(),
              },
            });
            break;

          case "fixed":
            applyComponentAction({
              type: "setStyles",
              value: {
                position: value,
                top: "0px",
                left: "0px",
                right: "auto",
                bottom: "auto",
                ...translateResetValue,
                ...getFlexboxModifierResetValue(),
              },
            });
            break;

          case "sticky":
            applyComponentAction({
              type: "setStyles",
              value: {
                position: value,
                top: "0px",
                bottom: "initial",
                left: "auto",
                right: "auto",
                zIndex: zIndex ?? FIXED_Z_INDEX,
                ...translateResetValue,
              },
            });

            break;

          case "absolute":
            applyComponentAction({
              type: "setStyles",
              value: {
                zIndex: zIndex ?? FIXED_Z_INDEX,
                position: value,
                top: "0px",
                left: "0px",
                right: "auto",
                bottom: "auto",
                ...translateResetValue,
                ...getFlexboxModifierResetValue(),
              },
            });

            break;

          case "__alchemy:stickyToHeader":
            applyComponentAction({
              type: "setStyles",
              value: {
                position: value,
                top: "0px",
                left: "auto",
                right: "auto",
                bottom: "auto",
                ...translateResetValue,
              },
            });
            break;

          default:
            return;
        }
      }}
    />
  );
};

const PositionConditionalControls: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const value = useCurrentPosition();
  const topValue = useEditorSelector(selectTop);
  const bottomValue = useEditorSelector(selectBottom);
  if (value === "relative") {
    return <ZIndexControl />;
  }

  if (value === "__alchemy:stickyToHeader") {
    return <StickyToHeaderPositioningModifier />;
  }

  if (value === "sticky") {
    const getStickyValue = () => {
      if (topValue && topValue !== "initial") {
        return "top";
      }
      if (bottomValue && bottomValue !== "initial") {
        return "bottom";
      }
      return "top";
    };
    const stickyValue = getStickyValue();
    return (
      <>
        <div className="flex gap-2">
          <LabeledControl
            label="Sticky to"
            containerClassName="w-1/2"
            size="sm"
          >
            <Selectable
              className="w-full"
              value={stickyValue}
              options={[
                {
                  value: "top",
                  label: getCursorLabel(
                    "Top",
                    <span style={{ fontSize: 10 }}>S</span>,
                  ),
                },
                {
                  value: "bottom",
                  label: getCursorLabel(
                    "Bottom",
                    <span style={{ fontSize: 10 }}>S</span>,
                  ),
                },
              ]}
              onSelect={(value: "top" | "bottom") => {
                const propToReset = value === "top" ? "bottom" : "top";
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    [value]: "0px",
                    [propToReset]: "initial",
                  },
                });
              }}
            />
          </LabeledControl>
          <LabeledControl
            label={`Offset from ${capitalize(stickyValue)}`}
            containerClassName="w-1/2"
            size="sm"
          >
            <LengthInputModifier
              draggingType={DraggingTypes.Vertical}
              draggingDirection={DraggingDirections.Positive}
              anchorValue="0px"
              // Note (Noah, 2024-05-04, USE-917): This modifier's resetValue is not the
              // same as the CSS default value, since having no "top" or "bottom" breaks
              // position: sticky, so we never want the user to remove this value
              resetValue="0px"
              allowsNegativeValue={true}
              menuOptions={menuOptions}
              placeholder="0px"
              key={`style.${stickyValue}`}
              field={`style.${stickyValue}`}
              startEnhancer={() => <HeightIcon />}
              metrics={CSS_LENGTH_TYPES}
              onChange={(v: any) => {
                applyComponentAction({
                  type: "setStyles",
                  value: { [stickyValue]: v },
                });
              }}
            />
          </LabeledControl>
        </div>
        <ZIndexControl />
      </>
    );
  }

  return (
    <>
      {value !== "sticky" && <PresetSpacingToggles />}
      <PositionSpacingControl />
      <ZIndexControl />
    </>
  );
};

const ZIndexControl = () => {
  const value = coerceNumberToString(useEditorSelector(selectZIndex)) ?? "0";
  const applyComponentAction = useApplyComponentAction();

  const onChange = (newValue: number) => {
    applyComponentAction({
      type: "setStyles",
      value: {
        zIndex: String(Number.isInteger(newValue) ? newValue : 0),
      },
    });
  };
  const valueAsNumber = Number.isInteger(Number.parseInt(value, 10))
    ? Number.parseInt(value, 10)
    : 0;

  return (
    <LabeledControl label="Z Index" size="sm">
      <Stepper
        value={valueAsNumber}
        onChange={onChange}
        startEnhancer={() => (
          <Tooltip
            inheritCursor
            content="Adjust Order (front/back)"
            triggerAsChild
          >
            <span tabIndex={0}>
              <BsFront size={12} />
            </span>
          </Tooltip>
        )}
        field="style.zIndex"
        testIdMinus="zIndex-minus"
        testIdPlus="zIndex-plus"
      />
    </LabeledControl>
  );
};

const StickyToHeaderPositioningModifier = () => {
  const applyComponentAction = useApplyComponentAction();
  return (
    <LabeledControl label="Offset From Header" size="sm">
      <LengthInputModifier
        draggingType={DraggingTypes.Vertical}
        draggingDirection={DraggingDirections.Positive}
        anchorValue="0px"
        // Note (Noah, 2024-05-04, USE-917): This modifier's resetValue is not the
        // same as the CSS default value, since having no "top" or "bottom" breaks
        // position: sticky, so we never want the user to remove this value
        resetValue="0px"
        allowsNegativeValue={true}
        menuOptions={menuOptions}
        placeholder="None"
        key="style.top"
        field="style.top"
        startEnhancer={() => <HeightIcon />}
        metrics={CSS_LENGTH_TYPES}
        onChange={(v: any) => {
          applyComponentAction({
            type: "setStyles",
            value: { top: v },
          });
        }}
      />
    </LabeledControl>
  );
};

const PresetSpacingToggles = () => {
  const iconSize = 14;
  const applyComponentAction = useApplyComponentAction();
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentPositionKey,
  );
  const transformValues = useEditorSelector(selectTransform);

  const onClickPresetSpacingArrangement = (
    orientation: BoxSide & "center",
    positionSelector: "vertical" | "horizontal",
  ) => {
    let newValues = {};
    const translateX = transformValues?.translateX ?? "0px";
    const translateY = transformValues?.translateY ?? "0px";
    if (positionSelector === "vertical") {
      let newVerticalValues = {
        top: styleAttributeToEditorData.top.defaultValue,
        bottom: styleAttributeToEditorData.bottom.defaultValue,
        __transform: {
          ...transformDefaultValues,
          translateX,
          translateY,
        },
      };
      if (orientation === "center") {
        newVerticalValues = merge({}, newVerticalValues, {
          top: "50%",
          bottom: "auto",
          __transform: {
            translateY: "-50%",
          },
        });
      } else {
        newVerticalValues = merge({}, newVerticalValues, {
          [orientation as "top" | "bottom"]: "0px",
          __transform: {
            translateY: "0px",
          },
        });
      }
      newValues = newVerticalValues;
    }
    if (positionSelector === "horizontal") {
      let newHorizontalValues = {
        left: styleAttributeToEditorData.top.defaultValue,
        right: styleAttributeToEditorData.bottom.defaultValue,
        __transform: {
          ...transformDefaultValues,
          translateX,
          translateY,
        },
      };
      if (orientation === "center") {
        newHorizontalValues = merge({}, newHorizontalValues, {
          left: "50%",
          right: "auto",
          __transform: {
            translateX: "-50%",
          },
        });
      } else {
        newHorizontalValues = merge({}, newHorizontalValues, {
          [orientation as "left" | "right"]: "0px",
          __transform: {
            translateX: "0px",
          },
        });
      }
      newValues = newHorizontalValues;
    }

    applyComponentAction({
      type: "setStyles",
      value: newValues,
    });
  };

  return (
    <div className="flex gap-2">
      <Selectable
        className="w-full"
        value={verticalPosition}
        options={[
          {
            value: "top",
            label: getCursorLabel(
              "Top",
              <AiOutlineVerticalAlignTop size={iconSize} />,
            ),
          },
          {
            value: "center",
            label: getCursorLabel(
              "Center",
              <AiOutlineVerticalAlignMiddle size={iconSize} />,
            ),
          },
          {
            value: "bottom",
            label: getCursorLabel(
              "Bottom",
              <AiOutlineVerticalAlignBottom size={iconSize} />,
            ),
          },
        ]}
        onSelect={(value: BoxSide & "center") =>
          onClickPresetSpacingArrangement(value, "vertical")
        }
      />
      <Selectable
        className="w-full"
        value={horizontalPosition}
        options={[
          {
            value: "left",
            label: getCursorLabel(
              "Left",
              <AiOutlineVerticalAlignTop
                size={iconSize}
                className="-rotate-90"
              />,
            ),
          },
          {
            value: "center",
            label: getCursorLabel(
              "Center",
              <AiOutlineVerticalAlignMiddle
                size={iconSize}
                className="rotate-90"
              />,
            ),
          },
          {
            value: "right",
            label: getCursorLabel(
              "Right",
              <AiOutlineVerticalAlignTop
                size={iconSize}
                className="rotate-90"
              />,
            ),
          },
        ]}
        onSelect={(value: BoxSide & "center") =>
          onClickPresetSpacingArrangement(value, "horizontal")
        }
      />
    </div>
  );
};

const PositionSpacingControl = () => {
  const applyComponentAction = useApplyComponentAction();
  const currentPosition = useCurrentPosition();
  const [verticalPosition, horizontalPosition] = useEditorSelector(
    selectDraftComponentPositionKey,
  );
  const verticalPositionDefaultValue =
    verticalPosition && verticalPosition !== "center"
      ? styleAttributeToEditorData[verticalPosition]?.defaultValue
      : undefined;
  const horizontalPositionDefaultValue =
    horizontalPosition && horizontalPosition !== "center"
      ? styleAttributeToEditorData[horizontalPosition]?.defaultValue
      : undefined;

  const horizontalPositionLabel =
    horizontalPosition === "center" ? "X" : capitalize(horizontalPosition);
  const verticalPositionLabel =
    verticalPosition === "center" ? "Y" : capitalize(verticalPosition);
  return (
    <>
      <div className="flex flex-row gap-x-2">
        <LabeledControl label={`${verticalPositionLabel} Offset`} size="sm">
          <LengthInputModifier
            placeholder="auto"
            draggingType={DraggingTypes.Vertical}
            draggingDirection={DraggingDirections.Positive}
            field={`style.${verticalPosition}`}
            anchorValue="0px"
            resetValue={verticalPositionDefaultValue}
            allowsNegativeValue={true}
            startEnhancer={() => <HeightIcon />}
            metrics={CSS_LENGTH_TYPES}
            onChange={(v: any) => {
              applyComponentAction({
                type: "setStyles",
                value: {
                  [verticalPosition as string]: v,
                  // Note (Sebas, 2022-06-20, REPL-2261): We need to always set the position because if not,
                  // the values top/left don't work.
                  position: currentPosition,
                },
              });
            }}
            previewProperty={`${verticalPosition}Offset` as PreviewableProperty}
            menuOptions={menuOptions}
            isDisabled={verticalPosition === "center"}
          />
        </LabeledControl>
        {currentPosition !== "sticky" && (
          <LabeledControl label={`${horizontalPositionLabel} Offset`} size="sm">
            <LengthInputModifier
              placeholder="auto"
              field={`style.${horizontalPosition}`}
              draggingType={DraggingTypes.Vertical}
              draggingDirection={DraggingDirections.Positive}
              allowsNegativeValue={true}
              anchorValue="0px"
              resetValue={horizontalPositionDefaultValue}
              startEnhancer={() => <WidthIcon />}
              metrics={CSS_LENGTH_TYPES}
              onChange={(v: any) => {
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    [horizontalPosition as string]: v,
                    // Note (Sebas, 2022-06-20, REPL-2261): We need to always set the position because if not,
                    // the values top/left don't work.
                    position: currentPosition,
                  },
                });
              }}
              previewProperty={
                `${horizontalPosition}Offset` as PreviewableProperty
              }
              menuOptions={menuOptions}
              isDisabled={horizontalPosition === "center"}
            />
          </LabeledControl>
        )}
      </div>
    </>
  );
};

function getCursorLabel(text: string, label: React.ReactNode) {
  return (
    <span className="flex items-center text-xs w-full gap-2">
      <Tooltip content="Control Positioning (CSS)" triggerAsChild>
        <div tabIndex={0}>
          <Badge
            type="icon"
            icon={label}
            className="h-[18px] w-[18px] bg-accent text-white"
          />
        </div>
      </Tooltip>
      {text}
    </span>
  );
}

export default DeprecatedPositioningModifier;
