import * as React from "react";

import Avatar from "@common/designSystem/Avatar";

import classNames from "classnames";

type CardProps = {
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
  isActive?: boolean;
  title: string;
  date: string;
  userName?: string;
  children?: never;
};

export default React.forwardRef<HTMLDivElement, CardProps>(function VersionCard(
  { className, isActive, onClick, userName, title, date }: CardProps,
  ref,
) {
  const defaultClassNames = classNames(
    "flex flex-col px-2 py-3 !ring-inset hover:!ring-1 hover:!ring-blue-600 cursor-pointer w-full space-y-1",
    isActive ? "bg-blue-100 pointer-events-none" : "bg-white",
    className,
  );

  return (
    <div className={defaultClassNames} onClick={onClick} ref={ref}>
      <h3 className="truncate text-xs font-medium text-default">{title}</h3>
      <span className="text-xs font-normal text-slate-400">{date}</span>
      <div className="flex items-center">
        <Avatar name={userName} className="mr-1" size="xs" />
        <span className="text-xs font-normal text-slate-400">{userName}</span>
      </div>
    </div>
  );
});
