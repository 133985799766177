import type { SimpleExperiment } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import type {
  AnalyticsReadQuery,
  RangeResult,
} from "schemas/generated/analyticsRead";
import type { Experiment } from "schemas/generated/experiment";

import * as React from "react";

import MetricWithDelta from "@editor/components/analytics/MetricWithDelta";
import { Loader } from "@editor/components/common/Loader";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import {
  DEFAULT_FILTERS,
  DEFAULT_METRICS,
} from "@/features/analytics/constants";
import useWorkspaceUrlHosts from "@/features/analytics/useWorkspaceUrlHosts";
import { ExperimentActionsBar } from "@/features/experiments/components/ExperimentActionsBar";
import { ExperimentsLayout } from "@/features/experiments/Layout";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { TabMenu } from "@/features/experiments/tabs/TabMenu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@replo/design-system/components/shadcn/core/table";
import Tooltip from "@replo/design-system/components/tooltip";
import { skipToken } from "@tanstack/react-query";
import { endOfDay, startOfDay } from "date-fns";
import { BsDash } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";
import { convertMsToDays } from "replo-utils/lib/datetime";
import { ConditionOperatorEnum } from "schemas/analyticsRead";
import { getExperimentStatus } from "schemas/experiment";
import { twMerge } from "tailwind-merge";

const TABLE_METRICS: {
  label: string;
  key: string;
}[] = [
  { label: "Conversion Rate", key: "conversion_rates" },
  { label: "Sessions", key: "unique_sessions" },
  { label: "Average Order Value", key: "average_order_value" },
  { label: "Revenue per Session", key: "revenue_per_session" },
];

type ValueContainerProps = {
  className?: string;
  children: React.ReactNode;
};

const ValuesContainer: React.FC<ValueContainerProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={twMerge(
        "col-span-6 border border-subtle p-4 rounded-md flex flex-row gap-8",
        className,
      )}
    >
      {children}
    </div>
  );
};

type TitleWithValueProps = {
  title: string;
  value: string | null;
};

const TitleWithValue: React.FC<TitleWithValueProps> = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2 text-xs text-default">
      <span className="font-normal">{title}</span>
      <span className="font-semibold">
        {value !== null ? value : <BsDash />}
      </span>
    </div>
  );
};

const DEFAULT_QUERY_MAIN_RANGE_ID = crypto.randomUUID();

const useAnalyticsReadForExperiments = (
  experimentId: string,
  startDatetime: number,
  endDatetime: number,
  interval: number,
  workspaceId: string | null,
) => {
  const workspaceUrlHostsResult = useWorkspaceUrlHosts(workspaceId);
  const urlHosts = workspaceUrlHostsResult.data.map((host) => host.value);

  /**
   * NOTE (Max, 2024-10-17): We use a basic query where we get:
   * 1) urlHosts from the useWorkspaceUrlHosts hook
   * 2) mainRange datetimes from the Experiment's createdAt / endedAt dates
   * 3) filters using the experimentId: we're only interested in CH rows that contain
   * the experimentId as a urlParam
   */
  const query: AnalyticsReadQuery = {
    urlHosts,
    metrics: DEFAULT_METRICS,
    order: "DESC",
    sortMetric: "conversion_rates",
    offset: 0,
    limit: 10,
    ranges: {
      mainRange: {
        id: DEFAULT_QUERY_MAIN_RANGE_ID,
        startDatetime: startDatetime,
        endDatetime: endDatetime,
        interval,
      },
      compareAtRanges: [],
    },
    filters: {
      ...DEFAULT_FILTERS,
      urlParams: {
        "replo-experiment-id": [
          {
            operator: ConditionOperatorEnum.EQUALS,
            value: [`${experimentId}`],
          },
        ],
      },
    },
  };

  const shouldFetch =
    workspaceId && query.urlHosts && query.urlHosts.length > 0;

  const { data, isLoading } = trpc.analytics.find.useQuery(
    shouldFetch ? { query, workspaceId } : skipToken,
  );

  return {
    rangeResults: data?.rangeResults ?? null,
    isLoading,
  };
};

const ExperimentResultsTabInner: React.FC<{
  simpleExperiment: SimpleExperiment;
  workspaceId: string;
  experiment: Experiment | null;
}> = ({ simpleExperiment, experiment, workspaceId }) => {
  const {
    id,
    createdAt,
    createdAtHuman,
    endedAt,
    endedAtHuman,
    variations,
    name,
  } = simpleExperiment;

  const status = experiment ? getExperimentStatus(experiment) : null;

  const navigate = useNavigate();

  const winner = variations.find((variation) => variation.isWinner);

  const startDatetime = startOfDay(createdAt).valueOf();
  const endDatetime = endOfDay(endedAt ?? Date.now()).valueOf() + 1;
  const interval = endDatetime - startDatetime;

  const durationDays = convertMsToDays(interval);

  const { rangeResults, isLoading } = useAnalyticsReadForExperiments(
    id,
    startDatetime,
    endDatetime,
    interval,
    workspaceId ?? null,
  );

  const totalSessions = rangeResults
    ? rangeResults.reduce((total, rangeResult) => {
        const sessions = rangeResult.metrics.unique_sessions?.[0] || 0;
        return total + sessions;
      }, 0)
    : 0;

  const experimentResults = variations?.map((variation) => {
    const targetURL = new URL(variation.target);
    const urlPath = targetURL.pathname;

    const variationSlug = variation.slug;

    const associatedRangeResult: RangeResult | null =
      rangeResults?.find((rangeResult) => rangeResult.urlPath === urlPath) ??
      null;

    const metrics = associatedRangeResult?.metrics ?? {};

    return {
      urlPath,
      variationSlug,
      metrics,
    };
  });

  return (
    <ExperimentsLayout
      headerTitle={name}
      backNavigation={{
        label: "Back",
        onClick: () => {
          navigate(
            generatePath(routes.workspace.experiments.list, { workspaceId }),
          );
        },
      }}
      headerActions={
        experiment ? (
          <ExperimentActionsBar
            workspaceId={workspaceId}
            experiment={experiment}
          />
        ) : null
      }
      status={status ?? undefined}
      editable
    >
      <TabMenu />
      <div className="grid grid-cols-12 gap-5">
        <ValuesContainer className="col-span-6">
          <TitleWithValue title="Sessions" value={`${totalSessions}`} />
          <TitleWithValue
            title="Duration"
            value={`${durationDays} day${durationDays !== 1 ? "s" : ""}`}
          />
          <TitleWithValue title="Winner" value={winner?.slug ?? null} />
        </ValuesContainer>

        <ValuesContainer className="col-span-6">
          <TitleWithValue title="Start Date" value={createdAtHuman} />
          <TitleWithValue title="End Date" value={endedAtHuman} />
        </ValuesContainer>
      </div>
      <div className="mt-5">
        {!isLoading ? (
          <Table className="border border-subtle">
            <TableHeader className="bg-subtle font-medium text-xs text-default">
              <TableRow>
                <TableHead>Variant name</TableHead>
                {TABLE_METRICS.map((metric) => (
                  <TableHead key={metric.key}>
                    <div className="flex items-center gap-2">
                      <span>{metric.label}</span>
                    </div>
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {experimentResults?.map((experimentResult) => {
                return (
                  <ExperimentResultsTableRow
                    key={experimentResult.variationSlug}
                    experimentResult={experimentResult}
                  />
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <Loader />
        )}
      </div>
    </ExperimentsLayout>
  );
};

const ExperimentResultsTab: React.FC = () => {
  const { isLoadingExperiment, simpleExperiment, workspaceId, experiment } =
    useExperimentDetails();

  if (isLoadingExperiment || !simpleExperiment || !workspaceId) {
    return <Loader />;
  }
  return (
    <ExperimentResultsTabInner
      simpleExperiment={simpleExperiment}
      workspaceId={workspaceId}
      experiment={experiment}
    />
  );
};

type ExperimentResult = {
  urlPath: string;
  variationSlug: string;
  metrics: RangeResult["metrics"];
};

type ExperimentResultsTableRowProps = {
  experimentResult: ExperimentResult;
};

const ExperimentResultsTableRow: React.FC<ExperimentResultsTableRowProps> = ({
  experimentResult,
}) => {
  const { urlPath, variationSlug, metrics } = experimentResult;
  return (
    <TableRow className="text-default">
      <TableCell>
        <span className="font-semibold text-xs">
          <Tooltip triggerAsChild content={urlPath} delay={800}>
            <span>{variationSlug}</span>
          </Tooltip>
        </span>
      </TableCell>
      {TABLE_METRICS.map((metric) => {
        const currentMetricValues = metrics[metric.key] ?? [];
        return (
          <TableCell key={metric.key}>
            <MetricWithDelta
              name={metric.key}
              value={currentMetricValues[0] ?? null}
              doesCompareValueExist={false}
              wrapperClassName="justify-start flex-grow"
              valueClassName="text-xs"
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default ExperimentResultsTab;
