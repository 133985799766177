import type { AnalyticsUrlParams } from "@/features/analytics/useAnalyticsUrlParams";
import type { AnalyticsReadQuery } from "schemas/generated/analyticsRead";

import * as React from "react";

import useBaseAnalyticsRead from "@/features/analytics/useBaseAnalyticsRead";
import { ConditionOperatorEnum } from "schemas/analyticsRead";

const usePageAnalyticsRead = (
  workspaceId: string | null,
  pageUrlPath: string,
  defaultInterval?: AnalyticsReadQuery["ranges"]["mainRange"]["interval"],
) => {
  const getCustomFilters = (
    params: AnalyticsUrlParams,
  ): AnalyticsReadQuery["filters"] => ({
    ...params.filters,
    urlPath: [
      ...(params.filters?.urlPath || []),
      {
        value: [pageUrlPath],
        operator: ConditionOperatorEnum.EQUALS,
      },
    ],
    pageType: params.filters?.pageType || [],
    urlParams: params.filters?.urlParams || {},
  });

  return useBaseAnalyticsRead({
    workspaceId,
    defaultInterval,
    getCustomFilters,
  });
};

export default usePageAnalyticsRead;
