import type {
  RenderComponentProps,
  ShopifySellingPlan,
} from "../../../shared/types";

import * as React from "react";

import { useKlaviyoIdentifiersStore } from "replo-runtime/store/contexts/KlaviyoIdentifiers/context";
import { useRuntimeActiveStateStore } from "replo-runtime/store/contexts/RuntimeActiveState/hooks";

import {
  RenderEnvironmentContext,
  RuntimeHooksContext,
  ShopifyStoreContext,
  useRuntimeContext,
} from "../../../shared/runtime-context";
import { mapNull } from "../../../shared/utils/optional";
import { withLiquidAlternate } from "../../../shared/utils/withLiquidAlternate";
import { executeAction } from "../../AlchemyAction";
import ReploLiquidChunk from "../ReploLiquid/ReploLiquidChunk";

const EMPTY_ARRAY: any[] = [];
const DEFAULT_ONE_TIME_PURCHASE_TEXT = "One-time purchase";

const SellingPlanSelectDropdown: React.FC<RenderComponentProps> = ({
  component,
  componentAttributes,
  context,
}) => {
  const { templateProduct } = useRuntimeContext(ShopifyStoreContext);
  const products = useRuntimeContext(RuntimeHooksContext).useShopifyProducts();
  const { isEditorApp } = useRuntimeContext(RenderEnvironmentContext);
  const componentId = component.id;
  const includeOneTimePurchase =
    component.props._includeOneTimePurchase ?? true;
  const oneTimePurchaseText =
    component.props._oneTimePurchaseText ?? DEFAULT_ONE_TIME_PURCHASE_TEXT;
  const sellingPlans = context.attributes?._sellingPlans ?? EMPTY_ARRAY;
  const runtimeActiveStateStore = useRuntimeActiveStateStore();
  const klaviyoIdentifiersStore = useKlaviyoIdentifiersStore();

  if (!isEditorApp && sellingPlans.length === 0) {
    return null;
  }

  return (
    <select
      {...componentAttributes}
      role="listbox"
      value={
        mapNull(context.attributes?._selectedSellingPlan?.id, String) ?? "null"
      }
      // Note (Noah, 2024-09-11, USE-1209): Stop propagation on click so that any
      // interactions on parent containers don't get triggered
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        void executeAction(
          {
            id: `replo:selectSellingPlan`,
            type: "setActiveSellingPlan",
            value: {
              sellingPlanId:
                e.target.value === "null"
                  ? { __reploOneTimePurchase: true }
                  : Number(e.target.value),
            },
          },
          {
            componentId: componentId,
            componentContext: context,
            repeatedIndex: context.repeatedIndexPath,
            products: products,
            templateProduct,
            stores: {
              runtimeActiveState: runtimeActiveStateStore,
              klaviyoIdentifiers: klaviyoIdentifiersStore,
            },
          },
        );
      }}
    >
      {includeOneTimePurchase &&
        oneTimePurchaseText &&
        sellingPlans.length > 0 && (
          <option value="null" role="option">
            {oneTimePurchaseText}
          </option>
        )}
      {sellingPlans.map((sellingPlan: ShopifySellingPlan) => (
        <option value={sellingPlan.id} key={sellingPlan.id} role="option">
          {sellingPlan.name}
        </option>
      ))}
    </select>
  );
};

const SellingPlanSelectDropdownLiquid: React.FC<RenderComponentProps> = ({
  componentAttributes,
  component,
}) => {
  const includeOneTimePurchase =
    component.props._includeOneTimePurchase ?? true;
  const oneTimePurchaseText =
    component.props._oneTimePurchaseText ?? DEFAULT_ONE_TIME_PURCHASE_TEXT;
  return (
    <ReploLiquidChunk>
      {`{% if reploSortedSellingPlans != blank %}`}
      <select {...componentAttributes} role="listbox">
        {includeOneTimePurchase && (
          <option value="null" role="option">
            {oneTimePurchaseText}
          </option>
        )}

        {`{% for reploRepeatedSellingPlan in reploSortedSellingPlans %}`}
        {`{% if forloop.first %}`}
        <option
          value="{{reploRepeatedSellingPlan.id}}"
          key="{{reploRepeatedSellingPlan.id}}"
          role="option"
          selected
        >
          {"{{reploRepeatedSellingPlan.name}}"}
        </option>
        {`{% else %}`}
        <option
          value="{{reploRepeatedSellingPlan.id}}"
          key="{{reploRepeatedSellingPlan.id}}"
          role="option"
        >
          {"{{reploRepeatedSellingPlan.name}}"}
        </option>
        {`{% endif %}`}
        {"{% endfor %}"}
      </select>
      {`{% endif %}`}
    </ReploLiquidChunk>
  );
};

export default withLiquidAlternate(
  SellingPlanSelectDropdown,
  SellingPlanSelectDropdownLiquid,
);
