import type { Gradient } from "replo-runtime/shared/types.ts";

export function gradientToCssGradient(
  gradient: Partial<Gradient>,
): string | null {
  let stops = gradient.stops?.map((stop) => {
    return `${stop.color} ${stop.location}`;
  });

  if (stops?.length === 0) {
    return null;
  }

  if (stops?.length === 1) {
    stops = stops.concat(stops);
  }

  return `linear-gradient(${gradient.tilt}, ${stops?.join(", ")})`;
}

export function cssGradientToGradient(gradientString: string): Gradient {
  const tiltRegex = /linear-gradient\((\d+deg)/;
  const stopsRegex = /([\w#]+)\s+([\d.]+%)/g;

  const tiltMatch = gradientString.match(tiltRegex);
  const tilt = tiltMatch ? tiltMatch[1]! : "0deg";

  const stops: { color: string; location: string }[] = [];
  let match = stopsRegex.exec(gradientString);

  while (match !== null) {
    stops.push({ color: match[1]!, location: match[2]! });
    match = stopsRegex.exec(gradientString);
  }

  return { tilt, stops };
}
