import "./index.scss";

import * as React from "react";

import AccountDashboard from "@components/account/Dashboard";
import ProfileBoard from "@components/account/Dashboard/ProfileBoard";
import SecurityBoard from "@components/account/Dashboard/SecurityBoard";
import InitResetPassword from "@components/account/InitResetPassword";
import LoginForm from "@components/account/LoginForm";
import ResetPassord from "@components/account/ResetPasswordForm";
import SignupForm from "@components/account/SignupForm";
import { FullScreenLoader } from "@components/common/FullScreenLoader";
import {
  clearTemplateLibraryRedirection,
  getLibraryTemplateData,
  setLibraryTemplateDataToLocalStorage,
} from "@components/common/utils";
import ShopifyIntegrationSettings from "@components/dashboard/integrations/ShopifyIntegrationSettings";
import WorkspaceIntegrations from "@components/dashboard/integrations/WorkspaceIntegrations";
import { PartnerProgramLPContent } from "@components/dashboard/partner/partner-program-v2-lp-components/PartnerProgramLP";
import { PartnershipReferralBoard } from "@components/dashboard/partner/PartnershipReferralBoard";
import PartnershipDashboard from "@components/dashboard/PartnershipDashboard";
import NewProjectFlow from "@components/dashboard/projects/NewProjectFlow";
import ProjectDashboard from "@components/dashboard/projects/ProjectDashboard";
import { FlowsProvider } from "@components/flows/FlowsProvider";
import useExecuteFlowCallback from "@components/flows/hooks/useExecuteFlowCallback";
import { useGetCurrentFlow } from "@components/flows/hooks/useGetCurrentFlow";
import MarketplaceModal from "@components/marketplace/MarketplaceModal";
import TemplateCollectionsPageModal from "@components/marketplace/TemplateCollectionsPageModal";
import { TemplateDetailsModal } from "@components/marketplace/TemplateDetailsModal";
import DesignSystemTab from "@components/projectDashboard/DesignSystemTab";
import { ExperimentDetailsTab } from "@components/projectDashboard/experiments/ExperimentDetailsTab";
import { ExperimentEditTab } from "@components/projectDashboard/experiments/ExperimentEditTab";
import { ExperimentsTab } from "@components/projectDashboard/experiments/ExperimentsTab";
import ProjectDashboardLayout from "@components/projectDashboard/ProjectDashboardLayout";
import { ProjectIntegrationSettingsTab } from "@components/projectDashboard/ProjectIntegrationSettingsTab";
import { ProjectSettingsTab } from "@components/projectDashboard/ProjectSettingsTab";
import QuickStartModal from "@components/QuickStartModal";
import AuthenticatedUserRequiredRoute from "@components/router/AuthenticatedUserRequiredRoute";
import ReferralCodeRedirectRoute from "@components/router/ReferralCodeRedirectRoute";
import RouteWithModals from "@components/router/RouteWithModals";
import { EditorLayout } from "@editor/EditorApp";
import { store } from "@editor/store";
import { TRPCProvider } from "@editor/utils/trpc";
import { LocalStorageProvider, useLocalStorage } from "@hooks/useLocalStorage";
import useLoginSupportChatUser from "@hooks/useLoginSupportChatUser";
import { addReferralToUrl, routes } from "@utils/router";

import { AnalyticsOverview } from "@/features/analytics/AnalyticsOverview";
import { AnalyticsRedirect } from "@/features/analytics/AnalyticsRedirect";
import PageDetails from "@/features/analytics/PageDetails";
import WorkspaceAnalyticsDashboard from "@/features/analytics/WorkspaceAnalyticsDashboard";
import Experiments from "@/features/experiments/Experiments";
import { ExperimentsRedirect } from "@/features/experiments/ExperimentsRedirect";
import { ExperimentSettingsTab } from "@/features/experiments/ExperimentsSettingsTab";
import ExperimentDetailsTabV2 from "@/features/experiments/tabs/ExperimentDetailsTab";
import ExperimentResultsTab from "@/features/experiments/tabs/ExperimentResultsTab";
import Billing from "@/features/workspace/Billing";
import Members from "@/features/workspace/Members";
import WorkspaceSettings from "@/features/workspace/Settings";
import { WorkspaceBillingRedirectRoute } from "@/features/workspace/WorkspaceBillingRedirectRoute";
import WorkspaceDashboard from "@/features/workspace/WorkspaceDashboard";
import WorkspaceProjects from "@/features/workspace/WorkspaceProjects";
import { skipToken } from "@tanstack/react-query";
import { CookiesProvider } from "react-cookie";
import { Provider as ReduxProvider } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffectEvent } from "replo-utils/react/use-effect-event";

import { AuthLayout } from "./components/account/AuthLayout";
import SentryBoundary from "./components/common/SentryBoundary";
import { FlowsLayout } from "./components/flows/FlowsLayout";
import WorkspaceProvider from "./components/WorkspaceProvider";
import CurrentProjectProvider from "./contexts/CurrentProjectContext";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import useCurrentUser from "./hooks/useCurrentUser";
import useCurrentWorkspaceId from "./hooks/useCurrentWorkspaceId";
import { AIStreamingProvider } from "./providers/AIStreamingProvider";
import { trpc } from "./utils/trpc";

export function App() {
  return (
    <SentryBoundary>
      <ReduxProvider store={store}>
        <TRPCProvider>
          <RouterProvider router={router} />
        </TRPCProvider>
      </ReduxProvider>
    </SentryBoundary>
  );
}

const router = (() => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<RootRoute />}>
        {/* Index redirect route */}
        <Route index element={<RootRouteIndexRedirectRoute />} />

        {/* Auth routes */}
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="signup" element={<SignupForm />} />
          <Route path="password/reset/init" element={<InitResetPassword />} />
          <Route path="password/reset" element={<ResetPassord />} />
        </Route>

        {/* Redirect routes */}
        <Route
          path="/signup/:referralCode"
          element={<ReferralCodeRedirectRoute />}
        />
        <Route
          path="/partner/program"
          element={<PartnerProgramRedirectRoute />}
        />
        <Route
          path="/workspace/billing"
          element={<WorkspaceBillingRedirectRoute />}
        />

        {/* Project routes */}
        <Route
          path="/project"
          element={
            <AuthenticatedUserRequiredRoute>
              <TemplateDetailsRedirectRoute />
              <RouteWithModals>
                <ProjectDashboard />
              </RouteWithModals>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="new" element={<NewProjectFlow />} />
        </Route>

        {/* Dashboard routes */}
        <Route
          path={routes.dashboard}
          element={
            <AuthenticatedUserRequiredRoute>
              <TemplateDetailsRedirectRoute />
              <RouteWithModals>
                <ProjectDashboard />
              </RouteWithModals>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route
            path="details/:templateId"
            element={
              <AuthenticatedUserRequiredRoute>
                <TemplateDetailsModal />
              </AuthenticatedUserRequiredRoute>
            }
          />
        </Route>

        {/* Legacy settings routes */}
        <Route
          path="/settings"
          element={
            <AuthenticatedUserRequiredRoute>
              <TemplateDetailsRedirectRoute />
              <RouteWithModals>
                <AccountDashboard />
              </RouteWithModals>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route path="profile" element={<ProfileBoard />} />
          <Route path="security" element={<SecurityBoard />} />
        </Route>

        {/* Workspace routes */}
        <Route
          path="/workspace/:workspaceId"
          element={
            <AuthenticatedUserRequiredRoute>
              <TemplateDetailsRedirectRoute />
              <RouteWithModals>
                <WorkspaceDashboard />
              </RouteWithModals>
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route index element={<WorkspaceIndexRedirectRoute />} />
          <Route path="projects" element={<WorkspaceProjects />} />
          <Route
            path="project"
            element={
              <AuthenticatedUserRequiredRoute>
                <RouteWithModals>
                  <WorkspaceProjects />
                </RouteWithModals>
              </AuthenticatedUserRequiredRoute>
            }
          >
            <Route path="new" element={<NewProjectFlow />} />
          </Route>
          <Route path="members" element={<Members />} />
          <Route path="billing" element={<Billing />} />
          <Route path="partner" element={<PartnershipDashboard />}>
            <Route path="program" element={<PartnerProgramLPContent />} />

            <Route
              path="home"
              element={
                <ActivePartnershipRequiredRoute>
                  <PartnershipReferralBoard />
                </ActivePartnershipRequiredRoute>
              }
            />
          </Route>
          <Route path="integrations" element={<WorkspaceIntegrations />} />
          <Route
            path="integrations/shopify"
            element={<ShopifyIntegrationSettings />}
          />
          <Route path="analytics" element={<WorkspaceAnalyticsDashboard />}>
            <Route path="overview" element={<AnalyticsOverview />} />
            <Route path=":host/:pageUrlPath" element={<PageDetails />} />
          </Route>
          <Route path="experiments" element={<Experiments />} />
          <Route
            path={routes.workspace.experiments.details}
            element={<ExperimentDetailsTabV2 />}
          />
          <Route
            path={routes.workspace.experiments.results}
            element={<ExperimentResultsTab />}
          />
          <Route
            path={routes.workspace.experiments.settings}
            element={<ExperimentSettingsTab />}
          />
          <Route path="settings" element={<WorkspaceSettings />} />
        </Route>

        {/* Analytics workspace agnostic route */}
        <Route path="/analytics" element={<AnalyticsRedirect />} />

        {/* Experiments workspace agnostic route */}
        <Route path="/experiments" element={<ExperimentsRedirect />} />

        {/* Editor routes */}
        <Route
          path={routes.editor.project}
          element={
            <RouteWithModals>
              <EditorLayout />
            </RouteWithModals>
          }
        >
          <Route
            path={routes.marketplaceModal}
            element={<MarketplaceModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/category/:categorySlug`}
            element={<MarketplaceModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/collections`}
            element={<TemplateCollectionsPageModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/collection/:collectionId`}
            element={<TemplateCollectionsPageModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/details/:templateId`}
            element={<TemplateDetailsModal />}
          />
          <Route path="add" element={<QuickStartModal />} />
        </Route>

        {/* Editor alias routes for element */}
        <Route
          path={routes.editor.element}
          element={
            <RouteWithModals>
              <EditorLayout />
            </RouteWithModals>
          }
        >
          <Route
            path={routes.marketplaceModal}
            element={<MarketplaceModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/category/:categorySlug`}
            element={<MarketplaceModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/collections`}
            element={<TemplateCollectionsPageModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/collection/:collectionId`}
            element={<TemplateCollectionsPageModal />}
          />
          <Route
            path={`${routes.marketplaceModal}/details/:templateId`}
            element={<TemplateDetailsModal />}
          />
          <Route path="add" element={<QuickStartModal />} />
        </Route>

        {/* Editor alias route for product */}
        <Route
          path={routes.editor.product}
          element={
            <RouteWithModals>
              <EditorLayout />
            </RouteWithModals>
          }
        />

        {/* Editor routes with alternate layout */}
        <Route
          path={routes.editor.project}
          element={
            <RouteWithModals>
              <ProjectDashboardLayout />
            </RouteWithModals>
          }
        >
          <Route path="project-settings" element={<ProjectSettingsTab />} />
          <Route
            path="integrations"
            element={<ProjectIntegrationSettingsTab />}
          />
          <Route path="template-defaults" element={<DesignSystemTab />} />
          <Route path="experiments" element={<ExperimentsTab />} />
          <Route
            path="experiments/:experimentId"
            element={<ExperimentDetailsTab />}
          />
          <Route
            path="experiments/:experimentId/edit"
            element={<ExperimentEditTab />}
          />
        </Route>

        {/* Flow routes */}
        <Route
          path="/flows/:flowSlug"
          element={
            <AuthenticatedUserRequiredRoute>
              <FlowsLayout />
            </AuthenticatedUserRequiredRoute>
          }
        >
          <Route
            path=":flowStepId"
            element={
              <AuthenticatedUserRequiredRoute>
                <FlowsLayout />
              </AuthenticatedUserRequiredRoute>
            }
          />
        </Route>

        {/*  404 redirect route */}
        <Route path="*" element={<Navigate to={routes.dashboard} replace />} />
      </Route>,
    ),
  );
})();

function RootRoute() {
  return (
    <RootRouteProviders>
      <RootRouteLayout>
        <Outlet />
      </RootRouteLayout>
    </RootRouteProviders>
  );
}

function RootRouteProviders({ children }: React.PropsWithChildren) {
  return (
    // NOTE (Martin, 2024-10-09): We need an extra SentryBoundary component
    // here so that Sentry can catch errors inside the routing tree before
    // the React Router Boundary does it.
    <SentryBoundary>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <LocalStorageProvider>
          <CurrentUserProvider>
            <WorkspaceProvider>
              <CurrentProjectProvider>
                <AIStreamingProvider>
                  <FlowsProvider>{children}</FlowsProvider>
                </AIStreamingProvider>
              </CurrentProjectProvider>
            </WorkspaceProvider>
          </CurrentUserProvider>
        </LocalStorageProvider>
      </CookiesProvider>
    </SentryBoundary>
  );
}

function RootRouteLayout({ children }: React.PropsWithChildren) {
  const { currentFlow, isLoading: areFlowsLoading } = useGetCurrentFlow();
  useExecuteFlowCallback(currentFlow?.slug);
  useLoginSupportChatUser();
  useSaveTemplateDetailsIfNeeded();
  const referral = useReferralFromSearchParams();
  const { pathname } = useLocation();
  const { isLoading: isLoadingUser, isAuthenticated } = useCurrentUser();

  // NOTE (Martin, 2024-10-03): if we are not in auth routes and the user is not
  // authenticated, we redirect to the signup page.
  if (!pathname.includes("/auth") && !isLoadingUser && !isAuthenticated) {
    return <Navigate to={addReferralToUrl(routes.signup, referral)} replace />;
  }

  // NOTE (Fran 2024-10-25): We must wait until the flows are loaded to know if the user has an
  // incomplete onboarding flow.
  if (isLoadingUser || areFlowsLoading) {
    return <FullScreenLoader />;
  }

  return children;
}

function RootRouteIndexRedirectRoute() {
  const referral = useReferralFromSearchParams();
  const { data: workspaceData, isLoading: isLoadingWorkspaces } =
    trpc.workspace.getUserWorkspacesList.useQuery();

  if (isLoadingWorkspaces) {
    return <FullScreenLoader />;
  }

  const userHasOnlyOneWorkspace =
    (workspaceData?.workspaces ?? []).length === 1;
  const toUrl =
    userHasOnlyOneWorkspace && workspaceData?.workspaces![0]!.id
      ? generatePath("/workspace/:workspaceId/projects", {
          workspaceId: workspaceData.workspaces[0].id,
        })
      : routes.dashboard;

  return <Navigate to={addReferralToUrl(toUrl, referral)} replace />;
}

function PartnerProgramRedirectRoute() {
  const { user } = useCurrentUser();
  return (
    <Navigate
      to={
        user!.workspace?.id
          ? generatePath("/workspace/:workspaceId/partner/program", {
              workspaceId: user!.workspace.id,
            })
          : routes.dashboard
      }
      replace
    />
  );
}

function ActivePartnershipRequiredRoute({
  children,
}: {
  children: JSX.Element;
}) {
  const workspaceId = useCurrentWorkspaceId();

  const { data, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : skipToken,
    );

  if (!isLoadingWorkspace && !data?.workspace?.isOnboarded) {
    return (
      <Navigate
        to={
          workspaceId
            ? generatePath("/workspace/:workspaceId/partner/program", {
                workspaceId,
              })
            : // NOTE (Fran 2023-11-21): Fallback to home if user doesn't have a workspace
              routes.dashboard
        }
        replace
      />
    );
  }

  return children;
}

function WorkspaceIndexRedirectRoute() {
  const params = useParams();
  const toUrl = params?.workspaceId
    ? `/workspace/${params.workspaceId}/projects`
    : routes.dashboard;
  return <Navigate to={toUrl} replace />;
}

function TemplateDetailsRedirectRoute() {
  const localStorage = useLocalStorage();
  const navigate = useNavigate();

  const onBeforeUnload = useEffectEvent(() => {
    clearTemplateLibraryRedirection(localStorage);
  });

  React.useEffect(() => {
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [onBeforeUnload]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Disable exhaustive deps for now
  React.useEffect(() => {
    const libraryRedirectionData = getLibraryTemplateData(localStorage);
    if (libraryRedirectionData) {
      navigate(
        generatePath(`/home/${routes.templateSharedDetails}`, {
          templateId: libraryRedirectionData.templateId,
        }),
        { state: { marketplaceModalRequestType: "share" } },
      );

      clearTemplateLibraryRedirection(localStorage);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function useSaveTemplateDetailsIfNeeded() {
  const localStorage = useLocalStorage();
  const [searchParams] = useSearchParams();
  const libraryItemSlug = searchParams.get("libraryItemSlug");
  const templateId = searchParams.get("templateId");
  const libraryItemName = searchParams.get("libraryItemName");
  const type = searchParams.get("type");

  // biome-ignore lint/correctness/useExhaustiveDependencies: localStorage here is weird, I'm pretty sure we don't need this
  React.useEffect(() => {
    if (templateId) {
      setLibraryTemplateDataToLocalStorage({
        templateId,
        libraryItemSlug: libraryItemSlug ?? undefined,
        libraryItemName: libraryItemName ?? undefined,
        type: type ?? undefined,
      });
    }
  }, [libraryItemName, libraryItemSlug, localStorage, templateId, type]);
}

function useReferralFromSearchParams() {
  const [searchParams] = useSearchParams();
  return searchParams.get("referral");
}
