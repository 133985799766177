import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import Popover from "@editor/components/common/designSystem/Popover";
import Selectable from "@editor/components/common/designSystem/Selectable";
import SelectionIndicator from "@editor/components/common/designSystem/SelectionIndicator";
import FormFieldXButton from "@editor/components/common/FormFieldXButton";
import ModifierLabel from "@editor/components/editor/page/element-editor/components/extras/ModifierLabel";
import { LengthInputSelector } from "@editor/components/editor/page/element-editor/components/modifiers/LengthInputModifier";
import SolidColorSelector from "@editor/components/editor/page/element-editor/components/SolidColorSelector";
import { DraggingDirections } from "@editor/utils/editor";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import {
  formatTitle,
  getTextShadowString,
  parseTextShadows,
} from "@editor/utils/textShadow";

import { Badge } from "@replo/design-system/components/badge";
import Tooltip from "@replo/design-system/components/tooltip";
import { AiOutlineFontSize } from "react-icons/ai";
import {
  RiAlignCenter,
  RiAlignLeft,
  RiAlignRight,
  RiDropLine,
  RiItalic,
  RiLineHeight,
  RiStrikethrough,
  RiTextSpacing,
  RiUnderline,
} from "react-icons/ri";
import { FONT_WEIGHT_OPTIONS } from "replo-runtime/shared/utils/font";
import {
  CSS_LENGTH_TYPES,
  CSS_LENGTH_TYPES_WITH_COMPUTED,
} from "replo-runtime/shared/utils/units";
import { v4 as uuidv4 } from "uuid";

const DEFAULT_TEXT_SHADOW = {
  id: uuidv4(),
  offsetX: "0px",
  offsetY: "4px",
  blur: "1px",
  color: "#00000040",
};

const FONT_SIZE_MENU_ITEMS = [
  { label: "Reset", value: "" },
  { label: "12px", value: "12px" },
  { label: "14px", value: "14px" },
  { label: "16px", value: "16px" },
  { label: "18px", value: "18px" },
  { label: "20px", value: "20px" },
  { label: "24px", value: "24px" },
  { label: "32px", value: "32px" },
];

const LETTER_SPACING_MENU_OPTIONS = [
  { label: "Reset", value: "" },
  { label: "1px", value: "1px" },
  { label: "2px", value: "2px" },
  { label: "4px", value: "4px" },
];

const TEXT_ALIGN_OPTIONS = [
  {
    value: "left",
    label: <RiAlignLeft size={16} />,
    tooltipContent: "Align Left",
  },
  {
    value: "center",
    label: <RiAlignCenter size={16} />,
    tooltipContent: "Align Center",
  },
  {
    value: "right",
    label: <RiAlignRight size={16} />,
    tooltipContent: "Align Right",
  },
];

const TEXT_DECORATION_OPTIONS = [
  {
    value: "italic",
    label: <RiItalic size={16} />,
    tooltipContent: "Italic",
  },
  {
    value: "line-through",
    label: <RiStrikethrough size={16} />,
    tooltipContent: "Strikethrough",
  },
  {
    value: "underline",
    label: <RiUnderline size={16} />,
    tooltipContent: "Underline",
  },
];

const TEXT_TRANSFORM_OPTIONS = [
  { value: "none", label: "Ag" },
  { value: "uppercase", label: "AG" },
  { value: "lowercase", label: "ag" },
];

type BaseControlProps = {
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
};

const TOGGLE_GROUP_STYLES = {
  height: "26px",
  width: "100%",
} as const;

export const SavedFontWeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <Selectable
      className="w-full"
      placeholder="Weight"
      options={FONT_WEIGHT_OPTIONS.map((option) => ({
        label: option.label,
        value: option.value.toString(),
      }))}
      value={value?.toString()}
      onSelect={(value: string | null) => onChange?.(value ?? "")}
    />
  );
};

export const SavedFontSizeControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => (
  <LengthInputSelector
    className="w-full"
    label={<ModifierLabel label="Size" />}
    field="style.fontSize"
    previewProperty="fontSize"
    value={value ?? ""}
    onChange={(value) => onChange?.(value || "")}
    placeholder="Font Size"
    metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
    minValues={{ px: 0 }}
    allowsNegativeValue={false}
    anchorValue="16px"
    resetValue=""
    menuOptions={FONT_SIZE_MENU_ITEMS}
    dragTrigger="label"
    startEnhancer={() => (
      <Tooltip inheritCursor content="Font Size" triggerAsChild>
        <span tabIndex={0}>
          <AiOutlineFontSize />
        </span>
      </Tooltip>
    )}
  />
);

export const SavedLineHeightControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const lineHeightDefaultValue =
    styleAttributeToEditorData.lineHeight.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Line" />}
      field="style.lineHeight"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue === "auto" ? lineHeightDefaultValue : newValue);
      }}
      placeholder="Line Height"
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      minValues={{ px: 0 }}
      allowsNegativeValue={false}
      anchorValue="16px"
      resetValue={lineHeightDefaultValue}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Line Height" triggerAsChild>
          <span tabIndex={0}>
            <RiLineHeight />
          </span>
        </Tooltip>
      )}
    />
  );
};

export const SavedLetterSpacingControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  const letterSpacingDefaultValue =
    styleAttributeToEditorData.letterSpacing.defaultValue;

  return (
    <LengthInputSelector
      label={<ModifierLabel label="Spacing" />}
      field="style.letterSpacing"
      value={value}
      onChange={(newValue) => {
        onChange?.(newValue);
      }}
      placeholder="Letter Spacing"
      metrics={CSS_LENGTH_TYPES_WITH_COMPUTED}
      resetValue={letterSpacingDefaultValue}
      anchorValue="1px"
      menuOptions={LETTER_SPACING_MENU_OPTIONS}
      startEnhancer={() => (
        <Tooltip inheritCursor content="Letter Spacing" triggerAsChild>
          <span tabIndex={0}>
            <RiTextSpacing />
          </span>
        </Tooltip>
      )}
    />
  );
};

export const SavedTextAlignControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      value={value ?? ""}
      options={TEXT_ALIGN_OPTIONS}
      style={TOGGLE_GROUP_STYLES}
      onChange={(value) => value && onChange?.(value)}
    />
  );
};

export const SavedTextDecorationControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      value={value ?? ""}
      style={TOGGLE_GROUP_STYLES}
      options={TEXT_DECORATION_OPTIONS}
      onChange={(value) => value && onChange?.(value)}
    />
  );
};

export const SavedTextColorControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <SolidColorSelector
      popoverTitle="Text Color"
      value={value ?? ""}
      onChange={(value) => value && onChange?.(value)}
      defaultValue="#000000"
    />
  );
};

export const SavedTextTransformControl: React.FC<{
  value?: string | null;
  onChange?: (value: string) => void;
  disableWrapper?: boolean;
}> = ({ value, onChange }) => {
  return (
    <ToggleGroup
      type="single"
      style={TOGGLE_GROUP_STYLES}
      value={value ?? ""}
      options={TEXT_TRANSFORM_OPTIONS}
      onChange={(value) => value && onChange?.(value)}
    />
  );
};

export const SavedTextShadowControl: React.FC<BaseControlProps> = ({
  value,
  onChange,
}) => {
  const [openShadowIndex, setOpenShadowIndex] = React.useState<number | null>(
    null,
  );
  const textShadows = value ? parseTextShadows(value.split(",")) : [];

  const handleAddShadow = () => {
    const newShadows = [...textShadows, DEFAULT_TEXT_SHADOW];
    onChange?.(getTextShadowString(newShadows));
    setOpenShadowIndex(newShadows.length - 1);
  };

  const handleRemoveShadow = (index: number) => {
    const filteredShadows = textShadows.filter((_, i) => i !== index);
    onChange?.(getTextShadowString(filteredShadows));
  };

  const handleShadowChange = (
    index: number,
    updates: Partial<typeof DEFAULT_TEXT_SHADOW>,
  ) => {
    const updatedShadows = textShadows.map((shadow, i) =>
      i === index ? { ...shadow, ...updates } : shadow,
    );
    onChange?.(getTextShadowString(updatedShadows));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-[74px,auto] w-full items-center">
        <ModifierLabel label="Shadow" />
        <div className="flex flex-col gap-1">
          {textShadows.map((shadow, index) => (
            <div key={shadow.id} className="py-1">
              <SelectionIndicator
                className="max-w-40"
                title={formatTitle(shadow)}
                onClick={() => setOpenShadowIndex(index)}
                startEnhancer={
                  <Badge
                    type="color"
                    isFilled
                    backgroundColor={shadow.color ?? "text-subtle"}
                  />
                }
                endEnhancer={
                  <FormFieldXButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveShadow(index);
                    }}
                  />
                }
              />
              {openShadowIndex === index && (
                <TextShadowPopover
                  shadow={shadow}
                  index={index}
                  onChange={(updates) => handleShadowChange(index, updates)}
                  onClose={() => setOpenShadowIndex(null)}
                />
              )}
            </div>
          ))}
          {textShadows.length === 0 && (
            <ToggleGroup
              style={TOGGLE_GROUP_STYLES}
              type="single"
              options={[
                {
                  value: "shadow",
                  label: <RiDropLine size={16} />,
                  tooltipContent: "Add Shadow",
                },
              ]}
              value=""
              onChange={() => handleAddShadow()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TextShadowPopover: React.FC<{
  shadow: typeof DEFAULT_TEXT_SHADOW;
  index: number;
  onChange: (updates: Partial<typeof DEFAULT_TEXT_SHADOW>) => void;
  onClose: () => void;
}> = ({ shadow, index, onChange, onClose }) => {
  return (
    <Popover isOpen onOpenChange={(isOpen) => !isOpen && onClose()}>
      <Popover.Content
        title="Text Shadow"
        onRequestClose={onClose}
        shouldPreventDefaultOnInteractOutside={true}
      >
        <div className="flex flex-col gap-2">
          <LengthInputSelector
            label={<ModifierLabel label="X Axis" />}
            metrics={CSS_LENGTH_TYPES}
            field="offsetX"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetX}
            onChange={(value) => onChange({ offsetX: value })}
            previewProperty="textShadow"
            previewSubProperty="offsetX"
            previewPropertyIndex={index}
            draggingDirection={DraggingDirections.Negative}
            autofocus
            dragTrigger="label"
          />
          <LengthInputSelector
            label={<ModifierLabel label="Y Axis" />}
            metrics={CSS_LENGTH_TYPES}
            field="offsetY"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.offsetY}
            onChange={(value) => onChange({ offsetY: value })}
            previewProperty="textShadow"
            previewSubProperty="offsetY"
            previewPropertyIndex={index}
            draggingDirection={DraggingDirections.Negative}
            dragTrigger="label"
          />
          <LengthInputSelector
            label={<ModifierLabel label="Blur" />}
            metrics={CSS_LENGTH_TYPES}
            minDragValues={{ px: 0 }}
            minValues={{ px: 0 }}
            field="blur"
            resetValue="0px"
            anchorValue="0px"
            placeholder="0px"
            value={shadow.blur}
            onChange={(value) => onChange({ blur: value })}
            previewProperty="textShadow"
            previewSubProperty="blur"
            previewPropertyIndex={index}
            dragTrigger="label"
          />
          <div className="flex items-center">
            <ModifierLabel label="Color" />
            <SolidColorSelector
              popoverTitle="Color"
              value={shadow.color}
              onChange={(value) => onChange({ color: value ?? undefined })}
              defaultValue="#00000040"
            />
          </div>
        </div>
      </Popover.Content>
      <Popover.Anchor className="relative top-0 left-0" />
    </Popover>
  );
};
