import merge from "lodash-es/merge";

type Config = {
  // Called when the next element becomes "active"
  onBecomeActive(element: HTMLElement): void;
  // Node to use to query for spied elements
  querySelectorNode: HTMLElement | null;
  // Document that the spied elements are a part of
  document?: Document;
  // Window that the spied elements are a part of
  targetWindow?: Window;
  // Offset from the top of the page that an element is considered active. e.g if
  // this is 100, the element will be active when it gets to within 100 px of the
  // top of the page
  scrollOffset?: number;
  // Selector to use for spied elements. All elements matching this selector will
  // be spied on and have the possibility to become active.
  spiedElementsSelector?: string;
};

/**
 * Adapted/pared down from Bootstrap's scroll spy. Listens for scroll on the
 * document and calls onBecomeActive when any of the elements in the
 * spiedElementsSelector query become "active" (aka, get scrolled on to the
 * screen with the previous element completely scrolled away).
 */
export default class ScrollSpy {
  _config: Config;
  _querySelectorNode: HTMLElement | Document;
  _document: Document;
  _targetWindow: Window;
  _scrollOffset: number;
  _spiedElementsSelector: string;
  _targets: HTMLElement[] = [];
  _offsets: number[] = [];
  _activeTarget: HTMLElement | null = null;
  _scrollHeight = 0;

  static defaultScrollOffset = 100;

  constructor(config: Config) {
    // biome-ignore lint/style/noRestrictedGlobals: allow window
    const targetWindow = config.targetWindow ?? window;
    this._config = merge(
      {},
      {
        querySelectorNode: targetWindow.document,
        document: targetWindow.document,
        targetWindow: targetWindow,
        scrollOffset: ScrollSpy.defaultScrollOffset,
        spiedElementsSelector: "[data-alchemy-url-hashmark]",
      },
      config,
    );
    this._document = config.document ?? targetWindow.document;
    this._targetWindow = config.targetWindow ?? targetWindow;
    this._querySelectorNode = config.querySelectorNode || targetWindow.document;
    this._scrollOffset = config.scrollOffset || ScrollSpy.defaultScrollOffset;
    this._spiedElementsSelector =
      config.spiedElementsSelector || "[data-alchemy-url-hashmark]";
    this._document.addEventListener("scroll", () => this._process());

    this.refreshTargets();
    this._process();
  }

  refreshTargets() {
    const offsetBase = this._targetWindow.pageYOffset;

    this._offsets = [];
    this._targets = [];
    this._scrollHeight = this._getScrollHeight();

    const targets: HTMLElement[] = Array.from(
      this._querySelectorNode.querySelectorAll(this._spiedElementsSelector),
    );

    (
      targets
        .map((target): [number, HTMLElement] | null => {
          const targetBCR = target.getBoundingClientRect();
          if (targetBCR.width || targetBCR.height) {
            const additionalOffset = Number.parseInt(
              target.dataset?.alchemyUrlHashmarkOffset ?? "0",
            );
            const total = targetBCR.top - additionalOffset + offsetBase;
            return [total, target];
          }

          return null;
        })
        .filter(Boolean) as [number, HTMLElement][]
    )
      .sort((a: [number, HTMLElement], b: [number, HTMLElement]) => a[0] - b[0])
      .forEach((item: [number, HTMLElement]) => {
        this._offsets.push(item[0]);
        this._targets.push(item[1]);
      });

    this._process();
  }

  _getScrollTop() {
    return this._targetWindow.pageYOffset;
  }

  _getScrollHeight() {
    return Math.max(
      this._document.body.scrollHeight,
      this._document.documentElement.scrollHeight,
    );
  }

  _getOffsetHeight() {
    return this._targetWindow.innerHeight;
  }

  /**
   * Called on every scroll to see if a new element should be reported as active
   */
  _process() {
    // Y position of the "top" of the scrolling area we're observing
    const scrollTop = this._getScrollTop() + this._scrollOffset;
    // Total height of observable scroll area
    const scrollHeight = this._getScrollHeight();
    const maxScroll =
      this._scrollOffset + scrollHeight - this._getOffsetHeight();

    if (this._scrollHeight !== scrollHeight) {
      this.refreshTargets();
    }

    if (scrollTop >= maxScroll && this._targets.length > 0) {
      const target = this._targets[this._targets.length - 1] as HTMLElement;

      if (this._activeTarget !== target) {
        this._activate(target);
      }

      return;
    }

    if (
      this._activeTarget &&
      this._offsets.length > 0 &&
      scrollTop < this._offsets[0]! &&
      this._offsets[0]! > 0
    ) {
      this._activeTarget = null;
      return;
    }

    for (let i = this._offsets.length - 1; i >= 0; i--) {
      const isActiveTarget =
        this._activeTarget !== this._targets[i] &&
        scrollTop >= this._offsets[i]! &&
        (this._offsets[i + 1] === undefined ||
          scrollTop < this._offsets[i + 1]!);

      if (isActiveTarget) {
        this._activate(this._targets[i] as HTMLElement);
      }
    }
  }

  _activate(target: HTMLElement) {
    this._activeTarget = target;
    this._config.onBecomeActive(target);
  }
}
