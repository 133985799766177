// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import * as React from "react";

import {
  Diamond,
  tiers,
} from "@editor/components/dashboard/partner/partner-program-v2-lp-components/Hero";

type Sections = {
  name: string;
  features: { name: string; tiers: Record<string, string | boolean> }[];
}[];
export const sections: Sections = [
  {
    name: "Features",
    features: [
      {
        name: "Monthly Revenue Share",
        tiers: {
          Affiliate: "20%",
          Silver: "30%",
          Gold: "40%",
          Platinum: "50%",
        },
      },
      {
        name: "Number of Paying Referrals Needed to Qualify",
        tiers: {
          Affiliate: "0",
          Silver: "25",
          Gold: "50",
          Platinum: "100",
        },
      },
      {
        name: "Custom Referral Code",
        tiers: {
          Affiliate: true,
          Silver: true,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Reploverse Slack Access",
        tiers: {
          Affiliate: true,
          Silver: true,
          Gold: true,
          Platinum: true,
          Enterprise: true,
        },
      },
      {
        name: "Partner Dashboard",
        tiers: {
          Affiliate: true,
          Silver: true,
          Gold: true,
          Platinum: true,
          Enterprise: true,
        },
      },
      {
        name: "Custom Badges",
        tiers: {
          Affiliate: false,
          Silver: true,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Free Replo Swag",
        tiers: {
          Affiliate: false,
          Silver: true,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Co-marketing Opportunities",
        tiers: {
          Affiliate: false,
          Silver: true,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Partner Directory Listing",
        tiers: {
          Affiliate: false,
          Silver: true,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Product Roadmap Review",
        tiers: {
          Affiliate: false,
          Silver: false,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Dedicated Co-Branding Across Replo Collateral ",
        tiers: {
          Affiliate: false,
          Silver: false,
          Gold: true,
          Platinum: true,
        },
      },
      {
        name: "Dedicated Customer Success Manager",
        tiers: {
          Affiliate: false,
          Silver: false,
          Gold: false,
          Platinum: true,
        },
      },
      {
        name: "Monthly Strategy Jam",
        tiers: {
          Affiliate: false,
          Silver: false,
          Gold: false,
          Platinum: true,
        },
      },
    ],
  },
];
export default function PartnerProgramTiers() {
  return (
    <div className="w-full">
      <div className="max-w-custom mx-auto bg-white py-16 px-5 sm:py-24">
        <h3 className="mb-10 text-center text-2xl font-semibold text-black md:mb-16 md:text-4xl xl:text-left">
          Partner Program Tiers
        </h3>
        {/* NOTE (Juan 2023-06-27): This section displays content from extra small (xs) to large (lg) screens */}
        <div className="max-w-4xl space-y-16 lg:hidden">
          {tiers.map((tier) => (
            <section key={tier.name}>
              {sections.map((section) => (
                <table key={section.name} className="table w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 py-3 px-4 text-right text-lg font-medium text-gray-900">
                    {tier.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => (
                      <tr
                        key={feature.name}
                        className="border-t border-gray-200"
                      >
                        <th
                          className="py-5 px-4 text-left text-sm font-normal text-gray-500"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block w-2/4 text-right text-sm text-black">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] ? (
                                <Diamond
                                  primaryColor={tier.primaryColor}
                                  secondaryColor={tier.secondaryColor}
                                  size="18"
                                />
                              ) : (
                                <div />
                              )}
                              <span className="sr-only">
                                {feature.tiers[tier.name] ? "Yes" : "No"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </section>
          ))}
        </div>
        {/* NOTE (Juan 2023-06-27): This section displays content for lg screens */}
        <div className="hidden lg:block">
          <table className="w-full table-fixed ">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="px-6 pb-4 text-left text-sm font-medium text-gray-900"
                  scope="col"
                ></th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/ px-6 pb-4 text-left text-xl font-semibold leading-6 text-gray-900"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-black border-y border-t border-black">
              {sections.map((section) => (
                <React.Fragment key={section.name}>
                  {section.features.map((feature) => (
                    <tr className="border-slate-300" key={feature.name}>
                      <th
                        className="py-5 px-6 text-left text-lg font-normal text-gray-500"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.name} className="py-5 px-6">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <span className="block text-xl text-black">
                              {feature.tiers[tier.name]}
                            </span>
                          ) : (
                            <>
                              {feature.tiers[tier.name] ? (
                                <Diamond
                                  primaryColor={tier.primaryColor}
                                  secondaryColor={tier.secondaryColor}
                                  size="18"
                                />
                              ) : (
                                <div />
                              )}
                              <span className="sr-only">
                                {feature.tiers[tier.name]
                                  ? "Included"
                                  : "Not included"}{" "}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
