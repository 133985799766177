import type { SetDraftElementPayload } from "@editor/actions/core-actions";
import type { EditorRootState } from "@editor/store";
import type { AnyAction, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import type { Dispatch, Middleware } from "redux";
import type { ComponentData } from "replo-runtime/shared/Component";
import type { ReploComponentType } from "schemas/component";

import { getTargetFrameDocument } from "@editor/hooks/useTargetFrame";
import { isFeatureEnabled } from "@editor/infra/featureFlags";
import {
  selectComponentDataMapping,
  selectDraftComponentId,
  selectDraftElementFromCoreState,
  setComponentVariant,
  setDraftRepeatedIndex,
} from "@editor/reducers/core-reducer";
import {
  selectSharedState,
  setSharedState,
} from "@editor/reducers/paint-reducer";
import {
  isSlideTransition,
  nearestCarouselSlideIndex,
} from "@editor/utils/carousel";
import {
  findAncestorComponentDataByType,
  findAncestorComponentOrSelfWithVariants,
  findComponentByIdInComponent,
} from "@editor/utils/component";

import { selectActiveCanvasFrame } from "@/features/canvas/canvas-reducer";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import { findDefault } from "replo-runtime/shared/variant";

export const setDraftElementMiddleware: Middleware<
  (
    action: (
      | AnyAction
      | ThunkAction<void, EditorRootState, unknown, AnyAction>
    )[],
  ) => null,
  EditorRootState
> = ({ dispatch, getState }) => {
  return (next) => {
    return (action: PayloadAction<SetDraftElementPayload>) => {
      const state = getState();
      const draftElement = selectDraftElementFromCoreState(state.core);
      if (
        draftElement &&
        action.type === "elements/setDraftElement" &&
        action.payload.componentId
      ) {
        beforeAfterSliderHandler({ action, dispatch, state });
        carouselV3Handler({ action, dispatch, state });
        carouselV4Handler({ action, dispatch, state });
        collapsibleV2Handler({ action, dispatch, state });
        modalHandler({ action, dispatch, state });
        tabsV2Handler({ action, dispatch, state });
        tooltipHandler({ action, dispatch, state });
        variantsHandler({ action, dispatch, state });
      }

      return next(action);
    };
  };
};

type HandlerParams = {
  action: PayloadAction<SetDraftElementPayload>;
  dispatch: Dispatch;
  state: EditorRootState;
};

function beforeAfterSliderHandler({ action, dispatch, state }: HandlerParams) {
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const beforeAfterAncestorData = findAncestorComponentDataByType(
    "beforeAfterSlider",
    componentData.id,
    componentDataMapping,
  );

  if (!beforeAfterAncestorData) {
    return;
  }

  const beforeContentId = findContentId(
    "beforeAfterSliderBeforeContent",
    beforeAfterAncestorData,
  );
  const afterContentId = findContentId(
    "beforeAfterSliderAfterContent",
    beforeAfterAncestorData,
  );
  const sliderContentId = findContentId(
    "beforeAfterSliderThumb",
    beforeAfterAncestorData,
  );

  let selectedContentPosition: "before" | "after" | "both" | null = null;
  if (
    isAncestorTypeIncluded("beforeAfterSliderBeforeContent", componentData) ||
    componentData.id === beforeContentId
  ) {
    selectedContentPosition = "before";
  }
  if (
    isAncestorTypeIncluded("beforeAfterSliderAfterContent", componentData) ||
    componentData.id === afterContentId
  ) {
    selectedContentPosition = "after";
  }
  if (
    isAncestorTypeIncluded("beforeAfterSliderThumb", componentData) ||
    componentData.id === sliderContentId
  ) {
    selectedContentPosition = "both";
  }

  if (!selectedContentPosition) {
    return;
  }

  const selectedContentPositionToValue = {
    before: 100,
    both: 50,
    after: 0,
  };

  dispatch(
    setSharedState({
      key: `${beforeAfterAncestorData.id}.position`,
      value: selectedContentPositionToValue[selectedContentPosition],
    }),
  );
}

function tabsV2Handler({ action, dispatch, state }: HandlerParams) {
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const tabBlockAncestorData = findAncestorComponentDataByType(
    "tabsV2__block",
    componentData.id,
    componentDataMapping,
  );

  if (!tabBlockAncestorData) {
    return;
  }

  let tabInnerComponentDataFromMapping = null;
  if (isAncestorTypeIncluded("tabsV2__list", componentData)) {
    tabInnerComponentDataFromMapping = findAncestorComponentDataByType(
      "tabsV2__list",
      componentData.id,
      componentDataMapping,
    );
  } else if (isAncestorTypeIncluded("tabsV2__panelsContent", componentData)) {
    tabInnerComponentDataFromMapping = findAncestorComponentDataByType(
      "tabsV2__panelsContent",
      componentData.id,
      componentDataMapping,
    );
  }

  if (!tabInnerComponentDataFromMapping) {
    return;
  }

  const tabInnerComponentData =
    componentDataMapping[tabInnerComponentDataFromMapping.id];

  if (!tabInnerComponentData) {
    return;
  }
  const tabInnerComponentChildren = getDirectChildFromMapping(
    tabInnerComponentData,
  );

  let tabRepeatedIndex = tabInnerComponentChildren.findIndex(
    ([childId]) => childId === componentData.id,
  );

  if (tabRepeatedIndex === -1) {
    const directChildIndexData = componentData?.ancestorComponentData.find(
      ([id]) => tabInnerComponentChildren.find(([childId]) => childId === id),
    );
    if (directChildIndexData) {
      const [directChildId] = directChildIndexData;
      tabRepeatedIndex = tabInnerComponentChildren.findIndex(
        ([childId]) => childId === directChildId,
      );
    }
  }

  dispatch(
    setSharedState({
      key: `${tabBlockAncestorData.id}.activeTabIndex`,
      value: Math.max(0, tabRepeatedIndex),
    }),
  );
}

function tooltipHandler({ action, dispatch, state }: HandlerParams) {
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const tooltipAncestorData = findAncestorComponentDataByType(
    "tooltip",
    componentData.id,
    componentDataMapping,
  );

  if (!tooltipAncestorData) {
    return;
  }

  const tooltipContentAncestorData = findAncestorComponentDataByType(
    "tooltipContent",
    componentData.id,
    componentDataMapping,
  );

  const tooltipContentId = findContentId(
    "tooltipContent",
    tooltipContentAncestorData,
  );

  if (!tooltipContentAncestorData && tooltipContentId !== componentData.id) {
    return;
  }

  dispatch(
    setSharedState({
      key: `${tooltipAncestorData.id}.isOpen`,
      value: true,
    }),
  );
}

function variantsHandler({ action, dispatch, state }: HandlerParams) {
  const draftElement = selectDraftElementFromCoreState(state.core);
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  // Note (Noah, 2022-08-03, REPL-3310): If we're inside a component with an
  // "is current tab active" state, which is usually the case for when we just clicked
  // inside a tabsList component, we'll automatically set that tab to be active,
  // but now it's confusing because you're looking at the active state but you're
  // editing the default state. To avoid this, we set the selected variant to be the
  // default state automatically, so you see the default state and are editing the
  // default state (more consistent, less confusing). Note this doesn't mess with the
  // sharedState so you'll still see the correct thing in Preview Mode.
  const parentWithVariants = findAncestorComponentOrSelfWithVariants(
    draftElement!,
    componentData.id,
    {},
  );

  // Note (Noah, 2022-08-13, REPL-4565): If we're switching from one component to
  // another inside the same parent with variants, don't change the variant. This
  // prevents the annoying case where you're editing a selected state of tab component and
  // switch to a child of that component, and then the variant changes to the default
  const oldDraftComponentId = selectDraftComponentId(state);
  const oldDraftIsComponentInsideSameParentWithVariants =
    oldDraftComponentId &&
    Boolean(
      findComponentByIdInComponent(parentWithVariants, oldDraftComponentId),
    );
  if (
    !oldDraftIsComponentInsideSameParentWithVariants &&
    parentWithVariants?.variants &&
    parentWithVariants?.variants?.some((v) =>
      v.query?.statements.some(
        (s) => s.field === "state.tabsV2Block.isCurrentTab",
      ),
    )
  ) {
    const defaultVariant = findDefault(parentWithVariants.variants);

    dispatch(
      setComponentVariant({
        componentId: parentWithVariants.id,
        variantId: defaultVariant.id,
      }),
    );
  }
}

function collapsibleV2Handler({ action, dispatch, state }: HandlerParams) {
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const collapsibleAncestorData = findAncestorComponentDataByType(
    "collapsibleV2",
    componentData.id,
    componentDataMapping,
  );

  if (!collapsibleAncestorData) {
    return;
  }

  const collapsibleAncestorDirectChildren = getDirectChildFromMapping(
    collapsibleAncestorData,
  );
  // NOTE (Fran 2024-05-23): In collapsibles we always know that the first child is the header and
  // the second child is the content. And we only want to open the collapsible if the user clicks on
  // any component that is a child of a collapsibleV2Content.
  const collapsibleContentData = collapsibleAncestorDirectChildren[1];
  if (!collapsibleContentData) {
    return;
  }

  const accordionAncestorDataFromMapping = findAncestorComponentDataByType(
    "accordionBlock",
    componentData.id,
    componentDataMapping,
  );

  const sharedState = selectSharedState(state);

  const [collapsibleContentId] = collapsibleContentData;
  const isDirectChild = collapsibleContentId === componentData.id;
  if (isDirectChild) {
    // NOTE (Fran 2024-05-23): The way we manage the collapsible open in a accordion is
    // different than in a normal collapsible. In an accordion we need to keep track of the
    // open items, so we need to add the collapsible ancestor id to the array of open items
    // if it is not already there. In the case of a normal collapsible we just need to set
    // the value to true.
    if (accordionAncestorDataFromMapping) {
      const newSharedState = addIdToCurrentStateIfNecessary(
        collapsibleAncestorData.id,
        sharedState[
          `${accordionAncestorDataFromMapping.id}.accordionOpenItems`
        ] ?? [],
      );
      dispatch(
        setSharedState({
          key: `${accordionAncestorDataFromMapping.id}.accordionOpenItems`,
          value: newSharedState,
        }),
      );
    } else {
      dispatch(
        setSharedState({
          key: `${collapsibleAncestorData.id}.isOpen`,
          value: true,
        }),
      );
    }
  } else {
    const isChildOfCollapsibleContent =
      componentData?.ancestorComponentData.some(
        ([id]) => id === collapsibleContentId,
      );
    if (isChildOfCollapsibleContent) {
      // NOTE (Fran 2024-05-23): The way we manage the collapsible open in a accordion is
      // different than in a normal collapsible. In an accordion we need to keep track of the
      // open items, so we need to add the collapsible ancestor id to the array of open items
      // if it is not already there. In the case of a normal collapsible we just need to set
      // the value to true.
      if (accordionAncestorDataFromMapping) {
        const newSharedState = addIdToCurrentStateIfNecessary(
          collapsibleAncestorData.id,
          sharedState[
            `${accordionAncestorDataFromMapping.id}.accordionOpenItems`
          ] ?? [],
        );
        dispatch(
          setSharedState({
            key: `${accordionAncestorDataFromMapping.id}.accordionOpenItems`,
            value: newSharedState,
          }),
        );
      } else {
        dispatch(
          setSharedState({
            key: `${collapsibleAncestorData.id}.isOpen`,
            value: true,
          }),
        );
      }
    }
  }
}

function carouselV3Handler({ action, dispatch, state }: HandlerParams) {
  if (isFeatureEnabled("carousel-v4")) {
    return;
  }

  const draftElement = selectDraftElementFromCoreState(state.core);
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const sharedState = selectSharedState(state);
  const activeCanvasFrame = selectActiveCanvasFrame(state);
  const activeCanvasDocument = activeCanvasFrame
    ? getTargetFrameDocument(activeCanvasFrame)
    : null;

  // Note: Fran (2022-05-11) if the selected id is a child of a carousel we
  // need to set the new active slide to show it in the editor
  const parentCarouselComponent = findAncestorComponentDataByType(
    "carouselV3",
    componentData.id,
    componentDataMapping,
  );

  if (
    !parentCarouselComponent ||
    !isSlideTransition(componentData.id, draftElement, sharedState)
  ) {
    return;
  }

  // Note: Fran (2022-05-11) If we're transitioning to a new slide from
  // another slide, a timeout is needed so that the animation is
  // over before selecting a new draft component.
  const activeSlideStateKey = `${parentCarouselComponent.id}.activeSlide`;
  const currentActiveIndex = sharedState[activeSlideStateKey];

  const { index: nextActiveSlideIndex, repeatedIndex } =
    nearestCarouselSlideIndex(
      activeCanvasDocument,
      componentData.id,
      draftElement,
      currentActiveIndex,
    );

  if (nextActiveSlideIndex !== null) {
    if (currentActiveIndex !== nextActiveSlideIndex) {
      dispatch(
        setSharedState({
          key: activeSlideStateKey,
          value: nextActiveSlideIndex,
        }),
      );
    }
  } else if (repeatedIndex) {
    dispatch(setDraftRepeatedIndex(repeatedIndex));
  }
}

function carouselV4Handler({ action, dispatch, state }: HandlerParams) {
  if (!isFeatureEnabled("carousel-v4") || action.payload.repeatedIndex) {
    return;
  }

  const draftElement = selectDraftElementFromCoreState(state.core);
  const componentDataMapping = selectComponentDataMapping(state);
  const componentData = getFromRecordOrNull(
    componentDataMapping,
    action.payload.componentId,
  );

  if (!componentData) {
    return;
  }

  const carouselAncestorData = findAncestorComponentDataByType(
    "carouselV3",
    componentData.id,
    componentDataMapping,
  );

  if (!carouselAncestorData) {
    return;
  }

  const activeCanvasFrame = selectActiveCanvasFrame(state);
  const activeCanvasDocument = activeCanvasFrame
    ? getTargetFrameDocument(activeCanvasFrame)
    : null;
  const sharedState = selectSharedState(state);
  const activeSlideStateKey = `${carouselAncestorData.id}.activeSlide`;
  const currentActiveIndex = sharedState[activeSlideStateKey];

  const { repeatedIndex } = nearestCarouselSlideIndex(
    activeCanvasDocument,
    componentData.id,
    draftElement,
    currentActiveIndex,
  );

  if (!repeatedIndex) {
    return;
  }

  dispatch(setDraftRepeatedIndex(repeatedIndex));
}

function modalHandler({ action, dispatch, state }: HandlerParams) {
  const componentDataMapping = selectComponentDataMapping(state);
  const ancestorModal = findAncestorComponentDataByType(
    "modal",
    action.payload.componentId!,
    componentDataMapping,
  );

  if (!ancestorModal) {
    return;
  }

  dispatch(
    setSharedState({ key: `${ancestorModal.id}.isVisible`, value: true }),
  );
}

function isAncestorTypeIncluded(
  type: ReploComponentType,
  componentData: ComponentData,
) {
  return componentData.ancestorComponentData.some(
    ([, ancestorType]) => ancestorType === type,
  );
}

function getDirectChildFromMapping(componentData: ComponentData) {
  return componentData.containedComponentData.filter(
    ([, , level]) => level === 1,
  );
}

const addIdToCurrentStateIfNecessary = (id: string, currentState: string[]) => {
  if (!currentState.includes(id)) {
    return [...currentState, id];
  }
  return currentState;
};

function findContentId(
  type: ReploComponentType,
  componentData: ComponentData | undefined,
) {
  return componentData?.containedComponentData?.find(
    ([, t]) => t === type,
  )?.[0];
}
