import type { ComboboxProps } from "@replo/design-system/components/shadcn/combobox/types";

import * as React from "react";

import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { sanitizePageUrlPath } from "@editor/utils/analytics";

import useSearchAnalyticsRead from "@/features/analytics/useSearchAnalyticsRead";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { BsCaretDownFill, BsWindow } from "react-icons/bs";
import { useControllableState } from "replo-utils/react/use-controllable-state";

type AnalyticsHostPagesComboboxProps = Pick<
  ComboboxProps,
  "open" | "onOpenChange"
> & {
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
};

const AnalyticsHostPagesCombobox = ({
  onChange,
  disabled,
  value: controlledValue,
  ...props
}: AnalyticsHostPagesComboboxProps) => {
  const workspaceId = useCurrentWorkspaceId();

  const [value, setValue] = useControllableState(controlledValue, "", onChange);
  const [searchTerm, setSearchTerm] = React.useState("");

  const { rangeResults, isLoading } = useSearchAnalyticsRead(
    workspaceId,
    searchTerm,
  );

  const uniqueRangeResults = React.useMemo(() => {
    if (!rangeResults) {
      return [];
    }

    const uniqueResults = new Map();
    rangeResults.forEach((result) => {
      if (!uniqueResults.has(result.urlPath)) {
        uniqueResults.set(result.urlPath, result);
      }
    });

    return Array.from(uniqueResults.values());
  }, [rangeResults]);

  const { value: inputValue, onChange: onInputChange } = useOverridableInput({
    value: searchTerm,
    onValueChange: (value: string) => {
      setSearchTerm(value);
    },
  });

  const pageOptions = React.useMemo(() => {
    return uniqueRangeResults.map((result) => ({
      value: result.urlPath,
      label: sanitizePageUrlPath(result.urlPath),
    }));
  }, [uniqueRangeResults]);

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Combobox
      options={pageOptions}
      value={value}
      onChange={handleChange}
      isDisabled={disabled}
      isLoading={isLoading}
      areOptionsSearchable={true}
      input={inputValue}
      onInputChange={(value: string) => {
        onInputChange({
          target: { value },
        } as React.ChangeEvent<HTMLInputElement>);
      }}
      startEnhancer={() => <BsWindow className="h-4 w-4" />}
      endEnhancer={() => <BsCaretDownFill className="h-2 w-2 text-subtle" />}
      {...props}
    />
  );
};

export default AnalyticsHostPagesCombobox;
