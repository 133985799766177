/**
 * When linking to support docs from inside our app, this lets us keep our links
 * clean and in one place.
 */
export const docs = {
  home: "https://docs.replo.app/",
  abTesting: {
    main: "https://support.replo.app/hc/en-us/articles/20693018801549",
    projectCustomDomain:
      "https://support.replo.app/hc/en-us/articles/20693018801549#h_01HDJ5HA9VHCF2WMX46Q7SHHTG",
    projectShortName:
      "https://support.replo.app/hc/en-us/articles/20693018801549#h_01HDJ5HNDZBP66ZT2QCFKNWVQQ",
    analytics:
      "https://support.replo.app/hc/en-us/articles/20693018801549#h_01HDJ5HA9VHCF2WMX46Q7SHHTG",
  },
  klaviyo: "https://support.replo.app/hc/en-us/articles/17512842513165-Klaviyo",
  templateDefaults:
    "https://support.replo.app/hc/en-us/articles/20118402411021",
  sellingPlans: {
    thirdPartyAtc:
      "https://support.replo.app/articles/1716416054-subscriptions-and-selling-plans#allowing-third-party-apps-to-override-selling-plan-selections-16",
  },
  editorContentTypes: {
    pages: "https://docs.replo.app/docs/content-types/pages",
    shopifySections:
      "https://docs.replo.app/docs/content-types/shopify-sections",
    productTemplates:
      "https://support.replo.app/hc/en-us/articles/17881635030797-Product-Templates",
  },
  dynamicData:
    "https://support.replo.app/hc/en-us/articles/17131155971213-Dynamic-Data",
  permissions:
    "https://support.replo.app/hc/en-us/articles/25180416846861-Permissions-In-Replo",
  swatches: "https://docs.replo.app/docs/building/swatches",
  faq: "https://docs.replo.app/docs/getting-started/faq",
  shopifyMaxProductsOnPage:
    "https://support.replo.app/articles/8672651932-how-many-shopify-products-can-be-on-one-page",
  multiCanvas:
    "https://support.replo.app/articles/5169277701-multi-device-editing",
  billingPlans:
    "https://support.replo.app/articles/2238970734-what-replo-plan-is-right-for-me",
  dataAttributes:
    "https://support.replo.app/articles/1799213363-custom-development-with-replo#adding-data-attributes-to-replo-dom-nodes-18",
};
