import type { SvgProps } from "replo-utils/react/components";

import * as React from "react";

import { Svg } from "replo-utils/react/components";

export const SvgCornerRounding = (props: SvgProps) => (
  <Svg width="1em" height="1em" viewBox="0 0 9 9" fill="none" {...props}>
    <path stroke="#94A3B8" d="M0 1h4a4 4 0 0 1 4 4v4" />
  </Svg>
);
export default SvgCornerRounding;
