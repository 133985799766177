import * as React from "react";

import { BsXLg } from "react-icons/bs";
import { useMatchMedia } from "replo-utils/react/use-match-media";

import { PreviewBadge } from "../../shared/PreviewBadge";

const PreviewBanner: React.FC = () => {
  const [bannerOpen, setBannerOpen] = React.useState(true);
  const isDesktop = useMatchMedia("(min-width: 1024px)");

  if (!bannerOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        display: "flex",
        zIndex: 2_147_483_647,
        width: "100%",
        backgroundColor: "#1e293b",
        color: "white",
        textAlign: "center",
        fontSize: isDesktop ? "16px" : "12px",
        padding: "24px",
        flexDirection: isDesktop ? "row" : "column",
      }}
      className="alchemy-reset"
    >
      <a
        href="http://replo.app?utm_source=element-preview"
        aria-label="Preview by Replo"
        style={{
          ...(isDesktop
            ? {
                position: "absolute",
                bottom: 55,
                left: 55,
              }
            : null),
        }}
      >
        <PreviewBadge />
      </a>
      <div
        style={{
          margin: "auto",
          maxWidth: 700,
        }}
      >
        <p>
          This is a page preview by Replo. Go ahead and take a look at your
          soon-to-be masterpiece. Dismiss this popover at any time.
        </p>
        <p>
          If you have any questions about page previews, reach out to{" "}
          <a
            style={{ color: "#93c5fd", textDecoration: "none" }}
            href="mailto:support@replo.app"
          >
            support@replo.app
          </a>
          .
        </p>
      </div>
      <button
        type="button"
        aria-label="Dismiss banner"
        onClick={() => {
          setBannerOpen(false);
        }}
        // cancel out browser's button styles
        style={{
          display: "flex",
          position: "absolute",
          top: 20,
          right: 20,
          cursor: "pointer",
          WebkitAppearance: "button",
          backgroundColor: "initial",
          backgroundImage: "none",
          border: 0,
          boxShadow: "none",
          padding: 0,
          margin: 0,
          color: "inherit",
        }}
      >
        <BsXLg height={20} width={20} />
      </button>
    </div>
  );
};

export default PreviewBanner;
