import { trpc } from "@editor/utils/trpc";

import { skipToken } from "@tanstack/react-query";

export type WorkspaceUrlHost = {
  data: {
    value: string;
    label: string;
  }[];
  isLoading: boolean;
};

const useWorkspaceUrlHosts = (workspaceId: string | null): WorkspaceUrlHost => {
  const { data, isLoading } = trpc.analytics.getUrlHosts.useQuery(
    workspaceId ? { workspaceId } : skipToken,
  );

  return {
    data: (data ?? []).map((host) => ({
      value: host,
      label: host,
    })),
    isLoading,
  };
};

export default useWorkspaceUrlHosts;
