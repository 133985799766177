import type { Digits, LowercaseAlphabet } from "replo-runtime/shared/types";

export type HotkeyAction =
  | "copy"
  | "copyStyles"
  | "paste"
  | "pasteStyles"
  | "toggleVisibility"
  | "delete"
  | "debug"
  | "duplicate"
  | "undo"
  | "redo"
  | "groupIntoContainer"
  | "groupIntoContainerTwo"
  | "saveComponentTemplate"
  | "mockSave"
  | "zoomIn"
  | "zoomOut"
  | "resetZoom"
  | "grabCanvas"
  | "deselectCurrentComponent"
  | "preventSelectingAllTextsOnEditor"
  | "togglePreviewMode"
  | "escape"
  | "toggleAIMenu"
  | "toggleBoldText"
  | "toggleLinkText"
  | "toggleBulletList"
  | "toggleNumberedList"
  | "toggleH1Text"
  | "toggleH2Text"
  | "toggleH3Text"
  | "toggleH4Text"
  | "toggleH5Text"
  | "toggleH6Text"
  | "toggleItalicText"
  | "toggleUnderlineText"
  | "toggleStrikethroughText"
  | "setDesignPanel"
  | "setConfigPanel"
  | "setInteractionsPanel"
  | "setLayersPanel"
  | "setComponentsPanel"
  | "setSavedStylesPanel"
  | "setElementsPanel"
  | "openHotkeysModal"
  | "decreaseFontSize"
  | "increaseFontSize"
  | "decreaseLetterSpacing"
  | "increaseLetterSpacing"
  | "decreaseLineHeight"
  | "increaseLineHeight"
  | "decreaseFontWeight"
  | "increaseFontWeight"
  | "toggleCodeEditor"
  | "toggleVersionHistory"
  | "openProjectSettings"
  | "openPageSettings"
  | "moveUpInTheTree"
  | "moveDownInTheTree"
  | "setWidthToFillAvailable"
  | "setWidthToWrapContent"
  | "setHeightToFillAvailable"
  | "setHeightToWrapContent"
  | "openMarketplace"
  | "exportToSection"
  | "enter"
  | "editText"
  | "selectAIText"
  | "selectAIMobileResponsive";

export type HotkeyMetaKey =
  | "meta"
  | "shift"
  | "altOption"
  | "backspace"
  | "delete"
  | "space"
  | "esc"
  | "arrowUp"
  | "arrowDown"
  | "arrowLeft"
  | "arrowRight";

export type HotkeyEditorData = {
  controlStrings: string[];
  indicatorCharacters: (
    | HotkeyMetaKey
    | LowercaseAlphabet
    | Digits
    | "-"
    | "+"
    | "="
    | "<"
    | ">"
    | "↑"
    | "↓"
  )[];
  ignoresModifiersOnKeyup: boolean;
  displayName: string;
  triggerInTextInputs: boolean;
  group?: "Text" | "Edit" | "View" | "Zoom";
};

export const getHotkeyToEditorData = (
  // TODO (Sebas, 2024-11-13): Remove this once we remove the saved styles feature flag.
  isSavedStylesEnabled: boolean,
): Record<HotkeyAction, HotkeyEditorData> => ({
  undo: {
    controlStrings: ["mod+z"],
    indicatorCharacters: ["meta", "z"],
    displayName: "Undo",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  redo: {
    controlStrings: ["mod+shift+z"],
    indicatorCharacters: ["meta", "shift", "z"],
    displayName: "Redo",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  copy: {
    controlStrings: ["mod+c"],
    indicatorCharacters: ["meta", "c"],
    displayName: "Copy",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  copyStyles: {
    controlStrings: ["alt+mod+c"],
    indicatorCharacters: ["altOption", "meta", "c"],
    displayName: "Copy styles",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  paste: {
    controlStrings: ["mod+v"],
    indicatorCharacters: ["meta", "v"],
    displayName: "Paste",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  pasteStyles: {
    controlStrings: ["alt+mod+v"],
    indicatorCharacters: ["altOption", "meta", "v"],
    displayName: "Paste styles",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleVisibility: {
    controlStrings: ["mod+shift+h"],
    indicatorCharacters: ["meta", "shift", "h"],
    displayName: "Toggle visibility",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  delete: {
    controlStrings: ["backspace", "delete"],
    indicatorCharacters: ["backspace"],
    displayName: "Delete",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  duplicate: {
    controlStrings: ["mod+d"],
    indicatorCharacters: ["meta", "d"],
    displayName: "Duplicate",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  debug: {
    controlStrings: ["mod+shift+o"],
    indicatorCharacters: ["meta", "shift", "o"],
    displayName: "Debug",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  groupIntoContainer: {
    controlStrings: ["mod+g"],
    indicatorCharacters: ["meta", "g"],
    displayName: "Group into container",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  groupIntoContainerTwo: {
    controlStrings: ["shift+a"],
    indicatorCharacters: ["shift", "a"],
    displayName: "Group into container",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  saveComponentTemplate: {
    controlStrings: ["mod+shift+s"],
    indicatorCharacters: ["meta", "shift", "s"],
    displayName: "Save component template",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  mockSave: {
    controlStrings: ["mod+s"],
    indicatorCharacters: ["meta", "s"],
    displayName: "Save",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  zoomIn: {
    controlStrings: ["mod+="],
    indicatorCharacters: ["meta", "+"],
    displayName: "Zoom in",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  zoomOut: {
    controlStrings: ["mod+-"],
    indicatorCharacters: ["meta", "-"],
    displayName: "Zoom out",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  resetZoom: {
    controlStrings: ["mod+0"],
    indicatorCharacters: ["meta", "0"],
    displayName: "Reset zoom",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Zoom",
  },
  grabCanvas: {
    // Note (Noah, 2023-04-22, USE-77): We want to ignore modifier keys on keyup
    // here because the keydown and keyup for this hotkey are different - keydown
    // enables grab mode and keyup disables it. If we don't ignore modifiers on
    // keyup, then holding down space, then pressing shift, then releasing space
    // will result in the keyup not being called and the grab mode will be stuck.
    ignoresModifiersOnKeyup: true,
    controlStrings: ["space"],
    indicatorCharacters: ["space"],
    displayName: "Pan canvas",
    triggerInTextInputs: false,
    group: "View",
  },
  deselectCurrentComponent: {
    controlStrings: ["escape"],
    indicatorCharacters: ["esc"],
    displayName: "Deselect component",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleCodeEditor: {
    controlStrings: ["shift+d"],
    indicatorCharacters: ["shift", "d"],
    displayName: "Toggle code editor",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "Edit",
  },
  preventSelectingAllTextsOnEditor: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["mod+a"],
    indicatorCharacters: ["meta", "a"],
    displayName: "Prevent selecting all texts on editor",
    triggerInTextInputs: false,
  },
  togglePreviewMode: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["shift+p"],
    indicatorCharacters: ["shift", "p"],
    displayName: "Toggle preview",
    triggerInTextInputs: false,
    group: "View",
  },
  toggleVersionHistory: {
    controlStrings: ["alt+shift+h"],
    indicatorCharacters: ["altOption", "shift", "h"],
    displayName: "Show version history",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  escape: {
    ignoresModifiersOnKeyup: false,
    controlStrings: ["escape"],
    indicatorCharacters: ["esc"],
    displayName: "Escape",
    triggerInTextInputs: false,
    group: "Edit",
  },
  toggleAIMenu: {
    controlStrings: ["mod+e"],
    indicatorCharacters: ["meta", "e"],
    displayName: "Replo AI",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  toggleH1Text: {
    controlStrings: ["mod+alt+1"],
    indicatorCharacters: ["meta", "altOption", "1"],
    displayName: "Turn into H1",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH2Text: {
    controlStrings: ["mod+alt+2"],
    indicatorCharacters: ["meta", "altOption", "2"],
    displayName: "Turn into H2",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH3Text: {
    controlStrings: ["mod+alt+3"],
    indicatorCharacters: ["meta", "altOption", "3"],
    displayName: "Turn into H3",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH4Text: {
    controlStrings: ["mod+alt+4"],
    indicatorCharacters: ["meta", "altOption", "4"],
    displayName: "Turn into H4",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH5Text: {
    controlStrings: ["mod+alt+5"],
    indicatorCharacters: ["meta", "altOption", "5"],
    displayName: "Turn into H5",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleH6Text: {
    controlStrings: ["mod+alt+6"],
    indicatorCharacters: ["meta", "altOption", "6"],
    displayName: "Turn into H6",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleBoldText: {
    controlStrings: ["mod+b"],
    indicatorCharacters: ["meta", "b"],
    displayName: "Bold",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleLinkText: {
    controlStrings: ["mod+k"],
    indicatorCharacters: ["meta", "k"],
    displayName: "Create link",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleItalicText: {
    controlStrings: ["mod+i"],
    indicatorCharacters: ["meta", "i"],
    displayName: "Italic",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleUnderlineText: {
    controlStrings: ["mod+u"],
    indicatorCharacters: ["meta", "u"],
    displayName: "Underline",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleStrikethroughText: {
    controlStrings: ["shift+mod+x"],
    indicatorCharacters: ["shift", "meta", "x"],
    displayName: "Strikethrough",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleBulletList: {
    controlStrings: ["mod+alt+7"],
    indicatorCharacters: ["meta", "altOption", "7"],
    displayName: "Turn into a list",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  toggleNumberedList: {
    controlStrings: ["mod+alt+8"],
    indicatorCharacters: ["meta", "altOption", "8"],
    displayName: "Turn into a numbered list",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  setDesignPanel: {
    controlStrings: ["s"],
    indicatorCharacters: ["s"],
    displayName: "Show design panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setConfigPanel: {
    controlStrings: ["d"],
    indicatorCharacters: ["d"],
    displayName: "Show config panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setInteractionsPanel: {
    controlStrings: ["i"],
    indicatorCharacters: ["i"],
    displayName: "Show interactions panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setElementsPanel: {
    controlStrings: ["alt+1"],
    indicatorCharacters: ["altOption", "1"],
    displayName: "Show pages panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setLayersPanel: {
    controlStrings: ["alt+2"],
    indicatorCharacters: ["altOption", "2"],
    displayName: "Show layers panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setComponentsPanel: {
    controlStrings: ["alt+3"],
    indicatorCharacters: ["altOption", "3"],
    displayName: "Show components panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  setSavedStylesPanel: {
    controlStrings: [isSavedStylesEnabled ? "alt+4" : ""],
    indicatorCharacters: ["altOption", "4"],
    displayName: "Show saved styles panel",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openPageSettings: {
    controlStrings: [isSavedStylesEnabled ? "alt+5" : "alt+4"],
    indicatorCharacters: ["altOption", isSavedStylesEnabled ? "5" : "4"],
    displayName: "Open page settings",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openProjectSettings: {
    controlStrings: ["mod+alt+s"],
    indicatorCharacters: ["meta", "altOption", "s"],
    displayName: "Open project settings",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  openHotkeysModal: {
    controlStrings: ["shift+?"],
    indicatorCharacters: ["shift"],
    displayName: "Show hotkeys modal",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  decreaseFontSize: {
    controlStrings: ["shift+mod+,", "shift+mod+<"],
    indicatorCharacters: ["shift", "meta", "<"],
    displayName: "Decrease font size",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseFontSize: {
    controlStrings: ["shift+mod+.", "shift+mod+>"],
    indicatorCharacters: ["shift", "meta", ">"],
    displayName: "Increase font size",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseLetterSpacing: {
    controlStrings: ["alt+,", "alt+<"],
    indicatorCharacters: ["altOption", "<"],
    displayName: "Decrease letter spacing",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseLetterSpacing: {
    controlStrings: ["alt+.", "alt+>"],
    indicatorCharacters: ["altOption", ">"],
    displayName: "Increase letter spacing",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseLineHeight: {
    controlStrings: ["alt+shift+,", "alt+shift+<"],
    indicatorCharacters: ["altOption", "shift", "<"],
    displayName: "Decrease line height",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseLineHeight: {
    controlStrings: ["alt+shift+.", "alt+shift+>"],
    indicatorCharacters: ["altOption", "shift", ">"],
    displayName: "Increase line height",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  decreaseFontWeight: {
    controlStrings: ["alt+mod+,", "alt+mod+<"],
    indicatorCharacters: ["altOption", "meta", "<"],
    displayName: "Decrease font weight",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  increaseFontWeight: {
    controlStrings: ["alt+mod+.", "alt+mod+>"],
    indicatorCharacters: ["altOption", "meta", ">"],
    displayName: "Increase font weight",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Text",
  },
  moveUpInTheTree: {
    controlStrings: ["ArrowUp"],
    indicatorCharacters: ["↑"],
    displayName: "Move up in the tree",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  moveDownInTheTree: {
    controlStrings: ["ArrowDown"],
    indicatorCharacters: ["↓"],
    displayName: "Move down in the tree",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  exportToSection: {
    controlStrings: ["alt+mod+k"],
    indicatorCharacters: ["altOption", "meta", "k"],
    displayName: "Export to section",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setWidthToFillAvailable: {
    controlStrings: ["mod+shift+arrowRight"],
    indicatorCharacters: ["meta", "shift", "arrowRight"],
    displayName: "Fill Available Width",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setWidthToWrapContent: {
    controlStrings: ["mod+shift+arrowLeft"],
    indicatorCharacters: ["meta", "shift", "arrowLeft"],
    displayName: "Wrap Content Width",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setHeightToFillAvailable: {
    controlStrings: ["mod+shift+arrowUp"],
    indicatorCharacters: ["meta", "shift", "arrowUp"],
    displayName: "Fill Available Height",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  setHeightToWrapContent: {
    controlStrings: ["mod+shift+arrowDown"],
    indicatorCharacters: ["meta", "shift", "arrowDown"],
    displayName: "Wrap Content Height",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
    group: "Edit",
  },
  openMarketplace: {
    controlStrings: ["alt+mod+o"],
    indicatorCharacters: ["altOption", "meta", "o"],
    displayName: "Open marketplace",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
    group: "View",
  },
  enter: {
    controlStrings: ["Enter"],
    indicatorCharacters: [],
    displayName: "Enter",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  editText: {
    controlStrings: ["Enter"],
    indicatorCharacters: [],
    displayName: "Edit Text",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: true,
  },
  // TODO (Gabe 2024-08-09): Get these out of here, there's no reason to define
  // component specific hotkeys so far away from the components.
  selectAIText: {
    controlStrings: ["t"],
    indicatorCharacters: [],
    displayName: "Select AI Text",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
  selectAIMobileResponsive: {
    controlStrings: ["r"],
    indicatorCharacters: [],
    displayName: "Select AI Mobile Responsive",
    ignoresModifiersOnKeyup: false,
    triggerInTextInputs: false,
  },
});

export const getHotkeyEditorData = (
  action: HotkeyAction,
  // TODO (Sebas, 2024-11-13): Remove this once we remove the saved styles feature flag.
  isSavedStylesEnabled: boolean,
): HotkeyEditorData => {
  const data = { ...getHotkeyToEditorData(isSavedStylesEnabled)[action] };
  return data;
};
