import * as React from "react";

import { WorkspaceDashboardContext } from "@editor/contexts/WorkspaceDashboardContext";
import useCurrentUser from "@editor/hooks/useCurrentUser";
import { routes } from "@editor/utils/router";

import { Navigate, useLocation, useParams } from "react-router-dom";
import { validate as isUUIDValid } from "uuid";

const isWorkspacePath = (path: string) => {
  return new RegExp(`\\/workspace\\/.*\\/[a-zA-Z0-9]*`).test(path);
};

const WorkspaceProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { user } = useCurrentUser();
  const { workspaceId: workspaceIdFromParams } = useParams();
  const lastVisitedWorkspaceId = localStorage.getItem(
    "replo.workspace.lastvisitedWorkspaceId",
  );

  const isValidWorkspaceIdFromParams = workspaceIdFromParams
    ? isUUIDValid(workspaceIdFromParams)
    : false;
  const isValidLastVisitedWorkspaceId = lastVisitedWorkspaceId
    ? isUUIDValid(lastVisitedWorkspaceId)
    : false;

  let defaultWorkspaceId = null;
  if (isValidWorkspaceIdFromParams) {
    defaultWorkspaceId = workspaceIdFromParams!;
  } else if (isValidLastVisitedWorkspaceId) {
    defaultWorkspaceId = lastVisitedWorkspaceId!;
  }

  const [workspaceId, setWorkspaceId] = React.useState<string | null>(
    defaultWorkspaceId,
  );

  // Adds an extra async default value if workspaceId is not set via params or localstorage
  React.useEffect(() => {
    if (!workspaceId && user?.workspace) {
      setWorkspaceId(user.workspace.id);
    }
  }, [workspaceId, user?.workspace]);

  // Update last visited workspace id in localstorage when needed
  React.useEffect(() => {
    if (!workspaceId) {
      return;
    }
    localStorage.setItem("replo.workspace.lastvisitedWorkspaceId", workspaceId);
  }, [workspaceId]);

  if (!isValidWorkspaceIdFromParams && isWorkspacePath(location.pathname)) {
    return <Navigate to={routes.dashboard} />;
  }

  return (
    <WorkspaceDashboardContext.Provider
      value={{
        workspaceId,
        setWorkspaceId,
      }}
    >
      {children}
    </WorkspaceDashboardContext.Provider>
  );
};

export default WorkspaceProvider;
