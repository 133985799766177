import type { SavedTextStyle } from "schemas/generated/designLibrary";
import type { SavedStyleTextAttributes } from "schemas/generated/savedStyles";

import * as React from "react";

import SavedStyleItemEndEnhancer from "@editor/components/designLibrary/SavedStyleItemEndEnhancer";
import SavedStyleRow from "@editor/components/designLibrary/SavedStyleRow";
import { SavedStyleSkeleton } from "@editor/components/designLibrary/SavedStyleSkeleton";
import { TextSavedStyleModifier } from "@editor/components/designLibrary/TextSavedStyleModifier";
import useDeleteDesignLibrarySavedStyle from "@editor/hooks/designLibrary/useDeleteDesignLibrarySavedStyle";
import useGetDesignLibrarySavedStyles from "@editor/hooks/designLibrary/useGetDesignLibrarySavedStyles";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { generateTextSavedStylePreviewValue } from "@editor/utils/designLibrary";
import { trpc } from "@editor/utils/trpc";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { v4 as uuidv4 } from "uuid";

import { TEXT_MENU_ITEMS, TEXT_STYLE_DEFAULTS } from "../constants/textStyles";

type TextStyleGroupProps = {
  overrideSavedStyles?: SavedTextStyle[];
  isLoading?: boolean;
};

export const TextStyleGroup: React.FC<TextStyleGroupProps> = ({
  overrideSavedStyles,
  isLoading = false,
}) => {
  const [isTextModifierPopoverOpen, setIsTextModifierPopoverOpen] =
    React.useState(false);
  const [editingSavedStyleId, setEditingSavedStyleId] = React.useState<
    string | null
  >(null);

  const { textSavedStyles } = useGetDesignLibrarySavedStyles();

  const isEditable = !Boolean(overrideSavedStyles);
  const projectId = useCurrentProjectId();
  const utils = trpc.useUtils();
  const { mutateAsync: createStyleMutation } =
    trpc.designLibrary.savedStyles.create.useMutation({
      onSuccess: () => {
        void utils.designLibrary.get.invalidate();
      },
    });

  const generateNewName = (baseName: string): string => {
    const existingLetters = savedStyles
      .filter((style) => style.name.startsWith(baseName))
      .map((style) => {
        const match = style.name.match(/ ([a-z])$/);
        return match ? match[1] : "";
      })
      .filter(Boolean);

    if (existingLetters.length === 0) {
      return `${baseName} a`;
    }

    const highestLetter = existingLetters.sort().pop() || "a";
    const nextLetter = String.fromCharCode(highestLetter.charCodeAt(0) + 1);
    return `${baseName} ${nextLetter}`;
  };

  const handleItemClick = async (name: string) => {
    if (!projectId || !isEditable) {
      return null;
    }

    const newName = generateNewName(name);
    const savedStyleToCreate = {
      id: uuidv4(),
      name: newName,
      attributes: TEXT_STYLE_DEFAULTS[name] || { type: "text", htmlTag: "p" },
    };

    setIsTextModifierPopoverOpen(true);
    setEditingSavedStyleId(savedStyleToCreate.id);
    await createStyleMutation({
      projectId,
      type: "text",
      ...savedStyleToCreate,
    });
  };

  const savedStyles = overrideSavedStyles ?? textSavedStyles;

  const menuItems = TEXT_MENU_ITEMS.map(({ id, title }) => ({
    id,
    title,
    onSelect: () => void handleItemClick(id),
    type: "leaf" as const,
    isDisabled: false,
  }));

  return (
    <>
      {isEditable && (
        <TextSavedStyleModifier
          isPopoverOpen={isTextModifierPopoverOpen}
          onPopoverOpenChange={setIsTextModifierPopoverOpen}
          editingSavedStyle={
            textSavedStyles.find(
              (savedStyle) => savedStyle.id === editingSavedStyleId,
            ) ?? null
          }
        />
      )}
      <ModifierGroup
        title="Text Styles"
        tooltipText="Add new text style"
        isDefaultOpen={savedStyles.length > 0 || isLoading}
        isCollapsible={savedStyles.length > 0}
        menuItems={isEditable ? menuItems : undefined}
      >
        <div className="flex flex-col gap-1">
          {isLoading ? (
            <SavedStyleSkeleton />
          ) : (
            savedStyles.map((savedStyle, index) => (
              <div
                key={index}
                className="flex items-center gap-2 px-2 py-1 hover:bg-hover cursor-pointer rounded"
              >
                <SavedStylePaneRow
                  savedStyle={savedStyle}
                  type="text"
                  setEditingSavedStyleId={(id) => {
                    setEditingSavedStyleId(id);
                    setIsTextModifierPopoverOpen(true);
                  }}
                />
              </div>
            ))
          )}
        </div>
      </ModifierGroup>
    </>
  );
};

const SavedStylePaneRow: React.FC<{
  type: "text";
  savedStyle: SavedTextStyle & { id?: string };
  setEditingSavedStyleId: (id: string | null) => void;
}> = ({ type, savedStyle, setEditingSavedStyleId }) => {
  const { deleteSavedStyle, isLoading } = useDeleteDesignLibrarySavedStyle();

  return (
    <SavedStyleRow
      name={savedStyle.name}
      value={generateTextSavedStylePreviewValue(
        savedStyle.attributes as SavedStyleTextAttributes,
      )}
      type={type}
      isLoading={isLoading}
      endEnhancer={
        // NOTE (Fran 2024-11-14): In the case we were showing the imported or duplicated saved styles,
        // we don't have an id. And we don't want to show the end enhancer in that case, because they
        // are not editable.
        savedStyle.id && (
          <SavedStyleItemEndEnhancer
            type={type}
            savedStyleAttributes={savedStyle.attributes}
            name={savedStyle.name}
            id={savedStyle.id}
            deleteSavedStyle={deleteSavedStyle}
            setEditingSavedStyleId={setEditingSavedStyleId}
          />
        )
      }
    />
  );
};
