// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { EditorRootState } from "@editor/store";
import type {
  ImportWarning,
  ProgressStep,
} from "@reducers/figma-to-replo-reducer";

import * as React from "react";

import { errorToast } from "@editor/components/common/designSystem/Toast";
import { LEFT_BAR_WIDTH } from "@editor/components/editor/constants";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useUpdateOrCreateAssetMutation } from "@editor/reducers/api-reducer";
import {
  selectDraftElement_warningThisWillRerenderOnEveryUpdate,
  selectIsShopifyIntegrationEnabled,
  setDraftElement,
  undoOperation,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import { trpc } from "@editor/utils/trpc";
import {
  reset as resetFigmaState,
  selectIsOpenPanel,
  selectProgress,
  selectWarnings,
  setPanelVisibility,
} from "@reducers/figma-to-replo-reducer";

import { addListener } from "@reduxjs/toolkit";
import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import {
  BsCheck,
  BsChevronDown,
  BsFillExclamationCircleFill,
  BsFillQuestionCircleFill,
  BsInfoCircleFill,
  BsUpload,
  BsX,
} from "react-icons/bs";
import { forEachComponentAndDescendants } from "replo-runtime";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { pluralize } from "replo-utils/lib/pluralize";

const FigmaImportLoadingAnimation = ({
  isAnimated = true,
}: {
  isAnimated: boolean;
}) => {
  const style = {
    "--cube-color": "var(--replo-color-blue-400)",
    "--cube-before-color": "var(--replo-color-blue-600)",
    "--cube-after-color": "var(--replo-color-blue-800)",
    "--animationPlayState": isAnimated ? "running" : "paused",
  } as React.CSSProperties;
  return (
    <div className="relative m-[2em] h-[84px] w-[84px]" style={style}>
      <div className="replo-cubes-loading-box">
        <div className="replo-cubes-loading-cube one"></div>
        <div className="replo-cubes-loading-cube two"></div>
        <div className="replo-cubes-loading-cube three"></div>
      </div>
    </div>
  );
};

export const progressStepsMap: Record<ProgressStep, string> = {
  initializing: "Initializing",
  uploadingImages: "Uploading Assets",
  importComplete: "Import completed! ✅",
};

const MissingFontRow: React.FC<{
  fontFamily: string;
  uploadAndReplaceFont: (font: File, fontFamily: string) => Promise<void>;
}> = ({ fontFamily, uploadAndReplaceFont }) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  // Note (Evan, 2024-08-07): Maintain state independent of the RTK isLoading here
  // since we do some additional processing after the RTK upload is complete to
  // actually set the font
  const [requestState, setRequestState] = React.useState<
    "preRequest" | "loading" | "complete"
  >("preRequest");

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const fontFile = event.target.files && event.target.files[0];
    if (!fontFile) {
      return;
    }

    setRequestState("loading");

    void uploadAndReplaceFont(fontFile, fontFamily)
      .then(() => {
        setRequestState("complete");
      })
      .catch(() => {
        errorToast(
          "Error",
          `An error occurred uploading ${fontFamily}. Please try again or contact support.`,
        );
        setRequestState("preRequest");
      });
  };

  const isLoading = requestState === "loading";

  return (
    <div className="flex flex-row justify-between items-center gap-1">
      {fontFamily}
      {isShopifyIntegrationEnabled &&
        (requestState !== "complete" ? (
          <div className="w-[72px] shrink-0">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                fileInputRef.current?.click();
              }}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <div className="flex flex-row items-center gap-1">
                <BsUpload size={12} />
                <span className="text-slate-600">Upload</span>
              </div>
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              accept=".woff,.woff2"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center text-green-800 w-[72px] shrink-0">
            <BsCheck size={24} />
          </div>
        ))}
    </div>
  );
};

const CollapsibleWarningDetails: React.FC<{
  icon: React.ReactNode;
  title: string;
  content: React.ReactNode;
  shouldPreventOpenAndClose?: boolean;
}> = ({ icon, title, content, shouldPreventOpenAndClose }) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  return (
    <div className="transition-all">
      <div className="text-xs flex flex-row items-center justify-between">
        <div className="flex flex-row gap-2 items-center">
          {icon}
          <span className="text-sm text-default font-semibold">{title}</span>
        </div>
        <button
          onClick={() => {
            if (!shouldPreventOpenAndClose) {
              setMenuOpen(!isMenuOpen);
            }
          }}
        >
          <BsChevronDown
            size={12}
            className={classNames("text-slate-600 transition-all", {
              "rotate-180": isMenuOpen,
            })}
          />
        </button>
      </div>
      {isMenuOpen && <div className="mt-2 px-2">{content}</div>}
    </div>
  );
};

const MissingFontsWarningDetails: React.FC<{ missingFonts: string[] }> = ({
  missingFonts,
}) => {
  // Note (Evan, 2024-08-07): We don't want to allow closing the menu
  // once an upload has begun
  const [hasUploadBegun, setHasUploadBegun] = React.useState(false);

  const [updateOrCreateAsset] = useUpdateOrCreateAssetMutation();
  const projectId = useCurrentProjectId();
  const { refetch: refetchUploadedFonts } = trpc.store.getFonts.useQuery(
    projectId ? { storeId: projectId } : skipToken,
  );
  const applyComponentAction = useApplyComponentAction();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const store = useEditorStore();

  const pluralFonts = pluralize({
    singular: "font",
    plural: "fonts",
    count: missingFonts.length,
  });

  const message = `${missingFonts.length} missing ${pluralFonts}`;

  const uploadAndReplaceFont = React.useCallback(
    async (font: File, fontFamily: string) => {
      setHasUploadBegun(true);
      const uploadResult = await updateOrCreateAsset({
        file: font,
        filename: font.name,
        storeId: projectId ?? "",
        sourceType: "files",
        displayName: fontFamily,
      });

      if ("error" in uploadResult) {
        throw new Error("Error uploading font");
      }

      const { data: uploadedFonts } = await refetchUploadedFonts({
        throwOnError: true,
      });

      if (!uploadedFonts) {
        throw new Error("Error fetching fonts");
      }

      const uploadedFont = uploadedFonts.find(
        (font) => font.displayName === fontFamily,
      );

      if (!uploadedFont) {
        throw new Error("Error fetching uploaded font");
      }

      // Note (Evan, 2024-08-06): Set all components with the given font
      const draftElement =
        selectDraftElement_warningThisWillRerenderOnEveryUpdate(
          store.getState(),
        );

      const actions: UseApplyComponentActionType[] = [];
      forEachComponentAndDescendants(draftElement.component, (component) => {
        if (
          component.type === "text" &&
          component.props.style?.fontFamily === fontFamily
        ) {
          actions.push({
            componentId: component.id,
            type: "setStyles",
            value: {
              fontFamily: uploadedFont.name,
            },
          });
        }
      });

      applyComponentAction({
        type: "applyCompositeAction",
        value: actions,
      });
    },
    [
      updateOrCreateAsset,
      projectId,
      refetchUploadedFonts,
      store,
      applyComponentAction,
    ],
  );

  return (
    <CollapsibleWarningDetails
      icon={<BsFillQuestionCircleFill size={16} className="text-default" />}
      title={message}
      content={
        <div className="text-sm text-slate-400 flex flex-col gap-2 max-h-[160px] overflow-y-scroll">
          {!isShopifyIntegrationEnabled && (
            <div className="text-xs text-default font-medium">
              Connect a Shopify store to upload fonts.
            </div>
          )}
          {missingFonts.map((fontFamily) => (
            <MissingFontRow
              fontFamily={fontFamily}
              key={fontFamily}
              uploadAndReplaceFont={uploadAndReplaceFont}
            />
          ))}
        </div>
      }
      shouldPreventOpenAndClose={hasUploadBegun}
    />
  );
};

const MultipleFillsWarningDetails: React.FC<{ nodeNames: string[] }> = ({
  nodeNames,
}) => {
  const message =
    nodeNames.length === 1
      ? `Layer "${nodeNames[0]}" has multiple fills. Only the top fill has been imported.`
      : `${nodeNames.length} layers have multiple fills. Only the top fill for each layer has been imported.`;

  return (
    <CollapsibleWarningDetails
      icon={<BsInfoCircleFill size={16} className="text-blue-600 shrink-0" />}
      title="Multiple fills detected"
      content={<div className="text-slate-400 text-xs">{message}</div>}
    />
  );
};

const MaskWarningDetails: React.FC<{ nodeNames: string[] }> = ({
  nodeNames,
}) => {
  const message =
    nodeNames.length === 1
      ? `Layer "${nodeNames[0]}" was not imported.`
      : `${nodeNames.length} layers were not imported.`;

  return (
    <CollapsibleWarningDetails
      icon={<BsInfoCircleFill size={16} className="text-blue-600 shrink-0" />}
      title="Mask detected"
      content={<div className="text-slate-400 text-xs">{message}</div>}
    />
  );
};

const ImageUploadWarningDetails: React.FC<{ reploComponentIds: string[] }> = ({
  reploComponentIds,
}) => {
  const message = `Error uploading ${reploComponentIds.length} images`;
  return (
    <div className="flex flex-row gap-2 items-center">
      <BsFillExclamationCircleFill
        size={16}
        className="text-orange-700 shrink-0"
      />
      <span className="text-sm text-default font-semibold">{message}</span>
    </div>
  );
};

const WarningDetails: React.FC<{ warning: ImportWarning }> = ({ warning }) => {
  return exhaustiveSwitch(warning)({
    missingFonts: ({ fonts }) => (
      <MissingFontsWarningDetails missingFonts={fonts} />
    ),
    multipleFills: ({ nodeNames }) => (
      <MultipleFillsWarningDetails nodeNames={nodeNames} />
    ),
    mask: ({ nodeNames }) => <MaskWarningDetails nodeNames={nodeNames} />,
    uploadError: ({ reploComponentIds }) => (
      <ImageUploadWarningDetails reploComponentIds={reploComponentIds} />
    ),
  });
};

const FigmaImportPanelWrapper = () => {
  const isOpenPanel = useEditorSelector(selectIsOpenPanel);
  return isOpenPanel && <FigmaImportPanelContent />;
};

const FigmaImportPanelContent = () => {
  const dispatch = useEditorDispatch();
  const progress = useEditorSelector(selectProgress);
  const warnings = useEditorSelector(selectWarnings);
  const logEvent = useLogAnalytics();

  const importInProgress = !["importComplete", "importCancelled"].includes(
    progress,
  );

  const onClose = React.useCallback(() => {
    dispatch(setPanelVisibility(false));
    dispatch(resetFigmaState());
  }, [dispatch]);

  // Note (Evan, 2024-08-23): Add a listener here that will close the panel
  // when the draft element changes (since it's no longer relevant)
  React.useEffect(() => {
    const unsubscribe = dispatch(
      addListener({
        predicate: (action, _currentState, _previousState) => {
          if (!setDraftElement.match(action)) {
            return false;
          }

          // Note (Evan, 2024-08-23): Not sure why TS isn't inferring
          // these types here ...
          const currentState = _currentState as EditorRootState;
          const previousState = _previousState as EditorRootState;
          return (
            currentState.core.elements.draftElementId !==
            previousState.core.elements.draftElementId
          );
        },
        effect: () => {
          onClose();
        },
      }),
    );
    return unsubscribe;
  }, [dispatch, onClose]);

  const onConfirm = () => {
    logEvent("editor.paste.figma.confirm", {});
    onClose();
  };

  const onCancel = () => {
    logEvent("editor.paste.figma.cancel", {});
    dispatch(undoOperation());
    onClose();
  };

  return (
    <div
      className="fixed self-stretch w-64 p-4 bg-white rounded-lg shadow flex-col gap-2 inline-flex"
      style={{
        left: LEFT_BAR_WIDTH + 14,
        top: 124,
      }}
    >
      <div className="justify-between items-center flex">
        <p className="text-black text-bold text-sm">Figma Import</p>
        <IconButton
          variant="tertiary"
          icon={<BsX size={16} />}
          tooltipText="Close panel"
          onClick={onConfirm}
        />
      </div>
      <div className="h-[208px] flex flex-col justify-between">
        <div className="justify-center items-center flex">
          {importInProgress ? (
            <FigmaImportLoadingAnimation isAnimated={importInProgress} />
          ) : (
            <img
              className="h-[154px] w-[130px]"
              src="/images/figma/import-complete.svg"
              alt="Blue and white megaphone with confetti"
            />
          )}
        </div>
        <div className="p-2 gap-2 flex flex-col">
          <div className="text-center text-blue-600 text-sm font-semibold">
            {progressStepsMap[progress]}
          </div>
          {importInProgress && (
            <div className="text-center text-slate-400 text-xs font-medium">
              This may take a moment
            </div>
          )}
        </div>
      </div>

      {warnings.length > 0 && (
        <div className="bg-slate-50 rounded p-2 mb-2 flex flex-col gap-3">
          {warnings.map((warning, i) => (
            <WarningDetails warning={warning} key={i} />
          ))}
        </div>
      )}
      <Button
        onClick={onConfirm}
        variant="primary"
        size="base"
        data-testid="looks-good-button"
        tooltipText={importInProgress ? undefined : "Keep the imported design"}
        aria-label="Looks good"
        isLoading={importInProgress}
        isDisabled={importInProgress}
      >
        Looks Great!
      </Button>
      <Button
        onClick={onCancel}
        variant="secondary"
        size="base"
        isDisabled={importInProgress}
        data-testid="figma-cancel-button"
        tooltipText="Undo the import"
        aria-label="Cancel"
      >
        Cancel
      </Button>
    </div>
  );
};

export default FigmaImportPanelWrapper;
