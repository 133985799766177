export const CHOOSE_LINK_OPTION = {
  label: "Choose link",
  value: "chooseLink",
};

export const CREATE_NEW_LINK_OPTION = {
  label: "Create new link",
  value: "createNewLink",
};

export const SUBSECTION_OPTIONS = [CHOOSE_LINK_OPTION, CREATE_NEW_LINK_OPTION];
