import * as React from "react";

import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Slider from "@editor/components/common/designSystem/Slider";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import {
  selectDraftComponentId,
  selectTextOutline,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { DraggingTypes } from "@editor/utils/editor";
import { getIntegerValueFromString } from "@editor/utils/getIntegerValueFromString";
import {
  getTextOutlineObject,
  getTextOutlineString,
} from "@editor/utils/textOutline";
import SolidColorSelector from "@editorComponents/SolidColorSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { LengthInputSelector } from "@editorModifiers/LengthInputModifier";

import { BsBorderWidth } from "react-icons/bs";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";

// NOTE (Fran 2024-10-07): This will be part of the text style modifier
const TextOutlineModifierDeprecated: React.FC = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const draftComponentTextOutline = useEditorSelector(selectTextOutline);
  // NOTE (Sebas, 2024-04-23): This ref is used to prevent the modifier from closing when setting
  // 0px as the value for the text outline width.
  const draftComponentTextOutlineRef = React.useRef(draftComponentTextOutline);
  const textOutline = draftComponentTextOutline
    ? getTextOutlineObject(draftComponentTextOutline)
    : null;

  const handleInputChange = (value: string, inputType: "width" | "color") => {
    let textOutlineString = null;

    if (value && value !== "0px") {
      const newTextOutline = {
        width: textOutline?.width || "1px",
        color: textOutline?.color || "#000000",
        [inputType]: value,
      };
      textOutlineString = getTextOutlineString(newTextOutline);
    }
    const componentId = selectDraftComponentId(store.getState());
    applyComponentAction({
      componentId,
      type: "setStyles",
      value: {
        __textStroke: textOutlineString,
      },
    });
  };

  return (
    <ModifierGroup
      title="Text Outline"
      isDefaultOpen={Boolean(draftComponentTextOutlineRef.current)}
    >
      <div className="grid columns-2 gap-2">
        <div className="col-span-2 flex flex-col gap-2">
          <LabeledControl label="Size" size="sm">
            <div className="flex gap-2">
              <LengthInputSelector
                metrics={CSS_LENGTH_TYPES}
                className="col-span-1"
                startEnhancer={() => <BsBorderWidth />}
                minDragValues={{ px: 0 }}
                minValues={{ px: 0 }}
                maxValues={{ px: 30 }}
                maxDragValues={{ px: 30 }}
                field="width"
                resetValue="0px"
                anchorValue="0px"
                placeholder="0px"
                draggingType={DraggingTypes.Vertical}
                value={textOutline?.width ?? null}
                onChange={(value: string) => handleInputChange(value, "width")}
                previewProperty="__textStroke"
                previewSubProperty="width"
              />
              <Slider
                value={getIntegerValueFromString(textOutline?.width ?? null)}
                debounce
                minimum={0}
                maximum={30}
                onChange={(value: number) =>
                  handleInputChange(`${value.toString()}px`, "width")
                }
              />
            </div>
          </LabeledControl>
          <LabeledControl label="Color" size="sm">
            <SolidColorSelector
              popoverTitle="Color"
              value={textOutline?.color ?? null}
              onChange={(value: string) => handleInputChange(value, "color")}
            />
          </LabeledControl>
        </div>
      </div>
    </ModifierGroup>
  );
};

export default TextOutlineModifierDeprecated;
