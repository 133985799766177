import type { MenuItem } from "@editor/components/common/designSystem/Menu";
import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { LayoutPreset, LayoutPresetChild } from "@editor/types/modifiers";
import type { Component } from "schemas/component";
import type { LayoutControlType } from "schemas/modifiers";
import type { PreviewableProperty } from "schemas/preview";

import * as React from "react";

import ToggleGroup from "@common/designSystem/ToggleGroup";
import LabeledControl from "@editor/components/common/designSystem/LabeledControl";
import Selectable from "@editor/components/common/designSystem/Selectable";
import { useGetModifierControls } from "@editor/hooks/rightBar/useGetModifierControls";
import { useApplyComponentAction } from "@editor/hooks/useApplyComponentAction";
import {
  selectAlignItems,
  selectColumnGap,
  selectComponentChildrenPrivateDimensions,
  selectDisplay,
  selectDraftComponentAcceptsArbitraryChildren,
  selectDraftComponentChildren,
  selectDraftComponentType,
  selectFlexGap,
  selectFlexWrap,
  selectGridColumnEndTemplate,
  selectIsDraftComponentDynamic,
  selectNonTextComponentText,
  selectNumberOfColumns,
  selectOverflow,
  selectParentFlexDirection,
  selectPrivateOverflow,
  selectRowGap,
} from "@editor/reducers/core-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { DraggingDirections } from "@editor/utils/editor";
import {
  flipFlexStartAndFlexEnd,
  useAlignItems,
  useFlexDirection,
  useFlexJustifyState,
  useJustifyContent,
} from "@editor/utils/flex";
import ModifierGroup from "@editorExtras/ModifierGroup";
import LengthInputModifier, {
  LengthInputSelector,
} from "@editorModifiers/LengthInputModifier";
import { layouts } from "@editorModifiers/utils";

import classNames from "classnames";
import map from "lodash-es/map";
import { BsArrowDownShort, BsArrowRightShort } from "react-icons/bs";
import { PiArrowUUpRight } from "react-icons/pi";
import { mapNull } from "replo-runtime/shared/utils/optional";
import { CSS_LENGTH_TYPES } from "replo-runtime/shared/utils/units";
import { componentTypeToRenderData } from "replo-runtime/store/components";
import { twMerge } from "tailwind-merge";

import ModifierLabel from "../extras/ModifierLabel";

type LayoutPresets = {
  preview: (
    layoutPreset: LayoutPreset,
    onClick: (layoutPreset: LayoutPreset) => void,
    shouldAllowPreset: boolean,
    isSelected: boolean,
  ) => React.ReactNode;
  layouts: LayoutPreset[];
};

type Layout = "flex" | "grid";

const VALUE_TO_ALIGNMENT_OPTION: Record<string, string> = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  "flex-start": "flex-start",
  "flex-end": "flex-end",
  "space-between": "space-between",
};

const FLEX_OPTIONS = [
  {
    label: "Auto Layout",
    value: "flex",
    tooltipContent: "Horizontal/Vertical Stack",
  },
  {
    label: "Columns",
    value: "grid",
    tooltipContent: "Grid with Adjustable Columns",
  },
];

const LayoutModifier: React.FC = () => {
  const store = useEditorStore();
  const applyComponentAction = useApplyComponentAction();
  const [controls, addControl] = useGetModifierControls<"layout">("layout");
  const draftComponentType = useEditorSelector(selectDraftComponentType);
  const nonTextComponentText = useEditorSelector(selectNonTextComponentText);
  const flexGap = useEditorSelector(selectFlexGap) || 0;
  const overflow = useEditorSelector(selectPrivateOverflow);
  const columnGap = useEditorSelector(selectColumnGap);
  const rowGap = useEditorSelector(selectRowGap);
  const displayValue = useEditorSelector(selectDisplay);
  const parentFlexDirection = useEditorSelector(selectParentFlexDirection);

  const activeTab = displayValue === "grid" ? "grid" : "flex";
  const allowsLayoutModification =
    mapNull(draftComponentType, (draftComponentType) => {
      const allowsLayoutModificationOrFunction =
        componentTypeToRenderData[draftComponentType]?.allowsLayoutModification;
      if (typeof allowsLayoutModificationOrFunction === "function") {
        return allowsLayoutModificationOrFunction({
          textValue: nonTextComponentText,
        });
      }
      return allowsLayoutModificationOrFunction;
    }) ?? false;

  const getActions = (
    draftComponentChildren: Component[],
    styleProperties?: Record<string, string>,
  ) => {
    const childrenPrivateDimensions = selectComponentChildrenPrivateDimensions(
      store.getState(),
    );
    const actions: UseApplyComponentActionType[] =
      draftComponentChildren.map((component, index) => {
        return {
          componentId: component.id,
          type: "setStyles",
          value: styleProperties ?? {
            width: childrenPrivateDimensions?.[index]?.width.value ?? "auto",
            height: childrenPrivateDimensions?.[index]?.height.value ?? "auto",
          },
          analyticsExtras: {
            actionType: "edit",
            createdBy: "replo",
          },
        };
      }) || [];
    return actions;
  };

  const onChange = (value: Layout) => {
    const draftComponentChildren = selectDraftComponentChildren(
      store.getState(),
    );
    const numberOfColumns =
      selectNumberOfColumns(store.getState()) ??
      Math.min(draftComponentChildren.length, 4);
    if (value === "grid") {
      const actions = getActions(draftComponentChildren, {
        width: "auto",
        height: "auto",
      });
      applyComponentAction({
        type: "applyCompositeAction",
        value: actions.concat({
          type: "setStyles",
          value: {
            display: "grid",
            gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
            __numberOfColumns: numberOfColumns,
            __flexGap: 0,
            columnGap: columnGap ?? flexGap,
            rowGap: rowGap ?? flexGap,
            overflow: null,
          },
        }),
      });
    } else {
      const actions = getActions(draftComponentChildren);
      applyComponentAction({
        type: "applyCompositeAction",
        value: actions.concat({
          type: "setStyles",
          value: {
            display: "flex",
            __flexGap:
              parentFlexDirection === "column" ? columnGap ?? 0 : rowGap ?? 0,
            overflow,
          },
        }),
      });
    }
  };

  const menuItems: MenuItem[] = [
    {
      id: "order",
      title: "Order",
      type: "leaf",
      onSelect: () => {
        addControl("order");
      },
      isDisabled: controls.has("order"),
    },
    {
      id: "overflowX",
      title: "Overflow X",
      type: "leaf",
      onSelect: () => {
        addControl("overflowX");
      },
      isDisabled: controls.has("overflowX"),
    },
    {
      id: "overflowY",
      title: "Overflow Y",
      type: "leaf",
      onSelect: () => {
        addControl("overflowY");
      },
      isDisabled: controls.has("overflowY"),
    },
  ];

  return allowsLayoutModification ? (
    <ModifierGroup
      title="Layout"
      menuItems={menuItems}
      tooltipText="Add Overflow or Order"
    >
      <div className="flex flex-col gap-y-2">
        <ToggleGroup
          allowsDeselect={false}
          type="single"
          options={FLEX_OPTIONS}
          value={activeTab}
          onChange={onChange}
          style={{ width: "100%" }}
        />
        {activeTab === "flex" && <FlexLayoutModifier controls={controls} />}
        {activeTab === "grid" && <GridLayoutModifier />}
      </div>
    </ModifierGroup>
  ) : null;
};

const layoutPresets: LayoutPresets = {
  preview: (layoutPreset, onClick, shouldAllowPreset, isSelected) => {
    return (
      <div
        key={layoutPreset.parentStyle.gridTemplateColumns}
        className={twMerge(
          "group flex cursor-pointer flex-row rounded border border-transparent bg-subtle",
          shouldAllowPreset && "hover:border-blue-600",
          isSelected && "border-blue-600",
        )}
        onClick={shouldAllowPreset ? () => onClick(layoutPreset) : undefined}
        style={{ cursor: shouldAllowPreset ? "pointer" : "not-allowed" }}
      >
        {layoutPreset.children.map((child, index) => {
          return (
            <div
              className={twMerge(
                "width-auto m-0.5 flex justify-center rounded-md bg-slate-200 p-1 text-subtle",
                shouldAllowPreset && "group-hover:bg-hover",
                isSelected && "bg-selected ",
              )}
              key={index}
              style={{ ...child.style }}
            >
              {child.style.flexGrow}
            </div>
          );
        })}
      </div>
    );
  },
  layouts,
};

type LayoutPresetSelectorProps = {
  layoutPresets: LayoutPresets;
  isEnabledLayoutPreset: (layoutPreset: LayoutPreset) => boolean;
  onSelectLayoutPreset: (layoutPreset: LayoutPreset) => void;
};

const LayoutPresetSelector: React.FC<LayoutPresetSelectorProps> = ({
  layoutPresets,
  isEnabledLayoutPreset,
  onSelectLayoutPreset,
}) => {
  const gridTemplateColumns = useEditorSelector(selectGridColumnEndTemplate);
  const displayValue = useEditorSelector(selectDisplay);
  const isDisplayGrid = displayValue === "grid";

  return (
    <div className="grid grid-cols-2 gap-2">
      {layoutPresets.layouts.map((layoutPreset) => {
        const shouldAllowPreset = isEnabledLayoutPreset(layoutPreset);
        return layoutPresets.preview(
          layoutPreset,
          onSelectLayoutPreset,
          shouldAllowPreset,
          isDisplayGrid &&
            gridTemplateColumns ===
              layoutPreset.parentStyle.gridTemplateColumns,
        );
      })}
    </div>
  );
};

const FlexLayoutModifier: React.FC<{ controls: Set<LayoutControlType> }> = ({
  controls,
}) => {
  const applyComponentAction = useApplyComponentAction();
  const { value, onChange } = useFlexDirection();

  const onChangeFlexGap = (value: string) => {
    applyComponentAction({
      type: "setStyles",
      value: { __flexGap: value },
    });
  };

  const optionalControls = [
    {
      id: "order",
      label: "Order",
      component: <FlexDirectionToggles />,
      isDisabled: controls.has("order"),
    },
    {
      id: "overflowX",
      label: "Overflow X",
      component: <OverflowSelect type="x" />,
      isDisabled: controls.has("overflowX"),
    },
    {
      id: "overflowY",
      label: "Overflow Y",
      component: <OverflowSelect type="y" />,
      isDisabled: controls.has("overflowY"),
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <div
        className="grid items-center justify-items-start gap-2"
        style={{ gridTemplateColumns: "1fr 1fr" }}
      >
        <div className="grid items-center justify-items-start gap-2 auto-rows-min">
          <ToggleGroup
            allowsDeselect={false}
            type="single"
            style={{
              width: "100%",
            }}
            value={value}
            options={[
              {
                value: "row",
                label: <BsArrowRightShort size={16} />,
                tooltipContent: "Horizontal Direction",
              },
              {
                value: "column",
                label: <BsArrowDownShort size={16} />,
                tooltipContent: "Vertical Direction",
              },
              {
                value: "wrap",
                label: <PiArrowUUpRight size={12} className="rotate-180" />,
                tooltipContent: "Wrap",
              },
            ]}
            onChange={onChange}
          />
          <FlexSpacingToggles />
          <FlexGapInput
            onChange={onChangeFlexGap}
            placeholder="Gap"
            className="flex flex-col gap-1"
          />
        </div>
        <div className="h-full w-full">
          <AlignmentQuadrants />
        </div>
      </div>
      {optionalControls.map((control) => {
        if (controls.has(control.id as LayoutControlType)) {
          return (
            <div className="flex items-center" key={control.id}>
              <ModifierLabel label={control.label} />
              {control.component}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

const options = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
];

const GridLayoutModifier: React.FC = () => {
  const value = (useEditorSelector(selectNumberOfColumns) as number) ?? 1;
  const columnGap = useEditorSelector(selectColumnGap);
  const draftComponentAcceptsArbitraryChildren = useEditorSelector(
    selectDraftComponentAcceptsArbitraryChildren,
  );
  const isDraftComponentDynamic = useEditorSelector(
    selectIsDraftComponentDynamic,
  );

  const shouldAddNewContainer =
    draftComponentAcceptsArbitraryChildren && !isDraftComponentDynamic;

  const rowGap = useEditorSelector(selectRowGap);
  const applyComponentAction = useApplyComponentAction();
  const onChangePreset = (newValue: LayoutPreset) => {
    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        value: newValue.parentStyle,
      },
    ];

    if (shouldAddNewContainer) {
      actions.push({
        type: "applyPresetProps",
        value: newValue,
        analyticsExtras: {
          actionType: "create",
          createdBy: "replo",
        },
      });
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const onNumberOfColumnChange = (value: number) => {
    const layoutPreset: LayoutPreset = {
      children: Array.from({ length: value }).fill({
        style: {},
      }) as LayoutPresetChild[],
      parentStyle: {},
    };

    const actions: UseApplyComponentActionType[] = [
      {
        type: "setStyles",
        value: {
          display: "grid",
          __numberOfColumns: value,
          gridTemplateColumns: `repeat(${value}, minmax(0, 1fr))`,
        },
      },
    ];

    // Note (Ovishek, 2022-11-02): We only add new columns for components that accepts arbitrary children. For dynamic components
    // like product collection they repeat dynamically based on the dynamic data it has been assigned that's why we ignore adding
    // new container to such component type
    if (shouldAddNewContainer) {
      actions.push({
        type: "applyPresetProps",
        value: layoutPreset,
      });
    }

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <LayoutPresetSelector
        layoutPresets={layoutPresets}
        onSelectLayoutPreset={onChangePreset}
        isEnabledLayoutPreset={() => {
          return true;
        }}
      />
      <LengthInputModifier
        value={String(value ?? 1)}
        className="flex-grow"
        placeholder="1"
        field="style.__numberOfColumns"
        label={<ModifierLabel label="Columns" />}
        dragTrigger="label"
        resetValue="1"
        anchorValue="1"
        draggingDirection={DraggingDirections.Positive}
        allowsNegativeValue={false}
        minDragValues={{ "": 0 }}
        minValues={{ "": 0 }}
        onChange={onNumberOfColumnChange}
        menuOptions={options}
        metrics={[""]}
      />
      <FlexGapInput
        value={columnGap ? String(columnGap) : undefined}
        onChange={(value) => {
          applyComponentAction({
            type: "setStyles",
            value: {
              columnGap: value,
            },
          });
        }}
        label="Col Gap"
        placeholder="Column Gap"
        previewProperty="columnGap"
        className="flex flex-row items-center"
      />
      <FlexGapInput
        value={rowGap ? String(rowGap) : undefined}
        onChange={(value) => {
          applyComponentAction({
            type: "setStyles",
            value: {
              rowGap: value,
            },
          });
        }}
        label="Row Gap"
        placeholder="Row Gap"
        previewProperty="rowGap"
        className="flex flex-row items-center"
      />
    </div>
  );
};

const FlexSpacingToggles: React.FC = () => {
  const [value, setValue] = useFlexJustifyState();
  const rawAlignItems = useEditorSelector(selectAlignItems);
  const alignItems = VALUE_TO_ALIGNMENT_OPTION[rawAlignItems || "center"];
  const applyComponentAction = useApplyComponentAction();

  return (
    <LabeledControl label="Spacing" size="sm" containerClassName="w-full">
      <ToggleGroup
        type="single"
        value={value}
        allowsDeselect={false}
        options={[
          {
            label: "Packed",
            value: "packed",
            tooltipContent: "Group items together as close as possible",
          },
          {
            label: "Spaced",
            value: "spaced",
            tooltipContent: "Distribute the space between items equally",
          },
        ]}
        onChange={(newValue: "packed" | "spaced") => {
          const justifyContent =
            newValue === "packed" ? "center" : "space-between";

          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems,
              justifyContent,
            },
          });
          setValue(newValue);
        }}
        style={{ width: "100%" }}
      />
    </LabeledControl>
  );
};

const AlignmentQuadrants: React.FC = () => {
  const [value] = useFlexJustifyState();

  return (
    <div className="flex h-full flex-col rounded-sm" style={{ width: "110px" }}>
      {value === "packed" ? (
        <div className="grid h-full grid-cols-3 bg-subtle p-2">
          {[0, 1, 2].flatMap((rowNum) => {
            return [0, 1, 2].map((colNum) => {
              return (
                <PackedAlignmentQuadrant
                  key={`${rowNum}-${colNum}`}
                  row={rowNum}
                  col={colNum}
                />
              );
            });
          })}
        </div>
      ) : (
        <div className="grid h-full grid-cols-3 bg-subtle p-2">
          {map([0, 1, 2], (idx) => {
            return <SpacedAlignmentQuadrant idx={idx} />;
          })}
        </div>
      )}
    </div>
  );
};

const quadrantAlignmentClassNamesInOrder = [
  "items-start",
  "items-center",
  "items-end",
];
const alignmentValuesInOrder = ["flex-start", "center", "flex-end"];

type PackedAlignmentQuadrantProps = {
  row: number;
  col: number;
};

const PackedAlignmentQuadrant: React.FC<PackedAlignmentQuadrantProps> = ({
  row,
  col,
}) => {
  const applyComponentAction = useApplyComponentAction();
  const { orientation } = useFlexDirection();
  const justifyContent = useJustifyContent();
  const alignItems = useAlignItems();
  const { isReversed } = useFlexDirection();

  if (orientation === "row") {
    const justifyOption = alignmentValuesInOrder[col];
    const alignOption = alignmentValuesInOrder[row];
    const isSelected =
      justifyContent === justifyOption && alignItems === alignOption;
    const barBgColorClassName = isSelected
      ? "bg-blue-600"
      : "bg-subtle group-hover:bg-hover";

    return (
      <div
        key={`${row}-${col}`}
        className={`group flex flex-row ${quadrantAlignmentClassNamesInOrder[row]} cursor-pointer rounded`}
        onClick={() => {
          const effectiveJustifyOption = isReversed
            ? flipFlexStartAndFlexEnd(justifyOption!)
            : justifyOption;
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignOption,
              justifyContent: effectiveJustifyOption,
            },
          });
        }}
      >
        <div
          className={classNames("mx-0.5 h-7 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-9 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-5 w-2 rounded", barBgColorClassName)}
        />
      </div>
    );
  } else if (orientation === "column") {
    const justifyOption = alignmentValuesInOrder[row];
    const alignOption = alignmentValuesInOrder[col];
    const isSelected =
      justifyContent === justifyOption && alignItems === alignOption;
    const barBgColorClassName = isSelected
      ? "bg-blue-600"
      : "bg-subtle group-hover:bg-hover";

    return (
      <div
        key={`${row}-${col}`}
        className={`group flex flex-col ${quadrantAlignmentClassNamesInOrder[col]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignOption,
              justifyContent: justifyOption,
            },
          });
        }}
      >
        <div
          className={classNames("my-0.5 h-2 w-6 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-8 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-4 rounded", barBgColorClassName)}
        />
      </div>
    );
  }
  return null;
};

const SpacedAlignmentQuadrant: React.FC<{ idx: number }> = ({ idx }) => {
  const applyComponentAction = useApplyComponentAction();
  const { orientation } = useFlexDirection();
  const justifyContent = useJustifyContent();
  const alignItems = useAlignItems();

  const isSelected =
    justifyContent === "space-between" &&
    alignItems === alignmentValuesInOrder[idx];
  const barBgColorClassName = isSelected
    ? "bg-blue-600"
    : "bg-subtle group-hover:bg-hover";

  if (orientation === "row") {
    return (
      <div
        key={idx}
        className={`group col-span-3 col-start-1 flex flex-row justify-between ${quadrantAlignmentClassNamesInOrder[idx]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignmentValuesInOrder[idx],
              justifyContent: "space-between",
            },
          });
        }}
      >
        <div
          className={classNames("mx-0.5 h-7 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-9 w-2 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("mx-0.5 h-5 w-2 rounded", barBgColorClassName)}
        />
      </div>
    );
  } else if (orientation === "column") {
    return (
      <div
        key={idx}
        className={`group flex h-28 flex-col justify-between ${quadrantAlignmentClassNamesInOrder[idx]} cursor-pointer rounded`}
        onClick={() => {
          applyComponentAction({
            type: "setStyles",
            value: {
              display: "flex",
              alignItems: alignmentValuesInOrder[idx],
              justifyContent: "space-between",
            },
          });
        }}
      >
        <div
          className={classNames("my-0.5 h-2 w-6 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-8 rounded", barBgColorClassName)}
        />
        <div
          className={classNames("my-0.5 h-2 w-4 rounded", barBgColorClassName)}
        />
      </div>
    );
  }
  return null;
};

const FlexDirectionToggles: React.FC = () => {
  const { isReversed, onReverseChange } = useFlexDirection();

  return (
    <ToggleGroup
      allowsDeselect={false}
      type="single"
      style={{
        width: "100%",
      }}
      value={isReversed ? "reverse" : "forward"}
      options={[
        {
          value: "forward",
          label: <span className="text-xs">Forward</span>,
          tooltipContent: "Forward Order",
        },
        {
          value: "reverse",
          label: <span className="text-xs">Reverse</span>,
          tooltipContent: "Reverse Order",
        },
      ]}
      onChange={onReverseChange}
    />
  );
};

const overflowOptions = [
  { label: "Show", value: "visible" },
  { label: "Wrap", value: "_wrapLines" },
  { label: "Scroll", value: "scroll" },
  { label: "Hidden", value: "hidden" },
];

type OverflowOptionValue = (typeof overflowOptions)[number]["value"];

const OverflowSelect: React.FC<{
  type: "x" | "y";
}> = ({ type }) => {
  const applyComponentAction = useApplyComponentAction();

  const overflow = useEditorSelector(selectOverflow);
  const flexWrap = useEditorSelector(selectFlexWrap);

  const [xValue, yValue] = overflow?.split(" ") ?? [];

  const getCurrentValue = () => {
    if (flexWrap === "wrap") {
      return "_wrapLines";
    }
    if (type === "x") {
      return xValue;
    }
    return yValue;
  };

  const currentValue = getCurrentValue();

  const getNewOverflow = (type: "x" | "y", newOrDefaultValue = "visible") => {
    // TODO (Sebas, 2024-10-02, REPL-13903): We should re-think this in case there is a better way to handle overflow.
    if (type === "x") {
      return `${newOrDefaultValue} ${yValue ?? "visible"}`;
    }
    return `${xValue ?? "visible"} ${newOrDefaultValue}`;
  };
  const shouldDisable = type === "x" && flexWrap === "wrap";

  return (
    <Selectable
      className="w-full"
      value={currentValue ?? "visible"}
      options={overflowOptions}
      isDisabled={shouldDisable}
      onSelect={(value) => {
        switch (value) {
          case "_wrapLines":
            return applyComponentAction({
              type: "setStyles",
              value: {
                overflow: getNewOverflow(type, "visible"),
                flexWrap: "wrap",
                __overflow: getNewOverflow(type, "visible"),
              },
            });
          default:
            return applyComponentAction({
              type: "setStyles",
              value: {
                overflow: getNewOverflow(type, value as OverflowOptionValue),
                flexWrap: "nowrap",
                __overflow: getNewOverflow(type, value as OverflowOptionValue),
              },
            });
        }
      }}
    />
  );
};

type FlexGapInputProps = {
  onChange(value: string): void;
  previewProperty?: PreviewableProperty;
  placeholder: string;
  value?: string;
  label?: string;
  className?: string;
};

const FlexGapInput: React.FC<FlexGapInputProps> = (props) => {
  const flexGapDefaultValue = props.value;
  const options = [
    { value: "0px", label: "Reset" },
    { value: "8px", label: "8px" },
    { value: "16px", label: "16px" },
    { value: "24px", label: "24px" },
  ];

  return (
    <LengthInputSelector.Root
      value={flexGapDefaultValue}
      field="style.__flexGap"
      resetValue="0px"
      anchorValue="0px"
      draggingDirection={DraggingDirections.Positive}
      allowsNegativeValue={false}
      minDragValues={{ px: 0 }}
      minValues={{ px: 0 }}
      onChange={props.onChange}
      metrics={CSS_LENGTH_TYPES}
      previewProperty={props.previewProperty ?? "flexGap"}
    >
      <div className={props.className}>
        <LengthInputSelector.DraggableArea>
          <ModifierLabel label={props.label ?? "Gap"} />
        </LengthInputSelector.DraggableArea>
        <LengthInputSelector.DraggableArea>
          <LengthInputSelector.Input
            menuOptions={options}
            placeholder={props.placeholder}
            className="w-full"
          />
        </LengthInputSelector.DraggableArea>
      </div>
    </LengthInputSelector.Root>
  );
};

export default LayoutModifier;
