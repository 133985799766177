import type { ReactNode } from "react";
import type { ExperimentStatus } from "schemas/experiment";

import * as React from "react";

import Separator from "@editor/components/common/designSystem/Separator";
import EditableHeader from "@editor/components/dashboard/EditableHeader";
import Header from "@editor/components/dashboard/Header";
import { useExperimentApi } from "@editor/components/projectDashboard/experiments/common";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { trpc, trpcUtils } from "@editor/utils/trpc";

import { StatusTag } from "@/features/experiments/components/StatusTag";
import { useExperimentDetails } from "@/features/experiments/tabs/hooks/useExperimentDetails";
import { skipToken as reactQuerySkipToken } from "@tanstack/react-query";
import classNames from "classnames";
import { BsArrowLeft } from "react-icons/bs";

type ExperimentsLayoutProps = {
  children: ReactNode;
  headerTitle?: string;
  backNavigation?: {
    label: string;
    onClick: () => void;
  };
  headerActions?: ReactNode;
  status?: ExperimentStatus;
  editable?: boolean;
};

export const ExperimentsLayout = ({
  headerTitle,
  editable,
  children,
  backNavigation,
  headerActions,
  status,
}: ExperimentsLayoutProps) => {
  const workspaceId = useCurrentWorkspaceId();
  const { isLoadingExperiment, experiment } = useExperimentDetails();
  const { data: workspaceData, isPending: isLoadingWorkspace } =
    trpc.workspace.getById.useQuery(
      workspaceId ? { id: workspaceId } : reactQuerySkipToken,
    );
  const isLoadingRequiredData =
    isLoadingWorkspace ||
    (editable && headerTitle !== undefined && isLoadingExperiment);

  const { update: updateExperiment } = useExperimentApi({
    workspaceId: workspaceId ?? "",
    projectId: null,
  });

  const title =
    headerTitle ?? `${workspaceData?.workspace?.name}'s Experiments`;

  return (
    <div className="flex flex-col px-6 w-full relative overflow-hidden gap-4">
      <div
        className={classNames("flex", {
          "justify-between": backNavigation,
          "justify-end": !backNavigation,
        })}
      >
        {backNavigation && (
          <button
            className="flex items-center justify-center gap-1 font-medium text-sm text-blue-600"
            onClick={backNavigation.onClick}
          >
            <BsArrowLeft size={18} />
            {backNavigation.label}
          </button>
        )}
      </div>
      <div className="flex flex-col gap-2.5">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 items-center">
            {editable ? (
              <EditableHeader
                title={title}
                onTitleChange={async (newName) => {
                  await updateExperiment.mutateAsync({
                    id: experiment?.id ?? "",
                    workspaceId: workspaceId ?? "",
                    name: newName,
                  });
                  await trpcUtils.experiment.findDetail.invalidate({
                    experimentId: experiment?.id ?? "",
                    workspaceId: workspaceId ?? "",
                  });
                }}
                isLoading={isLoadingRequiredData}
              />
            ) : (
              <Header title={title} isLoading={isLoadingRequiredData} />
            )}
            {status && <StatusTag status={status} />}
          </div>
          <div className="flex gap-4 items-center justify-center">
            {headerActions}
          </div>
        </div>
        <Separator />
      </div>
      {children}
    </div>
  );
};
