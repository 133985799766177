// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { MenuItem } from "@common/designSystem/Menu";
import type { EditorRootState } from "@editor/store";
import type { EditorRoute } from "@editor/utils/router";
import type { ReploElementMetadata } from "schemas/element";
import type { ReploElement, ReploElementType } from "schemas/generated/element";

import * as React from "react";

import { updateAndSaveElement } from "@actions/core-actions";
import {
  Group,
  GroupHeader,
  GroupHeaderActionButton,
  GroupTitleContainer,
} from "@common/designSystem/Group";
import Input from "@common/designSystem/Input";
import { Menu } from "@common/designSystem/Menu";
import { TextTab } from "@common/TextTab";
import { elementTypeToEditorData } from "@components/editor/element";
import ResizablePane from "@components/ResizablePane";
import { useIsDebugMode } from "@editor/components/editor/debug/useIsDebugMode";
import { useCurrentProjectContext } from "@editor/contexts/CurrentProjectContext";
import useCurrentProjectId from "@editor/hooks/useCurrentProjectId";
import { useLocalStorageState } from "@editor/hooks/useLocalStorage";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import useSetDraftElement from "@editor/hooks/useSetDraftElement";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import {
  selectDefaultCollapsedElementTypeGroup,
  selectDraftElementType,
  selectElementById,
  selectElementMetadata,
  selectElementMetadataGroupedByType,
  selectElementsMapping,
  selectIsShopifyIntegrationEnabled,
} from "@editor/reducers/core-reducer";
import {
  useEditorDispatch,
  useEditorSelector,
  useEditorStore,
} from "@editor/store";
import {
  getElementFolderName,
  mapReploElementToReploElementMetadata,
  REPLO_ELEMENT_DEFAULT_FOLDER_NAME,
} from "@editor/utils/element";
import {
  getLastSelectedElementIdForTypeForStore,
  saveLastSelectedElementIdForTypeForStore,
} from "@editor/utils/localStorage";
import { routes } from "@editor/utils/router";

import IconButton from "@replo/design-system/components/button/IconButton";
import { Spinner } from "@replo/design-system/components/spinner";
import Tooltip from "@replo/design-system/components/tooltip";
import classNames from "classnames";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import capitalize from "lodash-es/capitalize";
import groupBy from "lodash-es/groupBy";
import isEqual from "lodash-es/isEqual";
import size from "lodash-es/size";
import { BiSearch } from "react-icons/bi";
import { BsFillCircleFill, BsFolder, BsPlus } from "react-icons/bs";
import { RiHomeFill } from "react-icons/ri";
import {
  generatePath,
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useOverridableState } from "replo-runtime/shared/hooks/useOverridableState";
import { removeFolderNameFromElementName } from "replo-utils/element";
import { exhaustiveSwitch } from "replo-utils/lib/misc";
import { useDebouncedCallback } from "replo-utils/react/use-debounced-callback";
import { reploElementTypeSchema } from "schemas/element";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import Separator from "./common/designSystem/Separator";

type PaneInfo = {
  id: string;
  label: string;
  newTooltip: string;
  searchBoxPlaceholder: string;
};

type PaneInfoRecord = Record<ReploElementType, PaneInfo>;

type ElementListProps = {
  groupedElementsByFolderName: Record<string, ReploElementMetadata[]>;
  hasElements?: boolean;
  onClick: (contentItem: ReploElementMetadata) => void;
  currentElementType: ReploElementType;
};

type ElementTypeGroupInfo = {
  type: ReploElementType;
  title: string;
  createTooltipText: string;
  createButtonDataTestId: string;
  groupDataTestId: string;
};

type ElementTypeGroupContentProps = {
  elementsMetadataGroupedByFolder: Record<string, ReploElementMetadata[]>;
  elementTypeGroup: ElementTypeGroupInfo;
  resetElement: (elementId: string) => void;
  isCollapsed: boolean;
  setCollapsedElementTypeGroups: (elementType: ReploElementType | null) => void;
  groupDataTestId: string;
};

const paneInfo: PaneInfoRecord = {
  page: {
    id: "add-page",
    label: "Pages",
    newTooltip: "New Page",
    searchBoxPlaceholder: "Search Pages",
  },
  shopifyArticle: {
    id: "add-article",
    label: "Posts",
    newTooltip: "New Blog Post",
    searchBoxPlaceholder: "Search Posts",
  },
  shopifySection: {
    id: "add-section",
    label: "Sections",
    newTooltip: "New Shopify Section",
    searchBoxPlaceholder: "Search Sections",
  },
  shopifyProductTemplate: {
    id: "add-product-template",
    label: "Product Templates",
    newTooltip: "New Product Template",
    searchBoxPlaceholder: "Search Product Templates",
  },
};

const ELEMENT_TYPE_GROUP_LIST: Array<ElementTypeGroupInfo> = [
  {
    title: "Pages",
    type: "page",
    createTooltipText: "New Page",
    createButtonDataTestId: "add-page",
    groupDataTestId: "pages-group",
  },
  {
    title: "Product Templates",
    type: "shopifyProductTemplate",
    createTooltipText: "New Product Template",
    createButtonDataTestId: "add-product-template",
    groupDataTestId: "product-templates-group",
  },
  {
    title: "Sections",
    type: "shopifySection",
    createTooltipText: "New Shopify Section",
    createButtonDataTestId: "add-section",
    groupDataTestId: "sections-group",
  },
  {
    title: "Blog Posts",
    type: "shopifyArticle",
    createTooltipText: "New Blog Post",
    createButtonDataTestId: "add-article",
    groupDataTestId: "blog-posts-group",
  },
];

const ElementsPane: React.FC = () => {
  const isNewLeftPanelEnabled = checkIfNewEditorPanelsUIIsEnabled();
  if (isNewLeftPanelEnabled) {
    return <NewElementPane />;
  }

  return <DeprecatedElementPane />;
};

const DeprecatedElementPane: React.FC = () => {
  const logEvent = useLogAnalytics();
  const [paneHeight, setPaneHeight] = React.useState(200);

  const reduxStore = useEditorStore();
  const projectId = useCurrentProjectId();
  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );
  const setDraftElement = useSetDraftElement();

  const navigate = useNavigate();
  const { search } = useLocation();

  const {
    elements,
    currentElementType,
    setCurrentElementType,
    currentPaneInfo,
    groupedElementsByFolderName,
  } = useGetElementsByCurrentElementType();

  const visibleElementTypes = useCurrentProjectElementTypes();

  const {
    filteredElements,
    searchTerm,
    setSearchTerm,
    groupedFilteredElementsByFolderName,
  } = useSearchableItems(elements);

  const resetDraftElement = () => {
    setDraftElement({
      componentId: null,
    });
  };

  const resetElement = (elementId: string) => {
    resetDraftElement();
    navigate(
      `${generatePath(routes.editor.element, {
        projectId,
        elementId,
      } as EditorRoute)}${search}`,
    );
  };

  const tabOptions = Object.entries(paneInfo);

  const emptyStateForCurrentElementType = exhaustiveSwitch({
    type: currentElementType,
  })({
    page: "Click the + icon to create a Shopify Page with Replo",
    shopifyArticle:
      "Click the + icon to create a Shopify Blog Article with Replo",
    shopifySection:
      "Click the + icon to create a Shopify OS2 section with Replo",
    shopifyProductTemplate:
      "Click the + icon to create a Product Template with Replo",
  });
  return (
    <div className="flex flex-col w-full">
      <div className="px-2">
        <TextTab
          options={tabOptions.map(([key, value]) => ({
            value: key,
            label: value.label,
            isDisabled:
              !isShopifyIntegrationEnabled &&
              !visibleElementTypes.includes(key),
            tooltipContent:
              !isShopifyIntegrationEnabled && !visibleElementTypes.includes(key)
                ? "Enabled with Shopify store integration"
                : undefined,
          }))}
          selectedValue={currentElementType}
          className="py-2"
          onChange={(value: ReploElementType) => {
            setCurrentElementType(value);
            if (projectId) {
              const lastSelectedElementIdForType =
                getLastSelectedElementIdForTypeForStore(projectId, value);
              const elementsMapping = selectElementsMapping(
                reduxStore.getState(),
              );
              if (
                lastSelectedElementIdForType &&
                elementsMapping &&
                lastSelectedElementIdForType in elementsMapping
              ) {
                // (2024-01-10 Vicky Note): Do not navigate to this element if element does not exist in redux state.
                resetElement(lastSelectedElementIdForType);
              }
            }
          }}
        />
      </div>
      <div className="flex flex-row gap-1 px-2">
        <Input
          autoComplete="off"
          autoFocus
          placeholder={currentPaneInfo.searchBoxPlaceholder}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          onBlur={() => {
            // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
            // unfocuses the search input. This is to avoid logging the search event when
            // the user is typing.
            if (searchTerm.length > 0) {
              logEvent("element.search", {
                searchString: searchTerm,
              });
            }
          }}
          onEnter={() => {
            // NOTE (Reinaldo, 2022-04-21): Select the first page on the list
            // when hitting enter if there's a page on the list and the search
            // input is not empty
            if (
              searchTerm.length > 0 &&
              filteredElements.length > 0 &&
              filteredElements[0]
            ) {
              resetElement(filteredElements[0].id);
              // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
              // press enter becasuse after this the search input will be cleared.
              if (searchTerm.length > 0) {
                logEvent("element.search", {
                  searchString: searchTerm,
                });
              }
              setSearchTerm("");
            }
          }}
          endEnhancer={() => <BiSearch size={12} />}
        />
        <div data-testid={`${currentPaneInfo.id}-button`}>
          <IconButton
            icon={<BsPlus size={16} />}
            tooltipText={currentPaneInfo.newTooltip}
            variant="tertiary"
            onClick={() => navigate(`add?type=${currentElementType}`)}
          />
        </div>
      </div>
      <ResizablePane
        isVertical
        size={paneHeight}
        onResize={setPaneHeight}
        minSize={200}
        maxSize={800}
        contentClassName="flex"
      >
        {filteredElements.length === 0 ? (
          <div
            className="flex p-2 h-fit w-full cursor-pointer flex-wrap text-left text-xs text-subtle"
            onClick={() => {
              navigate(`add?type=${currentElementType}`);
            }}
          >
            <span>{emptyStateForCurrentElementType}</span>
          </div>
        ) : (
          <ElementList
            hasElements={elements.length > 0}
            onClick={(element) => resetElement(element.id)}
            groupedElementsByFolderName={
              searchTerm.length > 0
                ? groupedFilteredElementsByFolderName
                : groupedElementsByFolderName
            }
            currentElementType={currentElementType}
          />
        )}
      </ResizablePane>
    </div>
  );
};

const NewElementPane: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const { search } = useLocation();
  const { elementId } = useParams();
  const navigate = useNavigate();

  const logEvent = useLogAnalytics();
  const setDraftElement = useSetDraftElement();
  const { isLoading, project } = useCurrentProjectContext();
  const projectId = project?.id;

  const elementMetadataGroupedByType = useEditorSelector(
    (state) => selectElementMetadataGroupedByType(state, searchTerm),
    isEqual,
  );

  const resetDraftElement = () => {
    setDraftElement({
      componentId: null,
    });
  };

  const resetElement = (elementId: string) => {
    resetDraftElement();
    navigate(
      `${generatePath(routes.editor.element, {
        projectId,
        elementId,
      } as EditorRoute)}${search}`,
    );
  };

  const defaultCollapsedElementTypeGroups = useEditorSelector((state) =>
    selectDefaultCollapsedElementTypeGroup(state, elementId),
  );

  const [collapsedElementTypeGroups, setCollapsedElementTypeGroups] =
    useLocalStorageState(
      "replo.collapsed-elements-type-groups",
      defaultCollapsedElementTypeGroups,
      {
        schema: z.array(reploElementTypeSchema),
      },
    );

  // NOTE (Fran 2024-10-24): Every time we search, we will add a debounce to open all groups that have
  // results. We don't want to do it every time the user types because it is unnecessary and can
  // result in a performance issue.
  const collapseElementsTypeGroupAfterSearch = useDebouncedCallback(
    (value: string) => {
      if (value.length === 0) {
        setCollapsedElementTypeGroups(defaultCollapsedElementTypeGroups);
      } else {
        const collapsedElementType = new Set() as Set<ReploElementType>;

        for (const [elementType, elements] of Object.entries(
          elementMetadataGroupedByType,
        )) {
          if (size(elements) > 0) {
            collapsedElementType.add(elementType as ReploElementType);
          }
        }

        setCollapsedElementTypeGroups(Array.from(collapsedElementType));
      }
    },
    300,
  );

  if (isLoading) {
    return (
      <div className="grid h-full w-full items-center justify-items-center">
        <Spinner width={2} size={20} />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full gap-3 overflow-y-hidden pl-2 pt-1">
      <div className="pl-1 pr-3">
        <Input
          autoComplete="off"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            // NOTE (Fran 2024-10-23): We want to collapse all the element type groups after the search.
            collapseElementsTypeGroupAfterSearch(e.target.value);
          }}
          onEnter={() => {
            // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
            // press enter becasuse after this the search input will be cleared.
            if (searchTerm.length > 0) {
              logEvent("element.search", {
                searchString: searchTerm,
              });
            }
          }}
          onBlur={() => {
            // NOTE (Sebas, 2024-10-18): We want to log the search event when the user
            // press enter becasuse after this the search input will be cleared.
            if (searchTerm.length > 0) {
              logEvent("element.search", {
                searchString: searchTerm,
              });
            }
          }}
          startEnhancer={() => <BiSearch size={12} />}
        />
      </div>
      <div className="flex flex-col gap-2 overflow-y-hidden h-full">
        {ELEMENT_TYPE_GROUP_LIST.map((elementTypeGroup) => {
          const elementsMetadataGroupedByFolder =
            elementMetadataGroupedByType[elementTypeGroup.type] ?? {};
          const isCollapsed = collapsedElementTypeGroups.includes(
            elementTypeGroup.type,
          );
          return (
            <ElementTypeGroupContent
              key={elementTypeGroup.createButtonDataTestId}
              elementsMetadataGroupedByFolder={elementsMetadataGroupedByFolder}
              elementTypeGroup={elementTypeGroup}
              resetElement={resetElement}
              isCollapsed={isCollapsed}
              groupDataTestId={elementTypeGroup.groupDataTestId}
              setCollapsedElementTypeGroups={(
                elementType: ReploElementType | null,
              ) => {
                const newCollapsedElementTypeGroups = new Set(
                  collapsedElementTypeGroups,
                );
                if (elementType) {
                  newCollapsedElementTypeGroups.add(elementType);
                } else {
                  newCollapsedElementTypeGroups.delete(elementTypeGroup.type);
                }
                setCollapsedElementTypeGroups(
                  Array.from(newCollapsedElementTypeGroups),
                );
              }}
            />
          );
        })}
        <Separator />
      </div>
    </div>
  );
};

const ElementTypeGroupContent: React.FC<
  React.PropsWithChildren<ElementTypeGroupContentProps>
> = ({
  elementsMetadataGroupedByFolder,
  elementTypeGroup,
  resetElement,
  isCollapsed,
  setCollapsedElementTypeGroups,
  groupDataTestId,
}) => {
  const { title, type, createButtonDataTestId, createTooltipText } =
    elementTypeGroup;

  const navigate = useNavigate();

  const isShopifyIntegrationEnabled = useEditorSelector(
    selectIsShopifyIntegrationEnabled,
  );

  const hasElements = Object.values(elementsMetadataGroupedByFolder).length > 0;
  const shouldAllowCreateReploElements =
    type === "page" || isShopifyIntegrationEnabled;

  return (
    <div
      key={type}
      className={classNames(
        "flex flex-col gap-2",
        // NOTE (Fran 2024-10-24): We want to make sure the element list shows at least two elements
        // if there are more than one group collapsed and one of them has several elements.
        isCollapsed && hasElements ? "min-h-28" : "min-h-9",
      )}
    >
      <Separator />
      <Group
        name={title}
        header={
          <GroupHeader
            className="pr-3"
            hideEndEnhancerOnGroupClosed={false}
            endEnhancer={
              <div
                data-testid={`${createButtonDataTestId}-button`}
                className="h-6 w-6"
              >
                <IconButton
                  icon={<BsPlus size={20} className="text-muted" />}
                  className="items-center justify-center p-0"
                  tooltipText={
                    shouldAllowCreateReploElements
                      ? createTooltipText
                      : "Enabled with Shopify store integration"
                  }
                  variant="tertiary"
                  onClick={() => {
                    // NOTE (Fran 2024-11-05): We need to open the group before navigating to the add page
                    // because the group will be collapsed after the navigation.
                    setCollapsedElementTypeGroups(type);
                    navigate(`add?type=${type}`);
                  }}
                  isDisabled={!shouldAllowCreateReploElements}
                />
              </div>
            }
          >
            <GroupTitleContainer groupDataTestId={groupDataTestId} />
          </GroupHeader>
        }
        isCollapsible={hasElements && shouldAllowCreateReploElements}
        isOpen={isCollapsed}
        onOpenChange={(isOpen) => {
          setCollapsedElementTypeGroups(isOpen ? type : null);
        }}
        contentClassName="overflow-y-scroll styled-scrollbar"
        className="overflow-hidden"
      >
        {hasElements && isCollapsed ? (
          <ElementList
            onClick={(element) => resetElement(element.id)}
            groupedElementsByFolderName={elementsMetadataGroupedByFolder}
            currentElementType={type}
          />
        ) : null}
      </Group>
    </div>
  );
};

/* Note (Ovishek, 2022-04-20): Content or Content Item means different types of element
 * such as page, blog article or product template (in the future maybe).
 */
const ElementList: React.FC<React.PropsWithChildren<ElementListProps>> = ({
  groupedElementsByFolderName,
  // TODO (Fran 2024-10-17): Remove this once the new left bar UI is enabled by default.
  hasElements,
  onClick,
  currentElementType,
}) => {
  const isDebugMode = useIsDebugMode();
  const logEvent = useLogAnalytics();
  const { isLoading } = useCurrentProjectContext();
  const [activeElementId, setActiveElementId] = React.useState<string | null>(
    null,
  );

  const navigate = useNavigate();
  const {
    handleDeletion,
    handleDuplication,
    handleEditPage,
    handleMoveToFolder,
    handleSupportDuplication,
  } = usePageModificationHandlers();

  const isNewLeftPanelUIEnabled = checkIfNewEditorPanelsUIIsEnabled();

  if (!isNewLeftPanelUIEnabled && isLoading) {
    return (
      <div className="grid h-full w-full items-center justify-items-center">
        <Spinner width={2} size={20} />
      </div>
    );
  }

  // NOTE (Fran 2024-10-18): We will handle the loading state in the new left bar UI in other component.
  if (isNewLeftPanelUIEnabled && isLoading) {
    return null;
  }

  // TODO (Fran 2024-10-17): Remove this once the new left bar UI is enabled by default.
  // We don't need this anymore, but we need to keep it for now to make sure that the
  // new and the old left bar UI is working as expected. We check if it is equal to false
  // because in the new left bar UI this will be undefined.
  if (hasElements === false) {
    return null;
  }

  const elementsFolderNames = (currentGroupName: string) => {
    return Object.keys(groupedElementsByFolderName)
      .filter(
        (name) =>
          ![currentGroupName, REPLO_ELEMENT_DEFAULT_FOLDER_NAME].includes(name),
      )
      .sort();
  };

  const sortedGroupedElementsByFolderName = Object.entries(
    groupedElementsByFolderName,
  ).sort(([titleOne], [titleTwo]) => {
    if (titleOne === REPLO_ELEMENT_DEFAULT_FOLDER_NAME) {
      return 1;
    }
    if (titleTwo === REPLO_ELEMENT_DEFAULT_FOLDER_NAME) {
      return -1;
    }
    return titleOne.localeCompare(titleTwo);
  });

  return (
    <ul
      className={classNames(
        "overflow-auto flex flex-col gap-1 w-full",
        isNewLeftPanelUIEnabled ? "styled-scrollbar" : "no-scrollbar mt-2",
      )}
      data-testid="elements-list"
    >
      {sortedGroupedElementsByFolderName.map(([title, elements], index) => {
        if (title === REPLO_ELEMENT_DEFAULT_FOLDER_NAME) {
          return elements.map((element) => (
            <Menu
              key={element.id}
              items={getMenuItemsForElementItems({
                element,
                handleDeletion,
                handleDuplication,
                handleSupportDuplication,
                handleEditPage,
                handleMoveToFolder,
                elementsFolderNames: elementsFolderNames(
                  REPLO_ELEMENT_DEFAULT_FOLDER_NAME,
                ),
                isDebugMode,
              })}
              menuType="context"
              onRequestOpen={() => setActiveElementId(element.id)}
              onRequestClose={() => setActiveElementId(null)}
              customWidth="auto"
              trigger={
                <li>
                  <ElementItem
                    element={element}
                    onClick={(element) => {
                      onClick(element);
                      logEvent("element.select", {
                        elementId: element.id,
                        elementType: element.type,
                      });
                      saveLastSelectedElementIdForTypeForStore(
                        element.projectId,
                        element.type,
                        element.id,
                      );
                    }}
                    isFocused={activeElementId === element.id}
                  />
                </li>
              }
            />
          ));
        }

        const actionLabel = `New ${elementTypeToEditorData[currentElementType].singularDisplayName} in ${title}`;
        return (
          <Group
            name={`${title} (${elements.length})`}
            isDefaultOpen
            isCollapsible
            key={`${title}-${index}`}
            contentClassName="py-0"
            header={
              <GroupHeader
                className={classNames(
                  "bg-default sticky top-0 z-10",
                  checkIfNewEditorPanelsUIIsEnabled() ? "pl-2 pr-px" : "px-2",
                )}
                hideEndEnhancerOnGroupClosed={false}
                startEnhancer={
                  isNewLeftPanelUIEnabled ? <BsFolder size={12} /> : undefined
                }
                endEnhancer={
                  <GroupHeaderActionButton
                    aria-label={actionLabel}
                    onClick={() => {
                      const initialName = `${title.trim()}/`;
                      navigate(
                        `add?type=${currentElementType}&initialName=${initialName}`,
                      );
                    }}
                  >
                    <div
                      data-testid={`${title}-${index}-add`}
                      className="h-6 w-6"
                    >
                      <IconButton
                        icon={<BsPlus size={20} className="text-muted" />}
                        tooltipText={actionLabel}
                        className="items-center justify-center p-0"
                        variant="tertiary"
                        // NOTE (Chance, 2023-11-08): This is purely decorative, as
                        // `GroupHeaderActionButton` will render the button element
                        isPhonyButton
                      />
                    </div>
                  </GroupHeaderActionButton>
                }
              >
                <GroupTitleContainer className="h-8" />
              </GroupHeader>
            }
          >
            <div className="flex flex-col gap-1">
              {elements.map((element) => (
                <Menu
                  key={element.id}
                  items={getMenuItemsForElementItems({
                    element,
                    handleDeletion,
                    handleDuplication,
                    handleSupportDuplication,
                    handleEditPage,
                    handleMoveToFolder,
                    elementsFolderNames: elementsFolderNames(title),
                    isDebugMode,
                  })}
                  menuType="context"
                  onRequestOpen={() => setActiveElementId(element.id)}
                  onRequestClose={() => setActiveElementId(null)}
                  customWidth="auto"
                  trigger={
                    <ElementItem
                      element={element}
                      onClick={(element) => {
                        saveLastSelectedElementIdForTypeForStore(
                          element.projectId,
                          element.type,
                          element.id,
                        );
                        onClick(element);
                      }}
                      isFocused={activeElementId === element.id}
                    />
                  }
                />
              ))}
            </div>
          </Group>
        );
      })}
    </ul>
  );
};

const ElementItem: React.FC<{
  element: ReploElementMetadata;
  onClick(element: ReploElementMetadata): void;
  isFocused: boolean;
}> = React.memo(function ElementItem({ element, onClick, isFocused }) {
  const draftElementType = useEditorSelector(selectDraftElementType);
  const { elementId } = useParams();
  const location = useLocation();
  const isCurrentElement = element.id === elementId;
  const ref = React.useRef<HTMLAnchorElement>(null);
  const difference = element.publishedAt
    ? formatDistanceToNowStrict(new Date(element.publishedAt))
    : null;
  const publishedTooltipLabel =
    element.isPublished && difference
      ? `Last Published ${difference} ago`
      : "Not Published";

  React.useEffect(() => {
    if (isCurrentElement) {
      ref.current?.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [isCurrentElement]);

  const { id, name, isHomepage, isTurbo, projectId } = element;

  if (!draftElementType) {
    return null;
  }

  if (!draftElementType) {
    return null;
  }

  const elementHasCategory = name.includes("/");
  const elementName = elementHasCategory
    ? removeFolderNameFromElementName(name)
    : name;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!e.metaKey && !e.ctrlKey) {
      e.preventDefault();
      onClick(element);
    }
  };

  const isNewLeftPanelUIEnabled = checkIfNewEditorPanelsUIIsEnabled();

  return (
    <Link
      ref={ref}
      to={`${generatePath(routes.editor.element, {
        projectId,
        elementId: id,
      })}${location.search}`}
      className={twMerge(
        classNames(
          "group flex h-8 cursor-pointer items-center gap-1 py-1 px-2 text-xs tracking-tight text-black transition duration-300",
          {
            "bg-blue-200 font-semibold": isCurrentElement,
            "bg-blue-50": isFocused && !isCurrentElement,
            "pl-6": elementHasCategory,
            "hover:bg-blue-50": !isCurrentElement,
            "bg-selected font-normal":
              isNewLeftPanelUIEnabled && isCurrentElement,
            "bg-default hover:bg-hover":
              isNewLeftPanelUIEnabled && !isCurrentElement,
            rounded: isNewLeftPanelUIEnabled,
          },
        ),
      )}
      onClick={handleClick}
    >
      <span
        className={twMerge(
          classNames("flex-1 truncate", {
            "text-blue-600": isCurrentElement,
            "text-black":
              (!element.isPublished && !isCurrentElement) ||
              (isNewLeftPanelUIEnabled && isCurrentElement),
          }),
        )}
      >
        {elementName}
      </span>
      {isHomepage && (
        <Tooltip content="This page is set as the Home Page" triggerAsChild>
          <div tabIndex={0}>
            <RiHomeFill size={16} className="text-subtle" />
          </div>
        </Tooltip>
      )}
      {isTurbo && (
        <Tooltip content="This page is using Replo Turbo" triggerAsChild>
          <div
            className="w-4 h-4 bg-subtle rounded-full text-[10px] font-bold leading-none grid place-content-center text-onEmphasis"
            tabIndex={0}
          >
            T
          </div>
        </Tooltip>
      )}
      {element.isPublished && (
        <Tooltip content={publishedTooltipLabel} triggerAsChild>
          {/* Note (Sebas, 2023-11-06): This margin is necessary to align the green dots
              with the content inside the buttons (plus icon) */}
          <div tabIndex={0} className="mr-0.5">
            <BsFillCircleFill size={8} className="text-green-400" />
          </div>
        </Tooltip>
      )}
    </Link>
  );
});

// NOTE (Matt 2024-10-15): this hook is used to show the element types for which
// a user has created elements. It is used to determine whether or not we should
// disable an element type in the ElementsPane if a user does not have Shopify installed.
function useCurrentProjectElementTypes() {
  const { project } = useCurrentProjectContext();
  const allProjectElementTypes =
    project?.elements?.map(({ type }) => type) ?? [];
  // NOTE (Matt 2024-10-15): We always include 'page' by default. This array will include other element
  // types if the user has any elements of that type.
  return [...new Set(["page", ...allProjectElementTypes])];
}

export function getMenuItemsForElementItems(params: {
  element: ReploElementMetadata;
  handleDeletion: (element: ReploElementMetadata) => void;
  handleDuplication: (element: ReploElementMetadata) => void;
  handleSupportDuplication: (element: ReploElementMetadata) => void;
  handleEditPage: (element: ReploElementMetadata) => void;
  handleMoveToFolder: (
    folderName: string,
    element: ReploElementMetadata,
  ) => void;
  elementsFolderNames: string[];
  isDebugMode?: boolean;
}): MenuItem[] {
  const { element } = params;
  const menuItems: MenuItem[] = [
    {
      type: "leaf",
      id: "edit",
      title: `Edit ${
        elementTypeToEditorData[element.type].singularDisplayName
      } Settings`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "rename",
      title: `Rename ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleEditPage(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "duplicate",
      title: `Duplicate ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDuplication(element),
      isDisabled: false,
    },
    {
      type: "leaf",
      id: "delete",
      title: `Delete ${
        elementTypeToEditorData[element.type].singularDisplayName
      }`,
      onSelect: () => params.handleDeletion(element),
      isDisabled: false,
    },
  ];

  if (params.isDebugMode) {
    menuItems.push({
      type: "leaf",
      id: "duplicate.support",
      title: `Duplicate | Support Copy`,
      onSelect: () => params.handleSupportDuplication(element),
      isDisabled: false,
    });
  }

  if (params.elementsFolderNames.length > 0) {
    menuItems.push({
      type: "nested",
      title: `Move ${
        elementTypeToEditorData[element.type].singularDisplayName
      } To Group`,
      items: params.elementsFolderNames.map((name) => {
        return {
          type: "leaf",
          id: name.toLowerCase(),
          title: capitalize(name),
          onSelect: () => params.handleMoveToFolder(name, element),
          isDisabled: false,
        };
      }),
    });
  }
  return menuItems;
}

export function useGetElementsByCurrentElementType() {
  const elementType = useEditorSelector(selectDraftElementType);
  const [currentElementType, setCurrentElementType] =
    useOverridableState<ReploElementType>(elementType);
  const elements = useEditorSelector((state: EditorRootState) => {
    return selectElementMetadata(state, currentElementType);
  }, isEqual);

  const groupedElementsByFolderName = React.useMemo(
    () =>
      groupBy(
        elements.map(mapReploElementToReploElementMetadata),
        getElementFolderName,
      ) as Record<string, ReploElementMetadata[]>,
    [elements],
  );

  return {
    elements,
    currentElementType,
    setCurrentElementType,
    currentPaneInfo: paneInfo[currentElementType],
    groupedElementsByFolderName,
  };
}

function useSearchableItems(elements: ReploElement[]) {
  const [searchTerm, setSearchTerm] = React.useState("");

  const filteredElements = elements.filter((element) => {
    return element.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const groupedFilteredElementsByFolderName = React.useMemo(
    () =>
      groupBy(
        filteredElements.map(mapReploElementToReploElementMetadata),
        getElementFolderName,
      ) as Record<string, ReploElementMetadata[]>,
    [filteredElements],
  );

  return {
    filteredElements,
    searchTerm,
    setSearchTerm,
    groupedFilteredElementsByFolderName,
  };
}

export function usePageModificationHandlers() {
  const store = useEditorStore();
  const modal = useModal();
  const dispatch = useEditorDispatch();
  const { elements } = useGetElementsByCurrentElementType();
  const { filteredElements } = useSearchableItems(elements);

  const handleDeletion = (metadata: ReploElementMetadata) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    modal.openModal({
      type: "deleteElementConfirmationModal",
      props: { type: "delete", element, elements: filteredElements },
    });
  };

  const handleDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "duplicateElementModal",
      props: {
        element,
      },
    });
  };

  const handleSupportDuplication = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "duplicateElementModal",
      props: {
        element,
        isSupportMode: true,
      },
    });
  };

  const handleEditPage = (metadata: ReploElementMetadata) => {
    const element = selectElementById(store.getState(), metadata.id);
    modal.openModal({
      type: "updateElementModal",
      props: {
        element,
      },
    });
  };

  const handleMoveToFolder = (
    groupName: string,
    metadata: ReploElementMetadata,
  ) => {
    const element = selectElementById(
      store.getState(),
      metadata.id,
    ) as ReploElement;
    const oldNameAfterSlash = removeFolderNameFromElementName(element.name);
    return dispatch(
      updateAndSaveElement(element.id, {
        ...element,
        name: `${groupName.trim()}/${oldNameAfterSlash}`,
      }),
    );
  };

  return {
    handleDeletion,
    handleDuplication,
    handleSupportDuplication,
    handleEditPage,
    handleMoveToFolder,
  };
}

export default ElementsPane;
