import type { UseApplyComponentActionType } from "@editor/hooks/useApplyComponentAction";
import type { ReploSpecificComponentIssue } from "@editor/types/component-issues";
import type { GetAttributeFunction } from "@editor/types/get-attribute-function";
import type {
  AncestorComponentData,
  ComponentData,
  ComponentDataMapping,
  ComponentMapping,
  ContainedComponentData,
} from "replo-runtime/shared/Component";
import type { AlchemyActionType } from "replo-runtime/shared/enums";
import type { ComponentMovementSource } from "replo-runtime/shared/utils/dragging";
import type { ProductResolutionDependencies } from "replo-runtime/store/ReploProduct";
import type { Component, ReploComponentType } from "schemas/component";
import type { ReploElement } from "schemas/generated/element";
import type { ConditionField, ReploSymbol } from "schemas/generated/symbol";
import type {
  RuntimeStyleAttribute,
  RuntimeStyleProperties,
} from "schemas/styleAttribute";

import {
  HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE,
  prepareComponentTemplate,
  VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
} from "@components/editor/defaultComponentTemplates";
import { getComponentName } from "@editor/components/editor/component";
import { normalizeFontFamily } from "@editor/components/editor/page/element-editor/components/modifiers/utils";
import { getWrapWithTickerTemplate } from "@editor/components/editor/templates/dynamic/marquee";
import { getWrapWithTooltipTemplate } from "@editor/components/editor/templates/tooltip";
import getIssuesForComponent from "@editor/utils/getIssuesForComponent";
import { hasVariants } from "@utils/component-attribute";
import { getEditorElementRootNode, getEditorModalBodyNode } from "@utils/dom";

import isObject from "lodash-es/isObject";
import isString from "lodash-es/isString";
import startCase from "lodash-es/startCase";
import { resolveSymbolRef } from "replo-runtime/shared/symbol";
import { forEachComponentStyleProps } from "replo-runtime/shared/utils/breakpoints";
import {
  findComponent,
  findComponentInComponent,
  findComponentPath,
  findIndexPathFromComponentToComponent,
  findParent,
  forEachComponentAndDescendants,
  getChildren,
  getCustomPropDefinitions,
} from "replo-runtime/shared/utils/component";
import { getCurrentComponentContext } from "replo-runtime/shared/utils/context";
import { getNormalizedFlexDirection } from "replo-runtime/shared/utils/flexDirection";
import { getFromRecordOrNull } from "replo-runtime/shared/utils/optional";
import {
  componentTypeToRenderData,
  getRenderData,
} from "replo-runtime/store/components";
import { exhaustiveSwitch, hasOwnProperty } from "replo-utils/lib/misc";
import tinycolor from "tinycolor2";

export const findComponentPathById = (
  element?: ReploElement,
  componentId?: string | null,
): string | null => {
  if (!componentId || !element) {
    return null;
  }
  return findComponentPath(element, (c) => c.id === componentId);
};
/**
 * For a given element and target component, return the index path of the deepest
 * ancestor of the target component in the element which matches `test`
 */
export function findAncestorComponentIndexPath(
  element: ReploElement,
  targetComponentId: string,
  test: (component: Component, parent: Component | null) => boolean,
): { indexPath: string | null; component: Component | null } {
  const { indexPath, foundComponent } = findIndexPathFromComponentToComponent(
    element.component,
    null,
    targetComponentId,
    test,
  );
  if (foundComponent !== null) {
    return { indexPath: `components.${indexPath}`, component: foundComponent };
  }
  return { indexPath: null, component: null };
}

export function findAncestorComponent(
  element: ReploElement | null,
  targetComponentId: string | null,
  test: (component: Component, parent: Component | null) => boolean,
): Component | null {
  if (!targetComponentId || !element) {
    return null;
  }
  const { component } = findAncestorComponentIndexPath(
    element,
    targetComponentId,
    test,
  );
  return component;
}

export function findAncestorComponentOrSelf(
  element: ReploElement | null,
  targetComponentId: string | null,
  test: (component: Component, parent: Component | null) => boolean,
): Component | null {
  const targetComponent = findComponentById(element, targetComponentId);
  if (!targetComponent || !element) {
    return null;
  }
  if (test(targetComponent, findParent(element, targetComponentId))) {
    return targetComponent;
  }
  const { component } = findAncestorComponentIndexPath(
    element,
    targetComponent.id,
    test,
  );
  return component;
}

export function findAncestorRepeatedIndex(
  element: ReploElement,
  targetComponent: Component | null,
  repeatedIndex: string | null,
  test: (component: Component) => boolean,
): number | null {
  const ancestorComponent = findAncestorComponentOrSelf(
    element,
    targetComponent?.id ?? null,
    test,
  );

  if (!ancestorComponent || !targetComponent || !repeatedIndex) {
    return null;
  }

  const splittedRepeatedIndex = repeatedIndex.split(".");
  const { indexPath } = findIndexPathFromComponentToComponent(
    ancestorComponent,
    findParent(element, ancestorComponent.id),
    targetComponent.id,
    () => true,
  );

  const ancestorDepth = indexPath ? indexPath.split("children").length - 2 : 0;

  const targetRepeatedIndex =
    splittedRepeatedIndex[splittedRepeatedIndex.length - 1 - ancestorDepth]!;
  return Number.parseInt(targetRepeatedIndex);
}

export function findAncestorComponentOrSelfWithVariants(
  element: ReploElement,
  targetComponentId: Component["id"],
  symbols: Record<string, ReploSymbol>,
) {
  return findAncestorComponentOrSelf(element, targetComponentId ?? null, (c) =>
    hasVariants(c, symbols),
  );
}
export function findAncestorComponentWithVariants(
  element: ReploElement,
  targetComponentId: Component["id"],
  symbols: Record<string, ReploSymbol>,
) {
  return findAncestorComponent(element, targetComponentId ?? null, (c) =>
    hasVariants(c, symbols),
  );
}

export function findSymbolAncestor(
  element: ReploElement,
  ancestorOfComponentId: string,
): Component | null {
  if (!element) {
    return null;
  }
  const target = findComponentById(element, ancestorOfComponentId);
  if (target?.type === "symbolRef") {
    return target;
  }
  return findAncestorComponent(
    element,
    ancestorOfComponentId,
    (component) => component.type === "symbolRef",
  );
}

const findEditorNodes = (
  targetDocument: Document,
  elementId: string | null,
  query: string,
): HTMLElement[] => {
  const rootNode = elementId
    ? getEditorElementRootNode(targetDocument, elementId)
    : targetDocument.body;
  let componentNodes = rootNode?.querySelectorAll<HTMLElement>(query) ?? null;
  if (!componentNodes || componentNodes.length === 0) {
    // Note (Noah, 2022-01-08, modalMountHack): Check both the regular document
    // and the modal mount point, because we render Modal components in a
    // separate React root
    const modalRoot = getEditorModalBodyNode(targetDocument);
    componentNodes = modalRoot
      ? modalRoot.querySelectorAll<HTMLElement>(query)
      : null;
  }
  return componentNodes ? Array.from(componentNodes) : [];
};

export const getEditorComponentNodes = (
  targetDocument: Document,
  elementId: string,
  componentId: string,
): HTMLElement[] => {
  if (!elementId || !componentId) {
    return [];
  }
  const query = `[data-rid="${componentId}"]`;
  return findEditorNodes(targetDocument, elementId, query);
};

export const getEditorComponentNode = (
  targetDocument: Document | null,
  elementId?: string | null,
  componentId?: string | null,
  repeatedId?: string | null,
): HTMLElement | null => {
  if (!componentId || !targetDocument) {
    return null;
  }
  const query = `[data-rid="${componentId}"]${
    repeatedId ? `[data-replo-repeated-index="${repeatedId}"]` : ""
  }`;
  const nodes = findEditorNodes(targetDocument, elementId ?? null, query);
  return nodes[0] ?? null;
};

/**
 * Returns the repeated index just like the component given as componentId but with the index of
 * current index
 */
export const getCurrentRepeatedIndex = (
  targetDocument: Document | null,
  elementId: string,
  componentId: string,
  currentIndex: number | null,
) => {
  const node = targetDocument
    ? getEditorComponentNode(targetDocument, elementId, componentId)
    : null;

  const repeatedIndex = node?.dataset.reploRepeatedIndex as string;
  if (!currentIndex) {
    return repeatedIndex;
  }

  if (!repeatedIndex) {
    return "";
  }

  const splicedArray = repeatedIndex.split(".");
  splicedArray.splice(-1, 1);
  return [...splicedArray, currentIndex.toString()].join(".");
};

export function findComponentById(
  element: { component: Component } | null,
  testId: string | null,
): Component | null {
  if (!element) {
    return null;
  }

  return findComponent(element, (component) => component.id === testId);
}

export function getParentComponentFromMapping(
  componentMapping: ComponentMapping,
  id: string | null,
) {
  if (!id) {
    return null;
  }
  return componentMapping[id]?.parentComponent || null;
}

export function findComponentByTypeInElement(
  element: { component: Component } | null,
  type: ReploComponentType,
): Component | null {
  if (!element) {
    return null;
  }
  return findComponent(element, (component) => component.type === type);
}

/**
 * Find the test component by ID if it exists inside component, else null
 */
export const findComponentByIdInComponent = (
  component: Component | null,
  testId: string,
): Component | null => {
  if (!component) {
    return null;
  }
  return findComponentInComponent(
    component,
    (component) => component.id === testId,
  );
};

/**
 * Returns the first child component of an element which contains the component ID
 *
 * Used to insert sections under the current draft component ID
 */
export const findFirstChildComponentWithComponentId = (
  element: ReploElement,
  testComponentId: string,
): string | null => {
  const firstChildren = element?.component?.children ?? [];
  for (const childComponent of firstChildren) {
    if (
      Boolean(findComponentByIdInComponent(childComponent, testComponentId))
    ) {
      return childComponent.id;
    }
  }
  return null;
};

export function findPreviousSibling(
  element: ReploElement,
  targetComponentId: string | null,
  test: (component: Component) => boolean = () => true,
): Component | null {
  const parent = findParent(element, targetComponentId);
  if (!parent || !targetComponentId) {
    return null;
  }
  const children = getChildren(parent).filter((c) => test(c));
  const targetComponentIndex = children.findIndex(
    (children) => children.id === targetComponentId,
  );

  return targetComponentIndex > 0 ? children[targetComponentIndex - 1]! : null;
}

export function findNextSibling(
  element: ReploElement,
  targetComponentId: string | null,
  test: (component: Component) => boolean = () => true,
): Component | null {
  const parent = findParent(element, targetComponentId);
  if (!parent || !targetComponentId) {
    return null;
  }
  const children = getChildren(parent).filter((c) => test(c));
  const targetComponentIndex = children.findIndex(
    (children) => children.id === targetComponentId,
  );

  return targetComponentIndex < children.length - 1
    ? children[targetComponentIndex + 1]!
    : null;
}

export function findAncestorComponentData(
  componentId: Component["id"],
  test: (componentData: ComponentData) => boolean,
  componentDataMapping: ComponentDataMapping,
): ComponentData | undefined {
  let currentComponentData = componentDataMapping[componentId];
  while (currentComponentData && currentComponentData.parentId) {
    if (test(currentComponentData)) {
      return currentComponentData;
    }
    currentComponentData = componentDataMapping[currentComponentData.parentId];
  }
  return undefined;
}

export function findAncestorComponentDataByType(
  type: ReploComponentType,
  componentId: Component["id"],
  componentDataMapping: ComponentDataMapping,
) {
  return findAncestorComponentData(
    componentId,
    (componentData) => componentData.type === type,
    componentDataMapping,
  );
}

export function anyComponentAndDescendents(
  component: Component | null,
  test: (component: Component) => boolean,
): boolean {
  let value = false;
  forEachComponentAndDescendants(component, (component) => {
    if (test(component)) {
      value = true;
    }
  });
  return value;
}

export function canComponentAcceptArbitraryChildren(
  component: Component,
  config: { movementSource: ComponentMovementSource },
) {
  return Boolean(
    componentTypeToRenderData[component.type]?.acceptsArbitraryChildren({
      hasTextProp: Boolean(component.props.text),
      movementSource: config.movementSource,
    }),
  );
}

export function supportsContentEditing(componentType: string) {
  return ["text", "button"].includes(componentType);
}

export function getFlexDirection(
  component: Component,
  getAttribute: GetAttributeFunction,
) {
  if (!component) {
    return null;
  }
  return getAttribute(component, "style.flexDirection").value || null;
}

function canReorderChildren(component: Component) {
  const renderData = componentTypeToRenderData[component.type];
  if (renderData && "allowChildrenReorder" in renderData) {
    return renderData.allowChildrenReorder;
  }
}

/**
 * terminateSearch indicates that this component cannot exist anywhere in this
 * element and we shouldn't look to parents to see if the component is allowed.
 */
export function canMoveComponentToParent(
  element: ReploElement,
  component: Component,
  parent: Component,
  getAttribute: GetAttributeFunction,
  source: ComponentMovementSource,
  componentDataMapping?: ComponentDataMapping,
):
  | { canMove: false; message: string; terminateSearch?: true }
  | { canMove: true } {
  const parentOfComponentBeingMoved = findParent(element, component?.id);
  const positionValue = getAttribute(component, "style.position", null)?.value;
  const isCanvas = source === "canvas";
  const issues = getIssuesForComponent({
    type: "nesting",
    componentDataMapping: componentDataMapping ?? {},
    currentDraggingComponentId: component.id,
    currentDraggingComponentType: component.type,
    dragToComponentId: parent.id,
    dragToComponentType: parent.type,
    elementType: element.type,
  });

  const moveParentToChildIssue = issues.find(
    (issue) => issue.type === "nesting.moveParentToChild",
  );
  if (moveParentToChildIssue) {
    return {
      canMove: false,
      message: moveParentToChildIssue.message,
    };
  }

  // If we're a fixed-position component, we can never be dragged anywhere
  if (isCanvas && positionValue === "fixed") {
    return {
      canMove: false,
      message: "",
    };
  }

  // If we're a relative-to-parent component, we don't allow dragging into any
  // of our own parent's descendents, but we can drag outside our parent just fine
  if (isCanvas && positionValue === "absolute") {
    const targetIsDescendentOfComponentsParent = anyComponentAndDescendents(
      parentOfComponentBeingMoved,
      (c) => c.id === parent?.id,
    );
    if (targetIsDescendentOfComponentsParent) {
      return {
        canMove: false,
        message: "",
      };
    }
  }

  const targetDoesNotAllowChildType = issues.find(
    (issue) => issue.type === "nesting.childNotAllowed",
  );
  if (targetDoesNotAllowChildType) {
    return {
      canMove: false,
      message: targetDoesNotAllowChildType.message,
    };
  }

  const targetTypeNotAllowedIssue = issues.find(
    (issue) => issue.type === "nesting.ancestorNotAllow",
  );
  if (targetTypeNotAllowedIssue) {
    return {
      canMove: false,
      message: targetTypeNotAllowedIssue.message,
    };
  }

  const targetTypeDissalowedIssue = issues.find(
    (issue) => issue.type === "nesting.ancestorDisallow",
  ) as ReploSpecificComponentIssue<"nesting.ancestorDisallow">;
  if (targetTypeDissalowedIssue) {
    return {
      canMove: false,
      terminateSearch: targetTypeDissalowedIssue.terminatedSearch,
      message: targetTypeDissalowedIssue.message,
    };
  }

  const parentAllowsReordering = canReorderChildren(parent);
  if (
    parentAllowsReordering === false &&
    parentOfComponentBeingMoved?.id === parent.id &&
    source === "componentTree"
  ) {
    return {
      canMove: false,
      message: "This component does not allow children to be reordered",
    };
  }

  if (parentOfComponentBeingMoved) {
    const componentPropDefinitions = getCustomPropDefinitions(
      parentOfComponentBeingMoved,
    ).filter((definition) => definition.type === "component");

    for (const propDefinition of componentPropDefinitions) {
      const propComponent = parentOfComponentBeingMoved.props?.[
        propDefinition.id
      ] as Component;
      if (propComponent?.id === component.id) {
        return {
          canMove: false,
          message: "This component cannot be reordered",
        };
      }
    }
  }

  const parentCanAcceptChild = canComponentAcceptArbitraryChildren(parent, {
    movementSource: source,
  });

  if (!parentCanAcceptChild && parentOfComponentBeingMoved?.id !== parent.id) {
    return {
      canMove: false,
      message: "This component cannot accept children",
    };
  }

  return {
    canMove: true,
  };
}

export function canDeleteComponent(
  componentId: string,
  componentMapping: ComponentMapping,
): { canDelete: boolean; message: string } {
  const parent = getParentComponentFromMapping(componentMapping, componentId);
  const component = getFromRecordOrNull(
    componentMapping,
    componentId,
  )?.component;
  if (!parent) {
    return {
      canDelete: false,
      message: "",
    };
  }

  if (parent.type === "player" && parent.children!.length === 1) {
    return {
      canDelete: false,
      message: "Video players must have at least one child component",
    };
  }

  if (parent.type === "slidingCarousel") {
    // Sliding carousel components have set children and can never have them deleted
    return {
      canDelete: false,
      message: "Sliding Carousel components cannot be deleted",
    };
  }
  if (parent.type === "collapsibleV2") {
    // CollapsibleV2 components have set children and can never have them deleted
    return {
      canDelete: false,
      message:
        "Collapsible components cannot be deleted. Did you mean to delete the Collapsible itself?",
    };
  }
  if (parent.type === "beforeAfterSlider") {
    // Before/After Slider components have set children and can never have them deleted
    return {
      canDelete: false,
      message:
        "Before/After Slider components cannot be deleted. Did you mean to delete the Before/After Slider itself?",
    };
  }
  if (parent.type === "dropdown") {
    return {
      canDelete: false,
      message: "Dropdown components cannot be deleted",
    };
  }
  if (component?.type === "tooltipContent") {
    return {
      canDelete: false,
      message: "Tooltip content components cannot be deleted",
    };
  }
  return { canDelete: true, message: "" };
}

export function canDuplicateComponent(
  componentId: string,
  componentDataMapping: ComponentDataMapping,
): { canDuplicate: boolean; message: string } {
  const component = componentDataMapping[componentId];
  const parentId = component?.parentId;
  const parent = parentId ? componentDataMapping[parentId] : null;

  if (!parent) {
    return {
      canDuplicate: false,
      message: "",
    };
  }

  if (parent.type === "beforeAfterSlider") {
    // Before/After Slide components cannot be duplicated
    return {
      canDuplicate: false,
      message: "Before/After Slide inner components cannot be duplicated",
    };
  }

  if (parent.type === "collapsibleV2") {
    // CollapsibleV2 components cannot be duplicated
    return {
      canDuplicate: false,
      message: "Collapsible inner components cannot be duplicated",
    };
  }

  if (component?.type === "tooltipContent") {
    // Tooltip content components cannot be duplicated
    return {
      canDuplicate: false,
      message: "Tooltip content components cannot be duplicated",
    };
  }

  return { canDuplicate: true, message: "" };
}

export function canCopyComponent(
  componentId: string,
  componentDataMapping: ComponentDataMapping,
): { canCopy: boolean; message: string } {
  const component = componentDataMapping[componentId];
  const parentId = component?.parentId;

  if (parentId) {
    const parent = componentDataMapping[parentId];

    if (parent?.type === "beforeAfterSlider") {
      // Before/After Slide components cannot be copied
      return {
        canCopy: false,
        message: "Before/After Slide inner components cannot be copied",
      };
    }

    if (parent?.type === "collapsibleV2") {
      // CollapsibleV2 components cannot be copied
      return {
        canCopy: false,
        message: "Collapsible inner components cannot be copied",
      };
    }
  }

  if (component?.type === "tooltipContent") {
    return {
      canCopy: false,
      message: "Tooltip content components cannot be copied",
    };
  }

  return { canCopy: true, message: "" };
}

export function canGroupIntoContainer(
  componentIds: string[],
  componentDataMapping: ComponentDataMapping,
): { canGroupIntoContainer: boolean; message: string } {
  for (const id of componentIds) {
    const component = componentDataMapping[id];
    if (!component) {
      return {
        canGroupIntoContainer: false,
        message: "",
      };
    }

    if (component.type === "tooltipContent") {
      return {
        canGroupIntoContainer: false,
        message:
          "Tooltip content components cannot be grouped into a container",
      };
    }

    if (component.type === "collapsibleV2Content") {
      return {
        canGroupIntoContainer: false,
        message:
          "Collapsible content components cannot be grouped into a container",
      };
    }

    if (component.type === "collapsibleV2Header") {
      return {
        canGroupIntoContainer: false,
        message:
          "Collapsible header components cannot be grouped into a container",
      };
    }

    if (component.type === "beforeAfterSliderThumb") {
      return {
        canGroupIntoContainer: false,
        message:
          "Before/After Slider thumb components cannot be grouped into a container",
      };
    }

    if (component.type === "beforeAfterSliderBeforeContent") {
      return {
        canGroupIntoContainer: false,
        message:
          "Before/After Slider before content components cannot be grouped into a container",
      };
    }

    if (component.type === "beforeAfterSliderAfterContent") {
      return {
        canGroupIntoContainer: false,
        message:
          "Before/After Slider after content components cannot be grouped into a container",
      };
    }
  }

  return { canGroupIntoContainer: true, message: "" };
}

const getParentData = (
  draftElement: ReploElement,
  parentId: string,
  getAttribute: GetAttributeFunction,
) => {
  const componentsParent = findParent(draftElement, parentId);
  if (!componentsParent) {
    return null;
  }

  const parentAttributeFlexDirection = getAttribute(
    componentsParent,
    "style.flexDirection",
    {
      defaultValue: "row",
    },
  ).value;
  const parentAttributeAlignItems = getAttribute(
    componentsParent,
    "style.alignItems",
    null,
  ).value;
  const parentAttributeJustifyContent = getAttribute(
    componentsParent,
    "style.justifyContent",
    null,
  ).value;
  const parentAttributeSpacing = getAttribute(
    componentsParent,
    "style.__flexGap",
    null,
  ).value;

  let containerPositionWithSiblings: number | undefined;
  componentsParent.children?.forEach((child, index) => {
    if (child.id === parentId) {
      containerPositionWithSiblings = index;
    }
  });

  return {
    parent: componentsParent,
    parentAttributeFlexDirection,
    parentAttributeAlignItems,
    parentAttributeJustifyContent,
    parentAttributeSpacing,
    containerPositionWithSiblings,
  };
};

export function generateContextMenuWrapperComponentActions(args: {
  draftElement: ReploElement;
  multipleSelectedNodeIds: string[];
  productResolutionDependencies: ProductResolutionDependencies;
  getAttribute: GetAttributeFunction;
  componentDataMapping: ComponentDataMapping;
  wrapperType: "container" | "tooltip" | "ticker";
}): { actions: UseApplyComponentActionType[]; newContainerId: string | null } {
  const {
    draftElement,
    multipleSelectedNodeIds,
    productResolutionDependencies,
    getAttribute,
    componentDataMapping,
    wrapperType,
  } = args;

  const actions: UseApplyComponentActionType[] = [];

  const parentData = getParentData(
    draftElement,
    multipleSelectedNodeIds[0]!,
    getAttribute,
  );

  if (!parentData) {
    return { actions, newContainerId: null };
  }

  const {
    parent,
    parentAttributeFlexDirection,
    parentAttributeAlignItems,
    parentAttributeJustifyContent,
    parentAttributeSpacing,
    containerPositionWithSiblings,
  } = parentData;

  const wrapper = exhaustiveSwitch({ type: wrapperType })({
    container: () =>
      getNormalizedFlexDirection(parentAttributeFlexDirection) === "row"
        ? HORIZONTAL_CONTAINER_COMPONENT_TEMPLATE
        : VERTICAL_CONTAINER_COMPONENT_TEMPLATE,
    tooltip: () => getWrapWithTooltipTemplate(),
    ticker: () => getWrapWithTickerTemplate(),
  });

  const newContainer = prepareComponentTemplate(wrapper, parent, draftElement, {
    getAttribute,
    productResolutionDependencies,
    context: getCurrentComponentContext(parent.id, 0) ?? null,
    componentDataMapping,
  });

  if (newContainer) {
    actions.push(
      {
        type: "addComponentToComponent",
        componentId: parent.id,
        elementId: draftElement.id,
        value: {
          newComponent: newContainer,
          position: "child",
          positionWithinSiblings: containerPositionWithSiblings,
        },
        analyticsExtras: {
          actionType: "create",
          createdBy: "user",
        },
      },
      {
        type: "setStyles",
        componentId: newContainer.id,
        value: {
          alignItems: parentAttributeAlignItems,
          justifyContent: parentAttributeJustifyContent,
          __flexGap: parentAttributeSpacing,
        },
        analyticsExtras: {
          actionType: "edit",
          createdBy: "replo",
        },
      },
      {
        type: "moveMultipleComponentsToParent",
        componentIds: multipleSelectedNodeIds,
        value: {
          parentComponentId: newContainer.id,
          positionWithinSiblings: 0,
        },
        source: "componentTree",
        analyticsExtras: {
          actionType: "edit",
          createdBy: "user",
        },
      },
    );

    return { actions, newContainerId: newContainer.id };
  }
  return { actions, newContainerId: null };
}

export function getEdgeAttribute(
  component: Component | null,
  attribute: RuntimeStyleAttribute,
  getAttribute: GetAttributeFunction,
) {
  if (!component) {
    return null;
  }
  const value = getAttribute(component, `style.${attribute}`).value || null;

  if (typeof value === "string" && value.includes("NaN")) {
    // Unfortunately, there's a data issue where some top/left/right/values have NaN
    return null;
  }
  return value;
}

export function getComponentsOfType(
  component: Component,
  type: string,
): Component[] | null {
  const _components: Component[] = [];
  forEachComponentAndDescendants(component, (child) => {
    if (child.type === type) {
      _components.push(child);
    }
  });
  return _components.length === 0 ? null : _components;
}

export function isComponent(component: unknown): component is Component {
  return (
    component != null &&
    typeof component === "object" &&
    hasOwnProperty(component, "id")
  );
}

export const getAlignSelf = (
  draftComponent: Component,
  parentComponent: Component | null,
  getAttribute: GetAttributeFunction,
) => {
  let alignSelf =
    getAttribute(draftComponent, "style.alignSelf", null).value || "auto";

  if (alignSelf === "auto" && parentComponent) {
    alignSelf =
      getAttribute(parentComponent, "style.alignItems", null).value ||
      "stretch";
  }
  return alignSelf;
};

export const isModal = (type: ReploComponentType) => {
  return exhaustiveSwitch({ type })({
    modal: true,
    shopifySection: false,
    text: false,
    image: false,
    circle: false,
    container: false,
    toggleContainer: false,
    toggleIndicator: false,
    symbolRef: false,
    button: false,
    spacer: false,
    icon: false,
    collapsible: false,
    slidingCarousel: false,
    player: false,
    player__playIcon: false,
    player__muteIcon: false,
    player__fullScreenIcon: false,
    collectionSelect: false,
    product: false,
    productCollection: false,
    quantitySelector: false,
    dropdown: false,
    variantSelect: false,
    optionSelect: false,
    variantSelectDropdown: false,
    optionSelectDropdown: false,
    sellingPlanSelect: false,
    sellingPlanSelectDropdown: false,
    collection: false,
    collectionV2: false,
    googleMapsEmbed: false,
    klaviyoEmbed: false,
    temporaryCart: false,
    temporaryCartItems: false,
    vimeoEmbed: false,
    vimeoEmbedV2: false,
    youtubeEmbed: false,
    youtubeEmbedV2: false,
    carouselV2: false,
    carouselV2__panels: false,
    carouselV2__indicator: false,
    carouselV3: false,
    carouselV3Slides: false,
    carouselV3Control: false,
    carouselV3Indicators: false,
    shopifyRawLiquid: false,
    shopifyAppBlocks: false,
    collapsibleV2: false,
    collapsibleV2Header: false,
    collapsibleV2Content: false,
    tabsBlock: false,
    tabs__list: false,
    tabs__panelsContent: false,
    tabs__onePanelContent: false,
    tabsV2__block: false,
    tabsV2__list: false,
    tabsV2__panelsContent: false,
    marquee: false,
    rawHtmlContent: false,
    starRating: false,
    tikTokEmbed: false,
    rechargeSubscriptionWidget: false,
    staySubscriptionWidget: false,
    okendoReviewsWidget: false,
    okendoProductRatingSummary: false,
    junipProductRating: false,
    junipReviews: false,
    yotpoProductRating: false,
    yotpoReviews: false,
    looxProductRating: false,
    looxReviews: false,
    knoCommerceWidget: false,
    reviewsIoProductRating: false,
    reviewsIoReviews: false,
    h1: false,
    h2: false,
    h3: false,
    spinner: false,
    dynamicCheckoutButtons: false,
    countdownTimer: false,
    accordionBlock: false,
    subscribeAndSave: false,
    rebuyWidget: false,
    buyWithPrimeButton: false,
    stampedProductReviewsWidget: false,
    stampedProductRatingWidget: false,
    feraProductRatingWidget: false,
    feraProductReviewsWidget: false,
    feraStoreReviewsWidget: false,
    feraMediaGalleryWidget: false,
    shopifyProductReviewsWidget: false,
    shopifyProductRatingWidget: false,
    judgeProductRatingWidget: false,
    judgeProductReviewsWidget: false,
    infiniteOptionsWidget: false,
    kachingBundles: false,
    postscriptSignupForm: false,
    beforeAfterSlider: false,
    beforeAfterSliderThumb: false,
    beforeAfterSliderBeforeContent: false,
    beforeAfterSliderAfterContent: false,
    carouselPanelsCount: false,
    tooltip: false,
    tooltipContent: false,
    selectionList: false,
  });
};

/**
 * TODO (gabe 2023-04-19): we should do a more robust check here, for
 * now this only rejects empty components
 */
export function isValidComponent(component: Component) {
  return Object.keys(component).length > 0;
}

export function getComponentsPassingTest(
  component: Component,
  test: (component: Component) => boolean,
): Component[] | null {
  const _components: Component[] = [];
  forEachComponentAndDescendants(component, (child) => {
    if (isValidComponent(component) && test(child)) {
      _components.push(child);
    }
  });
  return _components.length === 0 ? null : _components;
}

export function sanitizeComponentName(target: string) {
  if (!target) {
    return target;
  }

  target = startCase(target.toLowerCase());
  const words = target.split(" ");
  const filteredWords = words.filter((word) => !/^\d+$/.test(word));
  return filteredWords.join(" ");
}

function extractUrlFromBackgroundCss(cssValue: string) {
  // Remove the `url('` part from the beginning of the string
  if (!cssValue.startsWith("url('")) {
    return cssValue;
  }
  return cssValue.replace(/^url\('/, "").replace(/'\)$/, "");
}

function extractUrlFromString(target: string) {
  if (isString(target) && target?.includes("https://")) {
    target = extractUrlFromBackgroundCss(target);
    return target;
  }
  return null;
}

export function findAndReplaceAssetUrls(
  token: unknown,
  key: string,
  target: Record<string, unknown>,
) {
  if (isString(token)) {
    const url =
      "https://andytown-public.s3.us-west-1.amazonaws.com/templates/replo-ui/placeholders/image/type%3Dcircle%2C+aspect%3D1.8.png";
    /**
     * Replace the token with the S3 URL, we might be replacing a subset of the string
     * (e.g. url('XXX').replace('XXX', 'YYY')). This is why we call it a token vs. url
     */
    const tokenUrl = extractUrlFromString(token);
    if (tokenUrl && isObject(target)) {
      target[key] = token.replace(tokenUrl, url);
    }
  }
}

export function findAndRenameComponentNames(
  value: unknown,
  key: string,
  target: Record<string, any>,
) {
  if (key === "name" && hasOwnProperty(target, "type") && isString(value)) {
    target[key] = sanitizeComponentName(
      (target as { type: ReploComponentType }).type,
    );
  }

  if (key === "type" && value === "text") {
    target.name = sanitizeComponentName(value);
  }
}

const generateLoremIpsum = (wordsCount: number): string => {
  const loremIpsum = `Lorem ipsum dolor sit amet consectetur adipiscing elit ut dapibus mollis dictumst
    duis commodo in netus ridiculus aptent porta tempus mus vivamus dignissim ornare
    rhoncus himenaeos habitasse inceptos varius iaculis velit tincidunt parturient
    Facilisis fermentum fusce semper sociis sodales aliquam habitant ligula sagittis
    vehicula pharetra quis pretium molestie nunc placerat facilisi penatibus condimentum
    ultricies Urna interdum felis luctus curabitur auctor pulvinar conubia cras elementum
    nascetur at aenean nisl lectus ad gravida phasellus eu aliquet mauris id Enim mattis
    suspendisse lacinia erat est per vestibulum accumsan cubilia volutpat suscipit nostra
    malesuada posuere turpis imperdiet litora nec tellus potenti feugiat eleifend congue
    sollicitudin senectus Orci tortor dui nisi consequat bibendum donec magnis taciti
    vulputate tempor augue sapien sociosqu metus nibh scelerisque cursus mi justo nulla
    ante vitae integer pellentesque dictum natoque curae lobortis ullamcorper Ac fringilla
    class nam et proin nullam euismod porttitor tristique odio etiam fames rutrum hendrerit
    venenatis arcu faucibus non libero morbi montes risus viverra maecenas leo laoreet
    Dis platea hac eget sed neque quisque praesent convallis blandit lacus ultrices torquent
    eros vel primis diam massa cum quam a sem purus Magna egestas tincidunt fames potenti
    luctus senectus habitant natoque mollis tempor suscipit justo erat sed quis facilisis enim`;
  const loremIpsumWords = loremIpsum.split(" ");

  if (wordsCount <= 0) {
    return "";
  }

  const generatedWords = [];
  while (generatedWords.length < wordsCount) {
    generatedWords.push(...loremIpsumWords);
  }

  return generatedWords.slice(0, wordsCount).join(" ");
};

// NOTE (Fran 2023-10-31): Thanks GPT for the function. We need to replace the text inside the
// html tags because we could have text created with the rich text editor with different colors.
const replaceTextBetweenTags = (input: string) => {
  return input.replace(/<[^>]*>([^<]*)<\/[^>]*>/g, (match, text) => {
    const textLength = text.split(" ").length;
    const textToReplace = generateLoremIpsum(textLength);
    return `<${match.slice(
      1,
      1 + match.indexOf(">") - 1,
    )}>${textToReplace}</${match.slice(1, 1 + match.indexOf(">") - 1)}>`;
  });
};

export function findAndReplaceTextWithPlaceholderTexts(
  value: unknown,
  key: string,
  target: Record<string, any>,
) {
  if (key === "text" && isString(value)) {
    if (value.includes("{IGNORE}")) {
      target[key] = value.replace("{IGNORE}", "");
    } else {
      const replacedText = replaceTextBetweenTags(value);
      target[key] = replacedText;
    }
  }
}

const COLOR_FIELDS = [
  "color",
  "backgroundColor",
  "borderColor",
  "borderTopColor",
  "borderBottomColor",
  "borderRightColor",
  "borderLeftColor",
];

/**
 * Recursive helper for getComponentData. As a side-effect, updates all the
 * objects inside of `componentDataToUpdate`.
 */
function updateComponentDataRecursively(config: {
  component: Component;
  componentDataToUpdate: {
    componentDataMappingToUpdate: ComponentDataMapping;
    componentColorsToUpdate: Set<string>;
    componentFontFamiliesToUpdate: Set<string>;
    componentIdsThatNeedAriaLabelsToUpdate: Set<string>;
  };
  context: {
    parentId: string | null;
    ancestorIsDynamicRepetition: boolean;
    ancestorHasVariants: boolean;
    ancestorOrSelfWithVariantsId: string | null;
    symbols: Record<string, ReploSymbol>;
    containedComponentDataCollection: ContainedComponentData[];
    ancestorComponentData: AncestorComponentData;
  };
}) {
  const { component, componentDataToUpdate, context } = config;
  const {
    componentColorsToUpdate,
    componentFontFamiliesToUpdate,
    componentDataMappingToUpdate,
    componentIdsThatNeedAriaLabelsToUpdate,
  } = componentDataToUpdate;
  const resolvedComponent = resolveSymbolRef(component, context.symbols);

  if (resolvedComponent) {
    const isDynamicRepetition =
      context.ancestorIsDynamicRepetition ||
      Boolean(resolvedComponent.props?.items);
    const thisComponentHasVariants =
      (resolvedComponent.variants?.length ?? 0) > 0;
    const hasVariants = context.ancestorHasVariants || thisComponentHasVariants;
    let newAncestorOrSelfWithVariantsId = context.ancestorOrSelfWithVariantsId;
    if (thisComponentHasVariants) {
      newAncestorOrSelfWithVariantsId = resolvedComponent.id;
    }
    const updatedAncestorComponentData: AncestorComponentData = [
      ...context.ancestorComponentData,
      [resolvedComponent.id, resolvedComponent.type],
    ];

    for (const [
      index,
      containedComponentData,
    ] of context.containedComponentDataCollection.entries()) {
      containedComponentData.push([
        resolvedComponent.id,
        resolvedComponent.type,
        index + 1,
      ]);
    }

    const componentContainedComponentData: ContainedComponentData = [];

    const customPropDefinitions = getCustomPropDefinitions(resolvedComponent);

    for (const childComponent of getChildren(resolvedComponent)) {
      updateComponentDataRecursively({
        component: childComponent,
        componentDataToUpdate,
        context: {
          parentId: resolvedComponent.id,
          ancestorIsDynamicRepetition: isDynamicRepetition,
          ancestorHasVariants: hasVariants,
          ancestorOrSelfWithVariantsId: newAncestorOrSelfWithVariantsId,
          symbols: context.symbols,
          ancestorComponentData: updatedAncestorComponentData,
          containedComponentDataCollection: [
            componentContainedComponentData,
            ...context.containedComponentDataCollection,
          ],
        },
      });
    }

    forEachComponentStyleProps(
      resolvedComponent.props ?? {},
      (_mergedMediaSizeStyleRules, _mediaSize, onlyThisMediaSizeStyleRules) => {
        for (const colorField of COLOR_FIELDS) {
          const styleProp =
            onlyThisMediaSizeStyleRules?.[
              colorField as keyof RuntimeStyleProperties
            ];
          if (styleProp?.includes("alchemy")) {
            if (
              onlyThisMediaSizeStyleRules?.__alchemyGradient__backgroundColor__stops
            ) {
              for (const colorStop of onlyThisMediaSizeStyleRules.__alchemyGradient__backgroundColor__stops) {
                const color = tinycolor(colorStop.color).toHexString();
                componentColorsToUpdate.add(color);
              }
            }
          } else if (styleProp) {
            const color = tinycolor(styleProp).toHexString();
            componentColorsToUpdate.add(color);
          }
        }
        if (onlyThisMediaSizeStyleRules?.fontFamily) {
          const normalized = normalizeFontFamily(
            onlyThisMediaSizeStyleRules.fontFamily,
          );
          if (normalized) {
            componentFontFamiliesToUpdate.add(normalized);
          }
        }
      },
    );

    componentDataMappingToUpdate[resolvedComponent.id] = {
      id: resolvedComponent.id,
      type: resolvedComponent.type,
      parentId: context.parentId,
      label: getComponentName(resolvedComponent, context.symbols),
      ancestorIsDynamicRepetition: isDynamicRepetition,
      ancestorHasVariants: hasVariants,
      ancestorOrSelfWithVariantsId: newAncestorOrSelfWithVariantsId,
      customPropDefinitions,
      ancestorComponentData: context.ancestorComponentData,
      containedComponentData: componentContainedComponentData,
      stylesForChildren:
        componentDataToUpdate.componentDataMappingToUpdate[resolvedComponent.id]
          ?.stylesForChildren ?? null,
    };

    if (resolvedComponent?.props?._accessibilityLabelledBy) {
      componentIdsThatNeedAriaLabelsToUpdate.add(
        resolvedComponent.props._accessibilityLabelledBy,
      );
    }
  }
}

/**
 * Return a mapping of componentId to various data about the component (its
 * parent, attributes, what types of components it contains and is contained in)
 * for later lookup in the editor.
 *
 * Main reason to have this is for performance - we calculate a lot of stuff up
 * front once so we don't have to do expensive tree traversals to calculate it
 * multiple times later.
 */
export function getComponentData(config: {
  component: Component;
  context: {
    // NOTE (Martin, 2024-09-26): It's important that we remember to send the
    // current component data mapping here, otherwise styles for children, which
    // are stored in the mapping when generating styles might get lost. USE-1271
    componentDataMapping: ComponentDataMapping;
    symbols: Record<string, ReploSymbol>;
  };
}) {
  const { component, context } = config;
  const { componentDataMapping } = context;
  const componentColors = new Set<string>();
  const componentFontFamilies = new Set<string>();
  const componentIdsThatNeedAriaLabels = new Set<string>();
  const updatedComponentDataMapping = { ...componentDataMapping };

  updateComponentDataRecursively({
    component,
    componentDataToUpdate: {
      componentDataMappingToUpdate: updatedComponentDataMapping,
      componentColorsToUpdate: componentColors,
      componentFontFamiliesToUpdate: componentFontFamilies,
      componentIdsThatNeedAriaLabelsToUpdate: componentIdsThatNeedAriaLabels,
    },
    context: {
      parentId: null,
      ancestorIsDynamicRepetition: false,
      ancestorHasVariants: false,
      symbols: context.symbols,
      ancestorComponentData: [],
      containedComponentDataCollection: [],
      ancestorOrSelfWithVariantsId: null,
    },
  });

  for (const labelledById of componentIdsThatNeedAriaLabels) {
    // Note (Noah, 2024-07-29): If the component referenced as an ARIA label of another
    // component has been deleted, we won't have an entry in componentDataMapping for it,
    // but there's nothing we can do in this case
    if (updatedComponentDataMapping[labelledById]) {
      updatedComponentDataMapping[labelledById]!.isLabelledByOtherComponent =
        true;
    }
  }

  return {
    componentDataMapping: updatedComponentDataMapping,
    componentColors,
    componentFontFamilies,
  };
}

export function getComponentConditionFields(
  component: Component,
  componentDataMapping: ComponentDataMapping,
): ConditionField[] {
  const result = new Set<ConditionField>();
  const componentData = componentDataMapping[component.id];
  if (!componentData) {
    return [];
  }

  const ancestorTypes = componentData.ancestorComponentData.map(
    ([_, type]) => type,
  );
  for (const componentType of ancestorTypes) {
    const renderData = getRenderData(componentType);
    if (!renderData || !renderData.variantTriggers) {
      continue;
    }

    for (const trigger of renderData.variantTriggers) {
      result.add(trigger);
    }
  }

  return Array.from(result);
}

export function getComponentActionTypesFromAncestors(
  component: Component,
  componentDataMapping: ComponentDataMapping,
): AlchemyActionType[] {
  const result = new Set<AlchemyActionType>();
  const componentData = componentDataMapping[component.id];
  if (!componentData) {
    return [];
  }

  const ancestorTypes = componentData.ancestorComponentData.map(
    ([_, type]) => type,
  );
  for (const componentType of ancestorTypes) {
    const renderData = getRenderData(componentType);
    if (!renderData || !renderData.actionTypes) {
      continue;
    }

    for (const hook of renderData.actionTypes) {
      result.add(hook);
    }
  }

  return Array.from(result);
}
