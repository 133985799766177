// TODO (Noah, 2024-10-09): Re-enable this rule
/* eslint-disable replo/consistent-component-exports */
import type { ExperimentStatus } from "schemas/experiment";
import type {
  AnalyticsLinkForExperimentUpdate,
  AnalyticsLinkWithIds,
} from "schemas/generated/analyticsLink";
import type { ReploElementForExperiments } from "schemas/generated/element";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import { useExperimentApi } from "@components/projectDashboard/experiments/common";
import InputComponent from "@editor/components/common/designSystem/Input";
import Textarea from "@editor/components/common/designSystem/Textarea";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { Loader } from "@editor/components/common/Loader";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import useCurrentWorkspaceId from "@editor/hooks/useCurrentWorkspaceId";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { trpc } from "@editor/utils/trpc";
import ReploLogoBadge from "@svg/logo-badge";

import { useAnalyticsOnboardingOAuthLink } from "@/features/analytics/useAnalyticsOnboaredingOAuthLink";
import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import { CHOOSE_LINK_OPTION } from "@/features/experiments/components/sections/foreverLinkOptions";
import { LinkSection } from "@/features/experiments/components/sections/LinkSection";
import { TabMenu } from "@/features/experiments/tabs/TabMenu";
import { positiveIntToCapitalLetter } from "@/features/experiments/utils";
import Button from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import { skipToken } from "@tanstack/react-query";
import classNames from "classnames";
import isEqual from "lodash-es/isEqual";
import { BsInfoCircle, BsPlus, BsTrash } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { SHOPIFY_APP_LISTING_URL } from "replo-runtime/shared/config";
import { isValidHttpUrl } from "replo-utils/lib/url";
import { DEFAULT_DOMAIN } from "schemas/analyticsLink";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";
import { isPathSafeSlug } from "schemas/utils";
import { v4 as uuid } from "uuid";

const PLACEHOLDER_DOMAIN = "example.com";
const PLACEHOLDER_URL_PATH = "/pages/example";
const PLACEHOLDER_URL = `https://${PLACEHOLDER_DOMAIN}${PLACEHOLDER_URL_PATH}`;

export const ExperimentsEditTabV2: React.FC<{
  dataChanged: boolean;
  onDataChange: (dataChanged: boolean) => void;
}> = ({ dataChanged, onDataChange }) => {
  const { experimentId } = useParams();
  const workspaceId = useCurrentWorkspaceId() ?? undefined;
  const {
    list: { data, isFetching: isFetchingExperiments },
  } = useExperimentApi({ workspaceId, projectId: null });

  const experiment = data?.experiments.find((exp) => exp.id === experimentId);
  if (!workspaceId || !experiment || !data || isFetchingExperiments) {
    return <Loader />;
  }
  return (
    <ExperimentEditComponent
      workspaceId={workspaceId}
      experiment={experiment}
      dataChanged={dataChanged}
      onDataChange={onDataChange}
    />
  );
};

export type ExperimentValidation = {
  variations: {
    areSlugsValid: boolean;
    areSlugsUnique: boolean;
    areTargetsValid: boolean;
    arePercentagesValid: boolean;
  };
  analyticsLink: {
    isAnalyticsLinkProvided: boolean;
    isCreateAnalyticsLinkValid: boolean;
  };
};

export type ExperimentState = {
  data: Omit<Experiment, "analyticsLinkId">;
  validation: ExperimentValidation;
  analyticsLink: AnalyticsLinkForExperimentUpdate;
};

// TODO (Max, 2024-10-27, REPL-14369): Add UI form validation when creating the link (check it doesn't exist already & path is safe)
const isCreateAnalyticsLinkValid = (
  analyticsLinkWithIds: AnalyticsLinkWithIds | undefined,
) => {
  if (!analyticsLinkWithIds) {
    return false;
  }
  const { customDomainId, shortNameId, path } = analyticsLinkWithIds;

  // NOTE (Max, 2024-10-28): Path is required.
  if (!path || path === "") {
    return false;
  }

  // NOTE (Max, 2024-10-28): Either customDomain or shortName must be provided.
  if (!customDomainId && !shortNameId) {
    return false;
  }

  // NOTE (Max, 2024-10-28): If customDomain is default, shortName must be provided.
  if (customDomainId === DEFAULT_DOMAIN && !shortNameId) {
    return false;
  }

  // NOTE (Max, 2024-10-28): If customDomain is not default, shortName must not be provided.
  if (customDomainId !== DEFAULT_DOMAIN && shortNameId) {
    return false;
  }

  return true;
};

/**
 * An experiment is considered valid IFF:
 *
 * - Variations must have unique slugs.
 * - Variations must have valid target URLs.
 * - Variation percentages must total 100%.
 *
 * The validation when creating a new analyticsLink is handled in <LinkSection>
 */

export function validateExperiment(
  experiment: ExperimentState["data"],
  analyticsLink: ExperimentState["analyticsLink"],
): ExperimentValidation {
  const variations = experiment.variations;
  const status = getExperimentStatus(experiment);

  return {
    variations: {
      areSlugsValid:
        variations
          .map((variation) => isPathSafeSlug(variation.slug))
          .filter((value) => value).length === variations.length,
      areSlugsUnique:
        new Set(variations.map((variation) => variation.slug)).size ===
        variations.length,
      areTargetsValid:
        variations
          .map(({ target }) => isValidHttpUrl(target))
          .filter((valid) => valid).length === variations.length,
      arePercentagesValid:
        variations.reduce((sum, v) => v.allocationPercent + sum, 0) === 100,
    },
    analyticsLink: {
      isAnalyticsLinkProvided: status !== "active" || Boolean(analyticsLink),
      isCreateAnalyticsLinkValid:
        analyticsLink?.type === "chooseLink" ||
        isCreateAnalyticsLinkValid(analyticsLink?.value),
    },
  };
}

const ExperimentEditComponent = ({
  workspaceId,
  experiment,
  dataChanged,
  onDataChange,
}: {
  workspaceId: string;
  experiment: Experiment;
  dataChanged: boolean;
  onDataChange: (dataChanged: boolean) => void;
}) => {
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const logEvent = useLogAnalytics();
  const {
    update: { mutateAsync: update },
  } = useExperimentApi({ workspaceId, projectId: null });

  const { data: allReploElements, isLoading: isLoadingAllReploElements } =
    trpc.workspace.getAllElementsWithShopifyUrl.useQuery(
      workspaceId ?? skipToken,
    );

  const firstShopifyUrl =
    allReploElements?.elements[0]?.shopifyUrl ?? PLACEHOLDER_DOMAIN;

  const {
    links: { data: links },
  } = useExperimentApi({
    workspaceId: workspaceId ?? undefined,
    projectId: null,
  });

  const { data: customDomains } = trpc.workspace.getCustomDomains.useQuery(
    workspaceId ? workspaceId : skipToken,
  );

  const domainOptions = [
    { value: DEFAULT_DOMAIN, label: DEFAULT_DOMAIN },
    ...(customDomains ?? []).map((domain) => ({
      value: domain.id,
      label: domain.value,
    })),
  ];

  const { data: shortNames } = trpc.workspace.getShortNames.useQuery(
    workspaceId ? workspaceId : skipToken,
  );

  const shortNameOptions = (shortNames ?? []).map((shortName) => ({
    value: shortName.id,
    label: shortName.value,
  }));

  const placeholderUrl = `https://${firstShopifyUrl}`;

  const defaultAnalyticsLinkChoose: AnalyticsLinkForExperimentUpdate = {
    type: "chooseLink",
    value: experiment.analyticsLinkId ?? null,
  };

  const customDomainOptions = domainOptions.filter(
    (option) => option.value !== DEFAULT_DOMAIN,
  );
  const domainOptionsValue = customDomainOptions[0]?.value ?? DEFAULT_DOMAIN;

  const defaultAnalyticsLinkCreate: AnalyticsLinkForExperimentUpdate = {
    type: "createNewLink",
    value: {
      customDomainId: domainOptionsValue,
      shortNameId:
        domainOptionsValue !== DEFAULT_DOMAIN
          ? null
          : shortNameOptions[0]?.value ?? null,
      path: experiment.name,
    },
  };

  // NOTE (Max, 2024-11-05): Set analyticsLink to be of type "createNewLink" only if
  // the experiment doesn't have an analyticsLinkId, and if there are no links.
  const initialAnalyticsLink =
    Boolean(defaultAnalyticsLinkChoose.value) || (links?.length ?? 0) > 0
      ? defaultAnalyticsLinkChoose
      : defaultAnalyticsLinkCreate;

  const [state, setState] = React.useState<ExperimentState>({
    data: experiment,
    validation: validateExperiment(experiment, initialAnalyticsLink),
    analyticsLink: initialAnalyticsLink,
  });

  const handleLinkSubsectionChange = (value: string) => {
    setState({
      ...state,
      analyticsLink:
        value === CHOOSE_LINK_OPTION.value
          ? defaultAnalyticsLinkChoose
          : defaultAnalyticsLinkCreate,
    });
  };

  /**
   * NOTE (Max, 2024-11-11): onDataChange() sets dataChanged to true if the experiment
   * or the analyticsLink has changed.
   *
   * We need this to know whether we should show the "Save changes" button.
   *
   * The state.data doesn't contain the analyticsLink,
   * so that's why we also need to do the analyticsLinkId check.
   */
  React.useEffect(() => {
    const hasDataChanged =
      !isEqual(experiment, state.data) ||
      experiment.analyticsLinkId !== (state.analyticsLink?.value ?? null);

    onDataChange(hasDataChanged);
  }, [experiment, state.data, state.analyticsLink, onDataChange]);

  const status = getExperimentStatus(experiment);
  // TODO (Max, 2024-10-28, REPL-14369): use react-hook-form for all validations here
  const isExperimentValid =
    state.validation.variations.arePercentagesValid &&
    state.validation.variations.areSlugsUnique &&
    state.validation.variations.areSlugsValid &&
    state.validation.variations.areTargetsValid &&
    state.validation.analyticsLink.isAnalyticsLinkProvided &&
    state.validation.analyticsLink.isCreateAnalyticsLinkValid;
  const isUpdateAllowed = dataChanged && isExperimentValid;

  const onAddVariation = () => {
    let nextSlug = positiveIntToCapitalLetter(state.data.variations.length + 1);
    if (state.data.variations.find((v) => v.slug === nextSlug)) {
      nextSlug = uuid().slice(0, 8);
    }
    const data = {
      ...state.data,
      variations: [
        ...state.data.variations,
        {
          id: uuid(),
          target: `${placeholderUrl}${PLACEHOLDER_URL_PATH}`,
          slug: nextSlug,
          allocationPercent: 10,
        },
      ],
    };
    const validation = validateExperiment(data, state.analyticsLink);
    setState({
      ...state,
      data,
      validation,
    });
  };

  const onVariationChange = (changed: Variation) => {
    const data = {
      ...state.data,
      variations: state.data.variations.map((v) =>
        v.id === changed.id ? { ...v, ...changed } : v,
      ),
    };
    const validation = validateExperiment(data, state.analyticsLink);
    setState({
      ...state,
      data,
      validation,
    });
  };

  const onVariationRemoval = (id: string) => {
    const data = {
      ...state.data,
      variations: state.data.variations.filter(
        (variation) => variation.id !== id,
      ),
    };
    const validation = validateExperiment(data, state.analyticsLink);
    setState({
      ...state,
      data,
      validation,
    });
  };

  const onPropertyChange = (changes: Partial<Experiment>) => {
    const data = {
      ...state.data,
      ...changes,
    };
    const validation = validateExperiment(data, state.analyticsLink);
    setState({
      ...state,
      data,
      validation,
    });
  };

  const onAnalyticsLinkChange = (
    analyticsLink: AnalyticsLinkForExperimentUpdate,
  ) => {
    const validation = validateExperiment(state.data, analyticsLink);

    setState({
      ...state,
      validation,
      analyticsLink,
    });
  };

  const onSave = async () => {
    if (
      state.analyticsLink?.type === "createNewLink" &&
      state.analyticsLink?.value.customDomainId === DEFAULT_DOMAIN
    ) {
      onAnalyticsLinkChange({
        ...state.analyticsLink,
        value: {
          ...state.analyticsLink.value,
          customDomainId: null,
        },
      });
    }

    let adjustedAnalyticsLink = state.analyticsLink;
    if (
      state.analyticsLink &&
      state.analyticsLink.type === "createNewLink" &&
      state.analyticsLink.value.customDomainId === DEFAULT_DOMAIN
    ) {
      adjustedAnalyticsLink = {
        ...state.analyticsLink,
        value: {
          ...state.analyticsLink.value,
          customDomainId: null,
        },
      };
    }

    void update({
      ...state.data,
      status,
      analyticsLink: adjustedAnalyticsLink,
    });
    logEvent("experiment.updated", { billingPlanTier: subscriptionTier });
    successToast("Experiment Updated", "");
  };

  return (
    <div className="flex flex-col gap-2 pb-32">
      <div className="flex flex-row justify-between">
        <TabMenu />
        {dataChanged && (
          <div className="flex flex-row justify-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => void onSave()}
              isDisabled={!isUpdateAllowed}
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
      <div className="space-y-6 text-sm">
        <LinkSection
          analyticsLink={state.analyticsLink}
          onAnalyticsLinkChange={onAnalyticsLinkChange}
          handleLinkSubsectionChange={handleLinkSubsectionChange}
          isEditable={status === "draft"}
          domainOptions={domainOptions}
          shortNameOptions={shortNameOptions}
          links={links ?? []}
        />
        <DetailsContainer title="Pages to split traffic to" isRequired>
          <div className="space-y-3">
            <div className="space-y-3 pb-2">
              {state.data.variations.map((variation) => (
                <VariationEntry
                  key={variation.id}
                  {...variation}
                  onChange={onVariationChange}
                  onRemove={onVariationRemoval}
                  isAllocationPercentValid={
                    state.validation.variations.arePercentagesValid
                  }
                  allowRemoval={state.data.variations.length > 1}
                  status={status}
                  allReploElements={allReploElements?.elements ?? []}
                  isLoadingAllReploElements={isLoadingAllReploElements}
                />
              ))}
            </div>
            <ExperimentValidityComponent {...state.validation} />
            {status === "draft" && (
              <Button variant="primary" size="sm" onClick={onAddVariation}>
                <div className="flex flex-row items-center content-center">
                  <BsPlus size={20} />
                  <span>Add Variation</span>
                </div>
              </Button>
            )}
          </div>
        </DetailsContainer>
        <DetailsContainer title="Description">
          <Textarea
            className="grow"
            maxLength={256}
            placeholder="Keep track of why you are testing or other test details, for yourself or others on your team."
            onChange={(description) => onPropertyChange({ description })}
            value={state.data.description ?? ""}
          />
        </DetailsContainer>
      </div>
    </div>
  );
};

const ExperimentValidityComponent = ({
  variations: {
    areSlugsUnique,
    areSlugsValid,
    areTargetsValid,
    arePercentagesValid,
  },
}: ExperimentValidation) => {
  if (
    areSlugsUnique &&
    areSlugsValid &&
    areTargetsValid &&
    arePercentagesValid
  ) {
    return null;
  }
  return (
    <div className="pt-2 grid grid-cols-12 gap-4 items-center content-center text-slate-400">
      <div
        className={classNames("col-span-3 opacity-0", {
          "opacity-100 text-red-500": !areSlugsUnique || !areSlugsValid,
        })}
      >
        Must be unique, between 1 and 256 characters, with only letters,
        numbers, underscores, and hyphens.
      </div>
      <div
        className={classNames("col-span-7 opacity-0", {
          "opacity-100 text-red-500": !areTargetsValid,
        })}
      >
        Target URLs must be valid and start with{" "}
        <span className="font-mono">https://</span> or{" "}
        <span className="font-mono">http://</span>
      </div>
      <div
        className={classNames("col-span-2 opacity-0", {
          "opacity-100 text-red-500": !arePercentagesValid,
        })}
      >
        Must total 100%
      </div>
    </div>
  );
};

const ConnectShopifyButton: React.FC = () => {
  return (
    <div className="text-accent">
      <Button variant="inherit" size="sm" href={SHOPIFY_APP_LISTING_URL}>
        Connect Shopify
      </Button>
    </div>
  );
};

const EnableAnalyticsButton: React.FC = () => {
  const { oauthLink, isLoading } = useAnalyticsOnboardingOAuthLink();
  const productAnalytics = useLogAnalytics();

  return (
    <div className="text-accent">
      <Button
        size="sm"
        variant="inherit"
        href={oauthLink ?? ""}
        isLoading={isLoading || !oauthLink}
        onClick={() =>
          productAnalytics("analytics.connect", {
            tab: "experiment_details_tab",
          })
        }
      >
        Enable Analytics
      </Button>
    </div>
  );
};

const variationEntryInfoBannerOptions = {
  connectShopify: {
    description:
      "This page must be part of a Replo project with a Shopify integration to track results.",
    ButtonComponent: () => <ConnectShopifyButton />,
  },
  connectAnalytics: {
    description:
      "You must enable Analytics for this Shopify integration in order to track results on this page.",
    ButtonComponent: () => <EnableAnalyticsButton />,
  },
};

type VariationEntryInfoBannerProps = {
  type: keyof typeof variationEntryInfoBannerOptions;
};
const VariationEntryInfoBanner: React.FC<VariationEntryInfoBannerProps> = ({
  type,
}) => {
  const { description, ButtonComponent } =
    variationEntryInfoBannerOptions[type];

  return (
    <div className="bg-accent-emphasis flex flex-row rounded-lg p-4 items-center justify-between">
      <div className="flex flex-row gap-3 text-accent">
        <BsInfoCircle />
        <span className="text-xs">{description}</span>
      </div>
      <ButtonComponent />
    </div>
  );
};

const VariationEntry = ({
  onChange,
  onRemove,
  allowRemoval,
  isAllocationPercentValid,
  status,
  allReploElements,
  isLoadingAllReploElements,
  ...variation
}: Variation & {
  onChange: (v: Variation) => void;
  onRemove: (id: string) => void;
  allowRemoval: boolean;
  isAllocationPercentValid: boolean;
  status: ExperimentStatus;
  allReploElements: ReploElementForExperiments[];
  isLoadingAllReploElements: boolean;
}) => {
  const isEditable = status === "draft";

  const [inputValue, setInputValue] = React.useState("");

  const allReploElementsOptions = React.useMemo(() => {
    const options = allReploElements.map((page) => {
      const pageFullUrl = `https://${page.shopifyUrl}/pages/${page.shopifyPagePath}`;
      return {
        label: pageFullUrl,
        value: pageFullUrl,
      };
    });

    const doesInputExistInOptions = options.some(
      (option) => option.value === inputValue,
    );

    // NOTE (Max, 2024-11-12): If the input value is not in the options, add it.
    // This allows the user to selec ta page that is not in the dropdown (i.e. not
    // a replo page).
    if (inputValue && !doesInputExistInOptions) {
      options.push({
        label: inputValue,
        value: inputValue,
      });
    }

    return options;
  }, [inputValue, allReploElements]);

  const onInputChange = (value: string) => {
    setInputValue(value);
  };

  const target = variation.target;

  let isTargetConnectedToShopify = false;
  let isTargetStoreConnectedToAnalytics = false;

  if (isValidHttpUrl(target)) {
    const targetDomain = new URL(target).hostname;

    const matchingElement = allReploElements.find(
      (element) => element.shopifyUrl === targetDomain,
    );

    /**
     * TODO (Max, 2024-11-15, REPL-14693): As a temporary fix, we're checking if the target
     * is the placeholder URL. If it is, we're not showing the info banner (otherwise
     * all the experiments would show this banner upon creation).
     *
     * The long term fix (REPL attached) is to create the experiment and selecting as a default
     * 2 existing pages, and fallback on this placeholder URL if there are none
     */
    const isTargetPlaceholderURL = target === PLACEHOLDER_URL;

    isTargetConnectedToShopify =
      Boolean(matchingElement) || isTargetPlaceholderURL;
    isTargetStoreConnectedToAnalytics = Boolean(matchingElement?.hasWebPixelId);
  }

  return (
    <div className="flex flex-col gap-2 border border-slate-300 p-4 rounded">
      <div className="grid grid-cols-12 gap-4 h-8 items-center content-center font-semibold text-muted">
        <div className="col-span-3">Name</div>
        <div className="col-span-7">Page URL</div>
        <div className="col-span-1">Percent</div>
        <div className="col-span-1"></div>
      </div>
      <div className="grid grid-cols-12 gap-4 items-center content-center">
        <div className="col-span-3">
          {isEditable ? (
            <InputComponent
              size="base"
              value={variation.slug}
              type="text"
              unsafe_className={classNames({
                "text-red-600 ring-1 ring-red-600": !isPathSafeSlug(
                  variation.slug,
                ),
              })}
              maxLength={256}
              onChange={(e) =>
                onChange({ ...variation, slug: e.currentTarget.value })
              }
            />
          ) : (
            <div className="py-1">{variation.slug}</div>
          )}
        </div>
        <div className="col-span-7">
          <Combobox
            options={allReploElementsOptions}
            value={target}
            onChange={(value) => onChange({ ...variation, target: value })}
            areOptionsSearchable={true}
            input={inputValue}
            onInputChange={onInputChange}
            endEnhancer={() => (
              <ReploLogoBadge className="h-6 w-6 cursor-pointer text-default" />
            )}
            popoverTriggerClassName="w-full"
            isLoading={isLoadingAllReploElements}
          />
        </div>
        <div className="col-span-1">
          {isEditable ? (
            <InputComponent
              size="base"
              value={variation.allocationPercent.toString()}
              unsafe_className={classNames({
                "text-red-600 ring-1 ring-red-600": !isAllocationPercentValid,
              })}
              type="number"
              onChange={(e) => {
                const percent = Number.parseInt(e.currentTarget.value);
                const allocationPercent = Number.isNaN(percent)
                  ? 0
                  : Math.max(percent, 0);
                onChange({ ...variation, allocationPercent });
              }}
            />
          ) : (
            <div className="py-1">{variation.allocationPercent}%</div>
          )}
        </div>
        <div className="col-span-1">
          {isEditable && (
            <div className="mt-2">
              <Button
                variant="tertiary"
                size="sm"
                className="h-7"
                isDisabled={!allowRemoval}
                onClick={() => onRemove(variation.id)}
              >
                <BsTrash size={16} />
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isTargetConnectedToShopify && (
        <div className="mb-2">
          <VariationEntryInfoBanner type="connectShopify" />
        </div>
      )}

      {/* NOTE (Max, 2024-11-14): We only wanna show the analyticsBanner if the
       * target is connected to Shopify. */}
      {isTargetConnectedToShopify && !isTargetStoreConnectedToAnalytics && (
        <div className="mb-2">
          <VariationEntryInfoBanner type="connectAnalytics" />
        </div>
      )}
    </div>
  );
};
