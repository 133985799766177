import type { EditorCanvas } from "replo-utils/lib/misc/canvas";
import type { Animation } from "schemas/animations";
import type { MediaSize } from "schemas/breakpoints";
import type { Component } from "schemas/component";
import type { RuntimeStyleProperties } from "schemas/styleAttribute";
import type { GradientStops } from "../types";
import type { ComponentStyleProps } from "./renderComponents";

import { parseInteger } from "replo-utils/lib/math";

import {
  isShopifyLiquidImageSource,
  swapShopifyLiquidImageWidth,
} from "../../store/utils/liquid";
import { getExpectedFlexBasis } from "./component";
import {
  allCSSSideNames,
  getDynamicDataCustomPropertyName,
  isEmptyOrAuto,
} from "./css";
import { isDynamicDesignLibraryValue } from "./designLibrary";
import { gradientToCssGradient } from "./gradient";
import { getTransformStyleString } from "./transform";
import { parseUnit } from "./units";

// Note (Ovishek, 2022-08-29): This empty string means the default styles
// without any media query limit more technically, it represents
// component.props.style.
export const defaultMediaSize = "" as const;

export const MEDIA_MAX_SIZE = {
  sm: 640,
  md: 1024,
  lg: 2400,
} satisfies Record<MediaSize, number>;

const mediaSizesWithDefault = [
  defaultMediaSize,
  "sm",
  "md",
  "lg",
] as const satisfies ReadonlyArray<MediaSizeWithDefault>;
export type MediaSizeWithDefault = MediaSize | typeof defaultMediaSize;

export type MediaStyleKey = "style" | `style@${MediaSize}`;

// Note (Noah, REPL-1018): We have this array of media sizes that we actually
// support in the editor which should be used generally when we need to apply
// things for "all" canvas sizes. This is needed for animations because there
// are random issues with the aliased styles overriding actual styles in certain
// cases (see ticket)
export const supportedMediaSizes = [
  "sm",
  "md",
] as const satisfies ReadonlyArray<MediaSize>;
export type SupportedMediaSize = (typeof supportedMediaSizes)[number];
export type SupportedMediaStyleKey = "style" | `style@${SupportedMediaSize}`;

export type SupportedMediaSizeWithDefault =
  | SupportedMediaSize
  | typeof defaultMediaSize;

export const mediaSizeStyles = [
  "style",
  "style@sm",
  "style@md",
] as const satisfies ReadonlyArray<SupportedMediaStyleKey>;

export const mediaSizeStylesToAnimationSize = {
  style: ["lg", "xl"],
  "style@sm": ["sm"],
  "style@md": ["md"],
};

export const allMediaSizeStyles = [
  "style",
  "style@sm",
  "style@md",
  "style@lg",
] as const satisfies ReadonlyArray<MediaStyleKey>;

const mediaSizeToDeviceName = {
  sm: "Mobile",
  md: "Tablet",
  lg: "Laptop",
} as const satisfies Record<MediaSize, string>;

export const getDeviceNameFromMediaSize = (mediaSize: MediaSize) => {
  return mediaSizeToDeviceName[mediaSize];
};

// Note (Ovishek, 2022-07-23): These dependencies are ordered by large to small
// and that's exactly how they are dependent.
export const mediaSizeToDependentSizes: Record<MediaSize, MediaSize[]> = {
  sm: ["lg", "md"],
  md: ["lg"],
  lg: [],
};

// Note (Noah, 2021-08-10): We need both min and max widths here because Radium
// doesn't have a stable ordering for media queries so if we don't have them
// sometimes overrides get applied in the wrong order:
// https://github.com/FormidableLabs/radium/issues/879
export const mediaQueries = {
  sm: `(max-width: ${MEDIA_MAX_SIZE.sm}px)`,
  md: `(min-width: ${MEDIA_MAX_SIZE.sm + 1}px) and (max-width: ${MEDIA_MAX_SIZE.md}px)`,
  lg: `(min-width: ${MEDIA_MAX_SIZE.md + 1}px) and (max-width: ${MEDIA_MAX_SIZE.lg}px)`,
} as const satisfies Record<MediaSize, string>;

export const media = {
  sm: `@media ${mediaQueries["sm"]}`,
  md: `@media ${mediaQueries["md"]}`,
  lg: `@media ${mediaQueries["lg"]}`,
} as const satisfies Record<MediaSize, string>;

type MediaQuery = (typeof media)[MediaSize];

const resolveBackgroundImageUrl = (backgroundImage: string) => {
  // Note (Evan, 2024-10-22): A backslash in an image URL will break rendering on published
  // pages (USE-1375). We're also now validating the input in the editor, so this ~should~ never
  // happen, but in case it does, we'll skip rendering the background image so the rest of
  // the page still works.
  if (backgroundImage?.includes("\\")) {
    return "none";
  }
  if (
    backgroundImage &&
    typeof backgroundImage === "string" &&
    !backgroundImage.startsWith("url(") &&
    !backgroundImage.includes("gradient(")
  ) {
    return `url("${backgroundImage}")`;
  }
  return backgroundImage;
};

export function cleanupAliasedStyles(
  style: Record<string, any>,
  config: StyleAliasConfig,
) {
  const styles = { ...style };
  for (const key of Object.keys(styles)) {
    if (styles[key] == null || key.startsWith("__")) {
      delete styles[key];
    }

    if (config.isEditor) {
      // Note (Ovishek, 2023-02-23): We replace all the relative heights with
      // hardcoded pixel viewport heights. If we don't do this, the iframe does
      // load indefinitely long and it never stops. Viewport heights don't really
      // work inside iframes where, it's height depends on their content. This is
      // a circular dependency on height, and was causing the issue. And in
      // published page we keep the same vh/vw. That means this change only
      // effects in the editor.
      const propertiesAffectVerticalPosition = [
        "height",
        "minHeight",
        "maxHeight",
        "paddingTop",
        "paddingBottom",
        "marginTop",
        "marginBottom",
        "top",
        "bottom",
      ];

      if (propertiesAffectVerticalPosition.includes(key)) {
        styles[key] = calculateViewportRelativeValue(
          styles[key],
          config.viewportHeight,
        );
      }
    }
  }
  return styles;
}

// TODO (Gabe 2023-06-15): improve the types here
export function getAliasedStyles({
  styles,
  mediaSizeStyles,
  mediaSize,
  animations,
  config,
}: {
  styles: RuntimeStyleProperties;
  mediaSizeStyles: RuntimeStyleProperties;
  mediaSize: MediaSizeWithDefault;
  animations: Animation[];
  config: StyleAliasConfig;
}): RuntimeStyleProperties {
  if (!styles) {
    return {};
  }

  // Note (Noah, 2022-04-07): Make a copy of default styles since we're going to
  // mutate resolvedStyles and we don't want to accidentally update the original object
  const resolvedStyles: RuntimeStyleProperties & {
    "--replo-margin-left"?: RuntimeStyleProperties["marginLeft"];
    "--replo-margin-right"?: RuntimeStyleProperties["marginRight"];
    "--replo-margin-top"?: RuntimeStyleProperties["marginTop"];
    "--replo-margin-bottom"?: RuntimeStyleProperties["marginBottom"];
  } = Object.assign({}, mediaSizeStyles, styles);

  if (styles.__display === "none") {
    resolvedStyles.display = "none";
  }

  // Note (Noah, 2022-08-17, REPL-3053): There's some issue with templates where
  // sometimes apparently some images have display: inline. We never want this - we
  // should fix it on the template side, but for now we always reset the display
  // to flex
  if (styles.display === "inline") {
    resolvedStyles.display = "flex";
  }

  if (styles.position === "__alchemy:stickyToHeader") {
    resolvedStyles.position = "sticky";
    const target = resolvedStyles.top ?? null;
    const parsedUnit = parseUnit(target, { value: 0, unit: "px" }, "top", "px");
    if (parsedUnit.unit === "px") {
      const fullPageOffset = config.fullPageOffset;
      parsedUnit.value = parseInteger(`${parsedUnit.value}`) + fullPageOffset;
      resolvedStyles.top = `${parsedUnit.value}${parsedUnit.unit}`;
    }
  }

  if (styles.__animateVariantTransitions) {
    resolvedStyles.transition = "all 300ms cubic-bezier(0.4, 0, 0.2, 1)";
  }

  // If border-radius is set, we need to check overflow related styles
  if (
    Object.keys(styles).some((styleRule) =>
      [
        "borderRadius",
        "borderTopLeftRadius",
        "borderTopRightRadius",
        "borderBottomLeftRadius",
        "borderBottomRightRadius",
      ].includes(styleRule),
    )
  ) {
    if (styles.overflow) {
      if (!styles.overflow.includes("visible")) {
        // Note (Martin, 2023-04-11, USE-58): Workaround for Safari to make
        // border-radius and overflow to work together. This ensures things like
        // Youtube Embed components correctly cut corners off in Safari if a
        // border-radius is defined.
        // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
        // @ts-expect-error because this is non-standard
        resolvedStyles["WebkitMaskImage"] =
          "-webkit-radial-gradient(white, black)";
      }
    } else {
      // Note (Sebas, 2022-09-08): If no overflow is set, we need to set
      // overflow: 'hidden' for children to respect the border radius.
      resolvedStyles.overflow = "hidden";
    }
  }

  for (const property of ["width", "color"]) {
    // Note (Chance 2023-07-14) This is faster than startCase, perfectly fine
    // for this use case
    const prop = (property.charAt(0).toUpperCase() + property.slice(1)) as
      | "Width"
      | "Color";
    const shorthandProperty = `border${prop}` as const;
    if (
      allCSSSideNames("border", prop).every((property) =>
        Boolean(resolvedStyles[property]),
      ) &&
      resolvedStyles[shorthandProperty]
    ) {
      delete resolvedStyles[shorthandProperty];
    }
    if (styles[shorthandProperty]) {
      for (const property of allCSSSideNames("border", prop)) {
        if (!styles[property]) {
          // Note (Noah, 2023-10-13): Without the "as", this apparently
          // produces a type which is too complex for typescript to represent.
          // No problem, casting to the key type works fine for this case
          resolvedStyles[property as keyof RuntimeStyleProperties] =
            styles[shorthandProperty];
        }
      }
    }
  }

  if (styles.textAlign) {
    // Note (Noah, 2021-08-21): Set textAlignLast because it's necessary to style
    // text in <select> elements. This is fine because we don't support aligning
    // the last line of text in Replo otherwise. See for more info:
    // https://stackoverflow.com/questions/35654636/text-align-center-placeholder-text-in-select/35655402
    resolvedStyles.textAlignLast = styles.textAlign;
  }

  if (styles.color === "alchemy:gradient") {
    let tilt = styles["__alchemyGradient__color__tilt"] ?? "0deg";
    tilt = tilt.toString();
    // Note (Chance 2024-07-14) If stops is not an array or is empty, we will
    // assign it below so this cast is safe.
    let stops = styles["__alchemyGradient__color__stops"] as GradientStops;
    if (!Array.isArray(stops) || stops.length === 0) {
      stops = [{ color: "#000000", location: "0%" }];
    }

    resolvedStyles.backgroundColor = styles.backgroundColor ?? stops[0].color;
    resolvedStyles.backgroundImage = gradientToCssGradient({ tilt, stops });
    resolvedStyles.backgroundSize = "100%";

    // @ts-expect-error because these are non-standard
    resolvedStyles["-webkitTextFillColor"] = "transparent";
    // @ts-expect-error
    resolvedStyles["-webkitBackgroundClip"] = "text";
    // @ts-expect-error
    resolvedStyles["-mozBackgroundClip"] = "text";
  }

  if (styles.backgroundColor === "alchemy:gradient") {
    let tilt = styles["__alchemyGradient__backgroundColor__tilt"] ?? "0deg";
    tilt = tilt.toString();

    // Note (Chance 2024-07-14) If stops is not an array or is empty, we will
    // assign it below so this cast is safe.
    let stops = styles[
      "__alchemyGradient__backgroundColor__stops"
    ] as GradientStops;
    if (!Array.isArray(stops) || stops.length === 0) {
      stops = [{ color: "#000000", location: "0%" }];
    }

    const resolvedGradientCss = gradientToCssGradient({ tilt, stops });
    const resolvedBackgroundImage = styles.backgroundImage
      ? resolveBackgroundImageUrl(styles.backgroundImage)
      : null;

    // Note (Sebas, 2022-08-08): In case we have both an image and a gradient as background,
    // we need to combine them in the same prop.
    const backgroundImage = resolvedBackgroundImage
      ? `${resolvedBackgroundImage}, ${resolvedGradientCss}`
      : gradientToCssGradient({ tilt, stops });

    resolvedStyles.backgroundColor = styles.backgroundColor ?? stops[0].color;
    resolvedStyles.backgroundImage = backgroundImage;
    resolvedStyles.backgroundSize = styles.backgroundSize ?? "100%";
  }

  // TODO (Martin, 2023-09-29): Move this to getResolvedStyles and resolveStyleRule
  // since it's mostly related about resolving dynamic data.
  if (
    styles.color?.startsWith("{{") &&
    !isDynamicDesignLibraryValue(styles.color)
  ) {
    const dynamicDataKey = getDynamicDataCustomPropertyName(styles.color);
    resolvedStyles.backgroundColor = `var(${dynamicDataKey}-background-color, ${
      styles.backgroundColor?.startsWith("{{")
        ? `var(${getDynamicDataCustomPropertyName(
            styles.backgroundColor,
          )}-background-color, transparent)`
        : styles.backgroundColor ?? "transparent"
    })`;
    resolvedStyles.backgroundImage = `var(${dynamicDataKey}-background-image, ${
      styles.backgroundImage?.startsWith("{{")
        ? `var(${getDynamicDataCustomPropertyName(
            styles.backgroundImage,
          )}, none)`
        : styles.backgroundImage ?? "none"
    })`;

    // @ts-expect-error
    resolvedStyles["-webkitBackgroundClip"] =
      `var(${dynamicDataKey}-background-clip, border-box)`;
    // @ts-expect-error
    resolvedStyles["-mozBackgroundClip"] =
      `var(${dynamicDataKey}-background-clip, border-box)`;
    // @ts-expect-error
    resolvedStyles["-webkitTextFillColor"] =
      `var(${dynamicDataKey}-webkit-text-fill-color, currentcolor)`;
  } else if (
    styles.backgroundColor?.startsWith("{{") &&
    !isDynamicDesignLibraryValue(styles.backgroundColor)
  ) {
    const dynamicDataKey = getDynamicDataCustomPropertyName(
      styles.backgroundColor,
    );

    resolvedStyles.backgroundColor = `var(${dynamicDataKey}-background-color, transparent)`;
    resolvedStyles.backgroundImage = `var(${dynamicDataKey}-background-image, ${
      styles.backgroundImage?.startsWith("{{")
        ? `var(${getDynamicDataCustomPropertyName(
            styles.backgroundImage,
          )}, none)`
        : styles.backgroundImage ?? "none"
    })`;
  }

  if (
    styles.backgroundColor !== "alchemy:gradient" &&
    styles.color !== "alchemy:gradient" &&
    !styles.color?.startsWith("{{") &&
    !styles.backgroundColor?.startsWith("{{") &&
    styles.backgroundImage
  ) {
    // Note (Sebas, 2022-08-16): In case there is no gradient and the user
    // selects a dynamic image we need to set a valid url for it to work.
    // Also we need to check if the color of the font is not a gradient
    // (only valid for text components) to not override it.
    resolvedStyles.backgroundImage = resolveBackgroundImageUrl(
      styles.backgroundImage,
    );
  }

  // NOTE (Martin, 2024-03-05, USE-764, USE-784): We need to explicitly set
  // background-image to none if background-color is set, it's not a gradient
  // nor dynamic and there's no background-image set. This is because if we
  // don't do this, background-image could be inherited from base styles on
  // media queries and that will interfere with what the user set to be the
  // background color.
  // TODO (Martin, 2024-03-08): We should abstract the checking below, along
  // with the ones above into some helper utils.
  if (
    styles.backgroundColor &&
    styles.backgroundColor !== "alchemy:gradient" &&
    !styles.backgroundColor.startsWith("{{") &&
    !styles.backgroundImage
  ) {
    resolvedStyles.backgroundImage = "none";
  }

  // Note (Noah, 2021-09-15): Always set flexShrink on ALL components, because
  // we never want the flexbox behavior where elements shrink if they have defined
  // content widths that are greater than their parent - we always want them to
  // overflow (and the user can specify overflow behavior)
  if (styles.flexGrow && styles.flexGrow !== "unset") {
    // Note (Evan, 2024-07-29): Use the flex shrink value from styles, if there
    // is one (currently just for Figma imports), fall back to the flex grow
    // value.
    resolvedStyles.flexShrink = styles.__flexShrink ?? styles.flexGrow;

    // Set flexBasis so that the containers don't expand based on their intrinsic
    // content
    // https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/
    resolvedStyles.flexBasis = getExpectedFlexBasis({
      hasFlexGrow: true,
      parentFlexDirection: styles.__parentFlexDirection,
      parentHasDefinedWidth: styles.__parentHasWidth,
      parentHasDefinedHeight: styles.__parentHasHeight,
    });
  } else {
    // Note (Chance 2023-06-30) If there is no flex-grow, the flex item should
    // never shrink when the element has a defined width, per our comment above
    // the if statement.
    resolvedStyles.flexShrink = styles.__flexShrink ?? 0;

    // Note (Noah, 2022-06-13, REPL-2605, REPL-2610): We always want flex-basis to be auto
    // if there's a width set, otherwise we can get into situations where there was a flex-basis
    // set from before, but now we set the flex-grow to "unset" because we set a defined width,
    // but flex-basis is 0 or something which causes a lot of issues.
    resolvedStyles.flexBasis = "auto";
  }

  // Note (Noah, 2022-07-18, REPL-2978): If a container is supposed to fill available
  // space, we need to make sure to set min-width/height to 0. This is because
  // flex containers are not allowed to shrink below their min-width/height, and
  // the default min-width/height is auto, which means with enough content, a container
  // will expand beyond the available space it's filling. Setting min-width to 0 makes
  // the "fill available space" work as expected.
  //
  // HOWEVER, this only makes sense if the flex-wrap is set to not wrap. If it's wrap,
  // then we don't want to set any min-height/width, because it will prevent the wrap
  // logic from working correctly. So, we use a css var to set the min-width/height to 0px
  // UNLESS we specifically have flex-wrap: wrap on a parent.
  //
  // See: https://stackoverflow.com/questions/73016104/flexbox-growing-div-with-flex-basis-0px-expands-too-far-when-theres-lots-of-c
  if (styles.flexWrap) {
    // Note (Chance 2023-07-14) We can stop expecting this error when we augment
    // React.CSSProperties to include our custom properties
    // @ts-expect-error
    resolvedStyles["--replo-flex-min-dimension"] =
      styles.flexWrap === "wrap" ? "auto" : "0px";
  }

  // NOTE (Chance 2024-02-23): We want to set min-width or min-height to 0px
  // depending on the parent's flex-direction if it is not explicitly set. This
  // keeps the flex item from expanding beyond the available space. We evaluate
  // each property first by looking at resolvedStyles, as it may have been
  // previously overridden earlier in the function. Otherwise we evaluate
  // mergedStyles to see if it was set otherwise by the component definition or
  // in some dependant styles.
  const parentHasWidth =
    resolvedStyles.__parentHasWidth ?? styles.__parentHasWidth;
  const parentHasHeight =
    resolvedStyles.__parentHasHeight ?? styles.__parentHasHeight;
  const parentFlexDirection =
    resolvedStyles.__parentFlexDirection ?? styles.__parentFlexDirection;
  const flexBasis = resolvedStyles.flexBasis ?? styles.flexBasis;
  const flexGrow = resolvedStyles.flexGrow ?? styles.flexGrow;
  const minHeight = resolvedStyles.minHeight ?? styles.minHeight;
  const minWidth = resolvedStyles.minWidth ?? styles.minWidth;

  if (
    parentFlexDirection === "row" &&
    flexBasis === 0 &&
    flexGrow &&
    isEmptyOrAuto(minWidth) &&
    parentHasWidth
  ) {
    resolvedStyles.minWidth = "var(--replo-flex-min-dimension, 0px)";
  } else if (
    parentFlexDirection === "column" &&
    flexBasis === 0 &&
    flexGrow &&
    isEmptyOrAuto(minHeight) &&
    parentHasHeight
  ) {
    resolvedStyles.minHeight = "var(--replo-flex-min-dimension, 0px)";
  }

  for (const [entry, value] of Object.entries(resolvedStyles)) {
    if (value == null || value == "alchemy:gradient") {
      // 'string' can't be used to index type 'RuntimeStyleProperties'
      // but this is fine
      // @ts-expect-error
      delete resolvedStyles[entry];
    }
  }

  if (
    config.alwaysHideOverflowYIfPossible &&
    styles.height &&
    styles.height !== "auto"
  ) {
    resolvedStyles.overflowY = "hidden";
  }

  if (styles.__flexGap) {
    resolvedStyles.rowGap = styles.__flexGap;
    resolvedStyles.columnGap = styles.__flexGap;
    if (config.addGapCssVariable) {
      // Note (Chance 2023-07-14) We can stop expecting this error when we
      // augment React.CSSProperties to include our custom properties
      // @ts-expect-error
      resolvedStyles["--replo-gap"] = styles.__flexGap;
    }
  }

  if (styles.display === "grid" && (styles.rowGap || styles.columnGap)) {
    const rowGap = styles.rowGap || "0px";
    const columnGap = styles.columnGap || "0px";
    resolvedStyles.gap = `${rowGap} ${columnGap}`;
    // NOTE (Fran 2024-03-19): We should delete rowGap and columnGap from the resolvedStyles
    // to avoid conflicts with the gap property.
    delete resolvedStyles.rowGap;
    delete resolvedStyles.columnGap;
    // Note (Chance 2023-07-14) We can stop expecting this error when we augment
    // React.CSSProperties to include our custom properties
    // @ts-expect-error
    resolvedStyles["--replo-gap"] = columnGap;
  }

  if (mediaSizeStyles?.__animation) {
    const animation = animations?.find((animation) =>
      animation.devices.includes(mediaSize || "lg"),
    );
    for (const [key, value] of Object.entries(mediaSizeStyles.__animation)) {
      if (key === "animationName") {
        const shouldBeAnimated = animation && !config.isEditor;
        resolvedStyles[key as keyof RuntimeStyleProperties] = shouldBeAnimated
          ? value
          : "none";
      } else {
        resolvedStyles[key as keyof RuntimeStyleProperties] = value;
      }
    }
    resolvedStyles["animationPlayState"] =
      animation?.trigger.type === "onViewportEnter" ? "paused" : "running";
    if (animation?.runOnlyOnce === false) {
      resolvedStyles["animationIterationCount"] = "infinite";
    }
  }

  if (styles.__textStroke) {
    // @ts-expect-error
    resolvedStyles["-webkit-text-stroke"] = styles.__textStroke;
  }

  // Note (Noah, 2023-06-30, REPL-7839): If we have a legacy __alchemyRotation
  // value, then default to that and don't add in any transform value (if the
  // user ever sets a different transform value via the editor, the
  // __alchemyRotation value will be removed). However, default to the
  // __alchemyRotation value if it exists on a dependent canvas size (as long as
  // the current canvas size doesn't have a transform value set - otherwise we
  // would override it that transform value, which we don't want to do)
  const alchemyRotationValue =
    resolvedStyles.__alchemyRotation ??
    (!resolvedStyles.__transform ? styles.__alchemyRotation : null);
  if (alchemyRotationValue) {
    resolvedStyles.__transform = Object.assign(
      {},
      {
        translateX: "0px",
        translateY: "0px",
        translateZ: "0px",
        scaleX: "100%",
        scaleY: "100%",
        scaleZ: "100%",
        rotateX: "0deg",
        rotateY: "0deg",
        rotateZ: "0deg",
        skewX: "0deg",
        skewY: "0deg",
      },
      resolvedStyles.__transform,
      { rotateZ: alchemyRotationValue },
    );
  }

  if (resolvedStyles.__transform) {
    resolvedStyles.transform = getTransformStyleString(
      resolvedStyles.__transform,
    );
  }

  if (!styles.position && config.defaultToPositionRelative) {
    resolvedStyles.position = "relative";
  }

  // # region Force max-width/height dimensions to fill available space in a flex container

  // NOTE (Fran 2024-09-19, USE-1306): In certain cases, align-self: stretch doesn't work when trying to
  // make a component take up the entire space of its parent. This can cause issues like USE-1306,
  // where the user is trying to center a container with max-width inside a flexbox container with
  // center or end alignment. Therefore, we should force the component to take up the entire space.
  // https://fathom.video/share/GQhKSYWjhr7QdBBdyxKghGJt5syVjLsb
  const isInsideAVerticalDirectionFlexboxContainer =
    styles.__parentFlexDirection === "column" &&
    styles.__parentAlignItems &&
    !["start", "flex-start"].includes(styles.__parentAlignItems);

  if (
    styles.alignSelf === "stretch" &&
    isInsideAVerticalDirectionFlexboxContainer &&
    styles.maxWidth &&
    styles.maxWidth !== "100%"
  ) {
    resolvedStyles["--replo-margin-left"] =
      resolvedStyles.marginLeft === "auto" ? "0px" : resolvedStyles.marginLeft;
    resolvedStyles["--replo-margin-right"] =
      resolvedStyles.marginRight === "auto"
        ? "0px"
        : resolvedStyles.marginRight;
    resolvedStyles.width =
      "calc(100% - (var(--replo-margin-left, 0px) + var(--replo-margin-right, 0px)))";
    resolvedStyles.alignSelf = "auto";
  }

  const isInsideAHorizontalDirectionFlexboxContainer =
    styles.__parentFlexDirection === "row" &&
    styles.__parentAlignItems &&
    !["start", "flex-start"].includes(styles.__parentAlignItems);

  if (
    styles.alignSelf === "stretch" &&
    isInsideAHorizontalDirectionFlexboxContainer &&
    styles.maxHeight &&
    styles.maxHeight !== "100%"
  ) {
    resolvedStyles["--replo-margin-top"] =
      resolvedStyles.marginTop === "auto" ? "0px" : resolvedStyles.marginTop;
    resolvedStyles["--replo-margin-bottom"] =
      resolvedStyles.marginBottom === "auto"
        ? "0px"
        : resolvedStyles.marginBottom;
    resolvedStyles.height =
      "calc(100% - (var(--replo-margin-top, 0px) + var(--replo-margin-bottom, 0px)))";
    resolvedStyles.alignSelf = "auto";
  }

  // #endregion

  if (styles.fontFamily) {
    // NOTE (Fran 2024-11-05 USE-1410): We need to wrap the font name in quotes
    // because it may contain spaces or any special characters that can
    // cause issues in a Replo Element.
    // Handle font family names that may contain special characters
    resolvedStyles.fontFamily = styles.fontFamily
      .split(",")
      .map((font) => {
        // NOTE (Fran 2024-11-06 USE-1421): If the font family name contains spaces, we need to wrap it in quotes.
        if (/\s/g.test(font)) {
          // NOTE (Fran 2024-11-06 USE-1421): We need to remove the quotes from the font family name because they are already escaped.
          return `"${font.replace(/(^"|"$)/g, "")}"`;
        }

        // Escape quotes and backslashes
        return font.replace(/(["'\\])/g, "\\$1");
      })
      .join(", ");
  }

  return resolvedStyles;
}

export type StyleAliasConfig = {
  /**
   * If true and the element has height, will automatically set overflow-y to hidden
   * (necessary for text).
   */
  alwaysHideOverflowYIfPossible: boolean;

  /**
   * If true, position: relative will be added if the component does not otherwise
   * specify position. Useful for components which can have children.
   */
  defaultToPositionRelative: boolean;

  /**
   * If true, a css var will be added for the flex-gap property (if it exists). Useful
   * for when things like CarouselV3 need to reference the gap property using a css var.
   */
  addGapCssVariable: boolean;
  fullPageOffset: number;
  isEditor?: boolean;
  viewportHeight?: number;
};

/**
 * This function converts viewport relative sizes to pixel values
 * Using window innerHeight/Width calculation
 */
const calculateViewportRelativeValue = (
  value: string,
  viewportHeight?: number,
) => {
  if (
    !value ||
    typeof value !== "string" ||
    // Note (Ovishek, 2023-02-23): This regular expression tests if the style
    // value is relative to viewport height for example 100vh, 30vh will be
    // passed but not 100% or 100px.
    !/^\d+vh$/.test(value)
  ) {
    return value;
  }

  return `${
    // Note (Ovishek, 2023-02-23): This 1400 is a safe fallback height if there's no window element in any time frame
    // And there's no viewport height
    (Number.parseFloat(value) / 100) * (viewportHeight ?? 1400)
  }px`;
};

function findDependents(mediaSize: MediaSize) {
  const dependents: MediaSize[] = [];
  Object.entries(mediaSizeToDependentSizes).forEach(([key, value]) => {
    if (value.includes(mediaSize)) {
      dependents.push(key as MediaSize);
    }
  });

  return dependents.reverse();
}

export const editorCanvasToMediaSize: Record<
  EditorCanvas,
  { mediaSize: MediaSize; dependents: MediaSize[] }
> = {
  desktop: {
    mediaSize: "lg",
    dependents: findDependents("lg"),
  },
  tablet: { mediaSize: "md", dependents: findDependents("md") },
  mobile: { mediaSize: "sm", dependents: findDependents("sm") },
};

export const mediaSizeToEditorCanvas: Record<MediaSize, EditorCanvas> = {
  lg: "desktop",
  md: "tablet",
  sm: "mobile",
};

export const mediaSizeToImageSize: Record<MediaSize, string> = {
  lg: "1800",
  md: "1024",
  sm: "820",
};

export const isShopifySizeableImage = (imageSrc: string | undefined) => {
  return (
    isShopifyLiquidImageSource(imageSrc) || isShopifySizeableImageUrl(imageSrc)
  );
};

/**
 * This function converts an image url to shopify sized image url based on the
 * canvas is being used, but keeps the same url if it's not from shopify
 */
export const convertToShopifySizedImageSource = (
  imageSource: string | undefined,
  mediaSize: MediaSize | null,
  width?: React.CSSProperties["width"] | null,
) => {
  if (!imageSource) {
    return imageSource;
  }

  let newImageSource = imageSource;

  const parsedWidth =
    typeof width === "number" ? width : Number.parseInt(width ?? "");
  const mediaWidth = mediaSize
    ? Number.parseInt(mediaSizeToImageSize[mediaSize])
    : null;

  let newWidth: number | null = null;

  /**
   * Note (Ovishek, 2023-01-02): This means how much quality we want. So, by 2x, we link to the image as
   * double of the fixed width/height, if it has any. For example, if we have a fixed width
   * image with width = 100px then we fetch the image with width = 200px which keeps the quality of the image.
   */
  const IMAGE_QUALITY_FACTOR = 2;

  if (
    typeof width === "string" &&
    width?.includes("px") &&
    !Number.isNaN(parsedWidth)
  ) {
    const qualityWidth = parsedWidth * IMAGE_QUALITY_FACTOR;
    newWidth = Math.min(qualityWidth, mediaWidth ?? Number.MAX_SAFE_INTEGER);
  } else if (mediaWidth) {
    newWidth = mediaWidth;
  }

  if (!newWidth) {
    return imageSource;
  }

  if (isShopifySizeableImageUrl(imageSource)) {
    if (!imageSource.includes("?")) {
      newImageSource += "?";
    } else if (
      imageSource.charAt(imageSource.length - 1) !== "?" &&
      imageSource.charAt(imageSource.length - 1) !== "&"
    ) {
      newImageSource += "&";
    }
    return `${newImageSource}width=${newWidth}`;
  }
  return swapShopifyLiquidImageWidth(imageSource, newWidth);
};

/**
 * Returns the style value for the given media size
 */
export const getMediaSizeStyleAttributeValue = (
  attribute: keyof RuntimeStyleProperties,
  props: Component["props"],
  mediaSize: MediaSizeWithDefault,
) => {
  if (mediaSize === "") {
    return props.style?.[attribute];
  }
  const sizes = mediaSizeToDependentSizes[mediaSize].concat(mediaSize);
  // NOTE (Chance 2024-02-22): We need to loop through the sizes in reverse
  // order because larger sizes are prioritized.
  for (let index = sizes.length - 1; index >= 0; index--) {
    const size = sizes[index]!;
    const value = props[`style@${size}`]?.[attribute];
    if (value != null) {
      return value;
    }
  }
  return props.style?.[attribute];
};

/**
 * Returns the media resolved styles for selected mapping, for example
 * {
 *   width: "10px",
 *   "@media (max-width: 640px)": {
 *      width: "5px",
 *   }
 * }
 */
// TODO (Gabe 2023-06-15): properly type the styles (if we plan to continue
// using this function)
export const mapMediaCanvasStyles = (
  // TODO (Noah, 2023-02-27, REPL-6462): Some older pages have components without props
  // (specifically Studs - I think this may only be a problem with Studs but I'm not sure).
  // Typescript is actually wrong here - most likely we should run a Distributed Element Query
  // and manually update all components which don't have props to have an empty object instead.
  // For now, to get around issues on their Piercing Services page, we assume this can be
  // undefined
  componentProps: Component["props"] | undefined,
  mapStyles: (
    styles: Record<string, any>,
    mediaSize: MediaSizeWithDefault,
  ) => Record<string, any>,
) => {
  let allMediaStyles: Record<string, any> = {};
  for (const mediaSize of mediaSizesWithDefault) {
    const styleProp = mediaSize ? (`style@${mediaSize}` as const) : "style";
    let mediaStyles = componentProps?.[styleProp] ?? {};
    if (mediaSize && componentProps) {
      mediaStyles = mediaSizeToDependentSizes[mediaSize]
        .concat(mediaSize)
        .reduce((resolvedStyles, currentMediaSize) => {
          const mediaStyles = componentProps[`style@${currentMediaSize}`];
          if (!mediaStyles || Object.keys(mediaStyles).length === 0) {
            return resolvedStyles;
          }
          return Object.assign({}, resolvedStyles, mediaStyles);
        }, componentProps.style ?? {});
    }

    const mappedStyles = mapStyles(mediaStyles, mediaSize);
    if (mediaSize) {
      allMediaStyles[media[mediaSize]] = mappedStyles;
    } else {
      allMediaStyles = { ...allMediaStyles, ...mappedStyles };
    }
  }
  return allMediaStyles;
};

/**
 * Returns the merge of two styles object into one, taking care of each media
 * query
 */
export const mergeStyles = (
  styles: Record<string, any>,
  otherStyles: Record<string, any>,
) => {
  const mergedStyles = { ...styles, ...otherStyles };
  if (!styles || !otherStyles) {
    return mergedStyles;
  }
  Object.values(mediaQueries).forEach((mediaQuery) => {
    mergedStyles[`@media ${mediaQuery}`] = {
      ...styles[`@media ${mediaQuery}`],
      ...otherStyles[`@media ${mediaQuery}`],
    };
  });
  return mergedStyles;
};

/**
 * Returns a copy of the component's props but only containing styles.
 * E.g. {"style": ..., "style@md": ..., ...}
 */
export function getStylePropsFromComponent(component: Component) {
  const styles: ComponentStyleProps = {};
  for (const key of mediaSizeStyles) {
    if (component.props[key]) {
      styles[key] = component.props[key];
    }
  }
  return styles;
}

/**
 * Build the styles object for a media size taking into account media size
 * dependencies. Can also ignore base styles as starting point if set.
 */
export function getMediaSizeStyles(
  componentStyleProps: ComponentStyleProps,
  mediaSize: MediaSize,
  ignoreBaseStyles?: boolean,
) {
  const styles = Object.assign(
    {},
    ignoreBaseStyles ? {} : componentStyleProps?.style,
  );
  const allSizes = mediaSizeToDependentSizes[mediaSize].concat(mediaSize);
  for (const size of allSizes) {
    const mediaStyles = componentStyleProps[`style@${size}`];
    if (!mediaStyles) {
      continue;
    }

    for (const key in mediaStyles) {
      styles[key as keyof RuntimeStyleProperties] =
        mediaStyles[key as keyof RuntimeStyleProperties];
    }
  }

  return styles;
}

/**
 * Callback for mapComponentStyleProps
 *
 * @param mergedMediaSizeStyleRules - Merged styles for all media sizes. This is the final
 * styles object which can be passed to JSS to generate a style sheet.
 * e.g. {"backgroundColor": "red"}
 * @param mediaSize - The media size which was resolved (e.g. "sm")
 * @param onlyThisMediaSizeStyleRules - Styles which were directly extracted from this
 * media size and have NOT been merged with any dependent styles. Useful if you want to
 * dynamically add/merge these styles yourself
 */
type MapComponentStylePropsCallback = (
  mergedMediaSizeStyleRules: RuntimeStyleProperties,
  mediaSize: MediaSizeWithDefault,
  onlyThisMediaSizeStyleRules: RuntimeStyleProperties,
) => RuntimeStyleProperties;

type ForEachStylePropsCallback = (
  mergedMediaSizeStyleRules: RuntimeStyleProperties,
  mediaSize: MediaSizeWithDefault,
  onlyThisMediaSizeStyleRules: RuntimeStyleProperties,
) => void;

type MapComponentStylePropsOptions = {
  ignoreBaseStyles?: boolean;
};

type RuntimeStylePropertiesWithMediaQueries = {
  [Query in MediaQuery]: RuntimeStyleProperties;
};

/**
 * Map over component styles props and run a callback function on each media
 * size considering the dependent sizes for each one unless they are set to be
 * ignored. Can also ignore base styles as starting point when calculating
 * styles for media queries.
 */
export function mapComponentStyleProps(
  componentStyleProps: ComponentStyleProps | undefined,
  callbackFn: MapComponentStylePropsCallback,
  opts: MapComponentStylePropsOptions = {},
) {
  if (!componentStyleProps) {
    return {};
  }

  const { ignoreBaseStyles } = opts;
  const styles: ComponentStyleProps = {};

  // Base styles
  if (componentStyleProps.style) {
    styles["style"] = callbackFn(
      componentStyleProps.style,
      "",
      componentStyleProps.style,
    );
  }

  // Media sizes styles
  for (const mediaSize of supportedMediaSizes) {
    const onlyThisMediaSizeStyles =
      componentStyleProps[`style@${mediaSize}`] ?? {};
    const mediaStyles = getMediaSizeStyles(
      componentStyleProps,
      mediaSize,
      ignoreBaseStyles,
    );
    styles[`style@${mediaSize}`] = callbackFn(
      mediaStyles,
      mediaSize,
      onlyThisMediaSizeStyles,
    );
  }

  return styles;
}

export function forEachComponentStyleProps(
  componentStyleProps: ComponentStyleProps | undefined,
  callbackFn: ForEachStylePropsCallback,
) {
  mapComponentStyleProps(
    componentStyleProps,
    (...args) => {
      callbackFn(...args);
      return {};
    },
    {},
  );
}

/**
 * Convert component style props to a style object with media queries
 */
export function convertComponentStylePropsToStyleRules(
  componentStyleProps: ComponentStyleProps | undefined,
): RuntimeStylePropertiesWithMediaQueries {
  if (!componentStyleProps) {
    return {};
  }

  let styles: RuntimeStylePropertiesWithMediaQueries = {};

  // Base styles
  if (componentStyleProps?.style) {
    styles = componentStyleProps.style;
  }

  // Media sizes styles
  for (const mediaSize of supportedMediaSizes) {
    const mediaStyles = componentStyleProps[`style@${mediaSize}`];
    if (mediaStyles) {
      styles[media[mediaSize]] = mediaStyles;
    }
  }

  return styles;
}

/**
 * Map over component style props, run a callback and then convert result
 * into a style object with media queries
 */
export function mapAndConvertComponentStylePropsToStyle(
  componentStyleProps: ComponentStyleProps | undefined,
  callbackFn: (
    mediaSizeStyles: RuntimeStyleProperties,
    mediaSize: MediaSizeWithDefault,
  ) => RuntimeStyleProperties,
): RuntimeStylePropertiesWithMediaQueries {
  if (!componentStyleProps) {
    return {};
  }

  let styles: RuntimeStylePropertiesWithMediaQueries = {};

  // Base styles
  if (componentStyleProps?.style) {
    styles = callbackFn(componentStyleProps.style, "");
  }

  // Media sizes styles
  for (const mediaSize of supportedMediaSizes) {
    const mediaStyles = getMediaSizeStyles(componentStyleProps, mediaSize);
    if (mediaStyles) {
      styles[media[mediaSize]] = callbackFn(mediaStyles, mediaSize);
    }
  }

  return styles;
}

/**
 * Return the proper styles prop for the current media size
 * This function WILL only work correctly in the browser and
 * it's not intended to be used in the publisher
 */
export function getCurrentMediaSizeStyleProps(
  componentStyleProps: ComponentStyleProps,
  extras: {
    matcher: (query: string) => boolean;
    isEditor: boolean;
    canvas: EditorCanvas | null;
  },
) {
  if (
    extras.isEditor &&
    extras.canvas &&
    extras.canvas in editorCanvasToMediaSize
  ) {
    const mediaSizeStyles =
      componentStyleProps[
        `style@${editorCanvasToMediaSize[extras.canvas].mediaSize}`
      ];
    return mediaSizeStyles ?? componentStyleProps["style"];
  }

  for (const mediaSize of supportedMediaSizes) {
    if (extras.matcher(mediaQueries[mediaSize])) {
      const mediaSizeStyles = componentStyleProps[`style@${mediaSize}`];
      return mediaSizeStyles ?? componentStyleProps["style"];
    }
  }
  return componentStyleProps["style"];
}

// NOTE (Evan, 8/25/23) This returns some sensible defaults for height and width
// if the component is empty. Useful for empty containers, etc.
export function getDefaultStyleRulesIfEmpty(
  component: Component,
  styleProps: ComponentStyleProps,
  customSize?: { defaultMinWidth?: number; defaultMinHeight?: number },
) {
  return mapAndConvertComponentStylePropsToStyle(styleProps, (styles) => {
    const hasChildren = (component.children?.length ?? 0) > 0;
    const isGridChild = styles.__parentDisplay === "grid";

    const resolvedStyles: RuntimeStyleProperties = {};

    if (!styles.minWidth) {
      resolvedStyles.minWidth =
        !styles.__hasWidth && !hasChildren && !isGridChild
          ? customSize?.defaultMinWidth ?? "200px"
          : "auto";
    }

    if (!styles.minHeight) {
      resolvedStyles.minHeight =
        !styles.__hasHeight && !hasChildren
          ? customSize?.defaultMinHeight ?? "200px"
          : "auto";
    }

    return resolvedStyles;
  });
}

/**
 * Checks if the url is from cdn.shopify
 */
export function isShopifySizeableImageUrl(imageUrl: string | undefined | null) {
  // if image url is already sized / suspicious to be sized we just ignore them
  if (
    !imageUrl ||
    /\d+x\d+/.test(imageUrl) ||
    imageUrl.includes("width=") ||
    imageUrl.includes("height=")
  ) {
    return false;
  }
  if (imageUrl.includes("cdn.shopify.com") || imageUrl.includes("/cdn/shop/")) {
    return !imageUrl.includes(".svg");
  }
  return false;
}
