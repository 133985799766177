import * as React from "react";

import InlinePopover from "@editor/components/common/designSystem/InlinePopover";
import TipTapToolbar from "@editor/components/TipTapRichTextToolbar";
import TipTap, { useTipTapEditor } from "@editor/components/TiptapTextEditor";
import { naiveRemoveHtmlTags } from "@utils/dom";

const PopupRichTextEditor = (props: {
  value: string | undefined;
  id: string;
  onChange: (value: string) => void;
}) => {
  const tiptapEditor = useTipTapEditor(props.value || "", props.onChange);

  return (
    <InlinePopover
      shouldPreventDefaultOnInteractOutside={false}
      side="left"
      triggerAsChild
      content={
        <div className="flex flex-col justify-between p-3 py-4">
          <div className="py-2">
            <TipTapToolbar editor={tiptapEditor} />
          </div>
          <div className="modifier justify-self-auto bg-slate-50 p-2 text-sm">
            <TipTap editor={tiptapEditor} />
          </div>
        </div>
      }
    >
      <div className="bg-gray-100 p-2 min-w-[150px] min-h-[40px] whitespace-nowrap overflow-hidden text-ellipsis bg-clip-content">
        {naiveRemoveHtmlTags(props.value ?? "", { tagCount: 2 })}
      </div>
    </InlinePopover>
  );
};

export default PopupRichTextEditor;
