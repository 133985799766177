import type { ExperimentStatus } from "schemas/experiment";
import type { Experiment } from "schemas/generated/experiment";
import type { Variation } from "schemas/generated/variation";

import * as React from "react";

import DeleteConfirmationModal from "@editor/components/common/DeleteConfirmationModal";
import Modal from "@editor/components/common/designSystem/Modal";
import {
  RadioGroup,
  RadioGroupItem,
} from "@editor/components/common/designSystem/RadioGroup";
import { successToast } from "@editor/components/common/designSystem/Toast";
import { useExperimentApi } from "@editor/components/projectDashboard/experiments/common";
import { useSubscriptionInfo } from "@editor/hooks/subscription";
import { useLogAnalytics } from "@editor/hooks/useLogAnalytics";
import { useModal } from "@editor/hooks/useModal";
import { routes } from "@editor/utils/router";
import { trpc } from "@editor/utils/trpc";

import Button from "@replo/design-system/components/button";
import IconButton from "@replo/design-system/components/button/IconButton";
import copy from "copy-to-clipboard";
import { BiCopy, BiTrash } from "react-icons/bi";
import { BsPlayFill, BsStopCircle } from "react-icons/bs";
import { generatePath, useNavigate } from "react-router-dom";
import { BillingTiers } from "schemas/billing";
import { getExperimentStatus } from "schemas/experiment";

type ExperimentActionsBarProps = {
  workspaceId: string;
  experiment: Experiment;
  isEditingExperiment?: boolean;
};

export const ExperimentActionsBar: React.FC<ExperimentActionsBarProps> = ({
  workspaceId,
  experiment,
  isEditingExperiment = false,
}) => {
  const experimentId = experiment.id;
  const logEvent = useLogAnalytics();
  const { subscriptionInfo } = useSubscriptionInfo();
  const subscriptionTier = subscriptionInfo?.tier || BillingTiers.FREE;
  const navigate = useNavigate();

  const status = getExperimentStatus(experiment);

  const allowActivate = status === "draft";
  const allowComplete = status === "active";

  const { data: planAllowsActivation } = trpc.experiment.canActivate.useQuery({
    workspaceId,
  });

  const { update: updateExperiment, remove: removeExperiment } =
    useExperimentApi({
      workspaceId,
      projectId: null,
    });

  const update = updateExperiment.mutateAsync;
  const isUpdating = updateExperiment.isPending;
  const remove = removeExperiment.mutateAsync;
  const isRemoving = removeExperiment.isPending;

  const [isCompletionModalOpen, setCompletionModalOpen] = React.useState(false);
  const [isDeletionConfirmationOpen, setDeletionConfirmationOpen] =
    React.useState(false);

  const disableControls = isRemoving || isUpdating;

  const modal = useModal();
  const openBillingModal = () =>
    modal.openModal({
      type: "billingModal",
      props: {
        source: "activate.experiments",
        billingPlanMessageKey: "billingPlan.experimentActivationAttempt",
      },
    });

  const updateWithStatus = async (status: ExperimentStatus) => {
    await update({
      ...experiment,
      status,
      variations: experiment.variations.map((v) => ({ ...v, isWinner: false })),
      analyticsLink: experiment.analyticsLinkId
        ? {
            type: "chooseLink",
            value: experiment.analyticsLinkId,
          }
        : undefined,
    });
    logEvent("experiment.updated", { billingPlanTier: subscriptionTier });
  };

  // Action handlers
  const onActivate = async () => {
    if (planAllowsActivation) {
      await updateWithStatus("active");
      successToast("Experiment Activated", "");
      logEvent("experiment.activated", { billingPlanTier: subscriptionTier });
    } else {
      openBillingModal();
    }
  };

  const onComplete = () => setCompletionModalOpen(true);
  const onCompleteCancel = () => setCompletionModalOpen(false);
  const onCompleteConfirm = async (winnerId: string) => {
    const analyticsLinkId = experiment.analyticsLinkId;
    await update({
      ...experiment,
      status: "complete",
      variations: experiment.variations.map((variation) => ({
        ...variation,
        isWinner: variation.id === winnerId,
      })),
      analyticsLink: analyticsLinkId
        ? { type: "chooseLink", value: analyticsLinkId }
        : undefined,
    });
    logEvent("experiment.completed", { billingPlanTier: subscriptionTier });
    successToast("Experiment Completed 🥳", "");
  };

  const onDeleteButtonClick = () => setDeletionConfirmationOpen(true);
  const onDeleteConfirmation = async () => {
    await remove({
      experimentId,
      workspaceId,
    });
    logEvent("experiment.deleted", { billingPlanTier: subscriptionTier });
    successToast("Experiment Deleted", "");
    navigate(generatePath(routes.workspace.experiments.list, { workspaceId }));
  };
  const onDeleteCancel = () => setDeletionConfirmationOpen(false);

  const disableActivateErrors = [];

  if (isEditingExperiment) {
    disableActivateErrors.push(
      "Save your current changes in order to start the experiment.",
    );
  }

  if (!experiment.analyticsLinkId) {
    disableActivateErrors.push(
      "An experiment link must be provided to activate your experiment.",
    );
  }

  const hasDisableActivateErrors = disableActivateErrors.length > 0;

  const disableDeleteErrors = [];

  if (status === "active") {
    disableDeleteErrors.push(
      "Please complete your experiment in order to delete it.",
    );
  }

  const hasDisableDeleteErrors = disableDeleteErrors.length > 0;

  return (
    <div>
      {isDeletionConfirmationOpen && (
        <DeleteConfirmationModal
          assetName={experiment.name}
          assetTypeDisplayName="Experiment"
          confirmationType="delete"
          extraMessage={
            <span className="font-medium">
              It will no longer serve traffic.
            </span>
          }
          onDelete={() => void onDeleteConfirmation()}
          onRequestClose={onDeleteCancel}
        />
      )}
      <CompleteModal
        variations={experiment.variations}
        onCancel={onCompleteCancel}
        onConfirm={(id) => void onCompleteConfirm(id)}
        isOpen={isCompletionModalOpen}
      />

      <div className="flex-none flex items-center content-center justify-between gap-x-3">
        <Button
          variant="secondary"
          size="base"
          onClick={() => void onDeleteButtonClick()}
          tooltipText={
            hasDisableDeleteErrors ? disableDeleteErrors[0] : undefined
          }
          isDisabled={disableControls || hasDisableDeleteErrors}
          icon={<BiTrash size={16} />}
        />
        {allowActivate && (
          <Button
            variant="primary"
            size="base"
            tooltipText={
              hasDisableActivateErrors ? disableActivateErrors[0] : undefined
            }
            onClick={() => void onActivate()}
            isDisabled={disableControls || hasDisableActivateErrors}
            endEnhancer={<BsPlayFill size={12} />}
            collisionPadding={10}
          >
            Start Experiment
          </Button>
        )}
        {allowComplete && (
          <Button
            variant="secondary"
            size="base"
            onClick={() => void onComplete()}
            isLoading={disableControls}
            endEnhancer={<BsStopCircle size={12} />}
          >
            End Experiment
          </Button>
        )}
      </div>
    </div>
  );
};

// Modal Components
type CompleteModalProps = {
  variations: Array<Variation>;
  isOpen: boolean;
  onConfirm: (id: string) => void;
  onCancel: () => void;
};

export const CompleteModal: React.FC<CompleteModalProps> = ({
  variations,
  isOpen,
  onConfirm,
  onCancel,
}) => {
  const [selection, setSelection] = React.useState(variations[0]?.id);

  return (
    <Modal isOpen={isOpen} className="max-w-4xl" onRequestClose={onCancel}>
      <div className="flex flex-col items-stretch justify-between rounded-lg bg-white p-3 text-default space-y-3 w-full">
        <h1 className="text-lg font-medium text-default">
          Choose a winning variation to complete this experiment
        </h1>
        <p>
          The experiment URL will direct all traffic to the winning variation.
        </p>
        <div className="flex flex-row">
          <div className="text-sm grow">
            <div className="p-4 mt-2 rounded border border-slate-300">
              <div className="grid grid-cols-12 gap-4 h-8 items-center content-center font-semibold">
                <div className="col-span-1"></div>
                <div className="col-span-2">Variation</div>
                <div className="col-span-7">URL</div>
                <div className="col-span-2">Percent</div>
              </div>
              <div className="space-y-1">
                <RadioGroup onValueChange={setSelection}>
                  {variations.map((variation) => (
                    <SelectableVariation
                      key={variation.id}
                      variation={variation}
                      isSelected={selection === variation.id}
                    />
                  ))}
                </RadioGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 flex flex-row items-center content-center flex-grow w-full justify-between">
          <Button
            variant="tertiary"
            size="lg"
            className="mt-4"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="lg"
            className="mt-4"
            type="button"
            onClick={() => onConfirm(selection ?? "")}
          >
            Complete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type SelectableVariationProps = {
  variation: Variation;
  isSelected: boolean;
};

const SelectableVariation: React.FC<SelectableVariationProps> = ({
  variation: { id, slug, target, allocationPercent },
  isSelected,
}) => {
  return (
    <div className="grid grid-cols-12 gap-4 h-8 items-center content-center">
      <div className="col-span-1">
        <RadioGroupItem isSelected={isSelected} value={id} />
      </div>
      <div className="col-span-2">
        <div className="rounded py-1 w-full">{slug}</div>
      </div>
      <div className="col-span-7 flex items-center content-center">
        <div className="rounded py-1 w-full truncate">{target}</div>
        <IconButton
          tooltipText="Copy variation URL"
          variant="tertiary"
          className="h-7 w-7"
          icon={<BiCopy size={16} />}
          onClick={() => {
            copy(target);
            successToast(
              "URL Copied",
              "Variation target URL copied successfully! 🎉 ",
            );
          }}
        />
      </div>
      <div className="col-span-2">
        <div className="rounded py-1 w-full">{allocationPercent}%</div>
      </div>
    </div>
  );
};
