import type { ObjectFitType } from "@editor/types/object-fit";
import type { AssetLoadingType } from "replo-runtime/shared/asset-loading";

import * as React from "react";

import ErrorMessage from "@editor/components/account/Dashboard/ErrorMessage";
import useApplyComponentAction from "@editor/hooks/useApplyComponentAction";
import { checkIfNewEditorPanelsUIIsEnabled } from "@editor/infra/featureFlags";
import {
  selectDraftComponentContext,
  selectDraftComponentId,
  selectImageSource,
  selectImageSourceComponentProps,
  selectObjectFit,
  selectObjectPosition,
  selectPropLoading,
  selectPropSrc,
} from "@editor/reducers/core-reducer";
import { selectEditorMediaUploadingComponentIds } from "@editor/reducers/editor-media-upload-reducer";
import { useEditorSelector, useEditorStore } from "@editor/store";
import { getImageSourceComponentActions } from "@editor/utils/getImageSourceComponentActions";
import { isImageSourceValid } from "@editor/utils/image";
import { styleAttributeToEditorData } from "@editor/utils/styleAttribute";
import ImageSourceSelector from "@editorComponents/ImageSourceSelector";
import ModifierGroup from "@editorExtras/ModifierGroup";

import { selectActiveCanvas } from "@/features/canvas/canvas-reducer";
import { coerceNumberToString } from "replo-utils/lib/misc";

import ModifierLabel from "../extras/ModifierLabel";
import LengthInputModifier from "./LengthInputModifier";

const ImageSourceModifier = () => {
  const store = useEditorStore();
  const activeCanvas = useEditorSelector(selectActiveCanvas);
  const draftComponentId = useEditorSelector(selectDraftComponentId);
  const draftComponentContext = useEditorSelector(selectDraftComponentContext);
  const objectFit = useEditorSelector(selectObjectFit);
  const imageSource = useEditorSelector(selectImageSource);
  const rawObjectPosition = useEditorSelector(selectObjectPosition);
  const propSrc = useEditorSelector(selectPropSrc);
  const propLoading = useEditorSelector(selectPropLoading);
  const editorMediaUploadingComponentIds = useEditorSelector(
    selectEditorMediaUploadingComponentIds,
  );
  const applyComponentAction = useApplyComponentAction();

  if (!draftComponentId) {
    return null;
  }

  const shouldOpenModifierWhenEmpty =
    !editorMediaUploadingComponentIds.includes(draftComponentId);

  const src = imageSource || propSrc;
  const isValidSrc = isImageSourceValid(src);

  const loading = propLoading ?? "eager";
  const objectPosition = coerceNumberToString(rawObjectPosition)?.split(" ");

  const onChangeImageSource = (value: string) => {
    const { imageComponentId, imageSourceProp, imageUrl } =
      selectImageSourceComponentProps(store.getState(), value);
    const actions = getImageSourceComponentActions({
      canvas: activeCanvas,
      draftComponentId,
      imageComponentId,
      imageSourceProp,
      imageUrl,
    });

    applyComponentAction({
      type: "applyCompositeAction",
      value: actions,
    });
  };

  const isNewRightBarEnabled = checkIfNewEditorPanelsUIIsEnabled();

  return (
    <ModifierGroup title="Image">
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col gap-1">
          <div
            data-testid="image-source-modifier"
            className="flex items-center"
          >
            {isNewRightBarEnabled && <ModifierLabel label="URL" />}
            <ImageSourceSelector
              size="sm"
              src={src}
              objectFitValue={
                // Note (Noah, 2024-03-06): Cast is fine here since we never allow selecting any
                // object fit value other than those in ObjectFitType
                (objectFit ??
                  styleAttributeToEditorData.objectFit
                    .defaultValue) as ObjectFitType
              }
              assetLoadingValue={loading}
              objectPositionValues={{
                x: objectPosition?.[0],
                y: objectPosition?.[1],
              }}
              componentContext={draftComponentContext ?? undefined}
              onChangeImageSource={onChangeImageSource}
              onChangeObjectFit={(value: string) => {
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    objectFit: value,
                  },
                });
              }}
              onChangeAssetLoading={(value: AssetLoadingType) => {
                applyComponentAction({
                  type: "setProps",
                  value: {
                    loading: value,
                  },
                });
              }}
              onChangeObjectPositionX={(value: string) => {
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    objectPosition: `${value} ${objectPosition?.[1] ?? "center"}`,
                  },
                });
              }}
              onChangeObjectPositionY={(value: string) => {
                applyComponentAction({
                  type: "setStyles",
                  value: {
                    objectPosition: `${objectPosition?.[0] ?? "center"} ${value}`,
                  },
                });
              }}
              onRemove={() => {
                applyComponentAction({
                  type: "applyCompositeAction",
                  value: [
                    {
                      type: "setStyles",
                      value: { __imageSource: null },
                    },
                    {
                      type: "deleteProps",
                      propName: "src",
                      analyticsExtras: {
                        actionType: "edit",
                        createdBy: "replo",
                      },
                    },
                  ],
                });
              }}
              componentId={draftComponentId ?? undefined}
              openPopoverWhenEmpty={shouldOpenModifierWhenEmpty}
            />
          </div>
          {!isValidSrc ? (
            <ErrorMessage error="Please enter a valid image source" />
          ) : null}
        </div>
        {isNewRightBarEnabled && <ZoomControl />}
      </div>
    </ModifierGroup>
  );
};

const ZoomControl: React.FC = () => {
  const applyComponentAction = useApplyComponentAction();
  const zoomDefaultValue = styleAttributeToEditorData.__zoom.defaultValue;

  return (
    <div className="flex w-full">
      <LengthInputModifier
        label={<ModifierLabel label="Zoom" />}
        placeholder={zoomDefaultValue}
        anchorValue={zoomDefaultValue}
        resetValue={zoomDefaultValue}
        dragTrigger="label"
        metrics={["%"]}
        field="style.__zoom"
        onChange={(newValue) => {
          applyComponentAction({
            type: "setStyles",
            value: { __zoom: newValue },
          });
        }}
        className="w-full"
        menuOptions={["Reset", "100%", "110%", "120%"].map((option) => ({
          label: option,
          value: option === "Reset" ? zoomDefaultValue : option,
        }))}
        minValues={{ "%": 1 }}
        minDragValues={{ "%": 1 }}
      />
    </div>
  );
};

export default ImageSourceModifier;
