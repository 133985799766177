import * as React from "react";

import { selectFontFamily } from "@editor/reducers/core-reducer";
import { useEditorSelector } from "@editor/store";
import ModifierGroup from "@editorExtras/ModifierGroup";
import { FontFamilyControl } from "@editorModifiers/TextStyleModifier";

import { isNotNullish } from "replo-utils/lib/misc";

// TODO (Sebas, 2024-10-16): This component will be removed once the feature flag for the
// new right bar is enabled.
const FontModifier: React.FC<React.PropsWithChildren<unknown>> = () => {
  const fontFamily = useEditorSelector(selectFontFamily);
  return (
    <ModifierGroup title="Font" isDefaultOpen={isNotNullish(fontFamily)}>
      <FontFamilyControl className="w-40" />
    </ModifierGroup>
  );
};

export default FontModifier;
