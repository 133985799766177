import type {
  AnalyticsLinkForExperimentUpdate,
  AnalyticsLinkWithIds,
  ConstructedAnalyticsLink,
} from "schemas/generated/analyticsLink";

import * as React from "react";

import Input from "@common/designSystem/Input";
import ToggleGroup from "@common/designSystem/ToggleGroup";
import { useOverridableInput } from "@editor/components/common/designSystem/hooks/useOverridableInput";

import { DetailsContainer } from "@/features/experiments/components/DetailsContainer";
import Button from "@replo/design-system/components/button";
import { Combobox } from "@replo/design-system/components/shadcn/combobox/Combobox";
import Tooltip from "@replo/design-system/components/tooltip";
import copy from "copy-to-clipboard";
import { BsCaretDownFill, BsInfoCircle, BsLockFill } from "react-icons/bs";
import { DEFAULT_DOMAIN } from "schemas/analyticsLink";
import { twMerge } from "tailwind-merge";

import {
  CHOOSE_LINK_OPTION,
  CREATE_NEW_LINK_OPTION,
  SUBSECTION_OPTIONS,
} from "./foreverLinkOptions";

type Option = { value: string; label: string };

type SubSectionType = "domain" | "shortName" | "slug";

type SubSectionProps = {
  title: string;
  className?: string;
  type: SubSectionType;
  value: string;
  options?: Option[];
  helpTooltipText: string;
  setValue: (value: string) => void;
  onBlur?: (value: string) => void;
  onEnter?: (value: string) => void;
};
const SubSection: React.FC<SubSectionProps> = ({
  title,
  className,
  type,
  value,
  options,
  helpTooltipText,
  setValue,
  onBlur,
  onEnter,
}) => {
  const { value: localValue, onChange: handleChange } = useOverridableInput({
    value,
    onValueChange: setValue,
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnter) {
      onEnter(localValue);
    }
  };

  return (
    <div className={twMerge(className, "flex flex-col gap-1")}>
      <div className="flex flex-row gap-1 items-center">
        <span className="text-xs font-semibold text-muted">{title}</span>
        <span className="text-danger">*</span>
        <Tooltip
          content={helpTooltipText}
          triggerAsChild
          side="top"
          delay={300}
        >
          <div>
            {" "}
            <BsInfoCircle size={12} className="text-muted" />{" "}
          </div>
        </Tooltip>
      </div>
      {type !== "slug" && options ? (
        <Combobox
          options={options}
          value={value}
          onChange={setValue}
          className="w-full"
          placeholder={`Select ${title}`}
          endEnhancer={() => (
            <BsCaretDownFill className="h-2 w-2 text-subtle" />
          )}
        />
      ) : (
        <Input
          size="base"
          placeholder={`Enter ${title}`}
          value={localValue}
          onChange={handleChange}
          onBlur={() => onBlur?.(localValue)}
          onKeyDown={handleKeyDown}
          unsafe_inputClassName="text-xs"
        />
      )}
    </div>
  );
};

type CreateNewLinkProps = {
  analyticsLinkWithIds: AnalyticsLinkWithIds;
  handleCreateNewLinkChange: (
    analyticsLinkWithIds: AnalyticsLinkWithIds,
  ) => void;
  domainOptions: Option[];
  shortNameOptions: Option[];
};

const CreateNewLink: React.FC<CreateNewLinkProps> = ({
  analyticsLinkWithIds,
  handleCreateNewLinkChange,
  domainOptions,
  shortNameOptions,
}) => {
  const onAnalyticsLinkPropertyChange = (
    changes: Partial<AnalyticsLinkWithIds>,
  ) => {
    handleCreateNewLinkChange({
      ...analyticsLinkWithIds,
      ...changes,
    });
  };

  const handleDomainChange = (value: string) => {
    let changes: Partial<AnalyticsLinkWithIds> = { customDomainId: value };

    if (value !== DEFAULT_DOMAIN) {
      changes = { ...changes, shortNameId: null };
    }
    onAnalyticsLinkPropertyChange(changes);
  };

  const handleShortNameChange = (value: string) => {
    onAnalyticsLinkPropertyChange({ shortNameId: value });
  };

  const handleSlugChange = (value: string) => {
    onAnalyticsLinkPropertyChange({ path: value });
  };

  const isShortNameFieldRequired =
    !analyticsLinkWithIds.customDomainId ||
    analyticsLinkWithIds.customDomainId === DEFAULT_DOMAIN;

  return (
    <div className="border border-slate-300 rounded-md p-4 grid grid-cols-12 gap-3">
      <SubSection
        title="Domain"
        className="col-span-4"
        type="domain"
        value={analyticsLinkWithIds.customDomainId ?? ""}
        options={domainOptions}
        setValue={handleDomainChange}
        helpTooltipText="Use our default reploedge domain, or use your own custom domain"
      />
      {isShortNameFieldRequired && (
        <SubSection
          title="Group Name"
          className="col-span-4"
          type="shortName"
          value={analyticsLinkWithIds.shortNameId ?? ""}
          options={shortNameOptions}
          setValue={handleShortNameChange}
          helpTooltipText="A unique name identifying your workspace, to distinguish it from others in the Replo system. Only required when using reploedge.com as your domain"
        />
      )}
      <SubSection
        title="Slug"
        className="col-span-4"
        type="slug"
        value={analyticsLinkWithIds.path ?? ""}
        setValue={handleSlugChange}
        helpTooltipText="The URL path that distinguishes this link from the other links in your workspace"
      />
    </div>
  );
};

const ChooseLinkOption: React.FC<{
  url: string;
  isActive: boolean;
  analyticsLinkId: string;
  handleClick: (analyticsLinkId: string) => void;
}> = ({ url, isActive, analyticsLinkId, handleClick }) => {
  return (
    <div
      className="flex flex-row gap-2 items-center"
      onClick={() => handleClick(analyticsLinkId)}
    >
      <span>{url}</span>
      {isActive && <span className="h-1 w-1 bg-green-600 rounded-full" />}
    </div>
  );
};

type ChooseLinkProps = {
  analyticsLinkId: string | null;
  handleChooseLinkChange: (analyticsLinkId: string) => void;
  links: ConstructedAnalyticsLink[];
  isEditable?: boolean;
};
const ChooseLink: React.FC<ChooseLinkProps> = ({
  analyticsLinkId,
  handleChooseLinkChange,
  links,
  isEditable = true,
}) => {
  const [isComboboxOpen, setIsComboboxOpen] = React.useState(false);

  const handleOptionClick = (analyticsLinkId: string) => {
    handleChooseLinkChange(analyticsLinkId);
    setIsComboboxOpen(false);
  };

  return (
    <Combobox
      open={isComboboxOpen}
      onOpenChange={setIsComboboxOpen}
      options={
        links.map((constructedLink) => {
          const { id, url, isActive } = constructedLink;
          return {
            value: id,
            label: url,
            component: (
              <ChooseLinkOption
                url={url}
                isActive={isActive}
                analyticsLinkId={id}
                handleClick={handleOptionClick}
              />
            ),
            isDisabled: isActive,
          };
        }) ?? []
      }
      placeholder="Select Link"
      value={analyticsLinkId ?? undefined}
      className="w-full"
      endEnhancer={() =>
        isEditable ? <BsCaretDownFill size={8} /> : <BsLockFill size={12} />
      }
      isDisabled={!isEditable}
    />
  );
};

type LinkSectionProps = {
  analyticsLink: AnalyticsLinkForExperimentUpdate;
  onAnalyticsLinkChange: (
    analyticsLink: AnalyticsLinkForExperimentUpdate,
  ) => void;
  isEditable?: boolean;
  domainOptions: Option[];
  shortNameOptions: Option[];
  handleLinkSubsectionChange: (value: string) => void;
  links: ConstructedAnalyticsLink[];
};
export const LinkSection: React.FC<LinkSectionProps> = ({
  analyticsLink,
  onAnalyticsLinkChange,
  isEditable = true,
  domainOptions,
  shortNameOptions,
  handleLinkSubsectionChange,
  links,
}) => {
  const handleCreateNewLinkChange = (
    analyticsLinkWithIds: AnalyticsLinkWithIds,
  ) => {
    const newLink = { ...analyticsLinkWithIds };

    if (analyticsLinkWithIds.customDomainId === DEFAULT_DOMAIN) {
      newLink.shortNameId = shortNameOptions[0]?.value ?? null;
    }

    onAnalyticsLinkChange({
      type: "createNewLink",
      value: newLink,
    });
  };

  const handleChooseLinkChange = (analyticsLinkId: string) => {
    onAnalyticsLinkChange({
      type: "chooseLink",
      value: analyticsLinkId,
    });
  };

  const getSelectedLinkUrl = (): string | undefined => {
    if (!links || analyticsLink?.type !== "chooseLink") {
      return undefined;
    }

    const selectedLink = links.find((link) => link.id === analyticsLink.value);
    return selectedLink?.url;
  };

  const selectedSubSection =
    analyticsLink.type === "chooseLink"
      ? CHOOSE_LINK_OPTION.value
      : CREATE_NEW_LINK_OPTION.value;

  return (
    <DetailsContainer
      title="Your Experiment Link"
      isRequired
      headerComponent={
        isEditable ? (
          <ToggleGroup
            allowsDeselect={false}
            type="single"
            options={SUBSECTION_OPTIONS}
            value={selectedSubSection}
            onChange={handleLinkSubsectionChange}
            className="max-w-[300px]"
            style={{ width: "100%" }}
          />
        ) : undefined
      }
    >
      <div className="flex flex-col gap-3">
        {analyticsLink.type === "chooseLink" ? (
          <ChooseLink
            analyticsLinkId={analyticsLink.value}
            handleChooseLinkChange={handleChooseLinkChange}
            links={links ?? []}
            isEditable={isEditable}
          />
        ) : (
          <CreateNewLink
            analyticsLinkWithIds={analyticsLink.value}
            handleCreateNewLinkChange={handleCreateNewLinkChange}
            domainOptions={domainOptions}
            shortNameOptions={shortNameOptions}
          />
        )}
        {!isEditable && (
          <div className="flex flex-row justify-end">
            <div className="flex flex-row gap-2">
              <Button
                variant="tertiary"
                size="base"
                textClassNames="text-blue-600"
                onClick={() => {
                  const url = getSelectedLinkUrl();
                  if (url) {
                    copy(url);
                  }
                }}
              >
                Copy Link
              </Button>
              <Button
                variant="secondary"
                size="base"
                href={getSelectedLinkUrl() ?? ""}
              >
                Preview
              </Button>
            </div>
          </div>
        )}
      </div>
    </DetailsContainer>
  );
};
