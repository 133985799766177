import * as React from "react";

import { BsArrowLeft } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { getCategoryNameFromSlug } from "replo-runtime/shared/componentTemplates";
import { twMerge } from "tailwind-merge";

const BackButton: React.FC<{
  collectionName?: string;
  classname?: string;
}> = ({ collectionName, classname }) => {
  const navigate = useNavigate();
  const backButtonLabel = useBackButtonLabel({ collectionName });

  const onBack = () => {
    navigate(-1);
  };

  return (
    <button
      className={twMerge(
        "flex items-center gap-1 font-medium text-lg text-blue-600",
        classname,
      )}
      onClick={onBack}
    >
      <BsArrowLeft size={18} />
      {backButtonLabel}
    </button>
  );
};

const useBackButtonLabel = ({
  collectionName,
}: {
  collectionName?: string;
}) => {
  const { state } = useLocation();

  const fromPath = state?.fromPath;

  const searchString = state?.searchString;
  const categorySlug = state?.categorySlug;
  const componentTemplateName = state?.componentTemplateName;

  if (fromPath?.includes("details") && componentTemplateName) {
    return `Back to "${componentTemplateName}" Template`;
  }

  if (fromPath?.includes("collection") && collectionName) {
    return `Back "${collectionName}" Collection`;
  }

  if (categorySlug) {
    return getCategoryNameFromSlug(categorySlug);
  }

  if (searchString) {
    return `Back to "${searchString}" Search`;
  }

  return "Back";
};

export default BackButton;
